import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { decisionMatrixData } from './mockup-data/descision-matrix';

@Injectable({
  providedIn: 'root'
})
export class VCallApiService {

  VCallBaseURL = environment.serverUrls.vCallExportUrl

constructor(
  private http: HttpClient,
  private store: Store
  ) { }

  public getFullApplication(
    applicationId
  ): Observable<any> {
    return this.http.get(
      `${this.VCallBaseURL}/${applicationId}`
    );
  }

}
