import { Injectable } from '@angular/core';

interface ChipDetail {
  imagePath: string,
  statusName: string,
  statusId: number | null,
  label: string
}

@Injectable({
  providedIn: 'root'
})
export class ChipFilterService {

  chipList: ChipDetail[] = [
    // myqueue tab -> applications status
    {
      statusId: null,
      statusName: "APS Order Queue",
      imagePath: "assets/status-pill-images/Status_Icons/Application_Status/APS_Order_Queue_Default.svg",
      label: "APS Order Queue"
    },
    {
      statusId: null,
      statusName: "APS OrderQueue",
      imagePath: "assets/status-pill-images/Status_Icons/Application_Status/APS_Order_Queue_Default.svg",
      label: "APS Order Queue"
    },
    {
      statusId: null,
      statusName: "APS Pending",
      imagePath: "assets/status-pill-images/Status_Icons/Application_Status/APS_Order_APS_Pending.svg",
      label: "APS Pending"
    },
    // myqueue tab -> requests tab
    ,
    {
      statusId: 0,
      statusName: "0",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/Order_Status_Open.svg",
      label: "Open"
    },
    {
      statusId: 3,
      statusName: "3",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/Order_Status_Pending.svg",
      label: "Requested"
    },
    {
      statusId: 5,
      statusName: "5",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/completed.svg",
      label: "Completed"
    },
    {
      statusId: 4,
      statusName: "4",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/Closed.svg",
      label: "Closed"
    },
    {
      statusId: 7,
      statusName: "7",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/Appointment_Met.svg",
      label: "With Examone"
    },
    {
      statusId: 6,
      statusName: "6",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/Order_Status_Canceled.svg",
      label: "Cancelled"
    },

    // work queue tab Order Level:Requested = 0,Inprogress = 1,Created = 2,Pending = 3,Closed = 4,Completed = 5,Cancelled = 6,WithDrawal = 7 (Appointment Met),ReOpen = 8, ->
    ,
    {
      statusId: 0,
      statusName: "APS",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Status/APS_Open.svg",
      label: "Requested"
    }
    ,
    {
      statusId: 1,
      statusName: "APS",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Status/svg",
      label: "Not Started"
    }
    ,
    {
      statusId: 2,
      statusName: "APS",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Status/APS_Completed.svg",
      label: "Order Completed"
    }
    ,
    {
      statusId: 3,
      statusName: "APS",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Status/APS_Pending.svg",
      label: "Order Queue"
    }
    ,
    {
      statusId: 4,
      statusName: "APS",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Status/APS_Pending.svg",
      label: "Pending"
    },
    {
      statusId: 5,
      statusName: "APS",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Status/APS_Completed.svg",
      label: "Completed"
    }
    ,
    {
      statusId: 6,
      statusName: "APS",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/Order_Status_Canceled.svg",
      label: "Cancelled"
    },
    {
      statusId: 12,
      statusName: "APS",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Status/APS_Pending.svg",
      label: "APS Pending Followup"
    },

    // work queue Lab_OE
    ,
    {
      statusId: 0,
      statusName: "LAB_OE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_OE/Open.svg",
      label: "Requested"
    }
    ,
    {
      statusId: 1,
      statusName: "LAB_OE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_OE/Pending.svg",
      label: "Not Started"
    }
    ,
    {
      statusId: 2,
      statusName: "LAB_OE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_OE/Completed.svg",
      label: "Order Completed"
    }
    ,
    {
      statusId: 3,
      statusName: "LAB_OE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_OE/Pending.svg",
      label: "Order Queue"
    }
    ,
    {
      statusId: 4,
      statusName: "LAB_OE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_OE/Pending.svg",
      label: "Pending"
    }
    ,
    ,
    {
      statusId: 5,
      statusName: "LAB_OE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_OE/Completed.svg",
      label: "Completed"
    }
    ,
    {
      statusId: 6,
      statusName: "LAB_OE",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/Order_Status_Canceled.svg",
      label: "Cancelled"
    },
    {
      statusId: 12,
      statusName: "LAB_OE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_OE/Pending.svg",
      label: "APS Pending Followup"
    }
    // Lab_AE
    ,
    {
      statusId: 0,
      statusName: "LAB_AE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_AE/Open.svg",
      label: "Requested"
    }
    ,
    {
      statusId: 1,
      statusName: "LAB_AE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_AE/Pending.svg",
      label: "Not Started"
    }
    ,
    {
      statusId: 2,
      statusName: "LAB_AE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_AE/Completed.svg",
      label: "Order Completed"
    }
    ,
    {
      statusId: 3,
      statusName: "LAB_AE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_AE/Pending.svg",
      label: "Order Queue"
    }
    ,
    {
      statusId: 4,
      statusName: "LAB_AE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_AE/Pending.svg",
      label: "Pending"
    }
    ,
    ,
    {
      statusId: 5,
      statusName: "LAB_AE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_AE/Completed.svg",
      label: "Pending"
    }
    ,
    {
      statusId: 6,
      statusName: "LAB_AE",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Order_Status/Order_Status_Canceled.svg",
      label: "Cancelled"
    },
    {
      statusId: 12,
      statusName: "LAB_AE",
      imagePath: "assets/status-pill-images/Status_Icons/Lab_AE/Pending.svg",
      label: "APS Pending Followup"
    }
    ,

    // HTWTBP pills
    {
      statusId: 0,
      statusName: "LAB_AE_HTWTBP",
      imagePath: "assets/status-pill-images/Status_Icons/HTWTBP/HTWTBP_Open.svg",
      label: "Requested"
    },
    {
      statusId: 2,
      statusName: "LAB_AE_HTWTBP",
      imagePath: "assets/status-pill-images/Status_Icons/HTWTBP/HTWTBP_Completed.svg",
      label: "Created"
    },
    {
      statusId: 3,
      statusName: "LAB_AE_HTWTBP",
      imagePath: "assets/status-pill-images/Status_Icons/HTWTBP/HTWTBP_Pending.svg",
      label: "Pending"
    },
    {
      statusId: 4,
      statusName: "LAB_AE_HTWTBP",
      imagePath: "assets/status-pill-images/Status_Icons/HTWTBP/HTWTBP_Close_out.svg",
      label: "CloseOut"
    },
    {
      statusId: 5,
      statusName: "LAB_AE_HTWTBP",
      imagePath: "assets/status-pill-images/Status_Icons/HTWTBP/HTWTBP_Completed.svg",
      label: "Completed"
    },
    {
      statusId: 6,
      statusName: "LAB_AE_HTWTBP",
      imagePath: "assets/status-pill-images/Status_Icons/HTWTBP/HTWTBP_Completed.svg",
      label: "Completed"
    }
    // NBC -> application stats -> kanbanboard

    ,
    {
      statusId: null,
      statusName: "VCall - Not Scheduled",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/VCall_Not_Scheduled.svg",
      label: "VCall - Not Scheduled"
    }
    ,
    {
      statusId: null,
      statusName: "App - Incomplete",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/App_Incomplete.svg",
      label: "App - Incomplete"
    }
    ,
    {
      statusId: null,
      statusName: "To Do",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/To_Do.svg",
      label: "To Do"
    }
    ,
    {
      statusId: null,
      statusName: "Stop Call",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/Stop_Call.svg",
      label: "Stop Call"
    }
    ,
    {
      statusId: null,
      statusName: "Pending Recall",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/Pending_Recall.svg",
      label: "Pending Recall"
    }
    ,
    {
      statusId: null,
      statusName: "APS_Pending",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/APS_Pending.svg",
      label: "APS Pending"
    }
    ,
    {
      statusId: null,
      statusName: "With_Underwriting",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/With_Underwriting.svg",
      label: "With Underwriting"
    }
    ,
    {
      statusId: null,
      statusName: "On Hold",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/On_Hold.svg",
      label: "On Hold"
    }
    ,
    {
      statusId: null,
      statusName: "With APS",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/With_APS.svg",
      label: "With APS"
    }
    ,
    {
      statusId: null,
      statusName: "With Issue Team",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/With_Issue_Team.svg",
      label: "With Issue Team"
    },
    {
      statusId: null,
      statusName: "APS_OrderQueue",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/APS_Order_Queue.svg",
      label: "APS OrderQueue"
    },
    {
      statusId: null,
      statusName: "Initial_State",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/VCall_Not_Scheduled.svg",
      label: "Initial State"
    },
    {
      statusId: null,
      statusName: "VCall_Scheduled",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/VCall_Scheduled.svg",
      label: "VCall Scheduled"
    },
    {
      statusId: null,
      statusName: "VCall_Started",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/VCall_Started.svg",
      label: "VCall Started"
    },
    {
      statusId: null,
      statusName: "VCall_Completed",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/With_Underwriting.svg",
      label: "VCall Completed"
    },
    {
      statusId: null,
      statusName: "Recall_Created",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/Pending_Recall.svg",
      label: "Recall Created"
    },
    {
      statusId: null,
      statusName: "Recall Completed",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/With_Underwriting.svg",
      label: "Recall Completed"
    },
    {
      statusId: null,
      statusName: "APS_Pending_FollowUp",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/APS_FollowUp.svg",
      label: "APS Pending-FollowUp"
    },
    {
      statusId: null,
      statusName: "Routed_To_Issue",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/With_Issue_Team.svg",
      label: "Routed To Issue"
    },
    {
      statusId: null,
      statusName: "APS_Returned",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/APS_Returned.svg",
      label: "APS Returned"
    },
    {
      statusId: null,
      statusName: "Recall_Completed",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/With_Underwriting.svg",
      label: "Recall Completed"
    },
    {
      statusId: null,
      statusName: "With_APS_TEAM",
      imagePath: "assets/status-pill-images/Status_Icons/NBC_Pills/With_APS_Team.svg",
      label: "With APS"
    },

    // followup queue tab
    {
      statusId: null,
      statusName: "APSPending-FollowUp",
      imagePath: "assets/status-pill-images/Status_Icons/Application_Status/APS_Order_Follow_Up.svg",
      label: "APS Pending Followup"
    },
    {
      statusId: null,
      statusName: "APS Returned",
      imagePath: "assets/status-pill-images/Status_Icons/APS_Status/APS_Returned.svg",
      label: "APS Returned"
    },
    {
      statusId: null,
      statusName: "AddOn",
      imagePath: "assets/status-pill-images/Application_Type/Add_On.svg",
      label: "Add On"
    },
  ];

  constructor() { }

  filterImagePath(details: any) {

    if (details?.requestType) {
      return this.chipList.filter(
        chip => (chip.statusName === details.requestType && chip.statusId === details.orderStatusId)
      )[0]?.imagePath
    }
    return this.chipList.filter(
      chip => (chip.statusName === details || chip.statusId === details)
    )[0]?.imagePath
  }

}
