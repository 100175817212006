import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';
import { OrderActionType } from 'src/app/aps/models/enums';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { UpdateOrder } from 'src/app/store/dispatchers';
import { demographicFormConfig } from '../create-lab-order-ae-components/lab-order-ae-demographic/demographic-form-config';
import { OrderCreatedModelComponent } from '../create-lab-order-ae-components/order-created-model/order-created-model.component';

@Component({
  selector: 'ushg-create-lab-order-oe',
  templateUrl: './create-lab-order-oe.component.html',
  styleUrls: ['./create-lab-order-oe.component.scss']
})
export class CreateLabOrderOeComponent implements OnInit {
  @Input() orderDetails;
  agentPortalNotes:string='';
  config = {
    hideNavigationButtons: true,
    hideShadow: true,
    hideEdit: true,
    label: "Applicant Details"
  }
  detailsToDisplay = [
    {
      label: 'Request Details',
      value: 'requestTypes',
      class: 'col-6'
    },
    {
      label: "Reason",
      value: 'reason',
      class: "col-6"
    },
    {
      label: "Company",
      value: 'company',
      class: "col-6"
    }
  ]

  demographicConfig = demographicFormConfig
  applicationDetails
  constructor(private dialogService: UshgDialogService, private apsService: ApsService, private route: Router, private store: Store) { }

  ngOnInit(): void {
    this.orderDetails.labs.forEach((test) => {
      if (test.notes && test.name) {
        this.agentPortalNotes += `${test.name}: ${this.orderDetails.notes || this.orderDetails.UWSNotes} \n`;
      }
    });
    if (this.orderDetails.policyInformation.length > 0) {
      this.orderDetails.policyInformation.forEach((policy) => {
        this.demographicConfig[0].options.push({ 'label': policy.policyNumber, 'value': policy.policyNumber });
      });
      this.orderDetails.orderApplicant.policyNumber = this.demographicConfig[0].options?.length > 0 ? this.demographicConfig[0].options[0].value : '';
    }
    this.concateRequestTypes()
    this.applicationDetails = { ...this.orderDetails, ...this.orderDetails.orderApplicant }
  }

  concateRequestTypes() {
    this.orderDetails['requestTypes'] = this.orderDetails.labs.reduce((acc, current) => {
      acc.push(current.name)
      return acc
    }, [])
  }

  closeHandler() {
    this.apsService.redirectToBase()
  }

  async orderCreatedModel() {
    this.applicationDetails.orderStatusId = 3;
    this.applicationDetails.APS = null;
    this.applicationDetails.isFinalStep = true;
    this.applicationDetails.orderDetails.agentNotes=this.agentPortalNotes;
    this.applicationDetails.orderDetails.uWSNotes=this.agentPortalNotes;
    this.applicationDetails.agentNotes=this.agentPortalNotes;
    this.applicationDetails.isFinalStep=true;
    // TODO : Show Dialog & Redirect
    this.store.dispatch(new UpdateOrder(this.applicationDetails, OrderActionType.CREATE_ORDER));
  }

  ngOnDestroy(){
    // Todo: This change is hack for now, this code needs to be refactored in the future
    this.demographicConfig[0].options = [];
  }
}
