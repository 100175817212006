export enum Permission {
  CanAccessNewRequestQueue = 'CanAccessNewRequestQueue',
  CanAccessWorkQueue = 'CanAccessWorkQueue',
  CanAccessMyQueue = 'CanAccessMyQueue',
  CanAccessApplicationDetails = 'CanAccessApplicationDetails',//can access application detail page
  CanAccessApplicationAssignemnt = 'CanAccessApplicationAssignemnt',
  CanAccessTeamWorkLoad = 'CanAccessTeamWorkLoad',
  CanAccessRouteApplication = 'CanAccessRouteApplication',
  CanAccessCreateOrder = 'CanAccessCreateOrder',
  CanAccessAttachDocuments = 'CanAccessAttachDocuments',
  CanAccessDocumentGeneration = 'CanAccessDocumentGeneration',
  CanAccessFollowUp = 'CanAccessFollowUp',
  CanAccessApplicationStatus = 'CanAccessApplicationStatus',
  CanAddReminderDate = 'CanAddReminderDate',
  CanEditOrderAndRequestCheck = 'CanEditOrderAndRequestCheck',
  CanAnswerCall = 'CanAnswerCall',
  CanPerformActionsOnOrderModal = 'CanPerformActionsOnOrderModal', 
  CanAddOrEditDocument = 'CanAddOrEditDocument', //added to access Documents ->IndividualOrder->Open->Doument tab
  CanChangeOrderStatus = 'CanChangeOrderStatus', //added to access Aps notes Section ->IndividualOrder->Open->Doument tab
  CanAccessRequestCheck = 'CanAccessRequestCheck', //added to access Request Check action ->IndividualOrder
  CanViewOrderDetails = 'CanViewOrderDetails', //added to access Open button feature ->IndividualOrder
  CanAccessToITHelpCenter = 'CanAccessToITHelpCenter', // added for entering issues to serviceNow from Aps
  MembershipVCallReadWrite = 'MembershipVCallReadWrite',
  MembershipVCallRead = 'MembershipVCallRead',
  MembershipRecallReadWrite = 'MembershipRecallReadWrite',
  MembershipRecallRead = 'MembershipRecallRead',
}
