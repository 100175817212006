export const PDF_ATTRIBUTE_KEYS = {
    REQUEST_LETTER: ['applicationId',
        'currentDate', 'controlNumber',
        'providerFullName',
        'facilityName',
        'providerstreetAddress',
        'providerCityStateZip',
        'providerPhone',
        'providerFax',
        'birthDate',
        'orderContactName',
        'applicantstreetAddress',
        'policyNumber',
        'applicantCityStateZip'], 
    AUTHORIZATION: [
        "providerFullName",
        "controlNumber",
        "facilityNamee",
        "orderContactName",
        "birthDate",
        "applicantssn",
        "applicantstreetAddress",
        "applicantCity",
        "applicantState",
        "applicantZip",
        "recordsDates",
        "DischargeSummary",
        "ERRecord",
        ".....Other",
        "othercomments",
        "Purpose",
        "ContinuedMedicalCare",
        "InsuranceLegal",
        "PatientsOwnUse",
        "Other",
        "otherPurpose",
        "eventDescription",
        "expiryDate",
        "AuthDisclAppSign",
        "SignatureDate",
        "PatientSignature",
        "datesOfTreatmentRequested",
        "informationToBeDisclosed",
        "reasonForDisclosure",
        "authorizationExpiresOn",
        "CompanyName",
        "policyNumber"
    ],
    AUTHORIZATION_WITHOUT_ESIGNATURE: [
        "providerFullName",
        "controlNumber",
        "facilityNamee",
        "orderContactName",
        "birthDate",
        "applicantssn",
        "applicantstreetAddress",
        "applicantCity",
        "applicantState",
        "applicantZip",
        "recordsDates",
        "DischargeSummary",
        "ERRecord",
        ".....Other",
        "othercomments",
        "Purpose",
        "ContinuedMedicalCare",
        "InsuranceLegal",
        "PatientsOwnUse",
        "Other",
        "otherPurpose",
        "eventDescription",
        "expiryDate",
        "datesOfTreatmentRequested",
        "informationToBeDisclosed",
        "reasonForDisclosure",
        "authorizationExpiresOn",
        "CompanyName",
        "policyNumber"
    ],
    EBU_LAB_LETTER: [
        "currentDate",
        "controlNumber",
        "orderContactName",
        "applicantstreetAddress",
        "applicantCityStateZip",
        "orderContactName",
        "labsNotes",
        "applicationId",
        "policyNumber",
    ],
    BLOOD_PRESSURE_LETTER: [
        "currentDate",
        "controlNumber",
        "orderContactName",
        "applicantstreetAddress",
        "applicantCityStateZip",
        "orderContactName",
        "labsNotes",
        "applicationId",
        "policyNumber",
    ]
}