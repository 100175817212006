import { createOrder, getListOfUnderwWitersQuery, listOfApplications, listOfApplicationsForLoggedInApsUser, listOfApsUsersQuery, updateOrderProcessStatus, workQueueApplications } from './query-objects'

export const Query = {
    LIST_OF_APPLICANTS: listOfApplications,
    LIST_OF_APS_USERS: listOfApsUsersQuery,
    LIST_OF_WORK_QUEUE_APPLICATIONS: workQueueApplications,
    LIST_OF_APPLICANTS_FOR_LOGGEDIN_APS_USER:listOfApplicationsForLoggedInApsUser,
    CREATE_ORDER:createOrder,
    GET_LIST_OF_UNDERWRITERS: getListOfUnderwWitersQuery,
    UPDATE_ORDER_STATUS_FLAG: updateOrderProcessStatus,
}