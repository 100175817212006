import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';
import { SecondsToMinutesFilterPipe } from '../pipes/seconds-to-minutes-filter.pipe';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { UshgDialogService } from './ushg-dialog-service';
import { UserIdleModalComponent } from '../user-idle-modal/user-idle-modal.component';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class IdleTimerService {
    idleState: string;
    USER_IDLE_TIME_SECONDS = 900; //15 mins of idle time
    END_COUNTDOWN_TIMER = 300;
    CHECK_COUNTDOWN_TIMER = 300;
    idleTimer: number;
    isIdle: boolean;
    constructor(
        private idle: Idle,
        private dialogService: UshgDialogService,
        private msalService: MsalService
    ) {}
    public getIdleInstance() {
        return this.idle;
    }
    public initIdleTimer(): void {
        this.idle.setIdle(this.USER_IDLE_TIME_SECONDS); // Set the idle timer
        this.idle.setTimeout(this.END_COUNTDOWN_TIMER); // Set the timeout duration
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // Set up idle events
        this.idle.onIdleStart.subscribe(() => {
            this.isIdle = true;
        });

        this.idle.onIdleEnd.subscribe(() => {
            this.isIdle = false;
        });

        this.idle.onTimeout.subscribe(() => {
            this.isIdle = true;
            this.loggedOut();
        });

        this.idle.onTimeoutWarning.subscribe((countdown) => {
            if (countdown === this.CHECK_COUNTDOWN_TIMER) {
                this.dialogService.open(UserIdleModalComponent, "TimeOut");
                this.idle.clearInterrupts();
            }
        });

        // Start watching for idleness
        this.idle.watch();
    }

        // Change the idle timer setting
    changeIdleTimer(newTimer: number): void {
        this.idleTimer = newTimer;
        this.idle.stop(); // Stop the idle service
        this.initIdleTimer(); // Reinitialize the idle timer with the new settings
    }

    reset() {
        this.dialogService.cancel();
        this.initIdleTimer();
    }

    loggedOut() {
        this.dialogService.cancel();
        this.msalService.logout();
        this.idle.stop();
    }
}
  