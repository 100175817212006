import { Component, OnInit } from '@angular/core';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { tap } from 'rxjs/operators';
import { ErrorService } from '../services/error.service';
@Component({
  selector: 'app-errors-list',
  templateUrl: './errors-list.component.html',
  styleUrls: ['./errors-list.component.scss'],
  providers: [DialogService, MessageService],
})
export class ErrorsListComponent implements OnInit {
  constructor(
    private primengConfig: PrimeNGConfig,
    private errorService: ErrorService
  ) {}

  errorList = [];
  errors$ = this.errorService.errors$;
  display: boolean = false;

  ngOnInit() {
    this.primengConfig.ripple = true;

    this.errors$
      .pipe(
        tap((data: any) => {
          this.errorList = [];
          data?.forEach((item: any) => {
            this.errorList.push(item);
          });
        })
      )
      .subscribe();
  }
}
