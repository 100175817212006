import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { UserProfileService } from 'src/app/aps/services/user/user-profile.service';
import { UserProfile } from 'src/app/aps/models/user-profile';
import * as _ from 'lodash';

/**
 * Authorization Guard
 */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationGuard implements CanActivate {
  /**
   * Authorization guard component constructor
   * @param adalSvc Adal service
   * @param router Router
   * @param userProfileService User profile service
   */
  constructor(
    // private msal: MsalService,
    private userProfileService: UserProfileService,
    private msalService: MsalService
  ) { }
  /**
   * Checks if user is authorized & returns boolean
   * @param next Activated route snapshot
   * @param state Router state snapshot
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const account = this.msalService.instance.getActiveAccount();
    if (account !== null) {
    }
    let resolve;
    const promise = new Promise<boolean>((r, rj) => {
      resolve = r;
    });
    this.msalService
      .handleRedirectObservable()
      .toPromise()
      .then((r) =>
        this.msalService.instance.setActiveAccount(
          this.msalService.instance.getAllAccounts()[0]
        )
      )
      .then((a) => {
        return this.processAccount();
      })
      .then(() => resolve(true))
      .catch((e) => {
        console.log(e);
        this.msalService.loginRedirect();
      });

    return promise;
  }

  processAccount() {
    const allAccounts = this.msalService.instance.getAllAccounts();
    const account = _.first(allAccounts);
    if (account && Object.keys(account).length !== 0) {
      const newProfile = new UserProfile(
        account['name'],
        account['idTokenClaims'].groups,
        account['localAccountId'],
        account['username']
      );
      this.userProfileService.setUserProfile(newProfile);
      this.userProfileService.loadRoles(account);

      // Lets get the callzone user since the user is authenticated
  
    }
  }
}