<div class="header-container flex justify-content-end">
  <ushg-button [label]="'+ Add New'" [type]="'primary'" style="margin-right: 29px;" (click)="openAuthFormModal()"></ushg-button>
</div>
<div class="pl-4 pr-4 bg-white" *ngIf="viewAuthForms && paginatedData !== undefined && paginatedData.length > 0; else noData">
  <h3 class="pt-3 pl-3">Authorization Forms</h3>
  <div class="grid mb-5">
    <p-card *ngFor="let authform of paginatedData" class="col-6  ">
      <div class="card-style ">
        <div class="content-one-padding col-12 grid">
          <div class="col-7">
            <div class="col-12 grid align-items-center">
              <div class="col-1" style="padding-left:0px !important"><img [src]="authconstants.PDF_ICON_PATH"
                  alt="image" /></div>
              <div class="col-6 ml-2 "><span class="f16 bold-font">{{authform.documents[0].name}}</span>
                <div class="f14">{{authform?.documentType}}</div>
              </div>
            </div>
          </div>
          <div class="col-5">
            <div class="col-12 grid align-items-center ">
              <div class="col-6 flex-align-end"><span><img [src]="authconstants.CALENDER_ICON_PATH"
                    alt="image" width="13px" height="13px" /></span> <span class="ml-2">{{authform.documents[0].createdDate | dateTime : timeZone :
                  "date"}}</span></div>
              <div class="col-6 flex-align-end"><span class="cursor-pointer"><img [src]="authconstants.PREVIEW_ICON_PATH" alt="image"
                    (click)="previewFile(authform)" /></span><span class="ml-3 cursor-pointer" (click)="downloadFile(authform)"><img
                    [src]="authconstants.DOWNLOAD_ICON_PATH" alt="image" /></span></div>
            </div>
          </div>
        </div>
        <div class="content-two-padding">
          <div class="col-12 grid div-height">
            <div class="col-2 ml-1"><span class="f14">{{PROVIDER_PARAMETER.PROVIDERNAME}}:</span>
              <div class="f16 bold-font">{{authform.fullname}}</div>
            </div>
            <div class="col-3"><span class="f14">{{PROVIDER_PARAMETER.FECILITYNAME}}:</span>
              <div class="f16 bold-font">{{authform.facilityName}}</div>
            </div>
            <div class="col-2"><span class="f14">{{PROVIDER_PARAMETER.STATE}}:</span>
              <div class="f16 bold-font">{{authform.state}}</div>
            </div>
            <div class="col-2"><span class="f14">{{PROVIDER_PARAMETER.CITY}}:</span>
              <div class="f16 bold-font">{{authform.city}}</div>
            </div>
            <div class="col-2"><span class="f14">{{PROVIDER_PARAMETER.ZIPCODE }}:</span>
              <div class="f16 bold-font">{{authform.zipCode}}</div>
            </div>
          </div>
        </div>
      </div>

    </p-card>

  </div>
  <div class="flex-align-end mr-4 mb-5">
    <ushg-button [label]="'Previous'"[type]="'secondaryoutline'" (click)="onPageChange(pageIndex - 1)"
      [disable]="pageIndex === 0"></ushg-button>
    <ushg-button class="ml-2" [label]="'Next'" [type]="'primary'" (click)="onPageChange(pageIndex + 1)"
      [disable]="pageIndex >= (authforms?.length / pageSize) - 1"></ushg-button>
  </div>
</div>
<ng-template #noData>
  <p-message severity="info" text="No records found" styleClass="p-mr-2 w-100"></p-message>
</ng-template>