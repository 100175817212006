/* eslint-disable */
import {
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { error } from 'console';
import { formControlError } from 'src/app/aps/models/ui-models';
import { NavigationService } from 'src/app/aps/services/aps-navigation-service';
import { ApsService } from 'src/app/aps/services/aps-service';
import { RequestType } from 'src/app/nbc/Models/enums';
import { Navigation } from 'src/app/shared/services/navigation';
import { UshgFormService } from 'src/app/shared/services/ushg-form-service';
import { AddDirtyFlag } from 'src/app/store/dispatchers';

@Component({
  selector: 'ushg-demographic-details',
  templateUrl: './demographic-details.component.html',
  styleUrls: ['./demographic-details.component.scss'],
})
export class DemographicDetailsComponent
  extends Navigation
  implements OnInit, OnChanges {
  @Input('config') config;
  @Input('detialsConfig') applicationDetailsKey;
  @Input('details') applicationDetails;
  @Output() isFormInEditMode = new EventEmitter();
  @Output() saveForm = new EventEmitter();
  backButtonDisabled = true;
  @Input() isEdit = false;
  currentSection: string;
  @Input() dynamicClass;
  childError: formControlError;
  currentOrderData = this.store.selectSnapshot(
    (state) => state.data.currentOrderData
  );
  constructor(
    private navigation: NavigationService,
    private store: Store,
    public formService: UshgFormService,
    private apsService: ApsService,
    private ngZone: NgZone

  ) {
    super(navigation);
  }

  ngOnInit() {
    if (this.applicationDetails) {
      this.isEdit = !Object.keys(this.applicationDetails).length ? true : false;
      this.isFormInEditMode.next(this.isEdit);
    }
    this.currentOrderData &&
      this.apsService.mapPoliciesBrcodes(this.currentOrderData.applicationId);
    this.getFormGroupValid();
  }

  getFormGroupValid() {
    if (this.childError != null && this.childError != undefined && this.childError.hasError) {
      this.isEdit = true
      this.handleChildErrors(this.childError);
    }
    return this.applicationDetailsKey.every((key) => {
      if (
        key.formControlName === 'preferredCopyService' &&
        this.formService.formGroup?.controls[key.formControlName].disabled
      ) {
        return true;
      }
      else {
        return this.formService.formGroup?.controls[key.formControlName]?.valid;
      }
    });
  }

  ngOnChanges(changes) {
    if (!changes.applicationDetailsKey) {
      this.isEdit = !Object.keys(this.applicationDetails).length ? true : false;
    }
  }

  editButtonHandler(isEdit?) {
    this.isEdit = isEdit ? isEdit : !this.isEdit;
    if (this.childError != null && this.childError != undefined && this.childError.hasError) {
      this.isEdit = true;
      this.handleChildErrors(this.childError)
    }
    this.isFormInEditMode.emit(this.isEdit);
  }

  save() {
    this.editButtonHandler();
    this.addDirtyFlag();
    if (this.isEdit) {
      Object.entries(this.applicationDetails).forEach(([key, value]) => {
        this.applicationDetails[key] = value;
      });
      this.formService.formGroup.patchValue(this.applicationDetails);
    }
    let appDetailsKey = JSON.parse(JSON.stringify(this.applicationDetailsKey));
    this.patchValues(appDetailsKey);
  }

  addDirtyFlag() {
    this.store.dispatch(new AddDirtyFlag());
  }

  patchValues(appDetailsKey) {
    this.applicationDetails = JSON.parse(
      JSON.stringify(this.applicationDetails)
    );
    appDetailsKey.forEach((detailKey) => {
      this.applicationDetails[detailKey.value] =
        this.formService.formGroup.getRawValue()[detailKey.value];
    });
    this.saveForm.emit(this.applicationDetails);
  }

  checkIfRequired(key) {
    return key?.hasOwnProperty('validators')
      ? key?.validators?.some((element) => element?.name === 'required')
      : key?.formControlName === 'policyNumber';
  }

  applicationDetailsValue(key) {
    return this.applicationDetails && this.applicationDetails[key?.value];
  }

  changeToEditMode(event: formControlError) {
    this.childError = event;
    if (event != null && event != undefined && event.hasError) {
      this.isEdit = this.applicationDetails?.orderDetails?.requestType === RequestType.LAB_OE ? false : true;
      this.handleChildErrors(this.childError)
    }else{
      this.handleChildErrors(this.childError)
    }
    
  }

  handleChildErrors(errorObj: formControlError) {
    if(errorObj.hasError){
      this.formService.formGroup && this.formService.formGroup.get(errorObj.formControlName) && this.formService.formGroup.get(errorObj.formControlName).setErrors({ 'customError': true })
      this.isFormInEditMode.emit(this.isEdit);
    }else{
      this.formService.formGroup.get(errorObj.formControlName) && this.formService.formGroup.get(errorObj.formControlName).setErrors(null)
    }
  }

}
