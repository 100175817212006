import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Observable } from '@apollo/client/utilities';
import { Application, HeadersList } from 'src/app/aps/models/application-model';
import { STATES } from 'src/app/aps/models/states';
import {
  ApplicationSearchResult,
  ApplicationTracker,
  DetailedApplicationSearchResult,
  NotificationType,
} from 'src/app/aps/models/ui-models';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { ToVCallService } from 'src/app/nbc/services/redirect/VCall-Worksheet.service';
import {
  SearchApplicationHeadersList,
  SearchApplicationSubHeaders,
} from './search-application-config';
import { UserProfileService } from 'src/app/aps/services/user/user-profile.service';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { NbcApiService } from 'src/app/nbc/services/nbc-api-services/nbc-api-service';
import { Router } from '@angular/router';
import { PermissionService } from 'src/app/aps/services/permission.service';
import {
  ErrorMessages,
  ImportGrid,
  PrimaryOrAddonGrid,
  SearchApplicationForm,
} from 'src/app/aps/models/enums';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-search-application',
  templateUrl: './search-application.component.html',
  styleUrls: ['./search-application.component.scss'],
})
export class SearchApplicationComponent implements OnInit {
  headersList: HeadersList[] = SearchApplicationHeadersList(this);
  subHeadersList: HeadersList[] = SearchApplicationSubHeaders;
  actionsList = [
    {
      label: 'Create Addon',
      button: true,
      type: 'primary',
      onClick: (data) => {
        this.validateAndImportApplication(data);
      },
    },
    {
      label: 'View Details',
      button: true,
      type: 'primary',
      onClick: (data) => {
        this.navigateToApplicationDetails(data);
      },
    },
  ];
  dropDown = {
    label: 'Change Status To',
    placeholder: 'Select State',
    filterValues: STATES,
  };
  clearDropDown = true;
  private readonly SEARCH_APPLICATION = 'search-application';
  selectedStateValue: string;
  searchForm: UntypedFormGroup;
  searchApplicationListO$: Observable<ApplicationSearchResult[]>;
  searchApplicationList: ApplicationSearchResult[];
  detailedApplicationList: DetailedApplicationSearchResult[]; //added to have application details for the  Primary / addon Grid
  pageSize = 10;
  presentPage = 1;
  applicationUnavailable = false;
  showSpinner = false;
  permittedActions = [];
  selectedApplication: Application;
  trackerRecord: ApplicationTracker[];
  enablePrimaryOrAddonGrid: boolean; //added to control the  Primary / addon Grid
  orderDetails = []; //added to allow a user to application detail page Iff there are orders created
  get SearchApplicationForm(): typeof SearchApplicationForm {
    return SearchApplicationForm;
  }
  get PrimaryOrAddonGrid(): typeof PrimaryOrAddonGrid {
    return PrimaryOrAddonGrid;
  }
  get ImportGrid(): typeof ImportGrid {
    return ImportGrid;
  }

  constructor(
    private apsApisService: ApsApiService,
    private toVCallService: ToVCallService,
    private userProfileService: UserProfileService,
    private toastr: ToastrService,
    private apiService: NbcApiService,
    private route: Router,
    private permissionService: PermissionService
  ) { }

  async ngOnInit() {
    this.searchForm = new UntypedFormGroup(
      {
        applicationId: new UntypedFormControl(
          '',
          Validators.pattern(/^-?(0|[1-9]\d*)?$/)
        ),
        firstName: new UntypedFormControl(''),
        lastName: new UntypedFormControl(''),
        state: new UntypedFormControl(''),
        controlNumber: new UntypedFormControl(''),
        policyNumber: new UntypedFormControl(''),
      },
      this.atLeastOneInputHasValue()
    );
    this.searchForm.valueChanges.subscribe((resp) => {
      this.atLeastOneInputHasValue();
    });
    this.enablePrimaryOrAddonGrid = false;
  }

  atLeastOneInputHasValue = () => {
    return (group: UntypedFormGroup) => {
      if (
        !Object.values(group.value).find(
          (value) => value !== '' && value !== null
        )
      ) {
        return { message: 'Please Enter at least one input value' };
      }
      return null;
    };
  };

  validateAndImportApplication(data) {
    this.apiService
      .getListOfApplicationsBasedOnSearch(
        `{applicationId: ${data.applicationId}}`
      )
      .subscribe((res) => {
        if (!res.data.applications.length) {
          data.canNavigate = true;
          this.importApplication(data);
        } else {
          this.toVCallService.openVCallLinkInNewTab(
            data.applicationId,
            'addOns'
          );
        }
      });
  }

  searchApplications() {
    const formData = this.searchForm.value;
    const searchData = {
      applicationId: Number(formData.applicationId),
      firstName: formData.firstName,
      lastName: formData.lastName,
      controlNumber: formData.controlNumber,
      policyNumber: formData.policyNumber,
    };
    this.search(searchData);
  }

  search(filters) {
    this.enablePrimaryOrAddonGrid = false;
    this.searchApplicationList = [];
    const searchFilter = Object.keys(filters).reduce((acc, el) => {
      if (filters[el] !== '') {
        acc[el] = filters[el];
      }
      if (acc['applicationId'] === 0 || acc['applicationId'] === '') {
        delete acc['applicationId'];
      }
      if (filters[el] === null) {
        delete acc[el];
      }
      return acc;
    }, {});
    searchFilter['page'] = this.SEARCH_APPLICATION;

    this.selectedStateValue = searchFilter['state'];
    this.searchForm.patchValue(searchFilter);
    if (this.searchForm.valid) {
      this.searchApplicationListO$ =
        this.apsApisService.searchApplications(searchFilter);
      this.searchApplicationListO$.subscribe((resp) => {
        if (resp.length) {
          this.applicationUnavailable = false;
          resp.forEach((application) => {
            application['createDate'] = dayjs(application['createDate']).format(
              'MM/DD/YYYY'
            );
            this.searchApplicationList.push(
              new ApplicationSearchResult(application)
            );
          });
        } else {
          this.applicationUnavailable = true;
        }
      });
    }
  }

  async importApplication(data?) {
    const applicationId =
      this.searchForm.get('applicationId')?.value ||
      (data && data.applicationId);
    const domainUserName = this.userProfileService
      .getUserProfile()
      .domainUserName.replace(/\\\\/, '');

    this.showSpinner = true;
    this.apsApisService
      .importApplication(applicationId, domainUserName)
      .subscribe(
        (resp) => {
          this.showSpinner = false;
          this.toastr.open({
            message: 'Application succesfully imported',
            type: NotificationType.SUCCESS,
            header: '',
          });
          if (resp) {
            this.searchApplications();
          }
          if (data && data.canNavigate) {
            this.toVCallService.openVCallLinkInNewTab(
              data.applicationId,
              'APSRequests'
            );
          }
        },
        (err) => {
          this.showSpinner = false;
          this.toastr.open({
            message: 'Application not imported',
            type: NotificationType.ERROR,
            header: '',
          });
        }
      );
  }

  getState(eve) {
    this.selectedStateValue = eve;
    this.searchForm.controls['state'].setValue(this.selectedStateValue);
  }
  //this code currently not in use may require in future
  // move(step) {
  //   this.presentPage = this.presentPage + step;
  //   this.searchApplications();
  // }

  reset() {
    this.enablePrimaryOrAddonGrid = false;
    this.searchForm.reset();
    this.getState('');
    this.searchApplications();
  }

  navigateToApplicationDetails(application) {
    if (this.orderDetails.length) {
      this.route.navigate(['aps/application-details'], {
        queryParams: {
          applicationTrackerId: application.applicationTrackerId,
        },
      });
    } else {
      this.toastr.open({
        message: ErrorMessages.orderDetailsNotfound,
        type: NotificationType.INFO,
        header: '',
      });
    }
  }

  async getApplicationTrackerDetails(selectedRowdata) {
    this.showSpinner = true;
    this.detailedApplicationList = [];
    this.orderDetails = [];
    this.apsApisService
      .getApplicationDetail(selectedRowdata.applicationId)
      .subscribe(
        (res) => {
          if (!res.errors) {
            this.showSpinner = false;
            this.orderDetails = res.data.application.orderDetails;
            if (res.data.application.applicationTracker.length == 0) {
              this.applicationUnavailable = true;
              this.searchForm.controls['applicationId'].setValue(
                selectedRowdata.applicationId
              );
            } else {
              this.showSpinner = false;
              this.enablePrimaryOrAddonGrid = true;
              this.applicationUnavailable = false;
              res.data.application.applicationTracker.forEach((tracker) => {
                tracker.createdDate = dayjs(tracker.createdDate).format(
                  'MM/DD/YYYY'
                );
                this.detailedApplicationList.push(
                  new DetailedApplicationSearchResult(selectedRowdata, tracker)
                );
              });
            }
          } else {
            this.showSpinner = false;
            if (
              res.errors[0].message === ErrorMessages.searchApplicationNotFound
            ) {
              this.searchForm.controls['applicationId'].setValue(
                selectedRowdata.applicationId
              );
              this.applicationUnavailable = true;
            }
          }
        },
        (err) => {
          console.log('something went wrong', err);
        }
      );
  }
}
