<div class="container m-0 p-0">
  <h4 class="text-center mt-3 header">APS Users & Workload</h4>
  <div class="grid">
    <div class="col-12 py-0">
      <div class="grid">
        <div class="col-2 orderby-dropdown pb-0">
          <h4>View</h4>
        </div>
        <div class="col-10 pb-0 pr-3">
          <ushg-drop-down [dropDown]="viewByFilter"></ushg-drop-down>
        </div>
      </div>
    </div>
    <div class="col-12 pt-0">
      <ushg-search-input
        [placeholder]="'Search By Name'"
        (searchInputEventHandler)="search($event)"
      >
      </ushg-search-input>
    </div>
  </div>
  <ng-container *ngFor="let user of apsUsersList | sort :'asc':'displayName' ">
    <div class="custom-card cursor-pointer mb-2 shadow-3">
      <div class="d-flex">
        <div class="d-flex align-items-center">
          <div class="initial-circle mr-3">
            {{ user.intial }}
          </div>
        </div>
        <div class="info-container">
          <div class="display-name">
            {{ user.displayName }}
          </div>
          <div class="job-title mb-2">({{ user.apsMemberRoles }})</div>
          <div class="d-flex">
            <span class="text-primary font-semibold mr-5"
              >New ({{ user?.workload?.new }})
            </span>
            <span class="text-danger font-semibold">
              Pending ({{ user?.workload?.pending }})
            </span>
          </div>
        </div>
        <div class="assign-btn" [ngClass]="{'p-disabled': selectedApplications?.length === 0}">
          <ushg-button
            label="Assign"
            [type]="'primarysm'"
            (click)="assignToApsUser(user)"
            [appPermission]="'CanAccessApplicationAssignemnt'"
          >
          </ushg-button>
        </div>
      </div>
    </div>
  </ng-container>
</div>
