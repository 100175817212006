import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store, Actions as StoreActions, ofActionCompleted } from '@ngxs/store';
import { Actions } from 'src/app/store/actions';
import { IDialogAware } from 'src/app/aps/models/IDialogAware';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { CopyServices } from 'src/app/shared/constants/filter-constants';
import * as _ from 'lodash';
import {
  AssignedApplicationsForLoggedInUser,
  CurrentOrderData,
  FilterAssignedApplicationsForFollowupQueue,
  NewOrderLogEntry,
  SaveReminderDate,
  ToggleOrderLogEntryPin,
  UpdateOrderProperties,
  UpdateOrderProviderPhoneExtension,
} from 'src/app/store/dispatchers';
import { tap } from 'rxjs/operators';
import { FollowUpLog, LogsAndNotes } from 'src/app/nbc/Models/nbc-models';
import {
  AuditTypeId,
  FOLLOWUP_ORDER_NOTES_TYPE,
  RedirectUrl,
} from 'src/app/aps/models/enums';
import { DropDownModel } from 'src/app/shared/drop-down/drop-down.component';
import { Router } from '@angular/router';
import { ProfileModel } from 'src/app/aps/models/application-model';

@Component({
  selector: 'app-followup-order',
  templateUrl: './followup-order.component.html',
  styleUrls: ['./followup-order.component.scss'],
})
export class FollowupOrderComponent implements OnInit, IDialogAware {
  selectedApplicationOrderData = this.store.selectSnapshot(
    (state) => state.data.currentOrderData
  );
  providerDetails;
  @Output() filterInput = new EventEmitter<string>();
  public readonly ANSWERED = 'CALL_ANSWERED';
  public readonly NO_ANSWER = 'NO_RESPONSE';
  public readonly ADD_NOTE = 'FOLLOWUP_LOG';
  public readonly APS = 'APS';
  public readonly TO_UWS = 'TO_UWS';
  public readonly TO_APS = 'TO_APS';

  public followUpForm: UntypedFormGroup;
  public showDateTime = false;
  public dueDate = '';
  public reminderDate = null;
  public allNotes: LogsAndNotes[];
  preferredCopyServiceType = '';
  copyServiceNote = '';
  selectedValue = 'Call Answered';
  dueFollowUpDateTime: string;
  dueFollowUpEdit: Boolean = false;
  iconPhoneExtensionEdit: Boolean = false;
  callingInstructions: string = "";
  currentDate = new Date();
  config = {
    width: '46%',
    closable: false,
  };
  editReminderDateButtons = [
    {
      label: 'Edit',
      type: 'primary',
      class: 'mr-2',
      disableCalendar: true,
      onClick: (data) => {
        this.reminderDateButtons = this.saveReminderDateButtons;
      },
    },
  ];
  saveReminderDateButtons = [
    {
      label: 'Save',
      type: 'primary',
      class: 'mr-2',
      onClick: (data) => {
        this.saveReminderDate();
      },
    },
    {
      label: 'Cancel',
      type: 'outline',
      class: 'mr-2',
      onClick: (data) => {
        this.reminderDateButtons = this.editReminderDateButtons;
        !this.reminderDate ? (this.showDateTime = false) : '';
      },
    },
    {
      label: 'Reset',
      type: 'outline',
      onClick: (data) => {
        this.resetFollowUpDateTime();
      },
    },
  ];
  reminderDateButtons = [];
  buttonsList = [
    {
      label: 'Close',
      type: 'secondaryoutline',
      onClick: (data) => {
        this.dialogService.cancel();
      },
    },
  ];
  noteTypesList: DropDownModel;
  timeZone: string = this.store.selectSnapshot(
    (state) => state.data.userProfile
  ).timeZone;
  copyServiceTypes: DropDownModel;
  profile: ProfileModel;

  constructor(
    private fb: UntypedFormBuilder,
    private dialogService: UshgDialogService,
    private store: Store,
    private apsService: ApsService,
    private datePipe: DatePipe,
    private actions$: StoreActions,
    private router: Router,
  ) {
    this.noteTypesList = {
      label: 'Document Type',
      onSelect: (ev) => {
        this.getNoteType(ev);
      },
      placeholder: 'Select the Option',
      filterValues: NOTE_TYPE_LIST,
    };

    this.copyServiceTypes = {
      label: '',
      selectedValue: this.preferredCopyServiceType,
      onSelect: (event) => {
        this.preferredCopyServiceType = event.value;
        this.getCopyServiceType(event);
      },
      filterValues: _.chain(CopyServices).sortBy('name').value(),
      placeholder: 'Choose Copy Service',
    }
  }

  getNoteType(val) {
    this.followUpForm.get('responseType').setValue(val.value);
  }

  showPhoneExtensionEdit(){
    this.followUpForm.get('callingInstructions').setValue(this.providerDetails.phoneExtension);
    this.iconPhoneExtensionEdit=true;
  }

  hidePhoneExtensionEdit(){
    this.iconPhoneExtensionEdit=false;
  }

  async savePhoneExtension(){
    if (this.followUpForm.get('callingInstructions').value) {
      const payload = {
        orderTrackerId: this.selectedApplicationOrderData.orderTrackerId,
        id: this.selectedApplicationOrderData.id,
        phoneExtension: this.followUpForm.get('callingInstructions').value
      }
      await this.store.dispatch(new UpdateOrderProviderPhoneExtension(payload));
      this.iconPhoneExtensionEdit=false;
    }
  }

  getCopyServiceType(val) {
    this.followUpForm.get('copyServiceType').setValue(val.value);
  }

  actionsEvent = (callback: Function) => {
    callback();
  };

  notesToUpper(){
    this.followUpForm
        .get('notes')
        .setValue(this.followUpForm.get('notes').value.toUpperCase());
  }

  ngOnInit() {
    this.profile = this.store.selectSnapshot((state) => state.data.userProfile);
    if(this.selectedApplicationOrderData.orderDetails.APS){
     this.providerDetails= this.selectedApplicationOrderData.orderDetails.APS.providerOverride ? this.selectedApplicationOrderData.orderDetails.APS.providerOverride : this.selectedApplicationOrderData.orderDetails.APS.provider;
    }
    this.initForm();
    this.patchDueFollowUpDateTime();
    this.dueFollowUpEdit = this.selectedApplicationOrderData.dueFollowUpDateTime
      ? false
      : true;
    this.dueDate =
      this.selectedApplicationOrderData?.applicationTracker[0]?.dueDate;
    if (!this.router.url.includes(RedirectUrl.APPLICATION_DETAILS)) {
      this.reminderDate = this.getReminderDate(
        this.selectedApplicationOrderData.orderTrackerId
      );
    } else {
      this.reminderDate = this.selectedApplicationOrderData.reminderDate;
    }

    if (this.reminderDate) {
      this.showDateTime = true;
      this.reminderDateButtons = this.editReminderDateButtons;
      this.followUpForm
        .get('followUpDate')
        .setValue(new Date(this.reminderDate));
    }
    this.preferredCopyServiceType = this.selectedApplicationOrderData.properties ?  (this.selectedApplicationOrderData.properties[0]?.key === 'preferredCopyService' ? this.selectedApplicationOrderData.properties[0]?.value : '') : '';
    this.copyServiceNote = this.selectedApplicationOrderData.properties ?  (this.selectedApplicationOrderData.properties[1]?.key === 'copyServiceNotes' ? this.selectedApplicationOrderData.properties[1]?.value : '') : '';
    this.followUpForm.get('copyServiceType').setValue(this.preferredCopyServiceType);
    this.followUpForm.get('copyServiceNotes').setValue(this.copyServiceNote);
    this.copyServiceTypes = {...this.copyServiceTypes, selectedValue: this.preferredCopyServiceType}
    this.getAllNotes();
  }

  getReminderDate(orderId) {
    return this.selectedApplicationOrderData?.orderTrackers.find(
      (order) => order.id === orderId
    )?.reminderDate;
  }

  initForm() {
    this.followUpForm = this.fb.group({
      followUpDate: [null],
      followUpTime: [null],
      responseType: [null, this.responseTypeValidator()],
      notes: ['', Validators.required],
      copyServiceType: [null],
      copyServiceNotes: [''],
      callingInstructions: ['', [
        Validators.maxLength(100),
        this.callingInstructionsValidator()
      ]],
    });
  }

  callingInstructionsValidator() {
    return (control) => {
      const validCharactersRegex = /^[A-Za-z0-9.,*&\/#\s]+$/;
      if (!control.value) {
        return null;
      }
      if (!validCharactersRegex.test(control.value)) {
        return { invalidCharacters: true };
      }
      return null;
    };
  }

  patchDueFollowUpDateTime() {
    this.dueFollowUpDateTime =
      this.selectedApplicationOrderData.dueFollowUpDateTime;
    this.followUpForm.patchValue({
      followUpDate: this.datePipe.transform(
        this.selectedApplicationOrderData.dueFollowUpDateTime,
        'dd/MM/yyyy'
      ),
    });
  }

  public getAllNotes() {
    this.store
      .select(
        Actions.AuditLogsById([
          AuditTypeId.FOLLOWUP_LOG,
          AuditTypeId.TO_UWS,
          AuditTypeId.CALL_ANSWERED,
          AuditTypeId.NO_RESPONSE,
        ])
      )
      .pipe(
        tap((res: FollowUpLog[]) => {
          let count = 0;
          this.allNotes = res
            ?.filter((order: FollowUpLog) => {
              return (
                order.orderTrackerId ===
                this.selectedApplicationOrderData.orderTrackerId
              );
            })
            .sort((a, b) => {
              // Compare by isPinned first (true values come first)
              const isPinnedA = a.isPinned || false;
              const isPinnedB = b.isPinned || false;
            
              if (isPinnedA && !isPinnedB) {
                return -1;
              } else if (!isPinnedA && isPinnedB) {
                return 1;
              } else {
                // If isPinned values are equal, compare by loggedInDate (latest date comes top)
                if (a.loggedDate > b.loggedDate) {
                  return -1;
                } else if (a.loggedDate < b.loggedDate) {
                  return 1;
                } else {
                  return 0;
                }
              }
            });
          let answeredAndNoAnsweredNotes = this.allNotes.filter((order) => {
            return (
              order.auditParameter === this.ANSWERED ||
              (order && order?.auditParameter === this.NO_ANSWER)
            );
          });
          count = answeredAndNoAnsweredNotes.length;
          this.allNotes.map((order: FollowUpLog) => {
            (order && order.auditParameter === this.NO_ANSWER) ||
              (order && order?.auditParameter === this.ANSWERED)
              ? ((order.index = count), (count = count - 1))
              : null;
            return order;
          });
        })
      )
      .subscribe();
  }

  responseTypeValidator() {
    return this.selectedApplicationOrderData.requestType === this.APS
      ? Validators.required
      : null;
  }

  async togglePin(note: any) {
    const payload = {
      id: note.id,
      isPinned: !note.isPinned
    };
    this.store.dispatch(new ToggleOrderLogEntryPin(payload));
    this.getAllNotes();
  }

  async addNotes() {
    const form = this.followUpForm.value;
    const { convertedVersion } = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    const payload = {
      information: form.notes,
      auditParameter: form.responseType,
      auditTypeId: AuditTypeId[form.responseType],
      loggedDate: new Date().toISOString(),
      orderTrackerId: this.selectedApplicationOrderData.orderTrackerId,
      convertedVersion,
    };
    this.store.dispatch(new NewOrderLogEntry(payload));
    this.followUpForm.get('notes').reset();
    this.getAllNotes();
  }

  async saveCopyServiceType() {
    this.preferredCopyServiceType = this.followUpForm.controls['copyServiceType'].value;
    this.copyServiceNote = this.followUpForm.controls['copyServiceNotes'].value;
    const properties = [
      {"key": "preferredCopyService", "value" : this.preferredCopyServiceType ? this.preferredCopyServiceType : ''},
      {"key" : "copyServiceNotes", "value": this.copyServiceNote ? this.copyServiceNote : ''}
    ];
    const payload = {
      orderTrackerId: this.selectedApplicationOrderData.orderTrackerId,
      Id: this.selectedApplicationOrderData.id,
      CreatedBy: this.selectedApplicationOrderData.orderDetails.updatedBy ? this.selectedApplicationOrderData.orderDetails.updatedBy : this.profile.domainUserName,
      properties: properties,
    }
    await this.store.dispatch(new UpdateOrderProperties(payload));
  }

  dateChange(e) {
    this.dueFollowUpDateTime = new Date(e).toISOString();
  }

  async saveReminderDate() {
    if (this.followUpDate) {
      const dateTime = this.followUpForm
        .get('followUpDate')
        .value.toISOString();
      this.store.dispatch(new SaveReminderDate(dateTime)).toPromise();
      this.reminderDateButtons = this.editReminderDateButtons;
    }
  }

  resetFollowUpDateTime() {
    this.followUpForm.get('followUpDate').reset();
    this.store.dispatch(new SaveReminderDate(null));
  }

  get disableReminderDate() {
    return this.reminderDateButtons.some((button) => button.disableCalendar);
  }

  cancel() {
    this.showDateTime = false;
  }

  showReminderDateCalendar() {
    this.showDateTime = true;
    this.reminderDateButtons = this.saveReminderDateButtons;
  }

  hideReminderDateCalendar() {
    this.showDateTime = false;
  }

  editButtonHandler() {
    this.dueFollowUpEdit = true;
  }

  get followUpDate() {
    return this.followUpForm.get('followUpDate').value;
  }

  get followUpTime() {
    return this.followUpForm.get('followUpTime').value;
  }

  get notes() {
    return this.followUpForm.get('notes').value;
  }
}
export const NOTE_TYPE_LIST = [
  { name: 'Call Answered', value: 'CALL_ANSWERED' },
  { name: 'No Response', value: 'NO_RESPONSE' },
  { name: 'Add Note', value: 'FOLLOWUP_LOG' }
];
