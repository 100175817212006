import { AuditTypeId, OrderStatus } from './enums';
import { Permission } from './permission';

export interface MenuModel {
  label: string;
  styleClass: string;
  routerLink: string[];
}
export interface DetailsToDisplay {
  label: string;
  value: string;
  class?: string;
}

export interface Document {
  label?: string;
  blob?: string;
  byteStream?: string;
}
export class DoctorLookup {
  first_name: string;
  last_name: string;
  constructor(options) {
    Object.assign(this, options);
  }
  fullname: string;
  street_address: string;
  city: string;
  state: string;
  zipcode: string;
  id: string;
  distance: string;
  specialties: string;
  phone_numbers: number;
  other_phone: number;
}
export class DoctorLookupAnswer {
  list: DoctorLookup[] = [];
  isProviderAvailable = false;
  answerType = 'DoctorDetailsAnswer';
}

export interface ProviderSearchModel {
  healthcareProviderSearchType: number;
  searchString: string;
  applicantZipcode: string;
  areaCode: string;
  phoneNumber: string;
  radius: number;
}

export class ProviderSearchPayload {
  healthcareProviderSearchType: number;
  searchString: string;
  applicantZipcode: string;
  areaCode: '';
  phoneNumber: '';
  radius: 0;
  constructor(
    healthcareProviderSearchType,
    searchString,
    applicantZipcode,
    phoneNumber
  ) {
    this.healthcareProviderSearchType = !phoneNumber
      ? healthcareProviderSearchType
      : 0;
    this.searchString = searchString;
    this.applicantZipcode = applicantZipcode;
    this.areaCode = phoneNumber ? phoneNumber.substring(0, 3) : '';
    this.phoneNumber = phoneNumber ? phoneNumber.substring(3, 10) : '';
    this.radius = 0;
  }
}

export interface CommunicationPreferences {
  electronicSignature: boolean;
  communicationMode: string;
  creditCardPayments: boolean;
  trunAroungTime?: string;
  specialRequestLetter: boolean;
  lastModifiedBy: string;
  lastModifiedDate?: string;
  documents?: Documents;
  preferredCopyService?: string;
}

export interface ProviderPreferences {
  id: string;
  fullname: string;
  communicationPreferences: CommunicationPreferences;
  documents?: Documents;
}

export interface ProviderModel {
  city: string;
  distance: number;
  first_name: string;
  fullname: string;
  id: string;
  label: string;
  last_name: string;
  last_visited?: any;
  phone: string;
  phone_numbers: string;
  specialties: string;
  state: string;
  streetAddress: string;
  street_address: string;
  value: string;
  zipCode: string;
}

export class Documents {
  name?: string;
  content?: string;
  blob?: string;
  byteStream?: string;
  attributes?: string;
  checked?: Boolean;
  documentType?: string;
  updatedDate?: string;
  label?: string;
  constructor(
    name?,
    content?,
    blob?,
    byteStream?,
    attributes?,
    checked?,
    documentType?,
    label?,
    updatedDate?
  ) {
    this.name = name;
    this.content = content;
    this.blob = blob;
    this.byteStream = byteStream;
    this.attributes = attributes;
    this.documentType = documentType;
    this.label = label;
    this.updatedDate = updatedDate;
  }
}
export interface userProfile {
  appointmentId: number;
  appId: number;
  scheduledDate: Date;
  firstName: string;
  lastName: string;
  username: string;
  callType: string;
  message: string;
  statusId: number;
  statusName: string;
  intial: string;
}

export interface labRequestModel {
  applicationId: number;
  requestedByUser: string;
  emailParameters: EmailParameters;
  PolicyName?: string | undefined;
  CCEmail?: string;
  template?: string;
  PolicyNumber?: string;
  orderTrackerId?: null;
}
export interface EmailParameters {
  RecipientEmail: string;
  RecipientName: string;
  CompanyName: string;
  Attachments?: AttachmentsEntity[] | null;
  CCEmail?: string;
  PolicyNumber: string | undefined;
}

export class AuditLog {
  auditTypeId: number;
  auditParameter: string;
  loggedDate: string;
  information: string;
  orderTrackerId: number;
  applicationTrackerId: number;
  constructor(typeId?, formValues?, information?) {
    this.auditTypeId = typeId;
    this.loggedDate = new Date().toISOString();
    this.auditParameter = AuditTypeId[this.auditTypeId];
    this.information = formValues?.notes || information;
    this.orderTrackerId =
      (formValues?.orderTrackerId ? formValues?.orderTrackerId : null) ||
      (information ? JSON.parse(information).orderTrackerId : null) ||
      null;
    this.applicationTrackerId = formValues?.applicationTrackerId;
  }
}

export interface AttachmentsEntity {
  FileName: string;
  Bytes: string;
}

export class DownloadDocumentsPayload {
  appId: string;
  orderId: string;
  clientNumber: string;
  documents;
  constructor(orderDetails, pdfTitle) {
    this.appId = '' + orderDetails.applicationId;
    this.orderId = '' + orderDetails.orderTrackerId;
    this.clientNumber = '' + orderDetails.clientNumber;
    this.documents = new Array({
      name: `${pdfTitle}`,
    });
  }
}

export class Contact {
  mobilePhone;
  homePhone;
  appId;
  streetAddress;
  city;
  state;
  zipCode;
  email;
  applicantZip;
  policy;
  contactState;
  applicantEmailAddress;
  applicantCityStateZip;
  constructor(data) {
    this.homePhone = data.applicantHomePhone;
    this.mobilePhone = data.applicantmobilePhone;
    this.zipCode = data.applicantzipCode;
    this.applicantZip = data.applicantzipCode;
    this.streetAddress = data.applicantstreetAddress;
    this.contactState = data.contactState;
    this.state = data.contactState;
    this.city = data.applicantCity;
    this.email = data.applicantEmail;
    this.applicantEmailAddress = data.applicantEmail;
    this.applicantCityStateZip = `${data.applicantCity || ''}, ${data.contactState || ''
      } - ${data.applicantzipCode || ''}`;
  }
}

export class HealthDiscloureInformation {
  reasonForDisclosure = 'Insurance';
  authorizationExpiresOn = '1 year from date signed';
  informationToBeDisclosed = 'Complete Medical Records';
  datesOfTreatmentRequested = 'Last 5 Years';
  constructor(orderDetails?) {
    if (orderDetails) {
      this.authorizationExpiresOn = orderDetails.authorizationExpiresOn;
      this.reasonForDisclosure = orderDetails.reasonForDisclosure;
      this.informationToBeDisclosed = orderDetails.informationToBeDisclosed;
      this.datesOfTreatmentRequested = orderDetails.datesOfTreatmentRequested;
    }
  }
}

export interface TableActions {
  label?: string;
  button?: Boolean;
  type?: string;
  permission?: Permission;
  onClick?: Function;
}

export enum NotificationType {
  ERROR = 'error',
  WARNING = 'warn',
  INFO = 'info',
  SUCCESS = 'success',
}

export class NotificationModel {
  message: string;
  type: NotificationType;
  header: string;
  constructor(type, header, message) {
    this.type = type;
    this.header = header;
    this.message = message;
  }
}

export class ApplicationSearchResult {
  appType: string;
  applicationId: number;
  company: string;
  createDate: string;
  firstName: string;
  lastName: string;
  state: string;
  applicantFullName?: string;

  constructor(application) {
    this.applicantFullName = application.firstName + ' ' + application.lastName;
    this.appType = application.appType;
    this.applicationId = application.applicationId;
    this.company = application.company;
    this.createDate = application.createDate;
    this.firstName = application.firstName;
    this.lastName = application.lastName;
    this.state = application.state;
  }
}

export class AuditLogModel {
  auditTypeId: number;
  auditParameter: string;
  loggedDate: string;
  information: string;
  orderTrackerId: number;
  applicationTrackerId: number;
  constructor(typeId?, currentOrderData?) {
    this.auditTypeId = typeId;
    this.loggedDate = new Date().toISOString();
    this.auditParameter = AuditTypeId[this.auditTypeId];
    this.information = message(currentOrderData);
  }
}

function message(currentOrderData) {
  const orderId = currentOrderData.orderTrackerId;
  let message = `Order ID ${orderId} Status Has been changed to ${OrderStatus[currentOrderData.orderStatusId]
    } `;
  if (currentOrderData.isEditOrder) {
    message = `Edited Order ${orderId}`;
  } else if (
    currentOrderData.orderDetails.updateOrders &&
    currentOrderData.orderStatusId !== OrderStatus.Pending
  ) {
    message = `Changed the ORDER ID ${orderId}  to '${OrderStatus[currentOrderData.orderStatusId]
      }'`;
  }
  return message;
}

export class ProviderCityStateZip {
  cityStateZip: string;
  constructor(providerDetails) {
    this.cityStateZip = `${providerDetails?.city || ''}, ${providerDetails?.state || ''
      } - ${providerDetails?.zipCode || ''}`;
  }
}
export interface ProcessStatusPayload {
  applicationId: number;
  applicationTrackerId: number;
  convertedVersion: string;
  orderTrackerId: number;
  processStatus: string;
  updatedBy: string;
  updatedDate: string;
}

export class DetailedApplicationSearchResult {
  applicationId: number;
  createdDate: Date;
  firstName: string;
  lastName: string;
  applicantFullName?: string;
  type: string;
  applicationTrackerId: number;

  constructor(application, applicationTracker) {
    this.applicantFullName = application.firstName + ' ' + application.lastName;
    this.applicationId = application.applicationId;
    this.firstName = application.firstName;
    this.lastName = application.lastName;
    this.type = applicationTracker.applicationTrackerType;
    this.createdDate = applicationTracker.createdDate;
    this.applicationTrackerId = applicationTracker.id;
  }
}
export interface ApplicationTracker {
  applicationStatusId: number;
  applicationTrackerType: string;
  assignedOn: Date;
  assignedTo: string;
  controlNumber: string;
  convertedVersion: string;
  createdBy: string;
  createdDate: Date;
  dueDate: Date;
  id: number;
  product: string;
}

export interface formControlError {
  formControlName: string;
  hasError: boolean;
}

export class AuthorizationDocument {
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  updatedDate: string;
  isActive: boolean;
  eTag: string;
  name: string;
  documentType: string;
  content: string;
  contentLength: string;
  attributes: string;
  constructor(content, file) {
    this.name = file.name;
    this.content = content;
    this.createdBy = "";
    this.updatedBy = "";
    this.createdDate = new Date().toISOString();
    this.updatedDate = new Date().toISOString();
    this.isActive = true;
    this.eTag = null;
    this.contentLength = file.size;
    this.attributes = '';
  }
}