import * as dayjs from 'dayjs';
import {
  ApplicationFilterConfiguration,
  ApplicationSearchInfo,
} from 'src/app/shared/application-filters/application-filters.component';
import { processStatusColors } from 'src/app/shared/constants/process-status-constant';
import { ApplicationStatusQueryId, queriesList, RequestTypeStatus } from '../models/enums';

export function sortByLastName(search) {
  if (!search) {
    return '';
  }
  const letters = search.split(/\s*|[a-zA-Z]/);
  let queryStr = '';
  for (let i = 0; i < letters.length; i++) {
    queryStr += `{
        primaryContactName_starts_with:"${letters[i]}"
      },`;
  }
  return `${queryStr}`;
}

export function sortByType(search) {
  if (!search || search?.length === 0) {
    return '';
  }
  let queryStr = '';
  const types = search.map((type) => type.value);
  for (let i = 0; i < types.length; i++) {
    queryStr += `{
        orderTrackers_some:${`{
          requestType: "${types[i]}"
        }`}
      },`;
  }
  const graphQlStr = `AND: [
    ${queryStr}
    ],`;
  return graphQlStr;
}

export function sortByStates(search) {
  if (!search || search?.length === 0) {
    return '';
  }
  let queryStr = '';
  const states = search.map((type) => `"${type.value}"`);
  queryStr = `state_in:[${states.slice()}]`;
  return queryStr;
}

export function sortBySearchValue(search, type) {
  let queryStr;
  if (!search) {
    queryStr = '';
  } else {
    if (type !== 'name' && type !== 'copyService') {
      queryStr = `
         ${queriesList[type]}:${isQueryTypeNumber(type) ? search : `"${search}"`
        },
      `;
    } else {
      queryStr = '';
    }
  }
  return queryStr;
}
export function searchByNameExact(search, type) {
  let queryStr;
  if (!search) {
    queryStr = '';
  } else {
    if (type === 'name') {
      queryStr = `
      {${queriesList[type]}:"${search}"},
      {orderTrackers_some: {OR: [{ firstName_contains: "${search}" }, { lastName_contains: "${search}"}]}},`;
    } else {
      queryStr = '';
    }
  }
  return queryStr;
}

export function searchByName(search, type) {
  let queryStr;
  if (!search) {
    queryStr = '';
  } else {
    if (type === 'name') {
      const trimmedSearchVal=search.trim();
      queryStr = `
          {
            orderTrackers_some: 
            {OR: 
              [
                { firstName_contains: "${trimmedSearchVal}" }, 
                { lastName_contains: "${trimmedSearchVal}"}
              ]
            }
          },`;
    } else {
      queryStr = '';
    }
  }
  return queryStr;
}

function isQueryTypeNumber(type): boolean {
  const queriesWithId = ['appId'];
  return queriesWithId.includes(type);
}

export function handleApplicationStatusId(search) {
  if (!search) {
    return '';
  }
  if (typeof search === 'number') {
    return `applicationStatusId: ${search},`;
  } else {
    return `applicationStatusId: ${RequestTypeStatus[search]},`;
  }
}

export function handleReminderDate(date) {
  if (!date) {
    return '';
  }
  return `reminderDate_gt: "01-01-1901"`;
}

export function handleExpired(data) {
  if (!data) {
    return '';
  }
  return `{ dueDate_lte: "${dayjs().format('MM-DD-YYYY')}" }`;
}

// export function handleAssignedTo(search) {
//   if (!search.assignedTo || search.lastName === 'ABCDEFGHIJKLMNOPQRSTUVWXYZ') {
//     return '';
//   }
//   return `assignedTo: "${search.assignedTo}",`;
// }

export function handleAssignedTo(assignedTo) {
  if (!assignedTo) {
    return '';
  }
  const loggedUserNameNoDomain = assignedTo.replaceAll('\\', '');
  return `assignedTo_in: ["${assignedTo}","${loggedUserNameNoDomain}"],`;
}

export function handleOrderBy(orderby, page) {
  if (!orderby && page === 'myQueue') {
    return `assignedOn: ASC, id: ASC`;
  } else if (!orderby && page === 'followUp') {
    return `lastFollowUpDate: ASC, id: ASC`;
  }

  let orderByQuery = '';

  switch (orderby) {
    case 'followUpDate':
      orderByQuery = `lastFollowUpDate: ASC`;
      break;
    case 'dueDate':
      orderByQuery = `dueDate: ASC`;
      break;
    case 'dueDate_asc':
      orderByQuery = `dueDate: ASC`;
      break;
    case 'dueDate_desc':
      orderByQuery = `dueDate: DESC`;
      break;
    case 'reminderDate_asc':
      orderByQuery = `reminderDate: ASC`;
      break;
    case 'reminderDate_desc':
      orderByQuery = `reminderDate: DESC`;
      break;
    case 'followUpDate_asc':
      orderByQuery = `lastFollowUpDate: ASC`;
      break;
    case 'followUpDate_desc':
      orderByQuery = `lastFollowUpDate: DESC`;
      break;
    case 'createdDate_asc':
      orderByQuery = `requestCreatedDate: ASC`;
      break;
    case 'createdDate_desc':
      orderByQuery = `requestCreatedDate: DESC`;  
      break;
    case 'expiredApplications':
      orderByQuery = `reminderDate: ASC`;
      break;
    default:
      orderByQuery = `lastFollowUpDate: ASC`;
  }
  return orderByQuery + ', id: ASC';
}

export function handleState(state) {
  if (!state) {
    return '';
  }
  return `state:"${state}"`;
}

export function handleProcessStatus(processStatus) {
  if (processStatus) {
    const processStatuskey = processStatusColors.find((status) => status.value === processStatus).key;
    return `orderTrackers_some: { processStatus: "${processStatuskey}" }`;
  }
  return ''
}
export function handleCopyService(search, type) {
  let queryStr;
  if (!search) {
    queryStr = '';
  } else {
    if (type === 'copyService') {
      queryStr = `
      {orderTrackers_some: {orderPropertyBags_some:{value_contains:"${search}"}}},`;
    } else {
      queryStr = '';
    }
  }
  return queryStr;
}
export function createSearchCriteria(filters: ApplicationSearchInfo) {
  const filter = filters.filters;
  const orderBy = filters.orderBy;
  const paginate = filters.paginate;
  const page = filters?.page;
  const pageDirectionCount = filters?.pageDirectionCount
    ? filters.pageDirectionCount
    : 10;
  const { pageDirection, pageCursor } = getPageInfo(paginate);
  const lastName = sortByLastName(filter.lastName);
  const type = sortByType(filter.type);
  const searchValue = sortBySearchValue(filter.searchValue, filter.filterType);
  const searchName = searchByName(filter.searchValue, filter.filterType);
  const searchNameExact = searchByNameExact(filter.searchValue, filter.filterType);
  const dueDate = handleExpired(filter.expired);
  const reminderDate = handleReminderDate(filter.reminderDate);
  const state = sortByStates(filter.state);
  const applicationStatusId = handleApplicationStatusId(
    filter.applicationStatusId
  );
  const processStatus = handleProcessStatus(filters.filters.processStatus);
  const copyService = handleCopyService(filter.searchValue, filter.filterType)
  const assignedTo = handleAssignedTo(filter.assignedTo);
  const orderByQuery = handleOrderBy(orderBy, page);
  const filterQueries = `${lastName}${dueDate}${searchName}${type}${reminderDate}${searchValue}${applicationStatusId}${assignedTo}${copyService}`;
  const filterQuery = `OR: [${lastName}${dueDate} ${searchName} ${copyService} ]${type}${reminderDate}${searchValue}${applicationStatusId}${assignedTo}`;

  return `${pageDirection}: ${pageDirectionCount},
  ${pageCursor} where :  {${paginate.queryId ? paginate.queryId : ''
    } ${state} ${filterQueries ? filterQuery : ''} ${processStatus ? processStatus : ''}} order: {${orderByQuery}}`;
}

export function createFollowupSearchCriteria(filters: ApplicationSearchInfo) {
  const queryId = ApplicationStatusQueryId.followUp;
  const filter = filters.filters;
  const orderBy = filters.orderBy;
  const page = filters?.page;
  const lastName = sortByLastName(filter.lastName);
  const type = sortByType(filter.type);
  const searchValue = sortBySearchValue(filter.searchValue, filter.filterType);
  const searchName = searchByName(filter.searchValue, filter.filterType);
  const searchNameExact = searchByNameExact(filter.searchValue, filter.filterType);
  const dueDate = handleExpired(filter.expired);
  const reminderDate = handleReminderDate(filter.reminderDate);
  const state = sortByStates(filter.state);
  const applicationStatusId = handleApplicationStatusId(
    filter.applicationStatusId
  );
  const processStatus = handleProcessStatus(filters.filters.processStatus);
  const copyService = handleCopyService(filter.searchValue, filter.filterType)
  const assignedTo = handleAssignedTo(filter.assignedTo);
  const orderByQuery = handleOrderBy(orderBy, page);
  const filterQueries = `${lastName}${dueDate}${searchName}${type}${reminderDate}${state}${searchValue}${applicationStatusId}${assignedTo}${copyService}`;
  const filterQuery = `OR: [${lastName}${dueDate} ${searchName} ${copyService} ]${type}${reminderDate}${searchValue}${applicationStatusId}${assignedTo}`;

  return ` 
  ${filterQueries?`where :  { ${queryId} ${state} ${filterQueries ? filterQuery : ''} ${processStatus ? processStatus : ''}}`: `where: {${queryId} ${processStatus ? processStatus : ''}}`} order: {${orderByQuery}}
   `;
}

export function getPageInfo(paginate: any) {
  if (!paginate.pageDirection) {
    return {
      pageDirection: 'first',
      pageCursor: ``,
    };
  }

  if (paginate.pageDirection === 'BEFORE') {
    return {
      pageDirection: 'last',
      pageCursor: paginate.pageInfo.startCursor
        ? `before:"${paginate.pageInfo.hasPreviousPage
          ? paginate.pageInfo.startCursor
          : paginate.pageInfo.endCursor
        }"`
        : '',
    };
  } else {
    return {
      pageDirection: `${paginate.pageInfo.hasNextPage ? 'first' : 'last'}`,
      pageCursor: paginate.pageInfo.endCursor
        ? `after:"${paginate.pageInfo.hasNextPage
          ? paginate.pageInfo.endCursor
          : paginate.pageInfo.startCursor
        }"`
        : '',
    };
  }
}
