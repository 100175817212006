import { Component, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import {
  CheckRequest,
  HeadersList,
} from 'src/app/aps/models/application-model';
import { AuditTypeId, ColumnType } from 'src/app/aps/models/enums';
import { ApsService } from 'src/app/aps/services/aps-service';
import { FollowUpLog, LogsAndNotes } from 'src/app/nbc/Models/nbc-models';
import { Actions } from 'src/app/store/actions';
import {
  GetPaymentCheck,
  SelectedApplicationDetailsByApplicationId,
} from 'src/app/store/dispatchers';
import { paymentsCheckHeaders } from '../../../selected-application-container/selected-application-container.config';
import { demographicDetails, emailLogHeaders } from './edit-or-followup-config';

@Component({
  selector: 'app-edit-or-followup-order',
  templateUrl: './edit-or-followup-order.component.html',
  styleUrls: ['./edit-or-followup-order.component.scss'],
})
export class EditOrFollowupOrderComponent implements OnInit {
  orderDetails;
  emailLogsHeaders: HeadersList[] = emailLogHeaders;
  emailLogs = [];
  orderTrackerId: number;
  paymentCheckData: Observable<CheckRequest[]>;
  paymentsCheckHeaders: HeadersList[] = paymentsCheckHeaders(this);
  showFollowUp: boolean;
  public requestType: string;
  public readonly APS = 'APS';
  config = {
    width: '70%',
    contentStyle: { height: '780px', overflow: 'auto' },
  };
  detailsToDisplay = demographicDetails;
  logsAndNotes: LogsAndNotes[];
  constructor(
    private dialogService: DynamicDialogConfig,
    public apsService: ApsService,
    private store: Store
  ) { }

  ngOnInit() {
    this.showFollowUp =
      this.dialogService.data &&
        this.dialogService.data.showFollowUp !== undefined
        ? this.dialogService.data.showFollowUp
        : false;
    this.getEmailLogs();
    this.getPaymentCheckData();
    this.store
      .select((state) => state.data.currentOrderData)
      .subscribe((res) => {
        this.orderDetails = res;
      });
    this.fetchAuditLogs();

  }

  getEmailLogs() {
    const { orderTrackerId, requestType } = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    this.orderTrackerId = orderTrackerId;
    this.requestType = requestType;

    this.emailLogs = this.store.selectSnapshot(
      Actions.AuditLogsById([AuditTypeId.EMAIL_LOGS])
    );
    this.emailLogs = [...this.emailLogs];
    this.emailLogs =
      this.emailLogs.length > 0
        ? this.emailLogs
          .map((emailLog) => {
            const information = JSON.parse(emailLog.information);
            return (emailLog = {
              ...emailLog,
              ...information?.emailParameters,
              ...information,
            });
          })
          .filter((log) => log.orderTrackerId === orderTrackerId)
        : [];
  }

  fetchAuditLogs() {
    this.store
      .select(
        Actions.AuditLogsById([
          AuditTypeId.FOLLOWUP_LOG,
          AuditTypeId.TO_UWS,
        ])
      ).pipe(
        tap((res: FollowUpLog[]) => {
          this.logsAndNotes = res.filter((log) => ["TO_UWS", "TO_APS"].includes(log.auditParameter) && this.orderDetails.orderTrackerId === log.orderTrackerId);
          this.logsAndNotes = this.logsAndNotes.sort((a, b) => {
            return (
              new Date(b.loggedDate).getTime() -
              new Date(a.loggedDate).getTime()
            );
          })

        })).subscribe();
  }

  tabViewChangeHandler({ index }) {
    index === 2 ? this.getEmailLogs() : '';
  }

  async getPaymentCheckData() {
    await this.store.dispatch(new GetPaymentCheck()).toPromise();
    this.paymentCheckData = this.store
      .select((state) => state.data.paymentChecks)
      .pipe(
        map((res: CheckRequest[]) => {
          let data = [];
          if (res) {
            data = res.filter((data) => {
              return data.orderTrackerId === this.orderDetails.orderTrackerId;
            });
          }
          return data;
        })
      );
  }
}
