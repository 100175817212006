import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { DividerModule } from 'primeng/divider';
import { PanelModule } from 'primeng/panel';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MenuModule } from 'primeng/menu';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ToolbarModule } from 'primeng/toolbar';
import { ChartModule } from 'primeng/chart';
import { ProgressBarModule } from 'primeng/progressbar';
import { ChipModule } from 'primeng/chip';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { BadgeModule } from 'primeng/badge';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CarouselModule } from 'primeng/carousel';
@NgModule({
  imports: [
    CommonModule,
    TabMenuModule,
    MenubarModule,
    CardModule,
    DividerModule,
    PanelModule,
    ButtonModule,
    TagModule,
    MessagesModule,
    MessageModule,
    MenuModule,
    CalendarModule,
    DropdownModule,
    OverlayPanelModule,
    ToolbarModule,
    ChartModule,
    ProgressBarModule,
    ChipModule,
    TableModule,
    ToastModule,
    BadgeModule,
    InputTextModule,
    AccordionModule,
    SidebarModule,
    ProgressSpinnerModule,
    CarouselModule
  ],
  declarations: [],
  exports:[
    TabMenuModule,
    MenubarModule,
    CardModule,
    DividerModule,
    PanelModule,
    FormsModule,
    ButtonModule,
    ReactiveFormsModule,
    TagModule,
    MessagesModule,
    MessageModule,
    MenuModule,
    CalendarModule,
    DropdownModule,
    OverlayPanelModule,
    ToolbarModule,
    ChartModule,
    ProgressBarModule,
    ChipModule,
    TableModule,
    ToastModule,
    BadgeModule,
    InputTextModule,
    AccordionModule,
    SidebarModule,
    ProgressSpinnerModule,
    CarouselModule
  ]
})
export class PrimengModule { }
