import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PageStateService {
  constructor(private route: ActivatedRoute, private router: Router) {}

  public getPageStateInformationFromUrlOrSessionStorage(page: string) {
    return this.route.queryParams.pipe(
      map(() => {
        const sessionData = sessionStorage[page] ? '{}' : '{}';
        const paramInfo = JSON.parse(sessionData);

        this.updateUrlWithPageStateInformation(page, paramInfo);

        return paramInfo;
      })
    );
  }

  public updateUrlWithPageStateInformation(page, filters) {
    const params = JSON.stringify(filters);

    this.router.navigate([], {
      relativeTo: this.route,
      // queryParams: {
      //   stateInformation: params,
      // },
    });

    // window.sessionStorage.setItem(page, params);
  }
}
