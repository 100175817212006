import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { Observable } from 'rxjs';
import { OrderCreatedModelComponent } from 'src/app/aps/components/core/create-order-components/create-lab-order-ae-components/order-created-model/order-created-model.component';
import { NavigationService } from 'src/app/aps/services/aps-navigation-service';
import { ApsService } from 'src/app/aps/services/aps-service';
import { Navigation } from 'src/app/shared/services/navigation';
import { UshgFormService } from 'src/app/shared/services/ushg-form-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { EmailSentModalComponent } from '../../aps/components/core/create-order-components/create-order-modal-components/email-sent-modal/email-sent-modal.component';
import {
  faxDetailsConfig,
  secureEmailConfig,
} from './secure-email-form-config';
import { AnalyticsService } from 'src/app/aps/services/analytics.service';
import { SendSecureEmailComponent } from '../send-secure-email/send-secure-email.component';
import { DocumentType, SECURE_EMAIL_TEMPLATES, TemplateTitle } from 'src/app/aps/models/enums';
import { formConfig } from '../models/formconfig-model';
import { CurrentOrderData, RequestOrderPdf, ResetRequestOrderPdf } from 'src/app/store/dispatchers';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
})
export class SendEmailComponent extends Navigation implements OnInit {
  routerData: any = this.store.selectSnapshot(
    (state) => state.data.currentOrderData
  );
  providerPreferences: any = this.store.selectSnapshot(
    (state) => state.data.providerPreferences
  );
  documents = this.store.selectSnapshot((state) => state.data.requestOrderPdf);
  acknowledged;
  withoutAuthorizationEmail = false
  secureEmailForm: UntypedFormGroup;
  secureEmailConfig: formConfig[] = secureEmailConfig(this);
  hasEmailSentToApplicant = false;
  constructor(
    private navigation: NavigationService,
    private formService: UshgFormService,
    private dialogService: UshgDialogService,
    private router: Router,
    private store: Store,
    private apsService: ApsService,
    private sanitizer: DomSanitizer,
    private analyticsService: AnalyticsService
  ) {
    super(navigation);
  }

  ngOnInit(): void {
    this.getOrderData();
    this.initForm();
  }

  initForm() {
    this.routerData.agent.agentEmailAdress = this.routerData.agent.agentEmailAdress ? this.routerData.agent.agentEmailAdress.toUpperCase() : '';
    this.secureEmailForm = this.formService.createForm(this.secureEmailConfig, {
      ...this.routerData,
      ...this.routerData.orderDetails?.APS?.providerOverride?this.routerData?.orderDetails?.APS?.providerOverride:this.routerData?.orderDetails?.APS?.provider,
      email: this.routerData.contact.applicantEmailAddress,
      ...this.routerData.agent,
    });
    console.log(this.secureEmailForm)
    if (this.routerData && this.routerData.orderType.requestType !== 'APS') {
      this.secureEmailForm.patchValue({ template: SECURE_EMAIL_TEMPLATES.LABORATORY });
    };
    this.markDocumentsAsChecked();
  }

  markDocumentsAsChecked() {
    this.documents.map((document) => {
      if (this.routerData.isEditOrder) {
        document.isChecked = document.isNewlyAdded && document.documentType !== TemplateTitle.AUTHORIZATION_WITHOUT_E_SIGNATURE ? true : false; //for Edit Order  newly added documents will be selected by default
      } else {
        document.isChecked = true; // for CreateOrder every document has to be checked
      }
      return document;
    });
  }

  sendEmail() {
    const currentOrderData = this.store.selectSnapshot((state) => state.data.currentOrderData)
    currentOrderData.stopRedirection = true;
    this.store.dispatch(new CurrentOrderData(currentOrderData))
    this.addAnalytics();
    this.apsService.createOrderRequest(this.secureEmailForm, true);
  }

  addAnalytics() {
    const orderStarted = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    ).orderStarted;
    this.analyticsService.setOrderEnded(orderStarted);
  }

  downloadFile(file) {
    this.apsService.downloadDocument(file.name);
  }

  getOrderData() {
    if (this.routerData && this.routerData.orderType.requestType === 'APS') {
      this.secureEmailConfig = faxDetailsConfig(this);
    }
  }

  checkBoxHandler(ev) {
    this.acknowledged = ev.checked;
  }

  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  checkIfRequired(key) {
    return key?.validators?.some((element) => element?.name === 'required');
  }

  sendEmailToApplicant(file) {
    file.checked = true;
    const currentOrderData = JSON.parse(JSON.stringify(this.routerData));
    currentOrderData.template = SECURE_EMAIL_TEMPLATES.SIGNED_AUTHORIZATION;
    currentOrderData.orderDocuments.documents = [file];
    currentOrderData.stopRedirection = true;
    this.store.dispatch(new CurrentOrderData(currentOrderData))
    this.dialogService.open(SendSecureEmailComponent, currentOrderData).onClose.subscribe((res) => {
      this.hasEmailSentToApplicant = res;
    })
  }

  get TemplateTitle(): typeof TemplateTitle {
    return TemplateTitle;
  }

  async documentscheckBoxHandler(ev, file) {
    this.documents.map((document) => {
      if (document.name === file.name) {
        document.isChecked = ev.checked;
      }
      return document;
    });
    await this.store.dispatch(new ResetRequestOrderPdf()).toPromise();
    this.store.dispatch(new RequestOrderPdf(this.documents));
  }

  get documentsCheckBoxValidation() {
    return this.documents.some((document) => document.isChecked)
  }
}
