export const applicationOverviewMetrics = [
  {
    status: "totalQuotes",
    count: 0
  },
  {
    status: "inprogress",
    count: 10
  },
  {
    status: "policyIssued",
    count: 0
  },
  {
    status: "declined",
    count: 0
  }
]


export const InprogressList = [
  {
    appId: "4004753",
    primaryApplicant: "Daniel Jacob",
    status: "Submitted",
    details: {
      scheduleDate: "",
      status: "VCall - Not Scheduled"
    }
  },
  {
    appId: "6009283",
    primaryApplicant: "Michael Ryan",
    status: "Submitted",
    details: {
      scheduleDate: "",
      status: "App - Incomplete"
    }
  },
  {
    appId: "4020601",
    primaryApplicant: "Michael Mathew",
    status: "AppointmentScheduled",
    details: {
      scheduleDate: "11-12-2021 10:45 AM",
      status: "To Do"
    }
  },
  {
    appId: "4004753",
    primaryApplicant: "Sam Simon",
    status: "AppointmentScheduled",
    details: {
      scheduleDate: "11-09-2021 10:45 AM",
      status: "Stop Call"
    }
  },
  {
    appId: "3008271",
    primaryApplicant: "Will Smith",
    status: "WithUnderwriting",
    details: {
      scheduleDate: "12-09-2021 01:45 AM",
      status: "With Underwriting"
    }
  },
  {
    appId: "3008277",
    primaryApplicant: "Micheal William",
    status: "RecallAppointmentScheduled",
    details: {
      vCallCompletedDate: "12-09-2021 01:50 AM",
      status: "Pending Recall",
      recallScheduledDate: "13-09-2021 01:50 AM"
    }
  },
  {
    appId: "3008288",
    primaryApplicant: "Steve Johnson",
    status: "RecallAppointmentScheduled",
    details: {
      assignedTo: "Usha",
      status: "APS Pending",
      vCallCompletedDate: "12-09-2021 01:45 PM"
    }
  },
  {
    appId: "3008888",
    primaryApplicant: "Nichole Van",
    status: "WithUnderwriting",
    details: {
      vCallCompletedDate: "01-12-2021 05:00 PM",
      status: "On Hold",
      assignedTo: "Mike"
    }
  },
  {
    appId: "3007777",
    primaryApplicant: "Json Roy",
    status: "WithUnderwriting",
    details: {
      vCallCompletedDate: "01-12-2021 05:00 PM",
      status: "With APS",
      assignedTo: "Mike"
    }
  },
  {
    appId: "2005678",
    primaryApplicant: "Daniel G",
    status: "With_APSTeam",
    details: {
      vCallCompletedDate: "03-09-2021 12:00 PM",
      status: "With APS",
      assignedTo: "Mike"
    }
  },
  {
    appId: "2003214",
    primaryApplicant: "Andrew Simonds",
    status: "APS_OrderQueue",
    details: {
      vCallCompletedDate: "03-09-2021 12:00 PM",
      status: "With APS",
      assignedTo: "Mike"
    }
  },
  {
    appId: "2018268",
    primaryApplicant: "Micheal Clark",
    status: "APS_Pending",
    details: {
      vCallCompleted: "05-10-2021 09:50 PM",
      status: "With APS",
      assignedTo: "Mike"
    }
  },
  {
    appId: "2018180",
    primaryApplicant: "Steve Smith",
    status: "With_Issue_Team",
    details: {
      vCallCompletedDate: "03-09-2021 12:00 PM",
      status: "With Issue Team"
    }
  },
  {
    appId: "2018181",
    primaryApplicant: "Steve",
    status: "With_Issue_Team",
    details: {
      vCallCompletedDate: "03-09-2021 12:00 PM",
      status: "With Issue Team"
    }
  },
  {
    appId: "2018182",
    primaryApplicant: "Smith",
    status: "With_Issue_Team",
    details: {
      vCallCompletedDate: "03-09-2021 12:00 PM",
      status: "With Issue Team"
    }
  }
]
