export const AUTH_FORM_CONSTANTS = {
    PDF_ICON_PATH: 'assets/icons/PDF_Icon.svg',
    PREVIEW_ICON_PATH: 'assets/icons/preview.png',
    DOWNLOAD_ICON_PATH: 'assets/icons/download.png',
    CALENDER_ICON_PATH: 'assets/icons/calender.svg',
    SUCCESS_MESSAGE: 'Form Uploaded Successfully',
    ERROR_MESSAGE: 'Something Went Wrong',
    AUTH_FORM_TITLE: 'Add New Authorization Form'
}

export const US_STATES = [
    { LABEL: 'Alabama', VALUE: 'AL' },
    { LABEL: 'Alaska', VALUE: 'AK' },
    { LABEL: 'Arizona', VALUE: 'AZ' },
    { LABEL: 'Arkansas', VALUE: 'AR' },
    { LABEL: 'California', VALUE: 'CA' },
    { LABEL: 'Colorado', VALUE: 'CO' },
    { LABEL: 'Connecticut', VALUE: 'CT' },
    { LABEL: 'Delaware', VALUE: 'DE' },
    { LABEL: 'Florida', VALUE: 'FL' },
    { LABEL: 'Georgia', VALUE: 'GA' },
    { LABEL: 'Hawaii', VALUE: 'HI' },
    { LABEL: 'Idaho', VALUE: 'ID' },
    { LABEL: 'Illinois', VALUE: 'IL' },
    { LABEL: 'Indiana', VALUE: 'IN' },
    { LABEL: 'Iowa', VALUE: 'IA' },
    { LABEL: 'Kansas', VALUE: 'KS' },
    { LABEL: 'Kentucky', VALUE: 'KY' },
    { LABEL: 'Louisiana', VALUE: 'LA' },
    { LABEL: 'Maine', VALUE: 'ME' },
    { LABEL: 'Maryland', VALUE: 'MD' },
    { LABEL: 'Massachusetts', VALUE: 'MA' },
    { LABEL: 'Michigan', VALUE: 'MI' },
    { LABEL: 'Minnesota', VALUE: 'MN' },
    { LABEL: 'Mississippi', VALUE: 'MS' },
    { LABEL: 'Missouri', VALUE: 'MO' },
    { LABEL: 'Montana', VALUE: 'MT' },
    { LABEL: 'Nebraska', VALUE: 'NE' },
    { LABEL: 'Nevada', VALUE: 'NV' },
    { LABEL: 'New Hampshire', VALUE: 'NH' },
    { LABEL: 'New Jersey', VALUE: 'NJ' },
    { LABEL: 'New Mexico', VALUE: 'NM' },
    { LABEL: 'New York', VALUE: 'NY' },
    { LABEL: 'North Carolina', VALUE: 'NC' },
    { LABEL: 'North Dakota', VALUE: 'ND' },
    { LABEL: 'Ohio', VALUE: 'OH' },
    { LABEL: 'Oklahoma', VALUE: 'OK' },
    { LABEL: 'Oregon', VALUE: 'OR' },
    { LABEL: 'Pennsylvania', VALUE: 'PA' },
    { LABEL: 'Rhode Island', VALUE: 'RI' },
    { LABEL: 'South Carolina', VALUE: 'SC' },
    { LABEL: 'South Dakota', VALUE: 'SD' },
    { LABEL: 'Tennessee', VALUE: 'TN' },
    { LABEL: 'Texas', VALUE: 'TX' },
    { LABEL: 'Utah', VALUE: 'UT' },
    { LABEL: 'Vermont', VALUE: 'VT' },
    { LABEL: 'Virginia', VALUE: 'VA' },
    { LABEL: 'Washington', VALUE: 'WA' },
    { LABEL: 'West Virginia', VALUE: 'WV' },
    { LABEL: 'Wisconsin', VALUE: 'WI' },
    { LABEL: 'Wyoming', VALUE: 'WY' }
];

export const PROVIDER_PARAMETER = {
    PROVIDERNAME: 'DOCTOR NAME',
    FECILITYNAME: 'FACILITY NAME',
    STATE: 'STATE',
    CITY: 'CITY',
    ZIPCODE: 'ZIPCODE'
}