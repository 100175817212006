<ng-container *ngIf="appFilterConfig">
  <div class="d-flex">

    <div class="col-12 p-0" *ngIf="appFilterConfig.displayStatusDropdown">
      <ushg-drop-down [dropDown]="statusFilter" [selectedValue]="applicationStatusId"></ushg-drop-down>
    </div>

    <div class="col-3 pr-1 pl-1 p-0" *ngIf="appFilterConfig.displayTypeDropdown">
      <ushg-multi-select-dropdown [dropDown]="typeFilter" [selectedValue]="type"></ushg-multi-select-dropdown>
    </div>
    <div class="col-3 p-0" *ngIf="appFilterConfig.displayStatesDropdown">
      <ushg-multi-select-dropdown [dropDown]="stateFilter" [selectedValue]="state"></ushg-multi-select-dropdown>
    </div>
    <div class="col-3 ml-1 p-0 process-status" *ngIf="appFilterConfig.displayProcessStatus">
      <p-dropdown [options]="processStatusColors" (ngModelChange)="updateStatusColor($event)" [(ngModel)]="statusColor"
        placeholder='Process status' pTooltip='Search By process status'>
        <ng-template pTemplate="selectedItem">
          <div class="selected">
            <div [style.backgroundColor]="statusColor" class="circle"></div>
          </div>
        </ng-template>
        <ng-template let-statusColor pTemplate="item">
          <div appChangeBackgroundColor [backGroundColor]="statusColor.id" class="circle"></div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="col-3 pr-1 p-0" *ngIf="appFilterConfig.displayLastNameDropdown" class="lastNameSearch">
      <input pInputText [(ngModel)]="lastName" (keyup.enter)="search()" name="lastName" class="ml-1" placeholder="Search by Alphabets"
        pTooltip='Search By Alphabets' />
    </div>
  </div>
  <div *ngIf="appFilterConfig.displaySearchByTypeRadio">
    <div class="flex text-xs">
      <div class="col-3 mt-1">
        <label class="text-xs text-700">
          Search By
        </label>
      </div>
      <div class="col-3 field-radiobutton">
        <p-radioButton name="groupname" value="name" id="name" [(ngModel)]="filterType"></p-radioButton>
        <label for="name">Name</label>
      </div>
      <div class="col-3  field-radiobutton">
        <p-radioButton name="groupname" value="appId" id="appId" [(ngModel)]="filterType"></p-radioButton>
        <label for="appId">AppId</label>
      </div>
      <!-- <div class="col-3  field-radiobutton">
        <p-radioButton name="groupname" value="policyNumber" id="policyNumber" [(ngModel)]="filterType" ></p-radioButton>
        <label for="policyNumber">PolicyNumber</label>
      </div> -->
      <div class="col-3  field-radiobutton">
        <p-radioButton name="groupname" value="controlNumber" id="controlNo" [(ngModel)]="filterType"></p-radioButton>
        <label for="controlNo">ControlNo</label>
      </div>
    </div>
    <div class="d-flex align-items-center">
      <ushg-search-input placeholder="Search" (searchInputEventHandler)="searchApplications($event)"
        [disable]="!!!filterType" [selectedValue]="searchValue" class="col-12 p-0"></ushg-search-input>
    </div>
  </div>
  <div class="d-flex align-items-center mt-2" *ngIf="appFilterConfig.displaySearchByTypeDropdown">
    <ushg-drop-down [dropDown]="SearchByTypes" class="col-6 p-0" [selectedValue]="searchByType"></ushg-drop-down>
    <ushg-search-input placeholder="Search" (searchInputEventHandler)="searchApplications($event)"
      [disable]="!!!filterType" [selectedValue]="searchValue" class="col-6 p-0 ml-2"
      *ngIf="!switchToCopyServiceDropDown"></ushg-search-input>
    <ushg-drop-down [dropDown]="CopyServices" [disabled]="disableCopyServiceDropdown" [selectedValue]="copyServiceType"
      (select)="searchApplications($event)" [filterByTyping]="true" class="col-6 p-0 ml-2"
      *ngIf="switchToCopyServiceDropDown"></ushg-drop-down>
  </div>
  <div class="d-flex justify-content-between col-12 pl-1 p-0">
    <div class="d-flex flex-row">
      <div class="p-1" *ngIf="appFilterConfig.displayShowExpiredIcon">
        <button class="search-button" [class.followUpDateActive]="expired" (click)="showExpiredApplications()"
          icon="pi pi-check" pTooltip="Expired Orders">
          <div class="expired-icon" [class.f]="expired">
            <img src="assets/logo/Show_Expired.svg" alt="" width="14px" />
          </div>
        </button>
      </div>
      <div class="p-1" *ngIf="appFilterConfig.displayReminderIcon">
        <button class="search-button" [class.followUpDateActive]="reminderDate" (click)="showReminderDate()"
          icon="pi pi-check" pTooltip="Reminder">
          <i class="fas fa-bell"></i>
        </button>
      </div>
      <div class="p-1 d-flex justify-content-center" *ngIf="appFilterConfig.displayOrderByDropdown">
        <button [class.followUpDateActive]="orderByStatus" type="button" pButton icon="pi pi-sort-alt"
          (click)="menu.toggle($event)" pTooltip="Sort by"></button>
        <p-menu #menu [popup]="true" [model]="orderByFilter"></p-menu>
      </div>
    </div>

    <div class="d-flex p-1 justify-content-end pr-0">
      <div class="p-1">
        <button type="button" class="search-button" [ngClass]="{'p-disabled':canDisableClearButton()}"
          (click)="clearFilters()" icon="pi pi-check" pTooltip="Clear">
          <i class="fa-solid fa-trash"></i>
          <!-- <div class="expired-icon">
            </div> -->
        </button>
      </div>
      <div class="p-1 pr-0">
        <button type="button" class="search-button" [ngClass]="{'p-disabled':canDisableSearchButton()}"
          (click)="search()" icon="pi pi-check" pTooltip="Search">
          <i class="fa-solid fa-magnifying-glass"></i>
        </button>
      </div>
    </div>
  </div>
</ng-container>