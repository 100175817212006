import { AuditTypeId, ColumnType, OrderStatus } from 'src/app/aps/models/enums';
import { ProcessStatusPayload } from 'src/app/aps/models/ui-models';

export const UWDecisionHeadersList = [
  { label: 'Policy Number', field: 'PolicyNo' },
  { label: 'Policy Name', field: 'policyName' },
  { label: 'Client Number', field: 'ClientNo' },
  { label: 'Client Name', field: 'clientName' },
  { label: 'BR Code', field: 'BRCode' },
  { label: 'Decision Code', field: 'DecisionCode' },
  { label: 'Reason Code', field: 'ReasonCode' },
  { label: 'Reason Description', field: 'ReasonDescription' },
  { label: 'Last Changed By', field: 'LastChangedBy' },
];

export const auditLogHeadersList = [
  {
    label: 'User Name',
    field: 'loggedBy',
    type: ColumnType.assignedTo,
    class: 'w-15',
  },
  {
    label: 'Date',
    field: 'loggedDate',
    type: ColumnType.date,
    class: 'w-15',
  },
  {
    label: 'Activity Type',
    field: 'auditParameter',
    type: ColumnType.auditParameter,
    class: 'w-15',
  },
  {
    label: 'Notes',
    field: 'notes',
    type: ColumnType.information,
    class: 'text-left text-justify w-55',
  },
];

export const notesHeadersList = (This) => {
  return [
    {
      label: 'Date',
      field: 'loggedDate',
      type: ColumnType.date,
      class: 'w-15',
    },
    {
      label: 'Added By',
      field: 'loggedBy',
      type: ColumnType.assignedTo,
      class: 'w-15',
    },
    {
      label: 'Order ID',
      field: 'orderTrackerId',
      type: ColumnType.general,
      class: 'w-15',
    },
    {
      label: 'Notes',
      field: 'information',
      type: ColumnType.information,
      class: 'w-60',
    },
    {
      label: '',
      field: 'orderTrackerId',
      type: ColumnType.orderTrackerId,
      actionList: (data) => {
        return data.orderTrackerId !== null
          ? This.notesActionsList
            .map((action) => {
              return action;
            })
            .filter((action) => action.label == 'Edit')
          : [];
      },
      class: 'w-10',
    },
  ];
};

// Requests Tab
export const headersList = (This) => {
  return [
    {
      label: '',
      field: 'aplicationProcessState',
      type: ColumnType.aplicationProcessState,
      onChange: (data: ProcessStatusPayload) => {
        This.followUpProcessOrderStatus(data);
      },
    },
    {
      label: 'Request Date',
      field: 'createdDate',
      type: ColumnType.DATE_ONLY,
    },
    { label: 'Applicant', field: 'orderContactName' },
    {
      label: 'Request Type',
      field: 'orderType',
      type: ColumnType.OrderRequestType,
    },

    {
      label: 'Doctor/Physician',
      field: 'originalproviderFullName',
      type: ColumnType.general,
    },
    { label: 'UW Reason/Notes', field: 'uwReasonTypes', subField: 'notes', type: ColumnType.uwReason },
    {
      label: 'UW Notes',
      field: '',
      type: ColumnType.uw_notes,
      toolsList: [
        {
          label: '',
          field: '',
          type: '',
          icon: '',
          count: (data) => {
            let count = 0
            const logsAndNotes = This.store.selectSnapshot(state => state.data.logsAndNotes)
            count = logsAndNotes?.filter((log) => [AuditTypeId.TO_UWS].includes(log.auditTypeId) && data.orderTrackerId === log.orderTrackerId).length
            return count
          },
          data: (refData) => {
            let data = []
            const logsAndNotes = This.store.selectSnapshot(state => state.data.logsAndNotes)
            data = logsAndNotes?.filter((log) => [AuditTypeId.TO_UWS].includes(log.auditTypeId) && refData.orderTrackerId === log.orderTrackerId)
            return data;
          },
        },
      ],
    },
    {
      label: 'APS Notes',
      field: '',
      type: ColumnType.aps_notes,
      toolsList: [
        {
          label: '',
          field: '',
          type: '',
          icon: '',
          count: (data) => {
            let count = 0
            const logsAndNotes = This.store.selectSnapshot(state => state.data.logsAndNotes)
            count = logsAndNotes?.filter((log) => [AuditTypeId.TO_APS].includes(log.auditTypeId) && data.orderTrackerId === log.orderTrackerId).length
            return count
          },
          data: (refData) => {
            let data = []
            const logsAndNotes = This.store.selectSnapshot(state => state.data.logsAndNotes)
            data = logsAndNotes?.filter((log) => [AuditTypeId.TO_APS].includes(log.auditTypeId) && refData.orderTrackerId === log.orderTrackerId)
            return data;
          },
        },
      ],
    },
    {
      label: 'Status',
      field: 'orderStatusId',
      type: ColumnType.orderStatusId,
      reminder: 'reminderDate',
    },
    // { label: 'Documents', field: 'orderDocuments', type: ColumnType.documents },
    {
      label: '',
      field: '',
      type: ColumnType.tools,
      toolsList: [
        {
          label: 'Documents',
          field: 'orderDocuments',
          type: 'orderDocuments',
          icon: '../../../assets/icons/Files_Icon.svg',
          count: (data) => {
            return data && data.orderDocuments && data.orderDocuments.documents
              ? data &&
              data.orderDocuments &&
              data.orderDocuments.documents.length
              : 0;
          },
          data: (data) => {
            return data && data.orderDocuments && data.orderDocuments.documents
              ? data && data.orderDocuments && data.orderDocuments.documents
              : [];
          },
        }
      ],
    },
    {
      label: 'Actions',
      field: 'status',
      type: ColumnType.actions,
      actionList: (data) => {
        return data.orderStatusId > 1
          ? data.requestType === 'APS'
            ? This.generateApsFollowUpButtonsList(data)
            : followUpActionsList(This, data)
          : createOrderActionList(This);
      },
    },
  ];
};

export const paymentsCheckHeaders = (This) => {
  return [
    { label: 'Order ID', field: 'orderTrackerId' },
    { label: 'Check Name', field: 'checkName' },
    {
      label: 'Request Date',
      field: 'checkRequestDateUTC',
      type: ColumnType.date,
    },
    { label: 'Cost', field: 'amount', type: ColumnType.amount },
    { label: 'Address', field: 'address' },
    { label: 'City', field: 'city' },
    { label: 'State', field: 'state' },
    { label: 'Zip', field: 'zip' },
    { label: 'Description', field: 'description' },
    {
      label: 'Actions',
      field: 'status',
      type: ColumnType.actions,
      actionList: (data) => {
        return paymentCheckTabActionButtons(data, This);
      },
    },
  ];
};

export const paymentCheckTabActionButtons = (data, This) => {
  return [
    {
      icon: 'pi pi-download text-primary font-light',
      label: 'Download',
      type: 'primarylg',
      disable: data && data.documentName === null,
      onClick: (data) => {
        This.apsService.downloadDocument(data.documentName, data);
      },
    },
  ];
};

export const followUpActionsList = (This, data) => {
  const actionButtons = This.followUpOrderActions
    .map((action) => {
      [
        OrderStatus.CloseOut,
        OrderStatus.Completed,
        OrderStatus.Cancelled,
      ].includes(data.orderStatusId)
        ? (action.disable =
          action.label === 'Edit Order'
            ? true
            : false)
        : (action.disable = false);
      return action;
    })
    .filter(
      (action) =>
        action.label == 'Edit' ||
        action.label == 'Edit Order' ||
        action.label == 'Open'
    );
  let buttons = [];
  actionButtons.forEach((actionButton) => {
    This.permissionService
      .IsPermitted(actionButton.permission)
      .subscribe((res) => {
        if (res) {
          buttons.push(actionButton);
        }
      });
  });

  return buttons;
};

export const createOrderActionList = (This) => {
  let actionButtons = This.createOrderActions;
  return actionButtons;
};
