import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import {  ofActionCompleted, Select, Store, Actions as storeActionHandlers } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {
  Application,
  ApsUserModel,
  selectedApplicantdetailsModel,
} from 'src/app/aps/models/application-model';
import * as dayjs from 'dayjs';
import {
  ApplicationStatus,
  ApplicationStatusQueryId,
  PAGE_STATE,
  RedirectUrl,
} from 'src/app/aps/models/enums';
import { ApsService } from 'src/app/aps/services/aps-service';
import { OrdersList } from 'src/app/aps/services/jsondata';
import {
  ApplicationFilterConfiguration,
  ApplicationSearchInfo,
} from 'src/app/shared/application-filters/application-filters.component';
import {
  ApplicationsForFollowUpQueue,
  AssignApplicationsToApsUser,
  ClearQuery,
  FilterAssignedApplicationsForFollowupQueue,
  SearchFilters,
  SelectedApplicationDetails,
  SetPageNo,
  UpdateSelectedApplicationDetails,
} from 'src/app/store/dispatchers';
import { PageStateService } from 'src/app/shared/services/page-state.service';
import { ApplicationFilterData } from 'src/app/aps/models/filter-data-model';
import { UtilityService } from 'src/app/aps/services/utility-service';
import { OrderService } from 'src/app/aps/services/aps/order-service';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { createFollowupSearchCriteria, createSearchCriteria } from 'src/app/aps/services/graphql-service';
import { processStatusColors } from 'src/app/shared/constants/process-status-constant';
import { ActivatedRoute } from '@angular/router';
import { orderByFilterWithSorting } from 'src/app/shared/constants/filter-constants';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import { Actions } from 'src/app/store/actions';
import { LockOrReleaseApplication } from 'src/app/aps/models/lock-or-release-application';
import { environment } from 'src/environments/environment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-followup-queue',
  templateUrl: './followup-queue.component.html',
  styleUrls: ['./followup-queue.component.scss'],
  providers: [PageStateService, ToastrService],
})
export class FollowupQueueComponent
  implements OnInit, AfterContentChecked, AfterViewInit {
  assignedApplications$: Observable<Application[]> = this.store.select(
    (state) => state.data.assignedApplicationsForFollowup
  );
  profile = this.store.selectSnapshot((state) => state.data.userProfile);
  apsUsers$ = this.store.select((state) => state.data.apsUsers);
  canDisplayFollowupLockFeature = environment.feature.followupLock;
  @Select(Actions.appPropertiesValues) appProperties$:Observable<any>;
  appFilterConfig: ApplicationFilterConfiguration;
  showReminderDate = false;
  disableActions: boolean;
  apsUsers: ApsUserModel[] = [];
  canEdit: boolean;
  currentPage = 0;
  readonly FOLLOWUP_QUEUE = 'followUp';
  applicationFilterData: ApplicationSearchInfo;
  selectedIndex: number = 0;
  listOfApplications: Application[] = [];
  selectedApplicationIndex = 0;
  selectedApplication: Application;
  ordersList = OrdersList;
  selectedApplicationDetails = selectedApplicantdetailsModel;
  lastName: string;
  timeInQueue: string;
  reminderDate: string;
  expired: string;
  type: string;
  searchValue: string;
  applicationStatusId: number;
  orderBy: string;
  pageNumber = 1;
  processStatusColors = processStatusColors;
  lockViewModel: LockOrReleaseApplication;
  first: number = 0;
  defaultSkip: number = 0;
  take: number = 10;
  componentConfiguration = {
    component: 'followUp',
  };

  constructor(
    private store: Store,
    private apsService: ApsService,
    private cd: ChangeDetectorRef,
    private pageStateService: PageStateService,
    private storeActionHandlers: storeActionHandlers,
    private orderService: OrderService,
    private utilityService: UtilityService,
    private toastrService: ToastrService,
    private router: ActivatedRoute,
    private signalrService: SignalrService
  ) {
    this.selectedApplication = null;
  }

  public get ApplicationStatus(): typeof ApplicationStatus {
    return ApplicationStatus;
  }

  public setApplicationFilters(data) {
    if (!this.utilityService.isEmpty(data)) {
      this.applicationFilterData = new ApplicationFilterData(data, this)
        .filterData as ApplicationSearchInfo;
    } else {
      this.orderBy = data.orderBy;
      this.applicationFilterData = new ApplicationFilterData(this, this)
        .filterData as ApplicationSearchInfo;
    }
  }

  public ngOnInit(): void {
    this.apsUsers$.subscribe((res) => {
      this.apsUsers = res;
    });
    this.startSinglarConnection();
    this.store.select((state)=>state.data.appProperties).subscribe((res )=>{
      this.processActionsDisable(res);
    })
    this.appProperties$.subscribe(res => {
      this.processActionsDisable(res);
    });
  }

  startSinglarConnection() {
    this.signalrService.startConnection();
  }

  public ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  public ngAfterViewInit() {
    this.setApplicationFilters({});
    this.setAppConfigdata();

    const persistedPageNo = this.store.selectSnapshot(
      (state) => state.data.pageNo
    );

    this.store
      .select((state) => state.data.assignedApplicationsForFollowup)
      .pipe(
        map((applications) => {
          if (applications?.length) {
            applications = this.sortRequestedTypes(applications);
          }
          return applications?.map((app: Application) => {
            if (app.reminderDate) {
              app.reminderDate = dayjs(app.reminderDate).format(
                'MM/DD/YYYY h:mm A'
              );
            }
            return app;
          });
        })
      )
      .subscribe((Application) => {
        let savedApplicationId;
        this.listOfApplications = Application;
        this.router.queryParams.subscribe((res) => {
          if (res && res.appId) {
            savedApplicationId = Number(res.appId);
          } else {
            savedApplicationId = this.store.selectSnapshot(
              (state) => state.data.selectedApplicationDetails
            )?.applicationId;
          }
        });
        if (this.listOfApplications?.length) {
          if (!savedApplicationId) {
            this.selectedApplication = this.listOfApplications[0];
            this.selectedApplicationIndex = 0;
            this.selectApplication(
              this.selectedApplication,
              this.selectedApplicationIndex
            );
          } else {
            this.selectedApplication = this.listOfApplications.find(
              (application: Application) => {
                return application.applicationId === savedApplicationId;
              }
            );
            this.selectedApplicationIndex = this.listOfApplications.findIndex(
              (application: Application) => {
                return application.applicationId === savedApplicationId;
              }
            );

            if (this.selectedApplicationIndex <= 0) {
              this.selectedApplication = this.listOfApplications[0];
              this.selectedApplicationIndex = 0;
            }
          }
          this.selectApplication(
            this.selectedApplication,
            this.selectedApplicationIndex
          );
          this.setAppConfigdata();
        } else {
          this.selectedApplication = null;
        }
      });

    this.assignedApplications$
      .pipe(
        map((applications) => {
          if (applications?.length) {
            applications = this.sortRequestedTypes(applications);
          }
          return applications?.map((app: Application) => {
            if (app.reminderDate) {
              app.reminderDate = dayjs(app.reminderDate).format(
                'MM/DD/YYYY h:mm A'
              );
            }
            return app;
          });
        })
      )
      .subscribe();
    new ApplicationsForFollowUpQueue(
      createSearchCriteria(this.applicationFilterData), 0, 10
    );
  }

  getLockStatus(app?) {
    const selectedApplication: Application = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    const a = app ? app : selectedApplication;
    this.lockViewModel = new LockOrReleaseApplication(a, this.profile);
    this.processActionsDisable(null, a);
    return this.lockViewModel.processLockStatus();
  };

   processActionsDisable(prop?, application?) {
    const selectedApplication: Application = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    const app = application || selectedApplication;
    this.lockViewModel = new LockOrReleaseApplication(app, this.profile, prop);
    this.disableActions =  this.lockViewModel.disableActions || !(selectedApplication?.applicationPropertyBags?.length);
  }

  removeFilters() {
    this.store.dispatch(new ClearQuery());
    this.orderBy = '';
    this.pageNumber = 1;
    this.applicationFilterData = new ApplicationFilterData(this, this)
      .filterData as ApplicationSearchInfo;
    this.store.dispatch(new ApplicationsForFollowUpQueue('', this.defaultSkip, this.take));
    this.store.dispatch(new SearchFilters(null));
  }

  private setAppConfigdata(): void {
    this.appFilterConfig = new ApplicationFilterConfiguration({
      defaultSetting: this.applicationFilterData,
      displayTypeDropdown: true,
      displayReminderIcon: true,
      displayLastNameDropdown: true,
      displayOrderByDropdown: true,
      displayShowExpiredIcon: true,
      displayStatesDropdown: true,
      displayProcessStatus: true,
      displaySearchByTypeDropdown: true,
      pageName: PAGE_STATE.FOLLOW_UP,
    });
  }

  public filter(data: ApplicationSearchInfo): void {
    this.orderBy = data.orderBy;
    this.store.dispatch(new ClearQuery());
    delete data.filters.assignedTo;
    data.page = this.FOLLOWUP_QUEUE;
    data.pageDirectionCount = null;
    this.first = 0;
    this.filterApplications(data);
  }

  get sortedOrderByFilter() {
    return orderByFilterWithSorting.find(
      (filter) => filter.value === this.orderBy
    )?.name;
  }

  public filterApplications(data?) {
    this.applicationFilterData = data;
    data ? this.paginate(data.paginate.pageNumber, true) : null;
  }

  selectApplication(Application, index) {
    this.setIndex(index);
    this.selectedApplication = Application;
    this.selectedApplication.isFollowup = true;
    this.getSelectedApplicationDetails(
      Application.id,
      Application.convertedVersion
    );

    this.applicationFilterData.applicationId =
      this.selectedApplication.applicationId;
    this.pageStateService.updateUrlWithPageStateInformation(
      this.FOLLOWUP_QUEUE,
      this.applicationFilterData
    );
  }

  async getSelectedApplicationDetails(appId, convertedVersion) {
    this.store.dispatch(
      new SelectedApplicationDetails(appId, convertedVersion)
    );

    await this.storeActionHandlers
      .pipe(ofActionCompleted(SelectedApplicationDetails), take(1))
      .toPromise();
    this.selectedApplication = {
      ...this.selectedApplication,
      ...this.store.selectSnapshot(
        (state) => state.data.selectedApplicationDetails
      ),
    };
    this.selectedApplication.orderDetails =
      this.orderService.processOrderByStatus(
        this.selectedApplication.orderDetails
      );
    this.selectedApplication.redirectUrl = RedirectUrl.FOLLOWUP_QUEUE;
    this.store.dispatch(
      new UpdateSelectedApplicationDetails(this.selectedApplication)
    );
  }

  async assignToMe(application: Application) {
    const agent = this.apsService.loggedInUserFromApsUsersList();
    this.store.dispatch(new AssignApplicationsToApsUser(agent, application));
    await this.storeActionHandlers
      .pipe((ofActionCompleted(AssignApplicationsToApsUser), take(1)))
      .toPromise();
    this.store.dispatch(new ApplicationsForFollowUpQueue('', this.defaultSkip, this.take));
  }

  setIndex(index: number) {
    this.selectedIndex = index;
    this.selectedApplicationIndex = index;
  }

  paginate(event: any, onLoad?: boolean) {
    const pageNumber = event ? event.page + 1 : 1;
    this.pageNumber = pageNumber;
    this.applicationFilterData.skip = (event?.page?event.page:0) * 10;
    this.updatePaginateInfo(this.applicationFilterData, pageNumber);
    if (onLoad) {
      const query = this.store.selectSnapshot((state) => state.data.query);
      this.updatePaginateInfo(this.applicationFilterData, this.pageNumber);
      this.store.dispatch(
        new ApplicationsForFollowUpQueue(
          query ? query : createFollowupSearchCriteria(this.applicationFilterData),this.applicationFilterData.skip, this.take
        )
      );
    } else {
      this.store.dispatch(new SetPageNo(pageNumber));
      this.store.dispatch(
        new ApplicationsForFollowUpQueue(
          createFollowupSearchCriteria(this.applicationFilterData), this.applicationFilterData.skip, this.take
        )
      );
      this.first = this.applicationFilterData.skip;
    }
    this.selectedApplicationIndex = 0;
  }

  private updatePaginateInfo(
    filterData: ApplicationSearchInfo,
    pageNumber: number
  ) {
    if (!this.listOfApplications?.length) {
      return;
    }
    filterData.paginate.pageInfo = this.listOfApplications[0].pageInfo;
    filterData.paginate.pageNumber = pageNumber;

    this.pageStateService.updateUrlWithPageStateInformation(
      this.FOLLOWUP_QUEUE,
      filterData
    );
  }

  private sortRequestedTypes(applications: Application[]) {
    for (const application of applications) {
      const types = {};
      for (const orderTracker of application.orderTrackers) {
        const type = orderTracker.requestType;
        if (types[type]) {
          types[type]++;
        } else {
          types[type] = 1;
        }
      }
      application.sortedRequestType = types;
    }
    return applications;
  }

  getStatusColor(proceesName): string {
    let result = '';
    if (this.processStatusColors.filter((e) => e.key == proceesName)[0]) {
      result = this.processStatusColors.filter((e) => e.key == proceesName)[0]
        .value;
    }
    return result;
  }

  ngOnDestroy() { }
}
