<div class="custom-card applicant-card">
  <table cellspacing="0" cellpadding="0">
    <tr class="heading-row">
      <th *ngFor="let heading of notesHeadersList" class="heading-label">
        {{ heading.label }}
      </th>
    </tr>
    <ng-container *ngFor="let data of notes; index as i">
      <tr class="orderList">
        <td ushgDate>{{ data.loggedDate }}</td>
        <td>{{ data.loggedBy }}</td>
        <td class="info py-3">{{ data.information }}</td>
      </tr>
    </ng-container>
  </table>
</div>
