import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import {
    AngularPlugin,
    AngularPluginService,
} from '../external/applicationinsights-angularplugin-js/applicationinsights-angularplugin-js';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { versionNo } from 'src/app/version/version-number'
import { Store } from '@ngxs/store';


@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    public ErrorsList: BehaviorSubject<any> = new BehaviorSubject<Error[]>([]);
    appInsights: ApplicationInsights;
    applicationId: number;

    constructor(
        private router: Router,
        private angularPluginService: AngularPluginService,
        private store: Store
    ) {
        const angularPlugin = new AngularPlugin();
        this.angularPluginService.init(angularPlugin, this.router);
        this.appInsights = new ApplicationInsights({
            config: {
                instrumentationKey: environment.instrumentationKey,
                extensions: [angularPlugin],
                extensionConfig: {
                    [angularPlugin.identifier]: { router: this.router },
                },
            },
        });
        this.appInsights.loadAppInsights();
        this.appInsights.trackPageView();
    }

    convertBehaviourSubjectToObservable() { }

    logPageView(name?: string, uri?: string) {
        // option to call manually
        this.appInsights.trackPageView({ name, uri });
    }

    async logEvent(name: string, properties?: { [key: string]: any }) {
        this.appInsights.trackEvent({ name }, properties);
        const log = {};

        log['level'] = 2;
        log['template'] = name;
        log['Parameters'] = properties;

        // this.loggerApiService.logMessage(log).subscribe((resp) => {
        //   // console.log(resp);
        // });
    }

    logUserLogin(name: string) {
        this.logEvent('Security User Login : {name} ', [name, new Date()]);
    }

    logMetric(
        name: string,
        average: number,
        properties?: { [key: string]: any }
    ) {
        this.appInsights.trackMetric({ name, average }, properties);
    }

    createMessage(exception) {
        const { name } = this.store.selectSnapshot((state) => state.data.userProfile)
        const { applicationId } = this.store.selectSnapshot((state) => state.data.selectedApplicationDetails)
        const { orderTrackerId } = this.store.selectSnapshot((state) => state.data.currentOrderData)
        const message = exception.message
            ? exception.message
            : exception.stack
                ? exception.stack
                : JSON.stringify(exception);
        return `${message} ${name ? `loggedInUser :${name}` : ''
            }  ${applicationId ? `appointmentId :${applicationId}` : ''} ${orderTrackerId ? `resourceId :${orderTrackerId}` : ''
            } `;
    }
    logException(exception: Error) {
       
        const properties = {
            url: exception['url'],
            buildVersion: versionNo,
            query: exception['query'],
            operationName: exception['name'],
           variables:exception['variables'],
           payload:exception['payload']
        };
        this.appInsights.trackException({
            exception,
            properties,
        });
        const logMessage = {};
        logMessage['level'] = 5;
        logMessage['template'] = this.createMessage(exception);
        logMessage['Parameters'] = [this.createMessage(exception)];
        this.ErrorsList.next([exception]);
        // do not disable the line below, otherwise we will never get console errors.
        console.log(exception.message);
    }

    logTrace(message: string, properties?: { [key: string]: any }) {
        this.appInsights.trackTrace({ message }, properties);
    }
}
