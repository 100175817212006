import { DatePipe } from '@angular/common';
import { Component,EventEmitter,Input, Output } from '@angular/core';
import { ControlContainer } from '@angular/forms';
import { formControlError } from 'src/app/aps/models/ui-models';

@Component({
  selector: 'ushg-form',
  templateUrl: './ushg-form.component.html',
  styleUrls: ['./ushg-form.component.scss'],
})
export class UshgFormComponent  {
  @Input() formControlComponent: any;
  isCustomUpload = true;
  isInValid = false;
  @Output() formErrorStatus = new EventEmitter<formControlError>();
  constructor(
    public controlContainer: ControlContainer,
    private datePipe: DatePipe
  ) {}

  uploadHandler(ev) {}

  patchPrimeNgInputValue(
    event: { orignalEvent: InputEvent; value: number },
    formInputName: string
  ) {
    this.controlContainer['form'] && this.controlContainer['form'].patchValue({ [formInputName]: event.value });
  }

  dateFormatChange(value, formControlComponent) {
    this.isInValid = value > new Date();
    let date;
    !formControlComponent?.showTime
      ? (date = this.datePipe.transform(value, 'MM/dd/yyyy'))
      : (date = value);
      this.controlContainer['form'] && this.controlContainer['form'].patchValue({
      [formControlComponent.formControlName]: date,
    });
  }

  actions(callback) {
    if (callback) callback();
  }
  
  propogateErrorToParent(error:formControlError){
    this.formErrorStatus.emit(error);
  }

}
