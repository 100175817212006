import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UshgDialogService } from '../services/ushg-dialog-service';

@Component({
  selector: 'app-ushg-dialog-footer',
  templateUrl: './ushg-dialog-footer.component.html',
  styleUrls: ['./ushg-dialog-footer.component.scss'],
})
export class UshgDialogFooterComponent {
  @Input() mode: any;
  @Output() saveDetails = new EventEmitter<string>();
  constructor() {}
  @Input() buttonsList = [];

  actionsEvent = (callback: Function) => {
    callback();
  };

  processConditionalFooter() {
    this.buttonsList = this.buttonsList.filter(
      (button) => this.mode === button.type
    );
  }
}
