import * as dayjs from "dayjs"

export const toolTipTemplates = {
  orderDocuments: (file) => {
    return `<div class="d-flex align-items-center f12 mb-1">
<div class="col-10  p-0 name-ellipsis" style="font-size:10px;">
  <span class="name-ellipsis">
    ${file.name}
  </span>
  <div>Updated Date : ${dayjs(file.updatedDate).format(
      'MM/DD/YYYY h:mm A'
    )}</div>
</div>
<div class="col-2 p-0" style="text-align:right;">
<img src="../../../assets/icons/PDF_Icon.svg" width="24px">
</div>
</div>`
  },
  underwriterNotes: (note) => {
return `<div class="d-flex align-items-center f12 mb-1">
<div class="col-10  p-0 name-ellipsis" style="font-size:10px;">
  <span class="name-ellipsis">
    ${note.information}
  </span>
  <div>Logged Date : ${dayjs(note.loggedDate).format(
      'MM/DD/YYYY'
    )} - (${note.loggedBy})</div>
</div>
<div class="col-2 p-0" style="text-align:right;">
<img src=${note.auditParameter==="TO_UWS"?"../../../assets/icons/aps.svg":"../../../assets/icons/uws.svg"} width="24px">
</div>
</div>

`

  }
}