import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Application } from 'src/app/aps/models/application-model';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

@Component({
  selector: 'app-application-request-modal',
  templateUrl: './application-request-modal.component.html',
  styleUrls: ['./application-request-modal.component.scss']
})
export class ApplicationRequestModalComponent implements OnInit {
  config = {
    width: '62%',
    closable:true
   }
  modeType = "custom";
  selectedApplication: Application;
  buttonsList = [
    {
      label: 'Close',
      type: 'secondaryoutline',

      onClick: () => {
        this.dialogService.cancel();
      }
    }
  ];

  constructor(
    private dialog: DynamicDialogConfig,
    private dialogService: UshgDialogService,
    private apsService: ApsService
  ) { }

  ngOnInit() {
    this.selectedApplication = this.dialog.data;
    this.selectedApplication['hideActions'] = true
  }



}
