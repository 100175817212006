export const environment = {
  production: true,
  clarity: "fv7ofvcsth",
  feature: {
    followupLock: true
  },
  serverUrls: {
    aps: 'https://nbc-gateway-qa.ushealthgroup.com/api/graphql',
    nbc: 'https://vcall-gateway-qa.ushealthgroup.com',
    secureEmail: 'https://nbc-gateway-qa.ushealthgroup.com/api/secureemail/aps/v1',
    nbcApiURl: 'https://nbc-gateway-qa.ushealthgroup.com/',
    VCall: 'https://vcall2-qa.ushealthgroup.com/',
    vCallExportUrl: "https://vcall-gateway-qa.ushealthgroup.com/api/vcall/v1/application",
    providerLookup:
      'https://vcall-gateway-qa.ushealthgroup.com/api/medicalsearch/v2/providerlookup',
    searchApplication: 'https://vcall-gateway-qa.ushealthgroup.com/api/ezapp/v1/application/search',
    apsApp: 'https://nbc-qa.ushealthgroup.com/aps',
    uws: 'https://stage.ushg.cc/underwritingworksheet/UnderwritingQueue.aspx?(Not.Licensed.For.Production)=',
    issues: 'https://stage.ushg.cc/underwritingworksheet/IssueQueue.aspx?(Not.Licensed.For.Production)=',
    ezapp: 'https://ezapp-qa.ushealthgroup.com/ImpersonateAgent.aspx?username=HOPE',
    callzone: 'https://stage.ushg.cc/Callzone/ListUpcomingAppointmentsAdminsView.aspx',
    nbcLegacy: 'https://nbc-gateway-qa.ushealthgroup.com',
    vcallURL: 'https://vcall-gateway-qa.ushealthgroup.com',
  },
  config: {
    azure_roles: [

      { name: "Field Support", role: "USHG_APS2_FieldSupport_TST" },
      { name: "Matchup Member", role: "USHG_APS2_MatchupMember_TST" },
      { name: "Supervisor", role: "USHG_APS2_Supervisor_TST" },
      { name: "SysAdmin", role: "USHG_APS2_SysAdmin_TST" },
      { name: "Team Member", role: "USHG_APS2_TeamMember_TST" },
      { name: 'Vcall2 Read Only', role: 'TST_VCall2_ReadOnly' },
    ],
    adRoles: 'USHG_APS2_FieldSupport_TST, USHG_APS2_MatchupMember_TST, USHG_APS2_SysAdmin_TST, USHG_APS2_TeamMember_TST, USHG_APS2_Supervisor_TST, DEV_VCall2_HQSysAdmin ,TST_VCall2_ReadOnly'
  },
  authConfig: {
    scope: 'api://6e794aee-8e8b-4e19-8f07-124afd39a89b/User.Execute',
    tenant: 'cc7c826f-6444-4c0e-8314-83afb87c98ea',
    clientId: 'f76b3bd2-96f0-4eca-bda9-3cf6d99adc94',
    redirectUri: `${window.location.origin}/auth`,
     addedProtectedResourceMap: [
      {
        key: 'https://vcall-gateway-qa.ushealthgroup.com/api',
        list: ['api://6e794aee-8e8b-4e19-8f07-124afd39a89b/User.Execute'],
      },
      {
        key: 'https://nbc-gateway-qa.ushealthgroup.com',
        list: ['api://6e794aee-8e8b-4e19-8f07-124afd39a89b/User.Execute'],
      },
    ],
    postLogoutRedirectUri: 'http://localhost:2025/home',
    endpoints: {
      'https://ushg-customerdashboard-api-dev.azurewebsites.net/':
        '142b4ed9-2a08-4b3f-afdc-fe91f847fa33',
    },
    cacheLocation: 'localStorage',
  },
  instrumentationKey:"05d64711-1f21-4114-8c6b-01f9626bad1a",
  testMode: false,

};

