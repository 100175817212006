import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { FileUpload } from 'primeng/fileupload';

import {
  Application,
  CheckRequest,
} from 'src/app/aps/models/application-model';
import {
  AuditTypeId,
  DocumentType,
  OrderActionType,
  OrderStatus,
  RedirectUrl,
} from 'src/app/aps/models/enums';
import { IDialogAware } from 'src/app/aps/models/IDialogAware';
import {
  Documents,
  NotificationModel,
  NotificationType,
} from 'src/app/aps/models/ui-models';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { ApsService } from 'src/app/aps/services/aps-service';
import { FileService } from 'src/app/shared/services/file-service';
import { DropDownModel } from 'src/app/shared/drop-down/drop-down.component';
import { SendSecureEmailComponent } from 'src/app/shared/send-secure-email/send-secure-email.component';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import {
  CurrentOrderData,
  InsertAuditLog,
  InsertRequestCheck,
  SearchFilters,
  UpdateOrder,
  UshgNotification,
} from 'src/app/store/dispatchers';
import { DOCUMENT_TYPE_LIST, ORDER_STATUS_LIST } from './edit-order-config';
import { Router } from '@angular/router';
import { ProgressBarComponent } from '../../../modals/progress-bar/progress-bar.component';
import { AuditLog } from 'src/app/aps/models/ui-models';

@Component({
  selector: 'app-edit-order',
  templateUrl: './edit-order.component.html',
  styleUrls: ['./edit-order.component.scss'],
})
export class EditOrderComponent implements OnInit, IDialogAware {
  paymentChecks =
    this.store !== undefined
      ? this.store.selectSnapshot((state) => state.data.paymentChecks)
      : [];
  currentOrderData;
  @ViewChild('file') file: FileUpload;
  OrderDetails;
  notes: string;
  uploadedFiles: Documents[] = [];
  documentTypesList: DropDownModel;
  paymentCheckList: DropDownModel;
  paymentCheckDocuments = [];
  status: DropDownModel;
  documentType: string;
  orderStatus: string;
  ismultiple = true;
  isUpload = false;
  public modeType = 'custom';
  apsNotes = new UntypedFormControl('');
  selectedApplication: Application;
  progressDialogRef: any;

  config = {
    width: '55%',
    closable: true,
  };
  buttonsList = [
    {
      label: 'Cancel',
      type: 'secondaryoutline',
      onClick: () => {
        this.service.cancel();
      },
    },
    {
      label: 'Save',
      type: 'primarylg',
      onClick: () => {
        this.onSave();
        setTimeout(() => {
          this.service.cancel();
        }, 100);
      },
      disable: false,
    },
  ];
  latestUploadedFiles = [];
  statusSelectedValue: number;
  selectedCheck: CheckRequest;
  orderStatusList = ORDER_STATUS_LIST;
  
  constructor(
    private service: UshgDialogService,
    private store: Store,
    private apsService: ApsService,
    private apsApiService: ApsApiService,
    private fileService: FileService,
    private router: Router
  ) {
    this.documentTypesList = {
      label: 'Document Type',
      onSelect: (ev) => {
        this.getDocumentType(ev);
      },
      placeholder: 'Select Document Type',
      filterValues: [],
    };
    this.paymentCheckList = {
      label: 'Payment Check',
      placeholder: 'Select Check',
      filterValues: [],
      onSelect: (ev) => {
        this.selectedCheck = this.paymentChecks.find((check) => {
          return check.checkName === ev.value;
        });
      },
    };
  }

  ngOnInit() {
    this.store
      .select((state) => state.data.currentOrderData)
      .subscribe((res) => {
        this.currentOrderData = res;
        this.statusSelectedValue = res.orderDetails.orderStatusId;
      });
    this.OrderDetails = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );

    this.bindOrderStatus();
    this.uploadedFiles =
      this.OrderDetails.orderDocuments !== null
        ? JSON.parse(JSON.stringify(this.OrderDetails.orderDocuments.documents))
        : [];
    if (
      this.OrderDetails.orderDetails.labs &&
      this.OrderDetails.orderDetails.labs.length
    ) {
      this.notes = this.OrderDetails.orderDetails.labs[0].apsNotes;
    } else {
      this.notes = this.OrderDetails.APS?.notes;
    }

    this.documentTypesList.filterValues =
      DOCUMENT_TYPE_LIST[this.OrderDetails.requestType];
    this.checkformValidation();
  }

  checkformValidation() {
    this.apsNotes.statusChanges.subscribe((res) => {
      if (this.buttonsList[1]) {
        this.buttonsList[1].disable = res === 'INVALID' ? true : false;
      }
    });
  }

  getUploadedDate(file) {
    return JSON.parse(file.attributes).uploadedOn;
  }

  bindOrderStatus() {
    this.status = {
      label: 'Change Status To',
      placeholder: 'Select Status',
      filterValues: [OrderStatus.Requested, OrderStatus.Cancelled].includes(
        this.OrderDetails.orderStatusId
      )
        ? this.orderStatusList.INITIAL
        : this.orderStatusList[this.OrderDetails?.requestType],
    };
  }

  getDocumentType(val) {
    this.documentType = val.value;
  }

  getOrderStatus(val) {
    this.orderStatus = val;
    this.statusSelectedValue = val;
    this.statusSelectedValue === OrderStatus.Cancelled
      ? this.setApsNotesRequired(true)
      : this.setApsNotesRequired(false);
  }

  get OrderStatus(): typeof OrderStatus {
    return OrderStatus;
  }

  setApsNotesRequired(required) {
    this.apsNotes.setValidators(required ? [Validators.required] : null);
    this.apsNotes.updateValueAndValidity();
  }

  uploadFileProgress(uploadProgress) {
    this.service.setData(uploadProgress);
    if (uploadProgress === 100) {
      this.progressDialogRef.close();
      this.service.setData('');
    }
  }

  notesToUpper(){
    this.notes = this.notes.toUpperCase();
  }

  async uploadFile(event) {
    for (let file of event.files) {
      this.progressDialogRef = this.service.open(ProgressBarComponent, 0, null);
      let content = await this.fileService.convertFileToByteStream(file);
      let attributes = `{"uploadedOn":"${new Date().toISOString()}"}`;

      const { data } = await this.apsApiService.savePdfToTheApplicationSas(this.OrderDetails, [
        {
          name: file.name,
          contentLength: content.length,
          documentType: this.documentType,
          updatedDate: new Date().toISOString(),
          attributes: JSON.stringify(attributes),
        },
      ], file, this.file).toPromise();

      this.file.uploading = false;
      this.isUpload = true;
      let uploadedFile: Documents = new Documents(
        data.uploadFiles.documents[0].name,
        data.uploadFiles.documents[0].content,
        null,
        null,
        null,
        null,
        this.documentType,
        file.name,
        new Date().toISOString()
      );
      this.addDocumentsToSelectedCheck(uploadedFile);
      this.store.dispatch(
        new UshgNotification(
          new NotificationModel(
            NotificationType.SUCCESS,
            '',
            'File Uploaded Sucessfully'
          )
        )
      );
      this.uploadedFiles.unshift(uploadedFile);
    }
    this.file.clear();
  }


  deleteFile(index) {
    this.uploadedFiles.splice(index, 1);
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.SUCCESS,
          '',
          'File Deleted Sucessfully'
        )
      )
    );
  }

  addDocumentsToSelectedCheck(uploadedFile) {
    if (this.documentType === DocumentType.PAYMENT_CHECK) {
      this.selectedCheck.documentName = uploadedFile.name;
      this.selectedCheck.documentUrl = uploadedFile.content;
      this.paymentCheckDocuments.push(this.selectedCheck);
    }
  }

  onSave() {
    const orderDetails = JSON.parse(JSON.stringify(this.OrderDetails));
    orderDetails.APS =
      this.OrderDetails.APS === null ? {} : this.OrderDetails.APS;
    if (orderDetails.orderDetails.APS) {
      let isNewApsNote = (orderDetails.orderDetails.APS['aPSNotes']!==this.notes);
      orderDetails.orderDetails.APS['notes'] = this.notes;
      orderDetails.orderDetails.APS['aPSNotes'] = this.notes;
      if(isNewApsNote){
        let apsNotes = new AuditLog(AuditTypeId.TO_APS,this.notes);
        apsNotes = {...apsNotes, applicationTrackerId: this.OrderDetails.applicationTrackerId, information: this.notes, orderTrackerId: this.OrderDetails.orderDetails.orderTrackerId}
        this.store.dispatch(new InsertAuditLog(apsNotes));
      }
    }
    if (
      orderDetails.orderDetails.labs &&
      orderDetails.orderDetails.labs.length
    ) {
      let isNewApsNoteForLab = (orderDetails.orderDetails.labs[0].apsNotes !== this.notes);
      if (isNewApsNoteForLab) {
        let apsNotes = new AuditLog(AuditTypeId.TO_APS, this.notes);
        apsNotes = { ...apsNotes, applicationTrackerId: this.OrderDetails.applicationTrackerId, information: this.notes, orderTrackerId: this.OrderDetails.orderDetails.orderTrackerId }
        this.store.dispatch(new InsertAuditLog(apsNotes));
      }
      orderDetails.orderDetails.labs[0].apsNotes = this.notes;
    }
    orderDetails.orderStatusId = this.statusSelectedValue;
    orderDetails.orderDetails.orderStatusId =
      this.statusSelectedValue === undefined ? 3 : this.statusSelectedValue;
    orderDetails.orderDocuments = this.OrderDetails.orderDocuments
      ? this.OrderDetails.orderDocuments
      : { documents: [] };
    orderDetails.orderDocuments.documents = this.uploadedFiles;
    orderDetails.orderDetails.orderDocuments = this.OrderDetails.orderDetails
      .orderDocuments
      ? this.OrderDetails.orderDetails.orderDocuments
      : { documents: [] };
    (orderDetails.orderDetails.orderDocuments = {
      appId: this.OrderDetails.orderDetails.applicationId,
      orderId: this.OrderDetails.orderDetails.orderTrackerId,
      clientNumber: this.OrderDetails.orderDetails.clientNumber,
    }),
      (orderDetails.orderDetails.orderDocuments.documents = this.uploadedFiles);
    (orderDetails.orderDetails.createdDate = this.OrderDetails.createdDate),
      (orderDetails.orderDetails.createdBy = this.OrderDetails.createdBy);
    orderDetails.orderDetails.state = this.OrderDetails.state;
    orderDetails.orderDetails.updateOrders = true;
    this.store.dispatch(new UpdateOrder(orderDetails.orderDetails));
    this.savePaymentCheckDocuments();
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.SUCCESS,
          '',
          'Changes Saved Sucessfully'
        )
      )
    );
  }

  savePaymentCheckDocuments() {
    if (this.paymentCheckDocuments.length) {
      this.paymentCheckDocuments.forEach((eachCheck) => {
        this.store.dispatch(new InsertRequestCheck(eachCheck));
      });
    }
  }

  downloadFile(file) {
    this.apsService.downloadDocument(file.name);
  }

  checkBoxEventHandler(ev, i) {
    this.uploadedFiles[i].checked = ev.checked;
  }

  get documentsCheckedForEmail() {
    return this.uploadedFiles && this.uploadedFiles !== null
      ? this.uploadedFiles.some((file) => file.checked)
      : '';
  }

  sendEmail() {
    this.service.cancel();
    this.service.open(SendSecureEmailComponent, this.OrderDetails);
    this.onSave();
  }

  get paymentChecksList() {
    let filterValues = this.store.selectSnapshot(
      (state) => state.data.paymentChecks
    );
    filterValues = filterValues.map((paymentCheck) => {
      paymentCheck.name = paymentCheck.checkName;
      paymentCheck.value = paymentCheck.checkName;
      return paymentCheck;
    });
    this.paymentCheckList.filterValues = filterValues;
    return this.paymentCheckList;
  }

  paymentCheckDropDownHandler(ev) {
    const paymentChecks = this.store.selectSnapshot(
      (state) => state.data.paymentChecks
    );
    this.selectedCheck = paymentChecks.find((check) => check.checkName === ev);
  }

  reopenCurrentOrder() {
    const order = JSON.parse(JSON.stringify(this.OrderDetails));
    order.orderDetails.state = this.OrderDetails.state;
    order.orderDetails.createdDate = this.OrderDetails.createdDate;
    order.orderDetails.createdBy = this.OrderDetails.createdBy;
    if (this.currentOrderData.orderCreatedOn) {
      order.orderStatusId = OrderStatus.Pending;
      order.orderDetails.orderStatusId = OrderStatus.Pending;
      this.statusSelectedValue = OrderStatus.Pending;
    } else {
      order.orderStatusId = OrderStatus.Requested;
      order.orderDetails.orderStatusId = OrderStatus.Requested;
      this.statusSelectedValue = OrderStatus.Requested;
    }
    this.OrderDetails.orderStatusId = order.orderStatusId;
    this.store.dispatch(new CurrentOrderData(order));
    this.store.dispatch(new UpdateOrder(order.orderDetails));
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.SUCCESS,
          '',
          'Order Reopened Sucessfully'
        )
      )
    );
    this.bindOrderStatus();
  }

  get orderStatusId(): typeof OrderStatus {
    return OrderStatus
  }
}
