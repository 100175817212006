<div
  class="flex align-items-center justify-content-center fixed-footer p-dialog-footer col-12 p-0 pt-3 mt-1"
>
  <div class="mr-3 mb-3" *ngFor="let button of buttonsList">
    <ushg-button
      [label]="button.label"
      class="min-width"
      [class]="button.class"
      [type]="button.type"
      (click)="actionsEvent(button.onClick)"
      [disable]="button.disable"
      [ngClass]="{ 'p-disabled': button?.disable }"
    ></ushg-button>
  </div>
</div>
