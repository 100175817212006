import { Component, Input } from '@angular/core';

@Component({
  selector: 'ushg-applicant-notes',
  templateUrl: './applicant-notes.component.html',
  styleUrls: ['./applicant-notes.component.scss'],
})
export class ApplicantNotesComponent {
  constructor() {}

  @Input() public notesHeadersList;
  @Input() public notes;
}
