export class ApplicationFilterData {
  filterData = new Object();
  constructor(data, currentApplication) {
    const {
      lastName,
      timeInQueue,
      reminderDate,
      expired,
      type,
      searchValue,
      applicationStatusId,
      state,
      status,
      appId,
      assignee,
      toDate,
      fromDate,
      controlNo,
      assigneeSelectedFilterValue,
      lastNameFilter,
      assignedTo
    } = !data.filters ? data : data.filters;
    const { pageNumber, pageDirection, pageInfo } = data.paginate;
    let startCursor, endCursor;

    if(pageInfo){
     startCursor =  data.paginate.pageInfo.startCursor;
     endCursor =  data.paginate.pageInfo.endCursor;
    }
    this.filterData = {
      filters: {
        lastName,
        timeInQueue,
        reminderDate,
        expired,
        type,
        searchValue,
        applicationStatusId,
        state,
        status,
        appId,
        assignee,
        toDate,
        fromDate,
        controlNo,
        assigneeSelectedFilterValue,
        lastNameFilter,
        assignedTo
      },
      orderBy: data.orderBy,
      page: currentApplication.page,
      paginate: {
        pageInfo: {
          startCursor:  startCursor ? startCursor : '',
          endCursor: endCursor ? endCursor : ''
        },
        pageDirection,
        pageNumber,
        queryId: null,
      },
      applicationId: data.applicationId,
    };
  }
}
