<div class="grid">
  <div class="col-12 p-3 pt-0 mx-auto">
    <ushg-details-container
      [detailsToDisplay]="detailsToDisplay"
      [data]="orderDetails"
    ></ushg-details-container>
  </div>
</div>
<p-tabView (onChange)="tabViewChangeHandler($event)">
  <p-tabPanel header="FollowUp" *ngIf="!showFollowUp">
    <app-followup-order></app-followup-order>
  </p-tabPanel>
  <p-tabPanel header="Documents">
    <app-edit-order></app-edit-order>
  </p-tabPanel>
  <p-tabPanel header="Email Logs">
    <div>
      <ushg-table
        [rowData]="emailLogs"
        [headerList]="emailLogsHeaders"
        [rows]="7"
      >
      </ushg-table>
    </div>
  </p-tabPanel>
  <p-tabPanel header="Check Requests" *ngIf="requestType === APS">
    <div class="grid shadow-5">
      <div class="col-12">
        <ushg-table
          [rowData]="paymentCheckData | async"
          [headerList]="paymentsCheckHeaders"
          [rows]="5"
        >
        </ushg-table>
      </div>
    </div>
  </p-tabPanel>
  <!--<p-tabPanel header="APS & UWS Conversations">
    <div class="grid shadow-5">
      <div class="col-12 d-flex logs" *ngFor="let log of logsAndNotes" [ngClass]="{'justify-content-end pl-4':log.auditParameter==='TO_UWS','pr-5':log.auditParameter==='TO_APS'}">
        <span class='p-2' *ngIf="log.auditParameter==='TO_APS'">
          <img src='../../../assets/icons/uws.svg' width="44px">
        </span>
        <div class="information p-2 font-bold">
          {{log.information}}
          <br>
          <span class="text-xs text-500"> Logged Date : {{log.loggedDate|date:'MM/dd/YYYY'}} - ({{log.loggedBy}})</span>
   
        </div>
        <span class='p-2' *ngIf="log.auditParameter==='TO_UWS'"> 
          <img src='../../../assets/icons/aps.svg' width="44px">  
        </span>
      </div>
    </div>
  </p-tabPanel>-->
  </p-tabView>