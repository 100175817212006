import { Component, Input } from '@angular/core';

@Component({
  selector: 'ushg-applicant-logs',
  templateUrl: './applicant-logs.component.html',
  styleUrls: ['./applicant-logs.component.scss'],
})
export class ApplicantLogsComponent {
  constructor() {}

  @Input() public auditLogHeadersList;
  @Input() public logsAndNotes;
}
