import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { Application } from '@microsoft/microsoft-graph-types';
import { Store } from '@ngxs/store';
import { toUnicode } from 'punycode';
import { HeadersList } from 'src/app/aps/models/application-model';
import { ColumnType } from 'src/app/aps/models/enums';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { ApsService } from 'src/app/aps/services/aps-service';
import * as dayjs from 'dayjs';
import { toolTipTemplates } from './ushg-table-config';

@Component({
  selector: 'ushg-table',
  templateUrl: './ushg-table.component.html',
  styleUrls: ['./ushg-table.component.scss'],
})
export class UshgTableComponent implements OnChanges, OnDestroy, AfterViewInit {
  selectedLang: string;
  OrderDetails: any;

  constructor(
    private store: Store,
    private apsService: ApsService,
    private apsApi: ApsApiService
  ) { }

  timeZone: string = this.store.selectSnapshot(
    (state) => state.data.userProfile
  ).timeZone;
  @ViewChild('table') table;
  selectedRows: any[] = [];
  @Input() headerList: HeadersList[] = [];
  @Input() rows;
  @Input() checkbox: boolean = false;
  @Input() list: any[] = [];
  @Input() rowData: any = [{ isLoading: true }];
  @Input() actions: any[] = [];
  @Input() ref: any;
  @Input() filterData: string;
  @Input() selectedApplication: any;
  @Output() selected = new EventEmitter();
  loading;
  @Input() resetSelectedRows: boolean;
  @Input() config;
  @Input() isLocked: boolean;
  @Input() disableActions: boolean;

  ngAfterViewInit(): void { }

  ngOnChanges() {
    this.table?.filterGlobal(this.filterData, 'contains');
    if (this.resetSelectedRows) {
      this.selectedRows = [];
    }
  }
  selctedRowsHandler() {
    this.selected.next(this.selectedRows);
  }

  get ColumnType(): typeof ColumnType {
    return ColumnType;
  }

  downloadFile(file) {
    this.apsService.downloadDocument(file.FileName);
  }
  ngOnDestroy() {
    this.loading = true;
  }

  showToolTip(data, type): string {
    let temp: string = ``;
    for (let file of data) {
      temp += toolTipTemplates[type](file)
    }
    return temp;
  }
}
