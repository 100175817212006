import { Component, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { Application } from 'src/app/aps/models/application-model';
import { NotificationModel, NotificationType } from 'src/app/aps/models/ui-models';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgNotification } from 'src/app/store/dispatchers';


@Component({
  selector: 'ushg-application-detail-header',
  templateUrl: './application-detail-header.component.html',
  styleUrls: ['./application-detail-header.component.scss'],
})
export class ApplicationDetailHeaderComponent {
  @Input() details: Application
  message='Order Creation Cancelled'
  constructor(
    private apsService: ApsService,
    private store: Store,
  ) { }

  cancelOrderCreation() {
    const isEditOrder = this.store.selectSnapshot((state) => state.data.currentOrderData).isEditOrder
    this.message = isEditOrder ? 'Order Update Cancelled' : 'Order Creation Cancelled'
    this.store.dispatch(new UshgNotification(new NotificationModel(NotificationType.INFO, '',this.message )));
    this.apsService.redirectToBase();
  }
}
