import { Component, Input } from '@angular/core';
import { HeadersList } from 'src/app/aps/models/application-model';
import { ColumnType } from 'src/app/aps/models/enums';

@Component({
  selector: 'ushg-applicant-request',
  templateUrl: './applicant-request.component.html',
  styleUrls: ['./applicant-request.component.scss'],
})
export class ApplicantRequestComponent {
  constructor() {}

  @Input() public headersList: HeadersList[];
  @Input() public ordersList;
}
