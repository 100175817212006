import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgFormService } from '../services/ushg-form-service';
import { Brcode } from 'src/app/aps/models/application-model';
import { formControlError } from 'src/app/aps/models/ui-models';

@Component({
  selector: 'app-brcode-dropdown',
  templateUrl: './brcode-dropdown.component.html',
  styleUrls: ['./brcode-dropdown.component.scss'],
})
export class BrcodeDropdownComponent implements OnInit {
  @Input() mode: string;
  @Input() brCode: string;
  @Output() childComponentHasError = new EventEmitter<formControlError>();
  orderData;
  policiesWithBRCodeArray: Brcode[];
  policyInformation = [];
  clientNumber: string;
  policyNumber: string;

  constructor(
    private apsService: ApsService,
    private formservice: UshgFormService
  ) {
  }

  async ngOnInit(): Promise<void> {
    this.apsService.getCurrentOrderData().subscribe((data) => {
      this.orderData = data;
      this.clientNumber = this.orderData.clientNumber;
    });
    this.policyNumber =   this.formservice.formGroup &&this.formservice.formGroup.get('policyNumber').value;
    if ( this.orderData !=undefined && this.orderData!=null &&  !this.orderData.isEditOrder) {
      this.policiesWithBRCodeArray = [];
      this.apsService.getPoliciesWithBRCode().subscribe((result) => {
        this.policiesWithBRCodeArray = result;
        if(result !== undefined || result!=null ) {
          if (this.mode !== 'readOnly') {
            this.assignDropdownValue();
            this.initialCheck();
          } else {
            const result = this.apsService.checkPolicyInpoliciesWithBRCodeArray(
              this.policyNumber,
              this.clientNumber,
              this.policiesWithBRCodeArray
            );
            if (result===null || result === undefined ) {
              this.childComponentHasError.emit({ formControlName: 'policyNumber', hasError: true });
            } else {
              this.childComponentHasError.emit({ formControlName: 'policyNumber', hasError: false });
            }
          }
        }
      });
    }
  }

  assignDropdownValue() {
    this.policyInformation = [];
    this.orderData.policyInformation.forEach((policy) => {
      this.policyInformation.push({
        label: policy.policyNumber,
        value: policy.policyNumber,
      });
    });
    this.policyInformation = this.apsService.reArrangeThePolicyNumber(
      this.policyInformation,
      'value',
      this.orderData.orderApplicant.policyNumber
    );
  }

  initialCheck() {
    const result = this.apsService.checkPolicyInpoliciesWithBRCodeArray(
      this.policyNumber,
      this.clientNumber,
      this.policiesWithBRCodeArray
    );
    if (result===null || result === undefined ) {
      this.childComponentHasError.emit({ formControlName: 'policyNumber', hasError: true });
    } else {
      this.childComponentHasError.emit({ formControlName: 'policyNumber', hasError: false });
    }
  }

  onDropdownChange(event) {
    this.formservice.formGroup.patchValue({
      policyNumber: event.value,
    });
    this.policyNumber = event.value;
    const result = this.apsService.checkPolicyInpoliciesWithBRCodeArray(
      this.policyNumber,
      this.clientNumber,
      this.policiesWithBRCodeArray
    );
    if (result===null || result === undefined ) {
      this.childComponentHasError.emit({ formControlName: 'policyNumber', hasError: true });
    } else {
      this.childComponentHasError.emit({ formControlName: 'policyNumber', hasError: false });
    }
  }
}
