import { Validators } from "@angular/forms";
import { SECURE_EMAIL_TEMPLATES } from "src/app/aps/models/enums";

export const secureEmailConfig = (This) => {
    return [
        {
            formControlName: "template",
            label: "Template",
            formControlType: 'select',
            options: [
                {
                    label: 'Laboratory Request', value: SECURE_EMAIL_TEMPLATES.LABORATORY,
                },
                {
                    label: 'Signed Authorization', value: SECURE_EMAIL_TEMPLATES.SIGNED_AUTHORIZATION,
                }
                // This is removed since we need the actual email template that is approved by business. currently the email template used is a copy.
                // ,{
                //     label: 'Underwriting Needs More Information', value: SECURE_EMAIL_TEMPLATES.UNDERWRITING_NEEDS_MORE_INFORMATION,
                // }
            ],
            value: "template",
            placeholder: "Select a Template",
            validationMessage: "This field is required",
            validators: [Validators.required],
        },
        {
            formControlName: "company",
            label: "Company",
            value: 'company',
            formControlType: 'text',
            validationMessage: "This field is required",
            validators: [Validators.required],


        },
        {
            formControlName: "orderContactName",
            label: "Applicant Name",
            formControlType: 'text',
            value: 'orderContactName',
            validationMessage: "This field is required",
            validators: [Validators.required],
        },
        {
            formControlName: "email",
            label: "Applicant Email Address",
            formControlType: 'text',
            value: 'email',
            validationMessage: "Please enter valid Email address",
            validators: [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,8}$")],
        },
        {
            formControlName: "agentName",
            label: "Agent Name",
            formControlType: 'text',
            value: 'agentName',
            validationMessage: "This field is required",
            validators: [Validators.required],
        },
        {
            formControlName: "agentEmailAdress",
            label: "Agent Email Address",
            formControlType: 'text',
            value: 'agentEmailAdress',
            validationMessage: "Please enter valid Email address",
            validators: [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,8}$")],
            onblur: () => {
                This.secureEmailForm.patchValue({ agentEmailAdress: This.secureEmailForm.value.agentEmailAdress.toUpperCase() });
            }
        },
        {
            formControlName: "cc1",
            label: "CC1",
            formControlType: 'text',
            validators: [Validators.email]
        },
        {
            formControlName: "cc2",
            label: "CC2",
            formControlType: 'text',
            validators: [Validators.email]

        },

    ]
}