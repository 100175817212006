import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent {
  steps$ = this.store.select((state) => state.data.currentPageSteps)
  createOrderData = this.store.selectSnapshot((state) => state.data.currentOrderData)

  constructor(private store: Store) {
  }
}
