import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { IDialogAware } from 'src/app/aps/models/IDialogAware';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})

export class ConfirmModalComponent implements OnInit, IDialogAware {
  config = {
    width: '35%',
    closable: false,
  };

  buttonsList = [
    {
      label: 'Cancel',
      type: 'secondaryoutline',
      onClick: () => {
        this.dialog.close(false);
      }
    },
    {
      label: 'Confirm',
      type: 'primarylg',
      onClick: () => {
        this.onSave();
      }
    },
  ]
  document;

  constructor(
    public dialogService: DynamicDialogConfig,
    public dialog: UshgDialogService,
  ) {
  }

  ngOnInit() {
    this.document = this.dialogService.data;
  }

  onSave() {
    this.dialog.close(true);
  }
}


