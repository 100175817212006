import { AfterViewInit, Directive, ElementRef } from "@angular/core";

@Directive({
    selector: "[dateTimeCst]"
})
export class DateTimeCstDirective implements AfterViewInit {
    constructor(private elRef: ElementRef) { }

    ngAfterViewInit() {
        const cst = new Date(this.elRef.nativeElement.innerHTML.trim()).toLocaleString('en-US', {
            timeZone: 'CST',
        })
        this.elRef.nativeElement.innerHTML = this.elRef.nativeElement.innerHTML !== '-' || this.elRef.nativeElement.innerHTML !== '' ? cst : '-'
    }
}