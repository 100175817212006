<div class="grid p-5 pt-0" [formGroup]="noteForm">
    <h4 class="m-0">Notes <span class="text-yellow-700 m-0">*</span></h4>
    <textarea rows="8" pInputTextarea formControlName="notes" class="w-full mt-2 mb-1"></textarea>
    <p class="text-yellow-700 m-0" *ngIf="noteForm.controls['notes'].errors?.required">Note Required</p>
    <div class="grid col-12 mt-2">
        <div class="col-9 pl-0">
            <p-dropdown [options]="listOfOrders" optionLabel="label" placeholder="Related to the order (Optional)"
            optionValue="value" formControlName="selectOrder" [style]="{'minWidth':'100%'}"></p-dropdown>
        </div>  
    </div>      
    <div class="col-12">
        <app-ushg-dialog-footer [mode]="'custom'" [buttonsList]="buttonsList"></app-ushg-dialog-footer>
    </div>
</div>