import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { LoadNErrors } from 'src/app/store/dispatchers';
import { ToastrService } from 'src/app/shared/services/toastr-service';

@Injectable({
  providedIn: 'root'
})
export class HttpMessageInterceptorService implements HttpInterceptor {
  constructor(private store: Store,private toastr:ToastrService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const success: string = req.headers.get('x-success-message');
    const error: string = req.headers.get('x-error-message');
    const deciderKey: string = req.headers.get('x-response-decider-key');

    return next.handle(req).pipe(
      tap((evt) => {
        if (evt instanceof HttpResponse) {
          if ((evt.ok && success) || evt.status === 200) {
            this.store.dispatch(new LoadNErrors(evt))
          } if (deciderKey) {
            this.store.dispatch(new LoadNErrors(evt))
          }
          if (req.url.indexOf('/graphql') > -1) {
          }
        }
      }),
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse && err.status !== 200) {
          
          try {
            this.store.dispatch(new LoadNErrors(err))

          } catch (e) {
            this.store.dispatch(new LoadNErrors(err))

          }
          if (req.url.indexOf('/graphql') > -1) {
          return throwError('')
          }
          this.store.dispatch(new LoadNErrors(err))

          return of(new HttpResponse({ status: 500, body: err }));
        }
        // this is for APIs that do not return graceful json
        // if (success && err.status === 200) {
        if (err.status === 200) {
          if (success) {
            this.store.dispatch(new LoadNErrors(err))
          }
          return of(new HttpResponse({ status: 200, body: err.json }));
        }
        this.store.dispatch(new LoadNErrors(err))

        return of(err);
      })
    );
  }

}
