<div class="container mx-auto p-3 pb-0 pr-2"
    [ngClass]='{"shadow-none":config?.hideShadow,"shadow-0":( !config || !config.hideShadow), "col-10": dynamicClass}'>
    <div>
        <h2 class="text-bold mb-4 mt-0">
            {{config?.label ||' Applicant Details'}}
            <span (click)="editButtonHandler(config)" *ngIf="!config?.hideEdit && !config?.editMenu">
                <i class="pi pi-user-edit text-xl ml-3" style="font-size: 3rem"></i>
            </span>
            <span *ngIf="!config?.hideEdit && config?.editMenu">
                <i class="pi pi-user-edit text-xl ml-3" style="font-size: 3rem" (click)="menu.toggle($event)"></i>
                <p-menu #menu [popup]="true" [model]="config.editMenuItems"></p-menu>
            </span>
        </h2>
    </div>
    <div class="row">
        <div class="col-12 d-flex">
            <div class="grid">
                <ng-container *ngIf="!isEdit ; else edit">
                    <div [ngClass]='{"col-12 d-flex pt-1 pb-1":config?.detailsDirection==="column","col-4 d-flex":( !config || !config.detailsDirection)}'
                        [class]="key.class" *ngFor="let key of applicationDetailsKey">
                        <div class="grid align-items-center col-4"
                            [ngClass]='{" w-full p-0":key.labelClass === undefined}'
                            [ngClass]="key.labelClass === undefined ? ' w-full p-0' : ''">
                            <div [ngClass]='{"col-6 ":config?.labelClass}' class="col-4 pr-0 pt-1 pb-1 pl-0">
                                <label for="" class="font-bold text-lg">{{key.label}}
                                    <span class="required" *ngIf="checkIfRequired(key)">*</span>
                                </label>
                            </div>
                            <div [ngClass]='[key?.customClass !== undefined ? key?.customClass : "col-8 p-0"]'
                                *ngIf='!key.type'>
                                <p class="p-0 m-0 text-lg " *ngIf=" applicationDetailsValue(key) === true ||
                                    applicationDetailsValue(key) === 'true'|| 
                                    applicationDetailsValue(key) === 'false'||
                                    applicationDetailsValue(key) === false">
                                    <span
                                        *ngIf="applicationDetailsValue(key) === true ||    applicationDetailsValue(key) === 'true'  ">
                                        Yes
                                    </span>
                                    <span
                                        *ngIf="applicationDetailsValue(key) === false ||  applicationDetailsValue(key) === 'false'">
                                        No
                                    </span>
                                </p>
                                <p class="p-0 m-0 text-lg "
                                    *ngIf="!(applicationDetailsValue(key) === true ||applicationDetailsValue(key) === false  ) && key.mask"
                                    [mask]="{'value':applicationDetails[key?.value], 'key':key?.mask}">
                                </p>
                                <p class="p-0 m-0 text-lg"
                                    *ngIf="!(applicationDetailsValue(key) === true  ||  applicationDetailsValue(key) === 'true' ||  applicationDetailsValue(key) === 'false'||applicationDetailsValue(key)===false ) && !key.mask">
                                    {{applicationDetails[key?.value] | reverseString:key.formControlName:'fullname'}}
                                </p>
                            </div>
                            <div [ngClass]='[key?.customClass !== undefined ? key?.customClass : "col-8 p-0"]'
                                *ngIf="key.type==='date'">
                                <p class="p-0 m-0 text-lg" ushgDate>
                                    {{applicationDetails[key?.value] || '-'}}
                                </p>
                            </div>
                            <div [ngClass]='[key?.customClass !== undefined ? key?.customClass : "col-8 p-0"]'
                                *ngIf="key.type==='brCode'">
                                <app-brcode-dropdown [mode]="'readOnly'"
                                    (childComponentHasError)="changeToEditMode($event)"></app-brcode-dropdown>
                            </div>
                        </div>
                    </div>
                    <div class="col-11 mb-0 mr-0 pr-6 pl-0">
                        <ng-content select="div[role=data]"></ng-content>
                    </div>
                </ng-container>
                <ng-template #edit>
                    <ng-container>
                        <div class="p-0"
                            [ngClass]='{"col-12 d-flex":config?.detailsDirection==="column","col-4 d-flex":( !config || !config.detailsDirection)}'
                            *ngFor="let key of applicationDetailsKey" [class]="key.class">
                            <div class="grid align-items-center col-4 pb-0 pt-0"
                                [ngClass]='{" w-full":key.labelClass === undefined}'>
                                <div [ngClass]='{"col-6 ":config?.labelClass}' *ngIf="key.label" class="col-4 p-0">
                                    <label for="" class=" font-bold text-lg">{{key.label}}
                                        <span class="required" *ngIf="checkIfRequired(key)">
                                            *
                                        </span>
                                    </label>
                                </div>
                                <div [ngClass]='[key?.customClass !== undefined ? key?.customClass : "col-8"]'>
                                    <ushg-form [formControlComponent]="key" (formErrorStatus)="changeToEditMode($event)"></ushg-form>
                                </div>
                            </div>

                        </div>
                        <div class="col-12 p-0">
                            <ng-content select="div[role=edit]"></ng-content>
                        </div>
                        <div class="col-12 mb-0 mr-0">
                            <ng-content select="div[role=data]"></ng-content>
                        </div>
                    </ng-container>
                    <div class="col-12 flex justify-content-end pr-4">
                        <ushg-button [label]="'Cancel'" [type]="'outline'" class="mr-2"
                            [ngClass]="{'p-disabled':(config && config.keepCancelDisabled)}"
                            (click)="this.editButtonHandler();"></ushg-button>
                        <ushg-button [label]="'Save'" [type]="'primary'" (click)="save()"
                            [ngClass]="{'p-disabled':(!getFormGroupValid() && !(config && config.keepSaveEnabled))}">
                        </ushg-button>
                    </div>
                </ng-template>
            </div>
        </div>
    </div>
</div>
<ushg-navigation-buttons (nextButtonClick)="nextButtonEventHandler($event)"
    (backButtonClick)="backButtonEventHandler($event)" [isBackButtonDisabled]='backButtonDisabled'
    [isNextButtonDisabled]="isEdit" *ngIf="!config?.hideNavigationButtons">
</ushg-navigation-buttons>