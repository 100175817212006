import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private msalService: MsalService) {}

  public getAccessToken(): Promise<string> {
    return this.msalService
        .acquireTokenSilent({
            scopes: [environment.authConfig.scope]
        })
        .toPromise()
        .then((result: AuthenticationResult) => {
            return result.accessToken;
        });
  }
}
