import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from '@apollo/client/utilities';
import { Store } from '@ngxs/store';
import { Subject } from 'rxjs';
import { DoctorLookupAnswer, ProviderSearchModel, ProviderSearchPayload } from 'src/app/aps/models/ui-models';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { UshgFormService } from 'src/app/shared/services/ushg-form-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { CurrentOrderData } from 'src/app/store/dispatchers';
import { doctorDetailsConfigKeys } from '../../create-order-components/create-aps-order-components/aps-order-demographic-details/aps-demographic-config';
import { providerDetailsConfig } from './provider-lookup-config';

@Component({
  selector: 'app-provider-lookup',
  templateUrl: './provider-lookup.component.html',
  styleUrls: ['./provider-lookup.component.scss']
})
export class ProviderLookupComponent implements OnInit {
  searchInputParameters = [
    {
      placeholder: 'Please enter Healthcare Provider',
      type: 'text',
      minLength: 3,
      maxLength: 200,
      searchSelection: 'healthcare',
    },
    {
      placeholder: 'Please enter Name of the Facility',
      type: 'text',
      minLength: 3,
      maxLength: 200,
      searchSelection: 'facility',
    },
    {
      // prettier-ignore
      placeholder: 'Please enter Provider\'s Phone Number ( ex : 1234567890 )',
      type: 'number',
      minLength: 10,
      maxLength: 10,
      searchSelection: 'phonenumber',
    },
  ];
  showAdvancedSearch = false
  formGroup: UntypedFormGroup
  providerDetailsConfig = providerDetailsConfig
  providersList$: Observable<DoctorLookupAnswer[]>
  providerInput$ = new Subject<string | null>()
  searchType = 0
  providerLoading = false
  selectedProvider
  config = {
    width: '45%',
    closable: true,
    header: 'Provider Lookup'
  };
  buttonsList = [
    {
      label: 'Cancel',
      type: 'secondaryoutline',
      onClick: () => {
        this.dialogService.cancel()
      }
    },
    {
      label: 'Add',
      type: 'primarylg',
      disable: true,
      onClick: () => {
        this.addProvider()
      }
    },
  ];
  applicantContactDetails;
  constructor(
    private dialogService: UshgDialogService,
    private apsApisService: ApsApiService,
    private formService: UshgFormService,
    private store: Store

  ) { }

  ngOnInit(): void {
    this.applicantContactDetails = this.store.selectSnapshot((state) => state.data.currentOrderData)
    this.searchProvider()
    this.createForm()
  }

  createForm() {
    this.formGroup = this.formService.createForm(this.providerDetailsConfig)
  }

  selectedProviderEventHandler(ev) {
    this.formGroup.patchValue(ev)
    this.buttonsList[1].disable = !this.formGroup.valid
  }

  searchProvider() {
    this.providerInput$.subscribe((input) => {
      this.providerLoading = true
      const payload: ProviderSearchModel = new ProviderSearchPayload(+this.searchType, +this.searchType !== 2 ? `${input}*`:'*', this.applicantContactDetails.contact.applicantzipCode, this.searchType == 2 ? `${input}`:'')
      this.providersList$ = this.apsApisService.searchProviders(payload)
      this.providerLoading = false
    })
  }

  addProvider() {
    const { name: providerName, name: facilityName, name: providerFullName, name: fullname,phone_numbers:providerPhone, street_address: streetAddress, street_address: providerstreetAddress, zipcode: zipCode, zipcode: providerCityStateZip, tax_id: taxId } = this.selectedProvider
    const provider = { ...this.selectedProvider, providerName, facilityName, streetAddress, zipCode,providerPhone, providerstreetAddress, fullname, providerFullName, taxId }
    this.applicantContactDetails.provider = provider;
    this.applicantContactDetails.orderDetails.APS.provider=provider;
    this.store.dispatch(new CurrentOrderData(this.applicantContactDetails));
    this.dialogService.close();
  }
}


