import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { Observable } from "@apollo/client/utilities";
import { Select, State, Store } from "@ngxs/store";
import { CurrentPageSteps } from "src/app/store/dispatchers";
import { Step } from "../models/application-model";
import { NavigationStepStatus } from "../models/enums";
import { ApsService } from "./aps-service";

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    @Select((state) => state.data.currentPageSteps) private steps$: Observable<Step[]>
    baseUrl = "aps/create-order"
    constructor(private router: Router, private store: Store, private apsService: ApsService) {


    }

    nextStep() {
        const currentOrderData = this.store.selectSnapshot((state) => state.data.currentOrderData)
        const currentUrl = window.location.pathname.split('/')[3]
        const currentStep = this.getCurrentStep(currentUrl)
        currentStep.status = NavigationStepStatus.COMPLETED
        this.steps[currentStep.index] = currentStep
        this.steps.map((step) => {
            if (this.steps[currentStep.index + 1].label === step.label) {
                step.status = NavigationStepStatus.ACTIVE
            }
            return step
        })
        const nextStepUrl = this.steps[currentStep.index + 1].url
        this.router.navigate([`${this.baseUrl}/${nextStepUrl}`], { queryParamsHandling: 'merge', })
        this.store.dispatch(new CurrentPageSteps(this.steps))
        currentOrderData.isEditOrder ? this.updateOrderOnNextStep() : null
    }

    previouStep() {
        const currentUrl = window.location.pathname.split('/')[3]
        const currentStep = this.getCurrentStep(currentUrl)
        const nextStepUrl = this.steps[currentStep.index - 1].url
        currentStep.status = NavigationStepStatus.INCOMPLETED
        this.steps[currentStep.index] = currentStep
        this.steps.map((step) => {
            if (this.steps[currentStep.index - 1].label === step.label) {
                step.status = NavigationStepStatus.ACTIVE
            }
            else if (this.steps[currentStep.index - 1] !== step.label && this.steps[currentStep.index + 1]?.status) {
                this.steps[currentStep.index + 1].status = NavigationStepStatus.INCOMPLETED
            }

            return step
        })
        this.steps[currentStep.index - 1].status = NavigationStepStatus.ACTIVE
        this.router.navigate([`${this.baseUrl}/${nextStepUrl}`], { queryParamsHandling: 'merge', })
        this.store.dispatch(new CurrentPageSteps(this.steps))
    }

    private getCurrentStep(currentUrl) {
        let currentStepObject
        let currentStepObjectIndex
        this.steps$.subscribe((step: Step[]) => {
            currentStepObjectIndex = step.findIndex((s) => s.url === currentUrl)
            currentStepObject = step.find((s) => s.url === currentUrl)
            if (currentStepObject) {
                currentStepObject.index = currentStepObjectIndex
            }
        })
        return currentStepObject
    }

    get steps() {
        let step
        this.steps$.subscribe((steps: Step[]) => {
            step = steps
        })
        return step
    }

    updateOrderOnNextStep() {
        this.apsService.createOrderRequest()
    }

    currentStep() {
        const currentUrl = window.location.pathname.split('/')[3]
        const currentStep = this.getCurrentStep(currentUrl);
        return currentStep;
    }
    
}
