import { ColumnType } from "src/app/aps/models/enums";

export const workQueueHeaders = [
  { label: 'App Info', field: 'applicationId', subField: 'controlNumber', addonPill: 'applicationTrackerType' },
  {
    label: 'Primary Name',
    field: 'primaryContactName',
    type: ColumnType.primaryName,
  },
  { label: 'Time In Queue', field: 'whenRoutedToTeam', type: ColumnType.timeInQueue },
  { label: 'Vcall Date', field: 'createdDate', type: ColumnType.date },
  { label: 'Agent Name', field: 'agentName' },
  { label: 'Products', field: 'product' },
  {
    label: 'Request Type',
    field: 'orderDetails',
    type: ColumnType.requestType,
  },
  {
    label: 'Status',
    field: 'applicationStatusId',
    type: ColumnType.applicationStatus,
  },
  { label: 'Assigned To', field: 'displayName' },
  { label: 'Actions', field: '', type: ColumnType.actions },
];