<app-errors-list></app-errors-list>
<div class="fixed bottom-0 left-5 ml-6 mb-2">
  <span class="version text-color-secondary text-sm">{{ version }}</span>
</div>
<div>
  <div class="bg-layout"></div>
    <router-outlet></router-outlet>
    <p-toast position="top-center" [baseZIndex]="10000" [preventOpenDuplicates]='true'></p-toast>
    <div class="progress-spinner" *ngIf="showSpinner">
      <p-progressSpinner></p-progressSpinner>
  </div>
</div>
