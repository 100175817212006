import { ColumnType } from './enums';

export const selectedApplicantdetailsModel = [
  {
    label: 'Request Date',
    value: 'createdDate',
    type: ColumnType.date,
  },
  {
    label: 'Request Type',
    value: 'requestTypes',
    type: ColumnType.requestType,
  },
  {
    label: 'App ID',
    value: 'applicationId',
    type: ColumnType.appId,
  },
  {
    label: 'Control No',
    value: 'controlNumber',
  },
  {
    label: 'Product(s)',
    value: 'product',
  },
  {
    label: 'Underwriter',
    value: 'createdBy',
  },
  {
    label: 'Assigned To',
    value: 'assignedTo',
  },
  {
    label: 'Assigned Date',
    value: 'assignedOn',
    type: ColumnType.date,
  },
  {
    label: 'Status',
    value: 'applicationStatusId',
    type: ColumnType.applicationStatus,
  },
];
export interface HeadersList {
  label: string;
  field: string;
  type?: ColumnType;
  class?: string;
  actionList?: Function;
  subField?: string;
  permission?: string;
  addonPill?: string;
  reminder?: string;
  onChange?: Function;
  toolsList?: ToolsList[]
}

export interface ApplicationStatus {
  name: string;
  __typename: string;
}

export interface ApsUsersModel {
  empName: string;
  intial: string;
  newCount: number;
  pendingCount: number;
  role: string;
}

export interface Step {
  label: string;
  url?: string;
  isFirst?: boolean;
  status?: string;
  isLast?: boolean;
  hasChildrenRoutes?: boolean;
  children?: { name: string; url: string }[];
}
export interface ApsUserModel {
  displayName?: string;
  domainName?: string;
  jobTitle?: string;
  id?: number;
  userId?: string;
  newCount?: number;
  pendingCount?: number;
  intial?: string;
  domainUserName?: string;
  userPrincipalName?: string;
}

export interface ProfileModel {
  name: string;
  initial: string;
  domainUserName?: string;
}

export interface Application {
  [x: string]: any;
  id?: number;
  applicationId?: number;
  convertedVersion: string;
  primaryApplicant: Object;
  primaryContactName?: string;
  agentName?: string;
  assignedTo?: string;
  applicationStatusId?: number;
  createdBy?: string;
  product?: string;
  applicationStatus?: ApplicationStatus;
  orderDetails: any;
  orderTrackers?: any[];
  reminderDate?: string;
  updatedBy?: string;
  createdDate?: Date;
  __typename?: string;
  timeInQueue?: string;
  requestType: [
    {
      label: string;
      status: string;
      type: string;
    }
  ];
  hideActions?: boolean;
  checkRequest?: CheckRequest;
  index: number;
  nodetotalCount?: number;
  displayName?: string;
  redirectUrl?: string;
  applicationTrackerId?: number;
  applicationPropertyBags?: any;
}

export interface Orders {
  applicantName: string;
  requestType: string;
  status: string;
  doctorInfo: string;
  orderId: number;
  assignedTo: string;
}

export interface CheckRequest {
  id: string;
  checkName: string;
  checkRequestDate: string;
  amount: number;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  description: string;
  createdBy: string;
  createdDate: string;
  updatedBy: string;
  documentUrl: string;
  documentName: string;
  updatedDate: string;
  orderTrackerId?: number;
}

export interface NewOrderLogEntryInterface {
  information: string;
  auditParameter: string;
  auditTypeId: number;
  loggedDate: string;
  orderTrackerId: string | number;
}

export interface ToggleOrderLogEntryPinInterface {
  id: number;
  pin: boolean;
}

export interface ToolsList {
  label: string,
  icon: string,
  field: string,
  type: string,
  count?: Function,
  data?: Function
}

export interface Brcode {
  brCode: string,
  clientNo: number,
  policyNumber: string,
  relationship: string
}

export interface AppPolicyInfo {
  appTypeId: number,
  policyNumber: string
}