import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ushg-message',
  templateUrl: './ushg-message.component.html',
  styleUrls: ['./ushg-message.component.scss']
})
export class UshgMessageComponent implements OnInit {
  @Input() severity
  @Input() text
  constructor() { }

  ngOnInit(): void {
    console.log('');
  }

}
