import { Component, Input } from '@angular/core';
import { ChipFilterService } from './chip-filter.service';

@Component({
  selector: 'ushg-chip',
  templateUrl: './ushg-chip.component.html',
  styleUrls: ['./ushg-chip.component.scss']
})
export class UshgChipComponent {
  @Input() value: any;
  @Input() chipLabel: string
  @Input() chipStatusId: number | string

  constructor(private chipFilterService: ChipFilterService) { }

  getStatusPillImagePath(statusName){
    return this.chipFilterService.filterImagePath(statusName);
  }

  get isValueArray() {
    return Array.isArray(this.value)
  }

}
