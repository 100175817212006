import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { NavigationService } from 'src/app/aps/services/aps-navigation-service';

@Component({
  selector: 'ushg-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss']
})
export class NavigationButtonsComponent implements OnInit {
  currentOrderData=this.store.selectSnapshot((state)=>state.data.currentOrderData);
  @Output() nextButtonClick = new EventEmitter();
  @Output() backButtonClick = new EventEmitter();
  @Input() isBackButtonDisabled: Boolean;
  @Input() isNextButtonDisabled: Boolean;
  isBackButtonHide: boolean;
  isLastStep = false;

  constructor(private navigation: NavigationService,private store:Store) {
  }

  ngOnInit(): void {
    const currentStep = this.navigation.currentStep();
    this.isBackButtonHide = (currentStep && currentStep !== null && currentStep?.isFirst === true) ? true : false;
    this.isLastStep = (currentStep && currentStep?.isLast === true) ? true : false;
  }

  nextButtonEventHandler() {
    this.nextButtonClick.emit();
  }

  backButtonEventHandler() {
    this.backButtonClick.emit();
  }

}
