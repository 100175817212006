<div class="custom-card pt-2 pb-2 f12">
  <div class="flex justify-content-between align-items-center px-2">
    <div>
      <p class="applicant-name m-2">
        {{ selectedApplication.primaryApplicant["firstName"] }}
        {{ selectedApplication.primaryApplicant["lastName"] }}

        <span class="text-primary ml-2">
          ({{ selectedApplication?.applicationId }})
        </span>
      </p>
    </div>

    <div *ngIf="
        !this.selectedApplication?.hideActions && !this.config?.disableRouteTo
      ">
      <ushg-button [label]="'Route To'" [type]="'primarySm'" (click)="openRouteToModal()"
        [appPermission]="'CanAccessRouteApplication'" [disable]="disableActions"></ushg-button>
    </div>
  </div>
  <div class="flex">
    <div class="grid pl-5 mt-2 col-12 m-0" [ngClass]="{
      'col-10': config?.component === 'followUp' && canDisplayFollowupLockFeature,
      'col-12': (!config || config.component !== 'followUp') || !canDisplayFollowupLockFeature
    }">
      <div class="col-4 p-0" *ngFor="let detail of selectedApplicantDetailsConfig">
        <div class="grid p-1 col-11">
          <div class="col-3 pr-0">
            <label>
              {{ detail.label }}
            </label>
          </div>
          <div class="col-6 font-bold">
            <ng-container [ngSwitch]="detail.type">
              <span *ngSwitchDefault>
                {{
                selectedApplication && selectedApplication[detail.value]
                ? selectedApplication[detail.value]
                : applicationTracker && applicationTracker[detail.value]
                }}
              </span>
              <span *ngSwitchCase="'Documents'"> N/A </span>
              <span *ngSwitchCase="ColumnType.applicationStatus">
                {{
                ApplicationtionStatus[selectedApplication[detail.value]]
                ? ApplicationtionStatus[selectedApplication[detail.value]]
                : applicationTracker &&
                ApplicationtionStatus[applicationTracker[detail.value]]
                }}
              </span>
              <span *ngSwitchCase="ColumnType.date">
                {{
                (selectedApplication && selectedApplication[detail.value]
                ? selectedApplication[detail.value]
                : applicationTracker && applicationTracker[detail.value]
                ) | dateTime : timeZone : "date"
                }}
              </span>
              <ng-container *ngSwitchCase="ColumnType.requestType">
                <span *ngFor="let request of selectedApplication[detail.value]" class="mt-4">
                  <app-request-type [requestType]="request.requestType"></app-request-type>,
                </span>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="col-2 grid justify-content-end align-items-center p-0 mt-3" *ngIf="config && config.component === 'followUp' && canDisplayFollowupLockFeature">
      <div class="override-card mt-3 mb-2" *ngIf="lockStatus === 'lockedByOther'; else unlockedContent">
        <p class="text-base m-0">As <span class="font-bold">{{lockedUser}}</span> is working on this application</p>
        <div class="mb-2 mt-3">
          <p-button label="Override" styleClass="p-button-danger" class="mt-3" (click)="lockOrReleaseApplication(true)"></p-button>
        </div>
      </div>
      <ng-template #unlockedContent>
        <div class="flex justify-content-end">
          <app-ushg-toggle-switch (emitEvent)="lockOrReleaseApplication($event)" [lockStatus]="lockStatus"></app-ushg-toggle-switch>
        </div>
        <p class="text-base font-medium">Please turn on the toggle or click override to Edit</p>
      </ng-template>
    </div>
  </div>
</div>