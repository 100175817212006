import { Component, Input, OnInit } from '@angular/core';
import { AuditTypeId } from '../../models/enums';
import { AuditLogModel } from '../../models/ui-models';

@Component({
  selector: 'ushg-audit-log-information',
  templateUrl: './audit-log-information.component.html',
  styleUrls: ['./audit-log-information.component.scss'],
})
export class AuditLogInformationComponent {
  @Input() notes: AuditLogModel;
  constructor() {}

  get AuditTypeId(): typeof AuditTypeId {
    return AuditTypeId;
  }

  getKeysFromJson(notes) {
    const keys = Object.keys(notes);
    return keys;
  }

  jsonParser(notes) {
    if (JSON.parse(notes)) {
      return JSON.parse(notes);
    }
  }

  isString(value) {
    return typeof value === 'string' ? false : true;
  }
}
