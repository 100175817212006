import { ErrorHandler, Injectable } from '@angular/core';
import { ErrorService } from './error.service';

@Injectable()
export class CustomErrorHandlerService extends ErrorHandler {
  constructor(private errorService: ErrorService) {
    super();
  }
  handleError(error) {
    this.errorService.addError({ error });
    super.handleError(error);
  }
}
