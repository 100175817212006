<form [formGroup]="secureEmailForm">


    <div class="grid  p-0 mx-auto mt-5 justify-content-center">
        <div class="col-5  " *ngFor="let formControl of secureEmailConfig">
            <p class="label">{{formControl.label}}
                <span class="required" *ngIf="checkIfRequired(formControl)">
                    *
                </span>
            </p>
            <ushg-form [formControlComponent]="formControl" class="mt-2"></ushg-form>
        </div>
        
    </div>
    <div class="grid">
        <div class="col-1"></div>
        <div class="col-10 mt-3">
            <p  class='m-0 ml-2 label'>Attachments</p>
            <div  *ngFor="let file of data?.orderDocuments?.documents"  >
                <div class="file" *ngIf='file.checked'>

                        <div class="grid">
                            <div class="col-11">
                                <app-ushg-doc [document]="file" [canDisableDelete]="true" [canDisableDownload]="false"
                                [canDisplayActionIcons]="true"></app-ushg-doc>
                            </div>
                        </div>
                </div>

            </div>
        </div>
    </div>

</form>
<app-ushg-dialog-footer  [buttonsList]='buttonsList'>
</app-ushg-dialog-footer>
<div  class="progress-spinner" *ngIf="showSpinner">
    <p-progressSpinner></p-progressSpinner>
</div>