<p-dialog
  [(visible)]="display"
  [modal]="true"
  header="Errors"
  [closable]="false"
  [breakpoints]="{ '960px': '90vw' }"
  [style]="{ width: '50vw' }"
>
  <div *ngIf="!errorList.length">
    <p-messages severity="success">
      <ng-template pTemplate>
        <div class="p-ml-2">No errors found!</div>
      </ng-template>
    </p-messages>
  </div>
  <div *ngFor="let error of errors$ | async">
    <!-- {{error | json}} -->
    <app-error [data]="error.error"></app-error>
  </div>

  <ng-template pTemplate="footer">
    <button
      pButton
      pRipple
      type="button"
      label="Close"
      class="p-button-outlined"      
      (click)="this.display = false"
    ></button>
  </ng-template>
</p-dialog>

<button
  pButton
  pRipple
  type="button"
  label="{{ errorList.length }}"
  styleClass="mr-2"
  (click)="this.display = true"
  class="p-button-secondary fixed bottom-0 left-0 m-1"
></button>

<p-toast position="top-center" [baseZIndex]="1000000" key="tc"></p-toast>
