import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SearchAuthorizationFormLabels } from 'src/app/aps/models/enums';
import { US_STATES } from '../authorization-form-constants';

@Component({
  selector: 'app-authorization-search-form',
  templateUrl: './authorization-search-form.component.html',
  styleUrls: ['./authorization-search-form.component.scss']
})
export class AuthorizationSearchFormComponent implements OnInit {
  @Output() searchAuthorization = new EventEmitter();
  @Output() saveForm = new EventEmitter();
  @Output() resetAuthForms = new EventEmitter();
  searchForm: FormGroup;
  usStates = US_STATES;
  get SearchAuthorizationFormLabels(): typeof SearchAuthorizationFormLabels {
    return SearchAuthorizationFormLabels;
  }

  constructor(private fb: FormBuilder) { }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.searchForm = this.fb.group({
      doctorName: [''],
      facilityName: [''],
      state: [''],
      city: [''],
      zipCode: ['']
    }, { validators: this.atLeastOneInputHasValue() });
  }

  atLeastOneInputHasValue = () => {
    return (group: FormGroup) => {
      if (
        !Object.values(group.value).find(
          (value) => value !== '' && value !== null
        )
      ) {
        return { message: 'Please Enter at least one input value' };
      }
      return null;
    };
  };

  searchAuthorizationForms() {
    this.searchAuthorization.emit(this.searchForm)
  }

  onReset() {
    this.searchForm.reset();
    this.resetAuthForms.emit(true);
  }

}
