<div class="custom-card applicant-card">
  <table cellspacing="0" cellpadding="0">
    <tr class="heading-row">
      <th
        *ngFor="let heading of auditLogHeadersList"
        class="heading-label"
      >
        {{ heading.label }}
      </th>
    </tr>
    <ng-container *ngFor="let data of logsAndNotes; index as i">
      <tr class="orderList">
        <td>{{ data.loggedBy }}</td>
        <td ushgDate>{{ data.loggedDate }}</td>
        <td>{{ data.auditParameter }}</td>
        <td class="info py-3">{{ data.information }}</td>
      </tr>
    </ng-container>
  </table>
</div>