import { NavigationStepStatus } from "../models/enums";

export const LABORDER_AE_STEPS = [{
    label: "Demographic",
    url: "laborder-ae-demographic",
    isFirst: true,
    status: NavigationStepStatus.ACTIVE
},
{
    label: "Request & Letters",
    url: "request-letters",
    isFirst: false,
},
{
    label: "Request & Letters",
    url: "generate-pdf",
    isFirst: false,

},
{
    label: "Send",
    url: "send",
    isFirst: false,
    isLast: true,
},
]

export const LAB_AE_HTWTBP_STEPS = [{
  label: "Demographic",
  url: "LAB_AE_HTWTBP",
  isFirst: true,
  status: NavigationStepStatus.ACTIVE
},
{
  label: "Request & Letters",
  url: "request-letters",
  isFirst: false,
},
{
  label: "Request & Letters",
  url: "generate-pdf",
  isFirst: false,

},
{
  label: "Send",
  url: "send",
  isFirst: false,
  isLast: true,
},
]

export const APSORDER_STEPS = [{
    label: "Demographic",
    url: "aps-demographic",
    isFirst: true,
    status: NavigationStepStatus.ACTIVE
},
{
    label: "Document & Letters",
    url: "generate-pdf",
    isFirst: false,
},
{
    label: "Send",
    url: "send",
    isFirst: false,
    isLast: true,
},
]
