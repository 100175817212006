import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UshgChipComponent } from './ushg-chip/ushg-chip.component';
import { ApsDateComponent } from './ushg-date/ushg-date.component';
import { UshgTableComponent } from './ushg-table/ushg-table.component';
import { UshgTableActionsComponent } from './ushg-table-actions/ushg-table-actions.component';
import { UshgUsersWorkloadComponent } from './ushg-users-workload/ushg-users-workload.component';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { CalendarModule } from 'primeng/calendar';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { ChipModule } from 'primeng/chip';
import { CardModule } from 'primeng/card';
import { PaginatorModule } from 'primeng/paginator';
import { HeaderComponent } from './header/header.component';
import { InputTextModule } from 'primeng/inputtext';
import { NavHeaderComponent } from './nav-header/nav-header.component';
import { TabMenuModule } from 'primeng/tabmenu';
import { MenubarModule } from 'primeng/menubar';
import { ChipColorDirective } from './directives/chip-color';
import { SearchInputComponent } from './ushg-input/search-input.component';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { UshgButtonComponent } from './ushg-button/ushg-button.component';
import { DialogService, DynamicDialogModule } from 'primeng/dynamicdialog';
import { DialogModule } from 'primeng/dialog';
import { UshgDialogService } from './services/ushg-dialog-service';
import { UshgDateDirective } from './directives/ushg-date';
import { ApplicationStatusDirective } from './directives/ushg-application-status';
import { ApplicationDetailHeaderComponent } from './application-detail-header/application-detail-header.component';
import { NavigationHeaderComponent } from './navigation-header/navigation-header.component';
import { StepsModule } from 'primeng/steps';
import { NavigationButtonsComponent } from './navigation-buttons/navigation-buttons.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UshgFormComponent } from './ushg-form/ushg-form.component';
import { FileUploadModule } from 'primeng/fileupload';
import { HttpClientModule } from '@angular/common/http';
import { UshgDetailsContainerComponent } from './ushg-details-container/ushg-details-container.component';
import { UshgDialogFooterComponent } from './ushg-dialog-footer/ushg-dialog-footer.component';
import { DropDownComponent } from './drop-down/drop-down.component';
import { OrderStatusDirective } from './directives/ushg-order-status';
import { ErrorsListComponent } from './errors-list/errors-list.component';
import { PrimengModule } from '../primeng/primeng.module';
import { ErrorComponent } from './errors-list/error/error.component';
import { PermissionDirective } from './directives/permission-directive';
import { DateTimeCstDirective } from './directives/date-time-cst';
import { SendSecureEmailComponent } from './send-secure-email/send-secure-email.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DateTimePipe } from './pipes/date-time.pipe';
import { UshgMultiSelectDropdownComponent } from './ushg-multi-select-dropdown/ushg-multi-select-dropdown.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { InputNumberModule } from 'primeng/inputnumber';
import { RequestTypeComponent } from './request-type/request-type.component';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { MaskDirective } from './directives/mask';
import { UshgDocComponent } from './ushg-doc/ushg-doc.component';
import { UshgMessageComponent } from './ushg-message/ushg-message.component';
import { ToastrService } from './services/toastr-service';
import { TimeinQueueDirective } from './directives/time-in-queue';
import { AuditLogInformationComponent } from '../aps/components/audit-log-information/audit-log-information.component';
import { ReverseStringPipe } from './pipes/reverse-string/reverse-string.pipe';
import { TooltipModule } from 'primeng/tooltip';
import { SortPipe } from './pipes/sort-by.pipe';
import { NumberDirective } from './directives/numbers-only';
import { ChangeBackgroundColorDirective } from './directives/change-background-color.directive';
import { UshgOrderProcessStatusComponent } from './ushg-order-process-status/ushg-order-process-status.component';
import { UshgToggleSwitchComponent } from './ushg-toggle-switch/ushg-toggle-switch.component';
import { AgePipe } from './pipes/age.pipe';
import { BrcodeDropdownComponent } from './brcode-dropdown/brcode-dropdown.component';
import { SafePipeModule } from 'safe-pipe';
import { DocumentPreviewComponent } from './document-preview/document-preview.component';
import { SecondsToMinutesFilterPipe } from './pipes/seconds-to-minutes-filter.pipe';
import { UserIdleModalComponent } from './user-idle-modal/user-idle-modal.component';

const maskConfigFunction: () => Partial<IConfig> = () => {
  return {
    validation: false,
  };
};
@NgModule({
    declarations: [
        ErrorsListComponent,
        UshgMultiSelectDropdownComponent,
        ErrorComponent,
        UshgChipComponent,
        ApsDateComponent,
        UshgTableComponent,
        UshgTableActionsComponent,
        UshgUsersWorkloadComponent,
        SearchFilterComponent,
        HeaderComponent,
        SearchInputComponent,
        NavHeaderComponent,
        ChipColorDirective,
        UshgButtonComponent,
        UshgDateDirective,
        DateTimeCstDirective,
        ApplicationStatusDirective,
        OrderStatusDirective,
        ApplicationDetailHeaderComponent,
        NavigationHeaderComponent,
        NavigationButtonsComponent,
        UshgFormComponent,
        UshgDetailsContainerComponent,
        UshgDialogFooterComponent,
        DropDownComponent,
        PermissionDirective,
        DateTimePipe,
        RequestTypeComponent,
        SendSecureEmailComponent,
        ReverseStringPipe,
        MaskDirective,
        UshgDocComponent,
        UshgMessageComponent,
        TimeinQueueDirective,
        AuditLogInformationComponent,
        SortPipe,
        NumberDirective,
        ChangeBackgroundColorDirective,
        UshgOrderProcessStatusComponent,
        UshgToggleSwitchComponent,
        AgePipe,
        BrcodeDropdownComponent,
        DocumentPreviewComponent,
        SecondsToMinutesFilterPipe,
        UserIdleModalComponent
    ],
    imports: [
        PrimengModule,
        CommonModule,
        CalendarModule,
        TableModule,
        DropdownModule,
        ReactiveFormsModule,
        ChipModule,
        CardModule,
        DropdownModule,
        TabMenuModule,
        MenubarModule,
        InputTextModule,
        ToastModule,
        StepsModule,
        FormsModule,
        FileUploadModule,
        HttpClientModule,
        CalendarModule,
        DynamicDialogModule,
        DialogModule,
        MultiSelectModule,
        ProgressSpinnerModule,
        InputNumberModule,
        SafePipeModule,
        NgxMaskModule.forRoot(maskConfigFunction),
        TooltipModule
    ],
    exports: [
        ErrorsListComponent,
        ErrorComponent,
        UshgChipComponent,
        ApsDateComponent,
        UshgTableComponent,
        UshgTableActionsComponent,
        UshgUsersWorkloadComponent,
        SearchFilterComponent,
        HeaderComponent,
        SearchInputComponent,
        NavHeaderComponent,
        UshgButtonComponent,
        ApplicationDetailHeaderComponent,
        NavigationHeaderComponent,
        NavigationButtonsComponent,
        UshgFormComponent,
        UshgDetailsContainerComponent,
        UshgDateDirective,
        DateTimeCstDirective,
        UshgDialogFooterComponent,
        DropDownComponent,
        UshgMultiSelectDropdownComponent,
        PermissionDirective,
        RequestTypeComponent,
        DialogModule,
        NgxMaskModule,
        MaskDirective,
        DateTimePipe,
        ReverseStringPipe,
        UshgDocComponent,
        UshgMessageComponent,
        TimeinQueueDirective,
        AuditLogInformationComponent,
        SortPipe,
        NumberDirective,
        ChangeBackgroundColorDirective,
        UshgToggleSwitchComponent,
        AgePipe,
        BrcodeDropdownComponent,
        DocumentPreviewComponent,
        SecondsToMinutesFilterPipe,
        UserIdleModalComponent
    ],
    providers: [UshgDialogService, DialogService, ToastrService]
})
export class SharedModule {}
