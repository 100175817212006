import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { KeyValue } from '@angular/common';

import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

import {
  ApplicationLogsAndNotes,
  ApplicationsForFollowUpQueue,
  AssignedApplications,
  FilterAssignedApplicationsForFollowupQueue,
  FilterAssignedApplicationsForMyQueue,
  InsertAuditLog,
  ProviderPreferences,
  RequestOrderPdf,
  ResetRequestOrderPdf,
  ResetSpinner,
  SelectedApplicationDetails,
  SelectedApplicationDetailsByApplicationId,
  SetSpinner,
  UpdateApplicationProperties,
  UpdateSelectedApplicationDetails,
  UpdateOrder,
  UshgNotification,
  PoliciesWithBRCode,
  SelectedApplicationStatusDetails,
} from 'src/app/store/dispatchers';
import { EmailSentModalComponent } from '../components/core/create-order-components/create-order-modal-components/email-sent-modal/email-sent-modal.component';
import { Application, Brcode } from '../models/application-model';
import {
  AuditTypeId,
  OrderActionType,
  PAGE_STATE,
  ROUTE_TO_OPTIONS,
  RedirectUrl,
} from '../models/enums';
import { QueryResponse } from '../models/query-reponse';
import {
  DownloadDocumentsPayload,
  AuditLog,
  labRequestModel,
  NotificationModel,
  NotificationType,
  ProviderCityStateZip,
  ProcessStatusPayload,
} from '../models/ui-models';

import { ApsApiService } from './aps-api-service';
import { FileService } from '../../shared/services/file-service';
import { UtilityService } from './utility-service';
import { environment } from 'src/environments/environment';
import { UserProfile } from '../models/user-profile';
import * as dayjs from 'dayjs';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ApsService {
  SHADOW_COPY_EMAIL = "NoReply_NBC@optum.com";

  constructor(
    private apsApiService: ApsApiService,
    private store: Store,
    private dialogService: UshgDialogService,
    private datePipe: DatePipe,
    private router: Router,
    private utilityService: UtilityService,
    private fileService: FileService,
    private signalrService: SignalrService
  ) { }

  getApsUsers() {
    return this.apsApiService.getApsUsersList();
  }

  assignApplicationToApsUser(application, agent) {
    let { domainUserName } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    application.updatedBy = domainUserName;
    return this.apsApiService.assignApplicationToApsUser(application);
  }

  listOfAssignedApplicationsOfCurrentApsMember() {
    let profile = this.store.selectSnapshot((state) => state.data.userProfile);
    this.apsApiService
      .getListOfAssignedApplicationsToCurrentApsMember(profile['name'])
      .subscribe(({ data }: QueryResponse) => {
        this.store.dispatch(new AssignedApplications(data.applications));
      });
  }

  selectedApplicationDetails(appId) {
    return this.apsApiService.getApplicationDetails(appId);
  }

  selectedApplicationStatusDetails(appId) {
    return this.apsApiService.getApplicationStatusDetails(appId);
  }

  selectedApplicationLogsAndNotes(appId) {
    this.apsApiService
      .getAuditLogAndNotes(appId)
      .subscribe(({ data }: QueryResponse) => {
        this.store.dispatch(
          new ApplicationLogsAndNotes(
            data['auditLogsByApplicationTrackerId'],
            appId
          )
        );
      });
  }

  newOrderLogEntry(payload) {
    return this.apsApiService.newOrderLogEntry(payload);
  }

  toggleOrderLogEntryPin(payload) {
    return this.apsApiService.togglOrderLogEntryPin(payload);
  }

  listOfWorkQueueApplicationsBasedOnSearch(searchCriteria) {
    return this.apsApiService.getListofApplicationsBasedOnSearch(
      searchCriteria
    );
  }

  filterAssignedApplications(filters): Observable<QueryResponse> {
    return this.apsApiService.getFilteredAssignApplications(filters);
  }

  filterAssignedFollowupApplications(filters): Observable<QueryResponse> {
    return this.apsApiService.getFilteredAssignedFollowupApplications(filters);
  }

  getApsUsersList() {
    return this.apsApiService.getApsUsersList();
  }

  saveReminderDate(currentDateTime) {
    this.apsApiService.saveReminderDate(currentDateTime).subscribe();
  }

  updateProviderPhoneExtension(providerPhoneExtension) {
    let application = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    this.apsApiService
      .updateProviderPhoneExtension(providerPhoneExtension)
      .subscribe(async ({ data }: QueryResponse) => {
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(
              NotificationType.SUCCESS,
              '',
              'Phone Extension Updated successfully'
            )
          )
        );
      })
  }

  updateProviderPreferences(provider, preferences) {
    let application = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    this.apsApiService
      .updateProviderDetailPreferences(provider, preferences, application)
      .subscribe(async ({ data }: QueryResponse) => {
        if (
          data.upsertProviderDetails &&
          data.upsertProviderDetails?.documents
        ) {
          data.upsertProviderDetails.communicationPreferences.documents =
            data.upsertProviderDetails?.documents[0];
          data.upsertProviderDetails.communicationPreferences.documents.blob =
            data.upsertProviderDetails.documents?.content;
        }
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(
              NotificationType.SUCCESS,
              '',
              'Provider Preferences Updated successfully'
            )
          )
        );
        this.store.dispatch(
          new ProviderPreferences(
            data.upsertProviderDetails.communicationPreferences
          )
        );
      });
  }

  saveProviderPreferences(specialRequestLetterFile, ev) {
    let doctorDetailss = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    let currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    let obj = `{"uploadedOn":"${new Date().toISOString()}", "appId":"${currentOrderData.applicationId
      }"}`;

    this.updateProviderPreferences(doctorDetailss.provider, ev);
    return this.store.dispatch(new ResetRequestOrderPdf());
  }

  async getProviderPreferences(id) {
    let res = await this.apsApiService
      .getProviderDetailPreferences(id)
      .toPromise();
    let communicationPaymentPreferences =
      res.data &&
        res.data.providerDetails &&
        res.data.providerDetails.communicationPreferences
        ? res.data['providerDetails']['communicationPreferences']
        : {};
    if (
      communicationPaymentPreferences.communicationMode &&
      res.data.providerDetails?.documents
    ) {
      communicationPaymentPreferences.documents =
        res.data.providerDetails?.documents[0];
    }
    if (communicationPaymentPreferences.documents) {
      communicationPaymentPreferences.documents.blob =
        communicationPaymentPreferences.documents?.content;
    }
    this.store.dispatch(
      new ProviderPreferences(communicationPaymentPreferences)
    );

    return communicationPaymentPreferences;
  }

  generatePdf(pdfTitle) {
    let selectedApplication = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    this.apsApiService.getRequestOrderPdf(pdfTitle, selectedApplication);
  }

  savePdf() {
    let documents = this.store.selectSnapshot(
      (state) => state.data.requestOrderPdf
    );
    let selectedApplicant = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    let obj = `{"uploadedOn":"${new Date().toISOString()}", "appId":"${selectedApplicant.applicationId
      }"}`;
    documents.map((document) => {
      document.name = document.label;
      document.content = document.byteStream;
      document.updatedDate = new Date().toISOString();
      document.attributes = JSON.stringify(obj);
      this.apsApiService
        .savePdfToTheApplication(selectedApplicant, [document])
        .subscribe(({ data }) => {
          const documentsData = data.uploadFiles.documents[0];
          document.content = documentsData.content;
          document.url = documentsData.content;
          document.eTag = documentsData.eTag;
          document.documentType = document.documentType;
        });
      document.attributes = obj;
      return document;
    });
    this.store.dispatch(new ResetRequestOrderPdf());
    this.store.dispatch(new RequestOrderPdf(documents));
  }

  public RouteTo(team, selectedUnderWriter) {
    this.store.dispatch(new SetSpinner());
    let profile = this.store.selectSnapshot((state) => state.data.userProfile);
    let selectedApplicant: Application = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    let currentOrderData: Application = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    const payload = {
      id: selectedApplicant.orderDetails[0].applicationTrackerId,
      applicationId: selectedApplicant.applicationId,
      convertedVersion:
        selectedApplicant.applicationTracker[0].convertedVersion,
      assignedTo: this.selectedUnderWriterTeam(
        team,
        selectedUnderWriter,
        selectedApplicant
      ),
      updatedBy: profile?.name,
      createdDate: selectedApplicant.createdDate,
      createdBy:
        team === ROUTE_TO_OPTIONS.CURRENT_UNDER_WRITER
          ? selectedUnderWriter
          : selectedApplicant.createdBy
            ? selectedApplicant.createdBy
            : currentOrderData.createdBy,
      team:
        team === 'currentUnderWriter'
          ? ROUTE_TO_OPTIONS.CURRENT_UNDER_WRITER
          : team,
      assignedOn: new Date().toISOString(),
    };
    const pageNumber = (this.store.selectSnapshot((state) => state.data.pageNo) > 1) ? (this.store.selectSnapshot((state) => state.data.pageNo) - 1) * 10 : 0;
    this.apsApiService.routeTo(payload).subscribe(
      (res) => {
        if (!res.errors) {
          const query = this.store.selectSnapshot((state) => state.data.query);
          this.store.dispatch(new ResetSpinner());
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.SUCCESS,
                null,
                'Routed Sucessfully'
              )
            )
          );
          selectedApplicant.config.component === PAGE_STATE.FOLLOW_UP
            ? this.store.dispatch(
              new ApplicationsForFollowUpQueue(query, pageNumber, 10)
            )
            : this.store.dispatch(
              new FilterAssignedApplicationsForMyQueue(query)
            );
        } else {
          this.store.dispatch(new ResetSpinner());
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.ERROR,
                null,
                'Route failed'
              )
            )
          );
        }
      },
      (err) => {
        this.store.dispatch(new ResetSpinner());
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(NotificationType.ERROR, null, 'Route failed')
          )
        );
      }
    );
  }
  selectedUnderWriterTeam(team, selectedUnderWriter, selectedApplicant) {
    let selectedTeam;
    switch (team) {
      case ROUTE_TO_OPTIONS.CURRENT_UNDER_WRITER:
        selectedTeam = selectedUnderWriter;
        break;
      case ROUTE_TO_OPTIONS.CURRENT_UNDER_WRITER_PLACEHOLDER:
        selectedTeam = selectedApplicant.currentUnderWriter;
        break;
      case ROUTE_TO_OPTIONS.ISSUES:
        selectedTeam = selectedApplicant.assignedTo;
        break;
      case ROUTE_TO_OPTIONS.FOLLOWUP:
        selectedTeam = selectedApplicant.assignedTo;
        break;
    }
    return selectedTeam;
  }

  async getDoctsListForApplication() {
    let selectedApplicant = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    const resp: any = await this.apsApiService
      .getDocsListForApplication(selectedApplicant.applicationId)
      .toPromise();
    let docsList = JSON.parse(
      resp.find((each) => each.tag === 'DOCSLIST').responses[0].value
    );
    return (docsList = docsList.map((doctor) => {
      doctor.label = doctor.value = doctor.fullname;
      doctor.zipCode = doctor.zipcode;
      doctor.streetAddress = doctor.street_address;
      doctor.providerstreetAddress = doctor.street_address;
      doctor.providerPhone = doctor.phone_numbers;
      doctor.facilityName = doctor.fullname;
      doctor.taxId = doctor.tax_id;
      return doctor;
    }));
  }

  patchApplicantByClientNumber(clientNo, order) {
    let applicant = null;
    if (order.family) {
      applicant = order.family.find((member) => member.clientNo === clientNo);
    }
    return applicant ? applicant : order.primaryApplicant;
  }

  patchApplicantOverRide(applicant) {
    return applicant;
  }

  lockOrReleaseFollowUpApplication(lock) {
    const profile = this.store.selectSnapshot((state) => state.data.userProfile);
    const selectedApplication: Application = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    const { applicationTrackerId } = selectedApplication.orderDetails[0];
    const payload = {
      applicationTrackerId: applicationTrackerId,
      userName: profile.name
    }
    if (lock) {
      this.signalrService.invokeCall('setApplicationLock', payload, 'Application Locked Successfully').then();
    } else {
      this.signalrService.invokeCall('releaseApplicationLock', { applicationTrackerId: applicationTrackerId }, 'Application Unlocked Successfully').then();
    }
  }

  updatePropertiesOnLock(appProps) {
    const LOCKED_USER_KEY = 'USHG.NewBusiness.ApplicationEvents.Locked';
    let applications: Application[];
    const { data } = appProps;
    const keyValueMap = { key: LOCKED_USER_KEY, value: data.userName };
    applications = this.store.selectSnapshot((state) => state.data.applications);
    let selectedApplication: Application = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );

    const index = applications['followUp'].findIndex((a) => a.id === data.applicationTrackerId);

    // updating applications property bags
    if (index !== -1) {
      if (!applications['followUp'][index].applicationPropertyBags) {
        applications['followUp'][index].applicationPropertyBags = [];
      }
      if (!applications['followUp'][index].applicationPropertyBags?.length) {
        applications['followUp'][index].applicationPropertyBags[0] = keyValueMap;
        applications['followUp'][index].convertedVersion = data.convertedVersion;
      } else {
        const lockedUserIndex = applications['followUp'][index].applicationPropertyBags.findIndex(
          (res) => res.key === LOCKED_USER_KEY
        );
        if (lockedUserIndex !== -1) {
          applications['followUp'][index].applicationPropertyBags[lockedUserIndex].value = data.userName;
          applications['followUp'][index].convertedVersion = data.convertedVersion;
        }
      }
    }

    // updating selected application property bags
    if (selectedApplication.applicationTrackerId === data.applicationTrackerId) {
      if (!selectedApplication.applicationPropertyBags) {
        selectedApplication.applicationPropertyBags = [];
      }
      if (!selectedApplication.applicationPropertyBags?.length) {
        selectedApplication.applicationPropertyBags[0] = keyValueMap;
        selectedApplication.convertedVersion = data.convertedVersion;
      } else {
        const lockedUserIndex = selectedApplication.applicationPropertyBags.findIndex(
          (res) => res.key === LOCKED_USER_KEY
        );
        if (lockedUserIndex !== -1) {
          selectedApplication.applicationPropertyBags[lockedUserIndex].value = data.userName;
          selectedApplication.convertedVersion = data.convertedVersion;
        }
      }
    }
    return { selectedApplication, applications };
  }

  updatePropertiesOnUnlock(appProps) {
    const LOCKED_USER_KEY = 'USHG.NewBusiness.ApplicationEvents.Locked';
    let applications: Application;
    const { data } = appProps;
    applications = this.store.selectSnapshot((state) => state.data.applications);
    let selectedApplication: Application = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    const index = applications['followUp'].findIndex(a => a.id === data.applicationTrackerId);

    // updating applications property bags
    if (index !== -1) {
      applications['followUp'][index].applicationPropertyBags = applications['followUp'][index].applicationPropertyBags.filter(bag => bag.key !== LOCKED_USER_KEY);
      applications['followUp'][index].convertedVersion = data.convertedVersion;
    }

    // updating selected application property bags
    if (selectedApplication.applicationTrackerId === data.applicationTrackerId) {
      selectedApplication.applicationPropertyBags = selectedApplication.applicationPropertyBags.filter(a => a.key !== LOCKED_USER_KEY);
      selectedApplication.convertedVersion = data.convertedVersion;
    }
    return { selectedApplication, applications };
  }

  patchIndividualApplicationDetails(ordersList, selectedApplicant) {
    if (!selectedApplicant) {
      return;
    }
    const profile = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    if (
      selectedApplicant &&
      selectedApplicant?.policyInformation !== undefined
    ) {
      var result = selectedApplicant?.policyInformation
        ?.filter(
          (item) =>
            item.policyNumber
              .charAt(item.policyNumber.length - 1)
              .toString() === 'B' ||
            item.policyNumber
              .charAt(item.policyNumber.length - 1)
              .toString() === 'G' ||
            item.policyNumber
              .charAt(item.policyNumber.length - 1)
              .toString() === 'F'
        )
        .sort(
          (a, b) =>
            a.policyNumber.charCodeAt(a.policyNumber.length - 1) -
            b.policyNumber.charCodeAt(b.policyNumber.length - 1)
        );

      result = result.concat(
        selectedApplicant?.policyInformation
          ?.filter(
            (item) =>
              item.policyNumber
                .charAt(item.policyNumber.length - 1)
                .toString() !== 'B' &&
              item.policyNumber
                .charAt(item.policyNumber.length - 1)
                .toString() !== 'G' &&
              item.policyNumber
                .charAt(item.policyNumber.length - 1)
                .toString() !== 'F'
          )
          .sort(
            (a, b) =>
              a.policyNumber.charCodeAt(a.policyNumber.length - 1) -
              b.policyNumber.charCodeAt(b.policyNumber.length - 1)
          )
      );
      for (var i = 0; i < result.length; i++) {
        if (
          result[i].policyNumber.charAt(result[i].policyNumber.length - 1) ===
          'F'
        ) {
          result.unshift(result.splice(i, 1)[0]);
        }
      }
      selectedApplicant.policyInformation = result;
    }
    return ordersList?.map((each) => {
      each.orderType = {
        orderStatusId: each.orderStatusId,
        requestType: each.requestType,
        orderTrackerId: each.orderTrackerId,
      };
      selectedApplicant['providerName'] = each.APS?.provider?.fullname;
      selectedApplicant['orderApplicant'] = each.applicantOverride?.firstName
        ? this.patchApplicantOverRide(each.applicantOverride)
        : this.patchApplicantByClientNumber(
          each.clientNumber,
          selectedApplicant
        );

      selectedApplicant[
        'orderContactName'
      ] = `${selectedApplicant?.orderApplicant?.firstName} ${selectedApplicant?.orderApplicant?.lastName}`;
      selectedApplicant['PatientSignature'] = 'Electronic Signature on File';
      selectedApplicant['AuthDisclAppSign'] =
        this.utilityService.capitalizeSentense(
          selectedApplicant['orderContactName']
        );
      selectedApplicant['applicantstreetAddress'] =
        selectedApplicant['primaryApplicant']?.streetAddress;
      selectedApplicant['orderApplicant']['policy'] = '';
      if (each.APS && each.APS.provider)
        each.APS.provider.taxId = each.APS.provider.taxId
          ? each.APS.provider.taxId
          : '';
      if (each.APS) {
        selectedApplicant['providerstreetAddress'] =
          each.APS.provider.streetAddress;
        selectedApplicant['providerCityStateZip'] = new ProviderCityStateZip(
          each.APS.providerOverride
            ? each.APS.providerOverride
            : each.APS.provider
        ).cityStateZip;
        if (each.APS.providerOverride) {
          each.APS.providerOverride.providerCityStateZip =
            new ProviderCityStateZip(each.APS.providerOverride).cityStateZip;
        } else if (each.APS.provider) {
          each.APS.provider.providerCityStateZip = new ProviderCityStateZip(
            each.APS.provider
          ).cityStateZip;
        }
        selectedApplicant['applicantHomePhone'] =
          selectedApplicant['primaryApplicant']?.applicantHomePhone;
        selectedApplicant['applicantmobilePhone'] =
          selectedApplicant['primaryApplicant']?.applicantHomePhone;
        selectedApplicant['notes'] = [each.APS.uWSNotes];
        selectedApplicant['UWSNotes'] = each.APS.uWSNotes;
        selectedApplicant['provider'] = each.APS.providerOverride
          ? each.APS.providerOverride
          : each.APS.provider;
      }
      selectedApplicant.createdDate = each.createdDate;
      selectedApplicant.orderDetails = selectedApplicant?.orderDetails.map(
        (order) => {
          if (order.APS && order.APS.provider) {
            order['originalproviderFullName'] =
              order.APS?.provider.providerFullName;
          }
          order['loggedInUser'] = profile.name;
          return order;
        }
      );
      selectedApplicant['applicantZipCode'] =
        selectedApplicant['primaryApplicant']?.applicantzipCode;
      selectedApplicant['applicationTrackerId'] = each.applicationTrackerId;
      let contact = each.contactOverride
        ? each.contactOverride
        : selectedApplicant.contact;

      if (selectedApplicant.orderApplicant) {
        selectedApplicant['orderApplicant']['UWSNotes'] = each.APS?.uWSNotes;
        selectedApplicant['orderApplicant'] = {
          ...selectedApplicant.orderApplicant,
          ...contact,
        };
      }
      selectedApplicant.uwReasonTypes = [];
      if (each?.labs?.length > 0) {
        selectedApplicant.notes = each.labs
          .reduce((acc, current) => {
            acc.push(`${current.notes}`);
            return acc;
          }, []);
        selectedApplicant.uwReasonTypes = each.labs
          .reduce((acc, current) => {
            acc.push(`${current.name}`);
            return acc;
          }, []);
      }
      selectedApplicant['currentDate'] = this.datePipe.transform(
        new Date(),
        'MM/dd/yyyy'
      );
      selectedApplicant['SignatureDate'] = this.datePipe.transform(
        new Date(),
        'MM/dd/yyyy'
      );
      selectedApplicant['applicantCityStateZip'] = `${selectedApplicant.orderApplicant.applicantCity || ''
        }, ${selectedApplicant.orderApplicant.contactState || ''} - ${selectedApplicant.orderApplicant['applicantZip'] || ''
        }`;
      selectedApplicant['orderApplicant'].birthDate = this.datePipe.transform(
        new Date(selectedApplicant['orderApplicant'].birthDate),
        'MM/dd/yyyy'
      );
      selectedApplicant['id'] = each.id; //guiid
      selectedApplicant['country'] =
        selectedApplicant['primaryApplicant']?.birthCountry;
      selectedApplicant['primaryApplicant'] = {
        ...selectedApplicant['primaryApplicant'],
        ...selectedApplicant['contact'],
        UWSNotes: each.APS?.uWSNotes,
      };
      each['reminderDate'] = this.getReminderDate(
        selectedApplicant.orderTrackers,
        each
      );
      delete selectedApplicant.reminderDate;
      selectedApplicant['orderApplicant'].state = selectedApplicant.state;
      return { ...each, ...selectedApplicant };
    });
  }

  getReminderDate(orderTrackers, eachOrder) {
    let orderTracker = orderTrackers
      ? orderTrackers.find((order) => order.id === eachOrder.orderTrackerId)
      : null;
    return orderTracker !== null && orderTracker.reminderDate !== null
      ? dayjs(orderTracker.reminderDate).format('MM/DD/YYYY h:mm A')
      : null;
  }

  getUsersWorkload() {
    return this.apsApiService.getUsersWorkload();
  }

  createOrder(request) {
    return this.apsApiService.createOrder(request).toPromise();
  }

  async addFollowUpResponse(followUpForm, dueFollowUpDateTime) {
    let profile = this.store.selectSnapshot((state) => state.data.userProfile);
    const selectedApplicationDetails = await this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    let orderLogDetails = selectedApplicationDetails?.followUps?.length
      ? selectedApplicationDetails?.followUps
      : [];
    selectedApplicationDetails.createdDate = new Date().toLocaleString(
      'en-US',
      {
        timeZone: 'CST',
      }
    );

    const payload = {
      id: selectedApplicationDetails.id,
      type: 'a', //,
      notes: followUpForm.get('notes').value,
      createdDate: new Date(), // time in CST
      createdBy: selectedApplicationDetails.createdBy,
      updatedBy: profile?.name,
      state: selectedApplicationDetails?.state,
      applicationTrackerId: selectedApplicationDetails.applicationTrackerId,
      appId: selectedApplicationDetails.applicationId,
      orderTrackerId: selectedApplicationDetails.orderTrackerId,
      convertdVersion: selectedApplicationDetails.convertdVersion,
    };
    selectedApplicationDetails.dueFollowUpDateTime = dueFollowUpDateTime;
    selectedApplicationDetails.DueFollowUpDateTime = dueFollowUpDateTime;
    selectedApplicationDetails.followups = orderLogDetails;

    await this.apsApiService.addFollowUp(payload).subscribe((resp) => {
      if (resp) {
        payload.createdDate = resp.data['patchFollowUp']?.createdDate;
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(
              NotificationType.SUCCESS,
              '',
              'Followup details updated successfully'
            )
          )
        );
        orderLogDetails.push(payload);
      }
    });

    return orderLogDetails;
  }

  async requestCheck(value) {
    const selectedApplicationDetails = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    const requestCheck = {
      id: '',
      checkName: value.checkName,
      address: value.address,
      city: value.city,
      state: value.state,
      zipCode: value.zipCode,
      description: value.description,
      checkRequestDate: new Date(value.checkRequestDate).toISOString(),
      amount: Number(value.amount),
      policyNumber: selectedApplicationDetails.orderApplicant.policyNumber,
    };
    selectedApplicationDetails.DueFollowUpDateTime = new Date().toISOString();
    selectedApplicationDetails.dueFollowUpDateTime = new Date().toISOString();
    selectedApplicationDetails.checkRequest = requestCheck;
    this.store.dispatch(new UpdateOrder(selectedApplicationDetails));
  }

  async sendSecureEmail(formGroup, attachments?, dialogRef?) {
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.INFO,
          '',
          ' Email is being sent, please wait'
        )
      )
    );

    this.store.dispatch(new SetSpinner());
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    const payload = await this.constructSecureEmailPayload(
      formGroup,
      attachments
    );
    this.apsApiService.sendSecureEmailRequest(payload).subscribe(
      (res) => {
        currentOrderData.orderDetails.isFinalStep
          ? this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.SUCCESS,
                '',
                'Email Sent Successfully'
              )
            )
          )
          : this.dialogService.open(EmailSentModalComponent);

        this.store.dispatch(new ResetSpinner());
        payload.emailParameters.Attachments =
          payload.emailParameters.Attachments.map((attachment) => {
            delete attachment.Bytes;
            return attachment;
          });
        const promise = this.store
          .dispatch(
            new InsertAuditLog(
              new AuditLog(
                AuditTypeId.EMAIL_LOGS,
                null,
                JSON.stringify(payload)
              )
            )
          )
          .toPromise();
        promise.then((c) => {
          if (dialogRef && dialogRef.close) {
            console.log('closing the dialog');
            dialogRef.close(true);
          }
        });
      },
      (err) => {
        this.store.dispatch(new ResetSpinner());
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(
              NotificationType.ERROR,
              '',
              'There was an error processing the request. Please refresh and try again'
            )
          )
        );
      }
    );
  }

  async createOrderRequest(secureEmailForm?, isFinalStep?) {
    let documents = this.store.selectSnapshot(
      (state) => state.data.requestOrderPdf
    );
    let currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    let documentsData = [];

    documents.forEach((each) => {
      documentsData.push({
        name: each.name,
        content: each.url,
        attributes: each.attributes,
        eTag: each.eTag,
        documentType: each.documentType,
        createdBy: currentOrderData.createdBy,
        createdDate: each.createdDate,
        updatedDate: each.updatedDate,
        updatedBy: each.name,
      });
    });

    let component = new EmailSentModalComponent();
    currentOrderData.orderStatusId = 3;
    currentOrderData.orderDetails.orderDocuments = {
      documents: documentsData,
      appId: currentOrderData.applicationId,
      orderId: currentOrderData.orderTrackerId,
      clientNumber: currentOrderData.clientNumber,
    };
    currentOrderData.orderDetails.orderStatusId = 3;
    (currentOrderData.orderDetails.createdDate = currentOrderData.createdDate),
      (currentOrderData.orderDetails.createdBy = currentOrderData.createdBy);
    currentOrderData.orderDetails.state = currentOrderData.state;
    currentOrderData.orderDetails.controlNumber =
      currentOrderData.controlNumber;
    currentOrderData.orderDetails.isFinalStep = isFinalStep;

    if (currentOrderData && currentOrderData.requestType === 'APS') {
      currentOrderData.isEditOrder
        ? this.store.dispatch(new UpdateOrder(currentOrderData.orderDetails))
        : this.store.dispatch(
          new UpdateOrder(
            currentOrderData.orderDetails,
            OrderActionType.CREATE_ORDER
          )
        );
    } else {
      component.title = 'Fax Sent Successfully';
      const checkedDocuments = documents.filter(
        (document) => document.isChecked
      );
      secureEmailForm && isFinalStep
        ? this.sendSecureEmail(secureEmailForm, checkedDocuments)
        : '';
      currentOrderData.isEditOrder
        ? this.store.dispatch(new UpdateOrder(currentOrderData.orderDetails))
        : this.store.dispatch(
          new UpdateOrder(
            currentOrderData.orderDetails,
            OrderActionType.CREATE_ORDER
          )
        );
    }
  }

  async constructSecureEmailPayload(formGroup, attachments) {
    const orderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    let listofPromises = [];
    attachments.forEach((attachment) => {
      const payload = new DownloadDocumentsPayload(
        orderData,
        attachment.name || attachment.label
      );
      listofPromises.push(
        this.apsApiService.downloadIndividualDocuments(payload).toPromise()
      );
    });
    let responses = await Promise.all(listofPromises);
    let files = attachments.map((attachment, index) => {
      return {
        FileName: attachment.name || attachment.label,
        Bytes: responses[index].data.downloadOrderFiles?.documents[0]?.content,
        url: attachment.content,
      };
    });
    let {
      orderContactName: RecipientName,
      email: RecipientEmail,
      cc1: cc,
      cc2: secondarycc,
      template,
      agentEmailAdress,
    } = formGroup.getRawValue();

    let {
      applicationId,
      createdBy: requestedby,
      company: CompanyName,
      orderTrackerId,
    } = orderData;

    const policynumber =
      orderData.policyNumber || orderData.orderDetails.policyNumber;

    let ccValue = '';
    if (cc) {
      ccValue = cc;
    }
    if (secondarycc) {
      ccValue = ccValue ? ccValue + ";" + secondarycc : secondarycc;
    };

    ccValue = ccValue.length ? ccValue.concat(';', `${agentEmailAdress};${this.SHADOW_COPY_EMAIL}`) : `${agentEmailAdress};${this.SHADOW_COPY_EMAIL}`;

    const payload: labRequestModel = {
      template,
      orderTrackerId,
      applicationId: Number(applicationId),
      requestedByUser: requestedby || '',
      emailParameters: {
        CCEmail: ccValue,
        PolicyNumber: policynumber || '000',
        RecipientName,
        RecipientEmail,
        CompanyName,
        Attachments: files.filter((file) => file.Bytes),
      },
    };
    return payload;
  }

  listOfAssignedApplicationsOfFollowupQueue(val) {
    console.log('aps service', val);
    this.apsApiService
      .getListOfAssignedApplicationsForFollowupQueue(val)
      .subscribe(({ data }: QueryResponse) => {
        this.store.dispatch(new AssignedApplications(data.applications));
      });
  }

  paginationQuery(pageDirection, pageCursor) {
    if (
      pageCursor &&
      (pageCursor.includes('before:') || pageCursor.includes('after:'))
    ) {
      pageCursor = pageCursor
        .split('before:')
        .join('')
        .split('after:')
        .join('')
        .split('"')
        .join('');
    }

    return pageDirection === 'BEFORE'
      ? `before:"${pageCursor ? pageCursor : null}"`
      : `after:"${pageCursor ? pageCursor : null}"`;
  }

  async downloadDocument(pdfTitle, orderDetails?) {
    let orderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    orderData = orderData ? orderData : orderDetails;
    const payload = new DownloadDocumentsPayload(orderData, pdfTitle);
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.SUCCESS,
          '',
          'File is being downloaded'
        )
      )
    );

    const { data } = await this.apsApiService
      .downloadIndividualDocuments(payload)
      .toPromise();
    const byteStream = data.downloadOrderFiles?.documents[0]?.content;
    const blob = await this.fileService.convertBinaryDatatoBlob(byteStream);
    this.downloadDocumentToDesktop(blob, pdfTitle);
  }

  private downloadDocumentToDesktop(blob, pdfTitle) {
    const link = document.createElement('a');
    link.href = blob;
    link.download = pdfTitle;
    document.body.append(link);
    link.click();
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.SUCCESS,
          '',
          'File Downloaded Sucessfully'
        )
      )
    );

    link.remove();
  }

  redirectToBase() {
    const selectedApplicationDetails = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    if (
      currentOrderData.config &&
      currentOrderData.config.isFromApplicationDetailPage
    ) {
      this.router.navigate([RedirectUrl.APPLICATION_DETAILS], {
        queryParams: {
          applicationTrackerId: currentOrderData.config.applicationTrackerId,
        },
      });
    } else {
      this.router.navigate([
        selectedApplicationDetails.redirectUrl
          ? selectedApplicationDetails.redirectUrl
          : currentOrderData?.redirectUrl,
      ]);
    }
  }

  loggedInUserFromApsUsersList() {
    const apsUsers = this.store.selectSnapshot((state) => state.data.apsUsers);
    const profile = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    return apsUsers.find((user) => {
      return (
        user.domainUserName.replace(/\s+/g, '') ===
        profile?.domainUserName.replace(/\s+/g, '')
      );
    });
  }

  gerOrderDetailsByOrderTrackerId(data, orderTrackerId) {
    return data.orderDetails.filter
      ? {
        ...data.orderDetails.filter(
          (order) => order.orderTrackerId === orderTrackerId
        )[0],
      }
      : data.orderDetails;
  }

  sortListByKey(list, key) {
    return list.sort((a, b) => (a[key] > b[key] ? 1 : -1));
  }

  setApsUsersRolesListFromApsUsersList(users, usersWorkload) {
    return Object.values(
      [
        ...new Set(
          users.map((user) => {
            user.apsMemberRoles = [];
            user.domainUserName = UserProfile.getDomainUserName(
              user.userPrincipalName
            );
            users.forEach((element) => {
              if (element.displayName === user.displayName) {
                const groupLabel = environment.config.azure_roles.find(
                  (r) => r.role === element.groupName
                )?.name;
                groupLabel
                  ? user.apsMemberRoles.push(groupLabel).toString()
                  : '';
              }
            });
            const noSlashUser = user.domainUserName.replace(/\\/g, '');
            user.workload = usersWorkload[noSlashUser]
              ? usersWorkload[noSlashUser]
              : { new: 0, pending: 0 };
            user.value = user.jobTitle;
            user.name = user.jobTitle;
            user.intial = this.utilityService.createIntials(user.displayName);
            return user;
          })
        ),
      ].reduce((acc, cur) => {
        return Object.assign(acc, { [cur['jobTitle']]: cur['groupName'] });
      }, {})
    );
  }

  getObjectFromList(list, key) {
    return list.find((application: Application) => {
      return application.applicationId === key;
    });
  }

  setQueryAndPageNo(query, pageNo) { }

  clearQueryAndPageNoFromStore() { }

  updateOrderStatusFlag(payload: ProcessStatusPayload) {
    return this.apsApiService.updateOrderStatusFlag(payload);
  }

  getApplicationDetailsByApplicationId(applicationId: number) {
    return this.store.dispatch(
      new SelectedApplicationDetailsByApplicationId(applicationId, null)
    );
  }

  getApplicationDetailsByApplicationTrackerId(applicationTrackerId: number) {
    return this.store.dispatch(
      new SelectedApplicationDetails(applicationTrackerId, null)
    );
  }

  getApplicationStatusDetailsByApplicationTrackerId(applicationTrackerId: number) {
    return this.store.dispatch(
      new SelectedApplicationStatusDetails(applicationTrackerId, null)
    );
  }

  getSelectedApplicationFromStore() {
    return this.store
      .select((state) => state.data.selectedApplicationDetails);
  }

  async mapPoliciesBrcodes(applicationId: number) {
  await  this.store.dispatch(new PoliciesWithBRCode(applicationId)).toPromise();
  }

  getPoliciesWithBRCode() {
    return this.store.select((state) => state.data.policiesWithBRCode);
  }

  getCurrentOrderData() {
    return this.store.select((state) => state.data.currentOrderData)
  }
  checkPolicyInpoliciesWithBRCodeArray(selectedPolicyNumber, clientNumber, policiesWithBRCodeArray) {
    const trimmedclientNumber = parseInt(clientNumber.replace(/^0+/, ''));//added to address leading 00 infront of client number
    return policiesWithBRCodeArray && policiesWithBRCodeArray.find((item) => item.policyNumber === selectedPolicyNumber && item.clientNo === trimmedclientNumber);
  }

  // to make Selected policy number to put at top;
  //in orderData policies are listed alphabetical order
  reArrangeThePolicyNumber(policyArray, key, selectedPolicyNumber) {
    const rearragedPolicies = [...policyArray]; // Create a copy of the original array
    const index = rearragedPolicies.findIndex(obj => obj[key] == selectedPolicyNumber);
    if (index !== -1) {
      rearragedPolicies.splice(index, 1);
      rearragedPolicies.unshift({
        label: selectedPolicyNumber,
        value: selectedPolicyNumber,
      }
      );
    }
    return rearragedPolicies;
  }

  saveAuthorizationFile(providerDetails, documentDetails) {
    const payload = {
      city: providerDetails.city,
      distance: 0.44,
      firstName: providerDetails.doctorFirstName,
      lastName: providerDetails.doctorLastName,
      state: providerDetails.state ? providerDetails.state.VALUE : '',
      zipCode: providerDetails.zipCode,
      documents: documentDetails,
      fullname: providerDetails.doctorFirstName + " " + providerDetails.doctorLastName,
      facilityName: providerDetails.facilityName
    }
    return this.apsApiService.saveAuthorizationFile(payload)
  }
  
  searchAuthorizationFiles(searchFormGroup) {
    const payload = {
      doctorName: searchFormGroup.value.doctorName,
      facilityName: searchFormGroup.value.facilityName,
      state: searchFormGroup.value.state ? searchFormGroup.value.state.VALUE : '',
      city: searchFormGroup.value.city,
      zipCode: searchFormGroup.value.zipCode
    }
    return this.apsApiService.searchAuthorizationFile(payload)
  }

  downloadProviderDocument(authform) {
    const payload = {
      id: authform.id,
      documents: {
        eTag: authform.documents[0].eTag,
        name: authform.documents[0].name,
        documentType: authform.documents[0].documentType,
        content: authform.documents[0].content,
        contentLength: authform.documents[0].contentLength,
        attributes: authform.documents[0].attributes,
        globalDocId: authform.documents[0].globalDocId,
        createdBy: authform.documents[0].createdBy,
        createdDate: authform.documents[0].createdDate,
        updatedBy: authform.documents[0].updatedBy,
        updatedDate: authform.documents[0].updatedDate,
        isActive: authform.documents[0].isActive
      }
    }
    this.apsApiService.downloadProviderDocument(payload).subscribe(
      async (res) => {
        const { pdfTitle, byteStream } = res;
        const blob = await this.fileService.convertBinaryDatatoBlob(byteStream);
        this.downloadDocumentToDesktop(blob, pdfTitle);
      }
    )
  }

  async previewDocument(authform) {
    const payload = {
      id: authform.id,
      documents: {
        eTag: authform.documents[0].eTag,
        name: authform.documents[0].name,
        documentType: authform.documents[0].documentType,
        content: authform.documents[0].content,
        contentLength: authform.documents[0].contentLength,
        attributes: authform.documents[0].attributes,
        globalDocId: authform.documents[0].globalDocId,
        createdBy: authform.documents[0].createdBy,
        createdDate: authform.documents[0].createdDate,
        updatedBy: authform.documents[0].updatedBy,
        updatedDate: authform.documents[0].updatedDate,
        isActive: authform.documents[0].isActive
      }
    }
    const {byteStream} = await this.apsApiService.downloadProviderDocument(payload).toPromise(
    )
    const url=this.fileService.convertByteStreamIntoFile(byteStream)
    return url;
  }

}
