export class UserProfile {
  constructor(
    name?: string,
    roles?: any,
    uniqueName?: string,
    username?: string
  ) {
    this.name = name;
    this.roles = roles;
    this.uniqueName = uniqueName;
    this.userName = username;
    const names = this.name && this.name.replace(/\s/g, '').split(',');
    this.firstName = names && names.length > 1 ? names[1].trim() : ' ';
    this.lastName = names && names.length > 0 ? names[0].trim() : ' ';
    this.domainUserName = UserProfile.getDomainUserName(this.userName);
    this.initial = this.firstName[0] + this.lastName[0];
  }

  name: string;
  roles: string[];
  uniqueName: string;
  userName: string;
  firstName: string;
  lastName: string;
  initial: string;
  domainUserName: string;
  timeZone?: string;

  static getDomainUserName(email: string, singleSlash?: boolean): string {
    const splitUser = email?.split('@');
    let domainName = '';
    if (splitUser && splitUser[1]) {

      if (!singleSlash) {
        switch (splitUser[1]) {
          case 'ushealthgroup.net':
          case 'ushealthgroup.com':
            domainName = `HQ\\\\${splitUser[0]}`;
            break;
          case 'optum.com':
            domainName = `MS\\\\${splitUser[0]}`;
        }

      } else {
        switch (splitUser[1]) {
          case 'ushealthgroup.net':
          case 'ushealthgroup.com':
            domainName = `HQ\\${splitUser[0]}`;
            break;
          case 'optum.com':
            domainName = `MS\\${splitUser[0]}`;
        }
      }
    }

    return domainName;
  }
}