<form [formGroup]='apsDemographicFormGroup' *ngIf="communicationPaymentPreferences">
    <div class="container">
        <div class="grid justify-content-center mb-3">
            <div class="col-12 grid justify-content-center" *ngIf="orderData.isEditOrder && isFormDirty">
                <ushg-message [severity]="'warn'"
                    [text]="'The edited information will reflect in the PDF documents once you navigate to the next page by clicking the `Next Button`'">

                </ushg-message>
            </div>
            <div class="col-10 shadow-3">
                <ushg-demographic-details [config]='applicationDetailsconfig' [detialsConfig]="demographicDetailsConfig"
                    (isFormInEditMode)="isFormInEditMode($event, 'applicantionDetailsForm')"
                    (saveForm)="saveApplicationDetails($event)" [details]='orderData.orderApplicant' class="mt-3">
                    <div role="data">
                        <div class="bg-gray-200 border-round">
                            <div class="grid mt-2">
                                <div class="col-12 p-3">
                                    <h3 class="m-0 p-0 ml-3 mb-3">
                                        UWS Notes
                                    </h3>
                                    <p class="ml-3">
                                        {{
                                        orderData.orderApplicant.UWSNotes
                                        }}
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </ushg-demographic-details>
            </div>
        </div>
        <div class="grid justify-content-center">
            <div class="col-4 shadow-3">
                <ushg-demographic-details [config]='doctorDetailsconfig' class="m-3" *ngIf="currentOrderData.provider"
                    [details]='currentOrderData.APS.providerOverride?currentOrderData.APS.providerOverride:currentOrderData.APS.provider' (saveForm)="saveProviderDetails($event)"
                    [detialsConfig]="doctorDetailsKey"
                    (isFormInEditMode)="isFormInEditMode($event, 'doctorDetailsForm')" #doctorDemographicDetails>
                </ushg-demographic-details>
            </div>
            <div class="col-6 shadow-3">
                <ushg-demographic-details [config]='communicationPaymentPreferencesConfig' class="m-3"
                    [details]='communicationPaymentPreferences' [detialsConfig]="communicationPaymentPreferencesKeys"
                    (isFormInEditMode)="isFormInEditMode($event, 'paymentPreferencesForm')"
                    (saveForm)="saveCommunicationAndPreferences($event)">
                
                    <div *ngIf=" !isNextButtonDisabled" role='data'>
                        <span>
                            <span class="label justify-content-end  flex text-xs text-500 pt-3 m-0 pl-3 mt-5"
                                *ngIf="communicationPaymentPreferences?.lastModifiedBy">Updated by
                                <span> &nbsp; {{communicationPaymentPreferences?.lastModifiedBy}},<span
                                        class="label  text-xs text-500 "
                                        *ngIf="communicationPaymentPreferences?.lastModifiedDate">Updated on
                                        <span ushgDate> {{communicationPaymentPreferences?.lastModifiedDate}}</span>
                                    </span>
                                </span>
                            </span>

                        </span>
                    </div>
                </ushg-demographic-details>
            </div>
            <div class="col-10 shadow-3  container mx-auto p-3 pb-2 pr-2">
                <h2 class="text-bold mb-4 mt-2">
                    {{healthInformationForAuthorizationLetterConfig.label}}
                </h2>
                <div *ngFor="let formControl of healthInformationForAuthorizationLetterKeys">
                    <div class="grid p-3 pb-1 pt-1 align-items-center">
                        <div class="col-3">

                            <p class="label">{{formControl.label}}<span class="required"> *</span></p>
                        </div>
                        <div class="col-8">
                            <ushg-form [formControlComponent]="formControl" class="mt-2"></ushg-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="grid">
            <div class="col-offset-1 col-10">
                <p class="font-bold text-lg mb-2">Agent Portal Notes <span class="required">*</span></p>
                <textarea class="col-12 mt-1 form-control p-inputtextarea p-inputtext p-component p-element" rows="5"
                    [(ngModel)]="OrderDetails.agentNotes" (blur)="getAgentNotes($event)" required
                    [ngModelOptions]="{standalone: true}"></textarea>
                    <p class="invalid-formcontrol m-1" *ngIf="!OrderDetails.agentNotes.length">
                        This Field is required
                    </p>        
            </div>
        </div>
    </div>
    <ushg-navigation-buttons (nextButtonClick)="demographicnextButtonEventHandler($event)"
        (backButtonClick)="apsDemographicBackButtonEventHandler()"
        [isNextButtonDisabled]='isNextButtonDisabled || !OrderDetails.agentNotes || !getFormValidity()'>
    </ushg-navigation-buttons>
</form>