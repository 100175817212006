import { Injectable, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { formConfig } from "../models/formconfig-model";

@Injectable({
    providedIn: 'root'
})

export class UshgFormService {
    formGroup: UntypedFormGroup
    constructor(private formBuilder: UntypedFormBuilder) { }

    createForm(formConfig, targetObject?) {
        if(formConfig){
            let config = this.createConfig(formConfig, targetObject)
            this.formGroup = this.formBuilder.group(config)
        }
        return this.formGroup
    
    }

    private createConfig(formConfig: [], targetObject = {}) {
        let config: formConfig = {}
        formConfig.forEach((eachConfig: formConfig) => {
            config[eachConfig.formControlName] = [Object.keys(targetObject).length > 0 ? { value: targetObject[eachConfig?.value], disabled: eachConfig.disable } : { value: '', disabled: eachConfig.disable }, this.validators(eachConfig)]
        })
        return config
    }

    private validators(config) {
        let validators = []
        if (config.validators) {
            config.validators.forEach(validator => {
                validators.push(validator)
            });
        }
        return validators.length ? validators : undefined
    }


}