import { ProfileModel } from '../models/application-model';
import { UserProfile } from '../models/user-profile';

export const applicants = [
  {
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs ,8 mins',
    createdDate: '1636966977000',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'APS', status: 'pending' },
      { label: 'EBU-AE,', status: 'closeout' },
      { label: 'EBS-AE', status: 'new' },
    ],
    status: 'With APS Team',
    followUp: false,
    uwReason: 'RX profile/Build',
    orderId: 'N/A',
    assignedTo: 'unassigned',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    applicationStatusId: 1,
    apsorderTrackers: [
      { orderStatusId: 0, requestType: 'APS' },
      { orderStatusId: 1, requestType: 'EBU-AE' },
    ],
  },
  {
    applicationId: 600014895,
    primaryName: 'long Martin',
    primaryContactName: 'long Martin',
    timeInQueue: ' 3hrs ,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    followUp: true,

    policyNo: '52Z203669B',
    agentName: 'Jojn john',
    product: 'Secure Advantage',
    requestType: [{ label: 'APS,EBU-AE', status: 'new' }],
    uwReason: 'Auth',
    orderId: 'N/A',
    status: 'With UWS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 2,
    apsorderTrackers: [{ orderStatusId: 3, requestType: 'APS' }],
  },
  {
    applicationId: 60001469,
    primaryName: 'Dwarf Michael',
    primaryContactName: 'Dwarf Michael',
    timeInQueue: '1 hrs ,8 mins',
    followUp: true,
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Maggie Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'APS', status: 'pending' }],
    uwReason: 'RX profile',
    orderId: 'N/A',
    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 1,
    apsorderTrackers: [
      { orderStatusId: 2, requestType: 'APS' },
      { orderStatusId: 1, requestType: 'EBU-AE' },
    ],
  },
  {
    applicationId: 6000146785,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs ,8 mins',
    createdDate: '1636966977000',
    followUp: false,

    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Maggie A',
    product: 'Secure Advantage',
    requestType: [
      { label: 'APS', status: 'pending' },
      { label: 'EBA', status: 'pending' },
    ],
    uwReason: 'RX profile',
    orderId: 'N/A',
    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 3,
    apsorderTrackers: [{ orderStatusId: 3, requestType: 'APS' }],
  },
  {
    applicationId: 6000146745,
    primaryName: 'H Martin',
    primaryContactName: 'H Martin',
    timeInQueue: '1 hrs ,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'APS', status: 'closeout' }],
    uwReason: 'Bank auth',
    orderId: 'N/A',
    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 2,
    apsorderTrackers: [
      { orderStatusId: 2, requestType: 'APS' },
      { orderStatusId: 1, requestType: 'EBU-AE' },
    ],
  },
  {
    applicationId: 6000146711,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'APS', status: 'pending' },
      { label: 'EBU-AE,', status: 'closeout' },
      { label: 'EBS-AE', status: 'new' },
    ],

    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 1,
    apsorderTrackers: [{ orderStatusId: 0, requestType: 'APS' }],
  },
  {
    applicationId: 6000146714,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Alect Helleing',
    requestType: [{ label: 'APS', status: 'pending' }],

    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 2,
    apsorderTrackers: [{ orderStatusId: 0, requestType: 'APS' }],
  },
  {
    applicationId: 6000146711,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'APS', status: 'pending' },
      { label: 'EBA', status: 'pending' },
    ],

    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 1,
    apsorderTrackers: [{ orderStatusId: 1, requestType: 'APS' }],
  },
  {
    aaa: '',
    applicationId: 6000146713,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'APS', status: 'new' }],

    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 1,
    apsorderTrackers: [{ orderStatusId: 3, requestType: 'APS' }],
  },
  {
    aaa: '',
    applicationId: 6000146717,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'APS', status: 'pending' }],

    status: 'With APS Team',
    assignedTo: 'unassigned',
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
  },
  {
    aaa: '',
    applicationId: 6000146777,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'APS', status: 'pending' }],

    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 2,
    apsorderTrackers: [{ orderStatusId: 3, requestType: 'APS' }],
  },
  {
    aaa: '',
    applicationId: 6000146722,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'APS,EBU-AE', status: 'pending' }],
    status: 'With APS Team',
    assignedTo: 'unassigned',
    applicationStatusId: 2,
    apsorderTrackers: [{ orderStatusId: 0, requestType: 'APS' }],
  },
];

export const requestedCheckDetails = {
  requestedCheck: true,
};

export const selectedApplicants = [
  {
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',

    timeInQueue: '1 hrs ,8 mins',
    vcallDate: '1636966977000',
    AgentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'APS', status: 'pending' },
      { label: 'EBU-AE,', status: 'closeout' },
      { label: 'EBS-AE', status: 'new' },
    ],

    status: 'With APS Team',
    uwReason: 'RX profile/Build',
    orderId: 'N/A',
    assignedTo: 'unassigned',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
  },
  {
    appId: 600014885,
    primaryName: 'long Martin',
    timeInQueue: ' 3hrs ,8 mins',
    primaryContactName: 'long Martin',

    vcallDate: '1636966977000',
    underWriter: 'Ford Kelly',
    policyNo: '52Z203669B',
    AgentName: 'Jojn john',
    product: 'Secure Advantage',
    requestType: [
      { orderStatusId: 0, requestType: 'APS' },
      { orderStatusId: 1, requestType: 'EBU-AE' },
    ],
    uwReason: 'Auth',
    orderId: 'N/A',
    status: 'With UWS Team',
    assignedTo: 'unassigned',
  },
];
export const orderQueueApplicants = [
  {
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'APS', status: 'pending' }],
    apsorderTrackers: [
      { orderStatusId: 2, requestType: 'APS' },
      { orderStatusId: 0, requestType: 'EBU-AE' },
    ],
    applicationStatusId: 2,
    status: 'With APS Team',
    assignedTo: 'JOHN DOE',
  },
  {
    aaa: '',
    applicationId: '60001467',
    primaryName: 'H Martin',
    primaryContactName: 'H Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '636966977000',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'EBU-AE', status: 'completed' }],
    apsorderTrackers: [
      { orderStatusId: 1, requestType: 'APS' },
      { orderStatusId: 3, requestType: 'EBU-OE' },
    ],
    applicationStatusId: 1,
    status: 'With APS Team',
    assignedTo: 'SAMUEL D',
  },
  {
    applicationId: 60001469,
    primaryName: 'Dwarf Michael',
    primaryContactName: 'Dwarf Michael',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977000',
    agentName: 'Maggie Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'EBU-AE', status: 'pending' },
      { label: 'APS', status: 'new' },
    ],
    apsorderTrackers: [{ orderStatusId: 1, requestType: 'EBU-AE' }],
    applicationStatusId: 3,
    status: 'With APS Team',
    assignedTo: 'JOHN DOE',
  },
  {
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '1636966977',
    agentName: 'Maggie A',
    product: 'Secure Advantage',
    requestType: [
      { label: 'EBU-AE', status: 'new' },
      { label: 'APS', status: 'completed' },
    ],
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
    applicationStatusId: 2,
    status: 'With APS Team',
    assignedTo: 'JOHN DOE',
  },
  {
    aaa: '',
    applicationId: '60001467',
    primaryName: 'H Martin',
    primaryContactName: 'H Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '636966977000',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'EBU-AE', status: 'completed' }],
    apsorderTrackers: [{ orderStatusId: 3, requestType: 'APS' }],
    applicationStatusId: 1,
    status: 'With APS Team',
    assignedTo: 'JOHN DOE',
  },
  {
    aaa: '',
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '16369669770001',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'EBU-AE', status: 'closeout' },
      { label: 'APS', status: 'completed' },
    ],
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
    applicationStatusId: 3,
    status: 'With APS Team',
    assignedTo: 'SAMEUL D',
  },
  {
    aaa: '',
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '01636966977000',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'EBU-AE', status: 'completed' }],
    apsorderTrackers: [{ orderStatusId: 0, requestType: 'APS' }],
    applicationStatusId: 1,
    status: 'With APS Team',
    assignedTo: 'JOHN DOE',
  },
  {
    aaa: '',
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '07/31/2021',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'EBU-AE', status: 'pending' },
      { label: 'APS', status: 'closeout' },
    ],
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
    applicationStatusId: 1,
    status: 'With APS Team',
    assignedTo: 'SAM',
  },
  {
    aaa: '',
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '07/31/2021',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'EBU-OE', status: 'completed' }],
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
    applicationStatusId: 1,
    status: 'With APS Team',
    assignedTo: 'unassigned',
  },
  {
    aaa: '',
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '07/31/2021',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'EBU-AE', status: 'pending' },
      { label: 'APS', status: 'completed' },
    ],
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
    applicationStatusId: 1,
    status: 'With APS Team',
    assignedTo: 'unassigned',
  },
  {
    aaa: '',
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '07/31/2021',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [{ label: 'EBU-AE', status: 'completed' }],
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
    applicationStatusId: 1,
    status: 'With APS Team',
    assignedTo: 'unassigned',
  },
  {
    aaa: '',
    applicationId: 60001467,
    primaryName: 'Short Martin',
    primaryContactName: 'Short Martin',
    timeInQueue: '1 hrs,8 mins',
    createdDate: '07/31/2021',
    agentName: 'Alect Helleing',
    product: 'Secure Advantage',
    requestType: [
      { label: 'EBU-AE', status: 'pending' },
      { label: 'APS', status: 'completed' },
    ],
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
    applicationStatusId: 1,
    status: 'With APS Team',
    assignedTo: 'unassigned',
  },
];

export const userProfile = {
  appointmentId: 3372,
  appId: 4004721,
  scheduledDate: new Date(),
  firstName: 'Allocco, Mike',
  lastName: 'TROY',
  username: 'HQ\\Madhav',
  callType: 'Standard',
  message: 'Successful',
  statusId: 0,
  statusName: 'TODO',
  intial: 'MB',
};

export const apsUsers = [
  {
    userName: 'Sarah',
    intial: 'S',
    newCount: 5,
    pendingCount: 23,
    role: 'APS Team Order Only',
  },
  {
    userName: 'Madhav',
    intial: 'MB',
    newCount: 2,
    pendingCount: 0,
    role: 'APS Team Member ',
  },
  {
    userName: 'John Doe',
    intial: 'JD',
    newCount: 5,
    pendingCount: 53,
    role: 'APS Team Member ',
  },
  {
    userName: 'Jay Shetty',
    intial: 'JS',
    newCount: 5,
    pendingCount: 23,
    role: 'APS Team Order Only',
  },
];

export const requestLettersData = {
  companyName: 'FLICA',
  reason: 'Rx Profile/Build',
  requests: [
    {
      requestType: 'Exam/Blood/Urine',
      checked: true,
      notes:
        'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
    },
    {
      requestType: 'Urinatory',
      checked: true,
      notes:
        'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
    },
    {
      requestType: 'Spine',
      checked: true,
      notes:
        'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
    },
    {
      requestType: 'Other',
      checked: false,
      notes:
        'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
    },
  ],
};
export const OrdersList = [
  {
    applicationId: 6858454,
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'LAB-AE' }],
    agentName: 'John Doe',
    uwReason: 'RX Profile',
    orderId: 'N/A',
    product: 'Secure Advantage',
    documents: 'N/A',
    orderType: 'LAB-AE',
    companyName: 'USHG',
    primaryContactName: 'SMITH',
    orderStatusId: 2,
    requests: [
      {
        requestType: 'Exam/Blood/Urine',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Urinatory',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Spine',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Other',
        checked: false,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
    ],
  },
  {
    applicationId: 6858454,
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'APS' }],
    agentName: 'SMITH',
    uwReason: 'RX Profile',
    orderId: 'N/A',
    product: 'Secure Advantage',
    documents: 'N/A',
    orderType: 'APS',
    companyName: 'USHG',
    primaryContactName: 'SMITH',

    orderStatusId: 2,
    requests: [
      {
        requestType: 'Exam/Blood/Urine',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Urinatory',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Spine',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Other',
        checked: false,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
    ],
  },
  {
    applicationId: 68584585,
    apsorderTrackers: [{ orderStatusId: 2, requestType: 'LAB-OE' }],
    agentName: 'RUTH',
    uwReason: 'RX Profile',
    orderId: 'N/A',
    product: 'Secure Advantage',
    documents: 'N/A',
    orderType: 'LAB-OE',
    companyName: 'USHG',
    primaryContactName: 'SMITH',

    orderStatusId: 2,
    requests: [
      {
        requestType: 'Exam/Blood/Urine',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Urinatory',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Spine',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Other',
        checked: false,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        applicationId: 6858454,
        apsorderTrackers: [{ orderStatusId: 3, requestType: 'APS' }],
        agentName: 'John Doe',
        uwReason: 'RX Profile',
        orderId: 'N/A',
        product: 'Secure Advantage',
        documents: 'N/A',
        orderType: 'APS',
        companyName: 'USHG',
        primaryContactName: 'SMITH',

        orderStatusId: 3,
        requests: [
          {
            requestType: 'Exam/Blood/Urine',
            checked: true,
            notes:
              'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
          },
          {
            requestType: 'Urinatory',
            checked: true,
            notes:
              'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
          },
          {
            requestType: 'Spine',
            checked: true,
            notes:
              'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
          },
          {
            requestType: 'Other',
            checked: false,
            notes:
              'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
          },
        ],
      },
    ],
  },
  {
    applicationId: 68584585,
    apsorderTrackers: [{ orderStatusId: 3, requestType: 'APS' }],
    agentName: 'RUTH',
    uwReason: 'RX Profile',
    orderId: 'N/A',
    product: 'Secure Advantage',
    documents: 'N/A',
    orderType: 'LAB-OE',
    companyName: 'USHG',
    primaryContactName: 'SMITH',
    address: 'OAK STRT,ADELL',
    DoctorName: 'Dr.Jones',
    street: '345 OAK ST',
    mobile: '-',
    lastName: 'Martin',
    city: 'ADELL',
    work: '-',
    gender: 'Male',
    state: 'WI',
    phone: '(205) 665 7896',
    zip: 53301,
    fax: '(205) 665 7896',
    date: '5/5/2021',
    orderStatusId: 3,
    requests: [
      {
        requestType: 'Exam/Blood/Urine',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Urinatory',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Spine',
        checked: true,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        requestType: 'Other',
        checked: false,
        notes:
          'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
      },
      {
        applicationId: 6858454,
        apsorderTrackers: [{ orderStatusId: 3, requestType: 'APS' }],
        agentName: 'John Doe',
        uwReason: 'RX Profile',
        orderId: 'N/A',
        product: 'Secure Advantage',
        documents: 'N/A',
        orderType: 'APS',
        companyName: 'USHG',
        orderStatusId: 3,
        requests: [
          {
            requestType: 'Exam/Blood/Urine',
            checked: true,
            notes:
              'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
          },
          {
            requestType: 'Urinatory',
            checked: true,
            notes:
              'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
          },
          {
            requestType: 'Spine',
            checked: true,
            notes:
              'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
          },
          {
            requestType: 'Other',
            checked: false,
            notes:
              'we will need a current comprehensive Metablic pane,Lipid Panel,GGT (Gamma Glutamyl Transferase),CDT (Carbohydrate Deficent Transferrin), HIV TEST,Height and Weight measurements,3 Blood',
          },
        ],
      },
    ],
  },
];

// export const doctorDetails = {
//   DoctorName: "Dr.Jones",
//   street: '345 OAK ST',
//   mobile: '-',
//   lastName: 'Martin',
//   city: 'ADELL',
//   work: '-',
//   gender: 'Male',
//   state: 'WI',
//   phone: "(205) 665 7896",
//   zip: 53301,
//   fax: "(205) 665 7896",
//   lastModifiedBY:"JOHNSON",
//   lastModifiedOn:"21/3/2021",
// }

// export const communicationPaymentPreferences = {
//   preferredCommunicationMode: 'FAX',
//   acceptsElectronicSignature: 'YES',
//   acceptsCreditCard: 'YES',
//   turnAroundTime: '7 Hours',
//   specialRequestLetter: 'Required',
//   lastModifiedBY:"JOHNSON",
//   lastModifiedOn:"21/3/2021",
// }

export function testProfile(profile: UserProfile) {
  let dummyProfileObj = {};
  dummyProfileObj['givenName'] = profile.initial;
  dummyProfileObj['intial'] = profile.initial;
  dummyProfileObj['displayName'] = profile.name;
  dummyProfileObj['jobTitle'] = 'Developer';
  dummyProfileObj['__typename'] = 'user';
  dummyProfileObj['name'] = 'Developer';
  dummyProfileObj['value'] = 'Developer';
  dummyProfileObj['domainUserName']= profile.domainUserName;
  dummyProfileObj['userPrincipalName']= profile.userName;
  return dummyProfileObj;
}
