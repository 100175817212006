import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUpload } from 'primeng/fileupload';
import { CreateAuthorizationFormLabels } from 'src/app/aps/models/enums';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { FileService } from 'src/app/shared/services/file-service';
import { AuthorizationDocument, NotificationType } from 'src/app/aps/models/ui-models';
import { ApsService } from 'src/app/aps/services/aps-service';
import { AUTH_FORM_CONSTANTS, US_STATES } from '../authorization-form-constants';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { ConfirmModalComponent } from '../../modals/confirm-modal/confirm-modal.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-authorization-form',
  templateUrl: './create-authorization-form.component.html',
  styleUrls: ['./create-authorization-form.component.scss']
})
export class CreateAuthorizationFormComponent implements OnInit {
  @ViewChild('file') file: FileUpload;
  uploadedFiles: AuthorizationDocument[] = [];
  config = {
    width: '50%',
    height: '50%',
    closable: true
  }
  createForm: FormGroup;

  get CreateAuthorizationFormLabels(): typeof CreateAuthorizationFormLabels {
    return CreateAuthorizationFormLabels;
  }

  usStates = US_STATES;
  showSpinner;
  authconstants = AUTH_FORM_CONSTANTS;
  private onCloseSubscription: Subscription;

  constructor(private fb: FormBuilder, private dialog: UshgDialogService, private fileService: FileService,
    private service: ApsService, private toaster: ToastrService) { }

  ngOnInit(): void {
    this.createForm = this.fb.group({
      doctorFirstName: [''],
      doctorLastName: [''],
      facilityName: [''],
      state: ['', Validators.required],
      city: [''],
      zipCode: [''],
    });
  }

  saveAuthorizationForm(): void {
    const providerDetails = this.createForm.value;
    const documentDetails = this.uploadedFiles;
    this.showSpinner = true;
    this.service.saveAuthorizationFile(providerDetails, documentDetails).subscribe((res) => {
      this.showSpinner = false;
      if (res['state'] !== "" && res['state'] !== undefined) {
        this.toaster.open({
          message: AUTH_FORM_CONSTANTS.SUCCESS_MESSAGE,
          type: NotificationType.SUCCESS,
          header: '',
        });
      }
      else {
        this.toaster.open({
          message: AUTH_FORM_CONSTANTS.ERROR_MESSAGE,
          type: NotificationType.ERROR,
          header: '',
        });
      }
      this.closeDialog();
      }, (error) => {
        this.showSpinner = false;
        this.toaster.open({
          message: AUTH_FORM_CONSTANTS.ERROR_MESSAGE,
          type: NotificationType.ERROR,
          header: '',
        });
        this.closeDialog();
    }
    )
  }

  cancel() {
    this.closeDialog();
  }

  closeDialog() {
    this.dialog.close();
  }

  async uploadFile(event) {
    this.uploadedFiles = [];
    for (let file of event.files) {
      let content = await this.fileService.convertFileToByteStream(file);
      this.uploadedFiles.unshift(
        new AuthorizationDocument(content, file)
      );
    }
    this.file.clear();
  }

  deleteFile(selectedDocument, index) {
    const compRef = this.dialog.open(ConfirmModalComponent, selectedDocument, 'Confirmation');
    this.onCloseSubscription = compRef.onClose.subscribe((res) => {
      if (res) {
        this.uploadedFiles.splice(index, 1);
      }
    });
    compRef.onClose.subscribe(() => {
      this.onCloseSubscription.unsubscribe();
    });
    this.file.clear();
  }

  ngOnDestroy(): void {
    if (this.onCloseSubscription) {
      this.onCloseSubscription.unsubscribe();
    }
    this.closeDialog();
  }
}
