import { versionNo } from './version/version-number';
import { AfterContentChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import {  MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AnalyticsService } from './aps/services/analytics.service';

import { environment } from 'src/environments/environment';
import { Store } from '@ngxs/store';
import { filter, takeUntil } from 'rxjs/operators';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IdleTimerService } from './shared/services/idle-timer.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterContentChecked {
  isIframe = false;
  private readonly destroying$ = new Subject<void>();
  spinner$ = this.store.select((state) => state.data.spinner);
  version = versionNo;
  subscription: any;
  showSpinner = false;
  postLoginPerformed = false;

  constructor(
    private msalBroadCastService: MsalBroadcastService,
    private analyticsService: AnalyticsService,
    private msalService: MsalService,
    private store: Store,
    private authService: MsalService,
    private cd: ChangeDetectorRef,
    private http: HttpClient,
    private idleTimerService: IdleTimerService)
  {
    this.spinner$.subscribe((res) => {
      this.showSpinner = res;
    });
  }

  ngOnInit() {
    var self = this;
    self.idleTimerService.initIdleTimer();
    clarityInjection(environment.clarity);
    // this.versionService.versionsCheck();
    self.msalBroadCastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(self.destroying$)
      )
      .subscribe(() => {
        self.postLogin();
      });

      self.msalBroadCastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        ),
        takeUntil(self.destroying$)
      )
      .subscribe((result: EventMessage) => {
        console.log(result);
        if(result.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
          console.log("Acquire Token Failure");
          self.msalService.loginRedirect();
        }
        else {
          self.postLogin();
        }
      });
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: '/',
      });
    } else {
      this.authService.logoutRedirect();
    }
  }


  async postLogin() {
    if (this.postLoginPerformed) {
      return;
    }
    const account = this.authService.instance.getAllAccounts()[0];
    this.authService.instance.setActiveAccount(account);
    this.postLoginPerformed = true;
  }
  public ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    // disconnect from broadcast service on component destroy
    this.destroying$.next(null);
    this.destroying$.complete();
  }
}

const clarityInjection = (env: string) =>
  (function (c, l, a, r, i, t, y) {
    c[a] =
      c[a] ||
      function () {
        (c[a].q = c[a].q || []).push(arguments);
      };
    t = l.createElement(r);
    t.async = 1;
    t.src = 'https://www.clarity.ms/tag/' + i;
    y = l.getElementsByTagName(r)[0];
    y.parentNode.insertBefore(t, y);
  })(window, document, 'clarity', 'script', env);
