import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { TabMenuModule } from 'primeng/tabmenu';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsStoragePluginModule, STORAGE_ENGINE } from '@ngxs/storage-plugin';
import { Actions } from './store/actions';
import { ApsModule } from './aps/aps.module';
import { ToastModule } from 'primeng/toast';
import { ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from './primeng/primeng.module';
import { CoreModule } from './core/core.module';
import {
 MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import { MsalConfigurationService } from './aps/services/user/msal-configuration.service';
import { NgxsHistoryModule } from 'ngxs-history-plugin';
import { AppComponent } from './app.component';
import { CustomErrorHandlerService } from './shared/services/custom-error-handler.service';
import { ErrorService } from './shared/services/error.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { AnalyticsService } from './aps/services/analytics.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SecondsToMinutesFilterPipe } from './shared/pipes/seconds-to-minutes-filter.pipe';
import { NgIdleModule } from '@ng-idle/core';
@NgModule({
  declarations: [AppComponent],
  imports: [
    ApsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxsModule.forRoot([Actions]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: [ 
        'data.selectedApplicationDetails',
        'data.currentOrderData',
        'data.requestOrderPdf',
        'data.currentPageSteps',
        'data.providerPreferences',
        'data.userProfile',
        'data.isFormDirty',
        'data.query',
        'data.searchFilters'
      ],
      storage: 1,
      serialize(data) {
        return JSON.stringify(data);
      },
      deserialize(data) {
        return JSON.parse(data); // by default it returns parsed data
      },
    }),
    NgxsHistoryModule.forRoot(),
    TabMenuModule,
    ReactiveFormsModule,
    PrimengModule,
    CoreModule,
    MsalModule,
    ProgressSpinnerModule,
    
    NgIdleModule.forRoot(),
  ],
  providers: [
    SecondsToMinutesFilterPipe,
    DatePipe,
    MessageService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MsalConfigurationService.MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MsalConfigurationService.MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MsalConfigurationService.MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: ErrorHandler, useClass: CustomErrorHandlerService },
    ErrorService,
    AnalyticsService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
