<div class="grid">
    <div class="col-10 p-0 mx-auto mt-5">
        <ushg-details-container [detailsToDisplay]="detailsToDisplay" [data]='orderDetails'>
        </ushg-details-container>
        <ushg-demographic-details [config]='config' [detialsConfig]="demographicConfig" [details]="applicationDetails"
            class="mt-3"></ushg-demographic-details>
        <div class="col-12">
            <h5 class="label mb-3">Agent Portal Notes</h5>
            <textarea pInputTextarea [rows]="7" [cols]="155"
                class="p-inputtextarea p-inputtext p-component p-element w-100" [(ngModel)]="agentPortalNotes"></textarea>
        </div>
        <div class="col-12 text-center mb-3">
            <ushg-button [label]="'Close'" [class]="'mr-3'" [type]="'outline'" (click)="closeHandler()"></ushg-button>
            <ushg-button [label]="'Create Order'" [type]="'primary'" (click)="orderCreatedModel()"></ushg-button>

        </div>
    </div>
</div>