<form [formGroup]="demographicFormGroup">
    <div class="grid justify-content-center">
        <div class="col-12 grid justify-content-center"  *ngIf="applicationDetails.isEditOrder && isFormDirty">
            <ushg-message [severity]="'warn'"
                [text]="'The edited information will reflect in the PDF documents once you navigate to the next page by clicking the `Next Button`'">
            </ushg-message>
        </div>
        <div>
            <ushg-demographic-details [detialsConfig]="detailsConfig" [details]="orderApplicant" class="mt-3" [dynamicClass]="'col-10'"
                (saveForm)="saveApplicationDetails($event)">
            </ushg-demographic-details>
        </div>
    </div>
</form>