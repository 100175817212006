import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ProcessStatusPayload } from 'src/app/aps/models/ui-models';
import { CurrentOrderData } from 'src/app/store/dispatchers';
import { processStatusColors } from '../constants/process-status-constant';

@Component({
  selector: 'app-ushg-order-process-status',
  templateUrl: './ushg-order-process-status.component.html',
  styleUrls: ['./ushg-order-process-status.component.scss'],
})
export class UshgOrderProcessStatusComponent implements OnInit {
  selectedColor: string;
  processStatusColors = processStatusColors;
  @Input() disabled: boolean;

  @Input() rowData;
  @Input() header;
  @Input() data;
  @Input() config;

  constructor(private store: Store, private router: Router) { }

  ngOnInit(): void {
  }
  updateTheStateColor(event, updateColor: Function, selectedOrderdata): void {
    let { domainUserName } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    selectedOrderdata['config'] = this.config;
    this.store.dispatch(new CurrentOrderData(selectedOrderdata));
    if (selectedOrderdata !== undefined) {
      const processStatusPayload: ProcessStatusPayload = {
        applicationTrackerId: selectedOrderdata['applicationTrackerId'],
        orderTrackerId: selectedOrderdata['orderTrackerId'],
        applicationId: selectedOrderdata['applicationId'],
        convertedVersion: null,
        processStatus: this.processStatusColors.filter(
          (e) => e.id == event.value
        )[0].key,
        updatedBy: domainUserName,
        updatedDate: new Date().toISOString(),
      };
      updateColor(processStatusPayload);
    }
  }
  getStatusColor(proceesName): string {
    if (proceesName === undefined) {
      proceesName = 'default';
    } else {
      this.selectedColor = this.processStatusColors.filter(
        (e) => e.key == proceesName
      )[0]?.value;
    }
    return this.selectedColor;
  }
}
