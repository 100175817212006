<p-dropdown
  [options]="processStatusColors"
  (onChange)="updateTheStateColor($event, header.onChange, rowData)"
  [ngModel]="getStatusColor(rowData?.processStatus)"
  [disabled]="disabled"
>
  <ng-template pTemplate="selectedItem">
    <div class="selected">
      <div
        appChangeBackgroundColor
        [backGroundColor]="getStatusColor(rowData?.processStatus)"
        class="circle"
      ></div>
    </div>
  </ng-template>
  <ng-template let-statusColor pTemplate="item">
    <div
      appChangeBackgroundColor
      [backGroundColor]="statusColor.id"
      class="circle"
    ></div>
  </ng-template>
</p-dropdown>
