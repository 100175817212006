<div class="grid">
  <div class="col-9">
    <ng-container *ngIf="applicationFilterData">
    <ushg-search-filter
      [applicationFilters]="applicationFilterData"
      (resetEventHandler)="resetFilter($event)"
      (searchEventHandler)="searchwithFilters($event)"
    ></ushg-search-filter>
    </ng-container>
    <div class="col-12 shadow-3 mt-3 p-0">
      <ushg-table
        [rowData]="listOfApplications 
        | paginate
        : {
            itemsPerPage: 10,
            currentPage: pageNumber,
            totalItems: listOfApplications[0]?.nodetotalCount
          }"
        [headerList]="headersList"
        [rows]="10"
        [checkbox]="true"
        [actions]="actionsList"
        (selected)="getSelectedRows($event)"
        [resetSelectedRows]="resetSelectedRows"
      >
      </ushg-table>      
      <div class="text-center pt-1 pb-2">
      <div *ngIf="listOfApplications.length === 0" class="my-3">
        <p-message severity="info" text="No records found" styleClass="p-mr-2 w-100"></p-message>
      </div>
      <div class="d-flex justify-content-between align-items-center">
        <div class="ml-3">Total : <b>{{listOfApplications[0]?.nodetotalCount}} </b></div>
        <div class="mr-3"><pagination-controls class="my-pagination" (pageChange)="paginate($event)"></pagination-controls></div>
      </div>
    </div>
      
    </div>
  </div>
  <div class="col-3">
    <div
      class="
        col-12
        shadow-3
        container
        mt-2
        overflow-y-scroll overflow-x-hidden
        max-height-80
      "
      [appPermission]="'CanAccessTeamWorkLoad'"
    >
      <ushg-aps-users-workload
        [selectedApplications]="selectedRows"
        (applicationAssigned)="applicationAssigned()"
      ></ushg-aps-users-workload>
    </div>
  </div>
</div>
