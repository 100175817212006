import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})


export class FileService {

    convertBinaryDatatoBlob(binaryData): Promise<string> {
        return new Promise((resolve, reject) => {
            var blob = new Blob([this.stringToUint8Array(binaryData)], {
                type: 'application/pdf',
            });
            let url = URL.createObjectURL(blob);
            if (url) {
                resolve(url);
            } else {
                reject("PDF Couldn't Load");
            }
        });
    }

    async convertFileToByteStream(file) {
        const bytes: any = await this.readFile(file);
        return btoa(bytes);
    }

    readFile(file) {
        return new Promise((resolve, reject) => {
            // Create file reader
            let reader = new FileReader();
            // Register event listeners
            reader.addEventListener('loadend', (e) => resolve(e.target.result));
            reader.addEventListener('error', reject);
            // Read file
            reader.readAsBinaryString(file);
        });
    }

    async getAsByteArray(file) {
        return new Uint8Array(<any>this.readFile(file)).subarray(0, 4);
    }

    getByteArray(file) {
        let fileReader = new FileReader();
        return new Promise(function (resolve, reject) {
            fileReader.readAsArrayBuffer(file);
            fileReader.onload = function (ev: any) {
                const array = new Uint8Array(ev.target.result);
                const fileByteArray = [];
                for (let i = 0; i < array.length; i++) {
                    fileByteArray.push(array[i]);
                }
                resolve(array); // successful
            };
            fileReader.onerror = reject; // call reject if error
        });
    }

    stringToUint8Array(raw) {

        raw = raw ? atob(raw) : '';
        var uint8Array = new Uint8Array(raw.length);
        for (var i = 0; i < raw.length; i++) {
            uint8Array[i] = raw.charCodeAt(i);
        }
        return uint8Array;
    }

    fileToByteArray(file) {
        return new Promise((resolve, reject) => {
            try {
                let reader = new FileReader();
                let fileByteArray = [];
                reader.readAsArrayBuffer(file);
                reader.onloadend = (evt) => {
                    if (evt.target.readyState == FileReader.DONE) {
                        let arrayBuffer: any = evt.target.result,
                            array: any = new Uint8Array(arrayBuffer);

                        array.forEach((byte) => {
                            fileByteArray.push(byte);
                        });
                    }
                    resolve(fileByteArray);
                };
            } catch (e) {
                reject(e);
            }
        });
    }

    uint8ArrayToBase64(uint8Array: Uint8Array): string {
        let binary = '';
        const length = uint8Array.byteLength;

        for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(uint8Array[i]);
        }

        return btoa(binary);
    }

    convertByteStreamIntoFile(resultByte) {
        const blob = new Blob([this.stringToUint8Array(resultByte)], {
            type: 'application/pdf',
        });
        const url = window.URL.createObjectURL(blob);
        return url;
    }

   
}