import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})

export class UtilityService {

    isEmpty(obj) {
        return Object.keys(obj).length === 0;
    }

    createIntials(name) {
        const userName = name?.split(',');
        return userName ? `${userName[1]?.charAt(1)}${userName[0].charAt(0)}` : "";
    }


    capitalizeFirstLetter(string: String) {
        return (
            string.toLowerCase().charAt(0).toUpperCase() +
            string.toLowerCase().slice(1)
        );
    }

    capitalizeSentense(string: string) {
        return string.toLowerCase()
        .split(' ')
        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
        .join(' ');
    }

    formatDate(date) {
        return [
            this.padTo2Digits(date.getMonth() + 1),
            this.padTo2Digits(date.getDate()),
            date.getFullYear(),
        ].join('/');
    }

    private padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    convertDifferenceBetweenDatesToDays(date) {
        if (date !== null && date !== undefined && date.trim() !== "") {
            var delta = Math.abs(new Date(date.trim()).valueOf() - new Date().valueOf()) / 1000;
            var days = Math.floor(delta / 86400);
            delta -= days * 86400;
            var hours = Math.floor(delta / 3600) % 24;
            delta -= hours * 3600;
            var minutes = Math.floor(delta / 60) % 60;
            delta -= minutes * 60;
            return `${days}d, ${hours}h, ${minutes}m`
        }
        else {
            return "N/A"
        }
    }

    transformFirstSpaceLastName(name: string) {
        if (!name) {
            return name;
        }
        return name.split(",").reverse().join(" ")
    }
}