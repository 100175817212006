import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import listOfTimeZonesWithUtc from '../models/timezones-utc';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'dateTime',
})

export class DateTimePipe implements PipeTransform {
  date;
  constructor(private datePipe: DatePipe) { }

  transform(value: Date, arg: any, arg2: string) {
    const loggedInTimezone = listOfTimeZonesWithUtc.find(
      (eachTimeZone) => eachTimeZone.timezone.toLowerCase() === arg?.toLowerCase());
      let str = new Date(value).toLocaleString('en-US', {
        timeZone: loggedInTimezone?.utc,
      });
    switch (arg2) {
      case 'date':
        this.date = loggedInTimezone
          ? `${dayjs(str).format('MM/DD/YYYY')}`
          : `${dayjs(str).format('MM/DD/YYYY')}`;
        break;
      case 'time':
        this.date = loggedInTimezone
          ? `${dayjs(str).format('h:mm A')} (${loggedInTimezone?.shortFormTimeZone}) `
          : `${dayjs(str).format('h:mm A')}`;
        break;
      case 'date-time':
        this.date = loggedInTimezone
          ? `${dayjs(str).format('MM/DD/YYYY h:mm A')} (${loggedInTimezone?.shortFormTimeZone}) `
          : `${dayjs(str).format('MM/DD/YYYY h:mm A')}`;
        break;
    }
    return this.date;
  }
}
