import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { DownloadDocumentsPayload } from 'src/app/aps/models/ui-models';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { ApsService } from 'src/app/aps/services/aps-service';
import { sendSecureEmail } from 'src/app/store/dispatchers';
import { formConfig } from '../models/formconfig-model';
import { UshgDialogService } from '../services/ushg-dialog-service';
import { UshgFormService } from '../services/ushg-form-service';
import { secureEmailConfig } from './send-secure-email-config';

@Component({
  selector: 'app-send-secure-email',
  templateUrl: './send-secure-email.component.html',
  styleUrls: ['./send-secure-email.component.scss']
})
export class SendSecureEmailComponent implements OnInit {
  secureEmailConfig: formConfig[] = secureEmailConfig(this);
  secureEmailForm: UntypedFormGroup
  config = {
    header: 'Secure Email Details',
    width: '55%',
    closable: true
  }
  buttonsList = [
    {
      label: 'Cancel',
      type: 'secondaryoutline',
      onClick: () => {
        this.service.cancel();
      }
    },
    {
      label: 'Send',
      type: 'primarylg',
      disable: false,
      onClick: () => {
        this.sendEmail()
      }
    },
  ];
  showSpinner
  data: any;
  selectedAttachments: any;
  constructor(
    private formService: UshgFormService,
    private dialogService: DynamicDialogConfig,
    private apsService: ApsService,
    private service: UshgDialogService,
    private apsApiService: ApsApiService,
    private dialogRef: DynamicDialogRef,
    private store: Store

  ) {
  }

  ngOnInit(): void {
    this.data = this.dialogService.data
    this.store.select((state) => state.data.spinner).subscribe((res) => {
      this.showSpinner = res
    })
    this.initForm()
    this.buttonsList[1].disable = this.secureEmailForm.invalid
  }

  initForm() {
    const email = this.data.orderApplicant.applicantEmailAddress; 
    this.data.agent.agentEmailAdress = this.data.agent.agentEmailAdress? this.data.agent.agentEmailAdress.toUpperCase() : '';
    this.secureEmailForm = this.formService.createForm(this.secureEmailConfig, { ...this.data.orderApplicant, ...this.data.agent,...this.data,  email });
    this.selectedAttachments = this.data?.orderDocuments?.documents.filter(async (eachDocument) => {
      if(eachDocument.checked){
        const payload = new DownloadDocumentsPayload(this.data, eachDocument.name)
        const { data } = await this.apsApiService.downloadIndividualDocuments(payload).toPromise()
        eachDocument.byteStream = data.downloadOrderFiles?.documents[0]?.content
      }
      return eachDocument.checked
    })
    this.checkFormValidation()
  }

  checkFormValidation() {
    this.secureEmailForm.valueChanges.subscribe((res) => {
      this.buttonsList[1].disable = this.secureEmailForm.invalid
    })
  }

  downloadFile(file) {
    this.apsService.downloadDocument(file.name)
  }

  sendEmail() {
    this.store.dispatch(new sendSecureEmail(this.secureEmailForm, this.selectedAttachments.filter(a => a.checked), this.dialogRef))
  }

  checkIfRequired(key) {
    return key?.validators?.some(element => element?.name === 'required');
  }

}
