export enum ApplicationStatus {
  Initial_State = 10,
  VCall_Scheduled = 100,
  VCall_Started = 110,
  VCall_Completed = 120,
  Recall_Created = 130,
  Recall_Completed = 140,
  With_Underwriting = 200,
  With_APS_TEAM = 300,
  APS_OrderQueue = 310,
  APS_Pending = 320,
  APS_Pending_FollowUp = 330,
  APS_Returned = 210,
  Routed_To_Issue = 400,
}

export enum Months {
  Jan = 1,
  Feb = 2,
  Mar = 3,
  Apr = 4,
  May = 5,
  Jun = 6,
  Jul = 7,
  Aug = 8,
  Sep = 9,
  Oct = 10,
  Nov = 11,
  Dec = 12
}

export enum GraphChart {
  Paperless_Color = '#5CB16F',
  Paper_Color = '#3892EB',
  Labels_Color = '#495057',
  Grid_Color = '#ebedef'
}

export enum CallDurationMetricsSectionNames {
  Introduction = 'Introduction',
  Payment = 'Payment',
  Demographic = 'Demographic',
  Medical = 'Medical',
  Medical_History = 'Medical History',
  Closing = 'Closing',
}

export enum CallType {
  Express = 'express',
  Standard = 'standard'
}

export enum RequestTypes {
  'APS' = 'APS',
  'LAB_AE' = 'LAB AE',
  'LAB_OE' = 'LAB OE',
  'LAB_AE_HTWTBP' = 'HTWTBP',
}

export enum OrderStatus {
  Requested = 0,
  Inprogress = 1,
  Created = 2,
  Pending = 3,
  CloseOut = 4,
  Completed = 5,
  Cancelled = 6,
  Withdrawal = 7,
  Reopen = 8,
}

export enum RequestType {
  LAB_AE = 'LAB_AE',
  LAB_OE = 'LAB_OE',
  APS = 'APS',
  LAB_AE_HTWTBP = 'LAB_AE_HTWTBP',
  LAB_OE_HTWTBP = 'LAB_OE_HTWTBP',
}