import { inject, INJECTOR, Injector, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, ApolloLink, DefaultOptions, InMemoryCache } from '@apollo/client/core';
import { environment } from 'src/environments/environment';
import { onError } from '@apollo/client/link/error';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { MessageService } from 'primeng/api';
import { Store } from '@ngxs/store';
import { LoadNErrors, UshgNotification } from 'src/app/store/dispatchers';
import { NotificationModel, NotificationType } from '../models/ui-models';
import { MsalService } from '@azure/msal-angular';
import { setContext } from '@apollo/client/link/context';
import { AuthService } from 'src/app/shared/services/auth.service';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}

export function createApollo(
  httpLink: HttpLink,
  toastr: ToastrService,
  store: Store,
  msalService: MsalService,
  authService: AuthService
): ApolloClientOptions<any> {
  const authLink = setContext(async (_, { headers }) => {
    const account = msalService.instance.getActiveAccount();
    if (!account) {
      throw new Error('No active account! Verify a user has been signed in.');
    }
    const accessToken = await authService.getAccessToken();

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${accessToken}`
      }
    };
  });
  
  const http = httpLink.create({ uri: environment.serverUrls.aps });
  const error = onError(({ networkError, operation, graphQLErrors, }) => {
                  store.dispatch(new UshgNotification(new NotificationModel(NotificationType.ERROR,'Error','There was an error processing the request. Please refresh and try again')))
                });
  const link1 = error.concat(http);
  const apolloLink = authLink.concat(link1);

  return {
    link: apolloLink,
    cache: new InMemoryCache(),
    defaultOptions: defaultOptions
  };
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: createApollo,
    deps: [HttpLink, ToastrService, Store, MsalService, AuthService]
  }]
})
export class GraphqlModule {

}
