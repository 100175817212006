import { DatePipe } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
} from '@angular/core';

@Directive({
  selector: '[ushgDate]',
})
export class UshgDateDirective implements AfterViewInit {
  constructor(private elRef: ElementRef, private datePipe: DatePipe) {}

  ngAfterViewInit() {
    this.elRef.nativeElement.innerHTML =
      this.elRef.nativeElement.innerHTML !== '-' ||
      this.elRef.nativeElement.innerHTML !== ''
        ? this.datePipe.transform(
            this.elRef.nativeElement.innerHTML.trim(),
            'MM/dd/yyyy'
          )
        : '-';
  }
}
