<div class="grid mt-2 ml-1">
  <div class="col-12">
    <div *ngIf="selectedApplication !== undefined">
      <ushg-selected-application-container
        [selectedApplication]="selectedApplication"
        [config]="applicationDetailConfig"
      >
      </ushg-selected-application-container>
    </div>
  </div>
</div>
