// message-handler.service.ts
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { LockApplication, ReleaseApplication, UshgNotification } from 'src/app/store/dispatchers';
import { NotificationModel, NotificationType } from '../models/ui-models';

@Injectable({
  providedIn: 'root',
})
export class MessageHandlerService {

    constructor(private store: Store) {}

  handleMessage(message: any): void {
    switch (message.action) {
      case 'SET-LOCK':
        this.dispatchLockApplicationAction(message);
        break;
      case 'RELEASE-LOCK':
        this.dispatchReleaseApplicationAction(message);
        break;
      default:
        break;
    }
  }

  private dispatchLockApplicationAction(message): void {
    this.store.dispatch(new LockApplication(message));
  }

  private dispatchReleaseApplicationAction(message): void {
    this.store.dispatch(new ReleaseApplication(message));
  }

  public handleSuccessMessage(successMessage): void {
    this.store.dispatch(new UshgNotification(new NotificationModel(NotificationType.SUCCESS, '', successMessage)))
  }

}
