import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { CreateAuthorizationFormComponent } from '../create-authorization-form/create-authorization-form.component';
import { Store } from '@ngxs/store';
import { ApsService } from 'src/app/aps/services/aps-service';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { AUTH_FORM_CONSTANTS, PROVIDER_PARAMETER, } from '../authorization-form-constants';
import { DocumentPreviewComponent } from 'src/app/shared/document-preview/document-preview.component';
@Component({
  selector: 'app-authorization-forms-list',
  templateUrl: './authorization-forms-list.component.html',
  styleUrls: ['./authorization-forms-list.component.scss']
})
export class AuthorizationFormsListComponent implements OnChanges {
  authconstants = AUTH_FORM_CONSTANTS
  @Input() authforms;
  @Input() viewAuthForms;
  @Input() pageIndex: number;
  get PROVIDER_PARAMETER(): typeof PROVIDER_PARAMETER {
    return PROVIDER_PARAMETER;
  }
  // Page size
  pageSize: number = 6;
  paginatedData: any[] = [];
  timeZone: string = this.store.selectSnapshot(
    (state) => state.data.userProfile
  ).timeZone;

  authorizationForms;
  constructor(private dialogService: UshgDialogService, private store: Store, private service: ApsService, private apsApiService: ApsApiService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.authorizationForms = this.authforms && this.authforms.filter(authform => {
      return authform.documents != null && Array.isArray(authform.documents) && authform.documents.length > 0;
    }
    )
    this.paginateData();
  }

  openAuthFormModal() {
    const data = '';
    const headerTitle = AUTH_FORM_CONSTANTS.AUTH_FORM_TITLE;
    this.dialogService.open(CreateAuthorizationFormComponent, data, headerTitle);
  }

  downloadFile(authform) {
    this.service.downloadProviderDocument(authform);
  }

  getValidDocuments() {
    this.authforms && this.authforms.filter(authform => {
      return authform.documents != null && Array.isArray(authform.documents) && authform.documents.length > 0;
    })
  }
  previewFile(authform) {
    this.service.previewDocument(authform).then((data) => {
      this.dialogService.open(
        DocumentPreviewComponent,
        { pdfUrl: data },
      );
    })
  }

  paginateData(): void {
    const startIndex = this.pageIndex * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.paginatedData = this.authorizationForms?.slice(startIndex, endIndex);
  }

  onPageChange(pageIndex: number): void {
    if (pageIndex < 0 || pageIndex > Math.floor(this.authorizationForms.length / this.pageSize)) {
      return; // Do nothing if the new page index is out of range
    }
    this.pageIndex = pageIndex;
    this.paginateData();
  }

}
