import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { NotificationModel } from 'src/app/aps/models/ui-models';
import { ClearUshgNotification } from 'src/app/store/dispatchers';

@Injectable({
  providedIn:'root'
})
export class ToastrService {
  notification$ = this.store.select((state) => state.data.notification);
  constructor(private messageService: MessageService, private store: Store) {
    this.openNotification();
  }

  openNotification() {
    this.notification$.subscribe((notification: NotificationModel) => {
      if (notification) {
        const { type, header, message } = notification;
        this.messageService.add({
          severity: type,
          summary: header,
          detail: message,
        });
        this.store.dispatch(new ClearUshgNotification())
      }
    });
  }

  open(notification: NotificationModel) {
    const { type, header, message } = notification;
    this.messageService.add({
      severity: type,
      summary: header,
      detail: message,
    });
  }
}
