import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ProfileModel } from 'src/app/aps/models/application-model';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  profile: ProfileModel;
  currentDate = new Date().toDateString();
  constructor(private store: Store) { }

  ngOnInit(): void {
    this.getUserProfile()
  }

  getUserProfile() {
    this.profile= this.store.selectSnapshot((state)=> state.data.userProfile);
  }
}
