<p-sidebar [(visible)]="showAssignApsUserModal" [baseZIndex]="10000" position="right"
  [appPermission]="'CanAccessTeamWorkLoad'">
  <ushg-aps-users-workload [selectedApplications]="selectedApplication"
    (applicationAssigned)="applicationAssignedToApsMember($event)"></ushg-aps-users-workload>
</p-sidebar>
<div class="grid">
  <div class="col-12">
    <ng-container *ngIf="applicationFilterData">
      <ushg-search-filter [applicationFilters]="applicationFilterData" [showDropDownFilter]="true"
        (resetEventHandler)="resetFilter($event)" (searchEventHandler)="search($event)"
        (selectEventHandler)="selectEventHandler($event)" [dropDownFilterList]="dropDownFilterList"
        [showDropDownView]="false"></ushg-search-filter>
    </ng-container>
    <div class="col-12 shadow-3 mt-3 p-0">
      <ushg-table [rowData]="orderQueueList 
      | paginate 
        : {
            itemsPerPage: 10,
            currentPage: pageNumber,
            totalItems: orderQueueList[0]?.nodetotalCount
          }" [headerList]="headersList" [actions]="actionsList" [rows]="10"
        [filterData]="filterValue">
      </ushg-table>
      <div class="text-center pt-1 pb-2">
        <div *ngIf="orderQueueList.length === 0" class="my-3">
          <p-message severity="info" text="No records found" styleClass="p-mr-2 w-100"></p-message>
        </div>
        <div class="d-flex justify-content-between align-items-center">
          <div class="ml-3">Total : <b>{{orderQueueList[0]?.nodetotalCount}} </b></div>
          <div class="mr-3"><pagination-controls class="my-pagination" (pageChange)="paginate($event)"></pagination-controls></div>
        </div>
      </div>
    </div>
  </div>
</div>