<div class="header-container">
  <div class="flex justify-content-between align-items-center mx-5">
    <div class="logo">
      <img src="assets/logo/APS_Logo.svg" class="my-2" />
    </div>
    <div>
      <div class="flex align-items-center">
        <div>{{ currentDate }}</div>
        <div *ngIf="profile" class="intial-circle mx-5">
          <span title="{{ profile | json }}">{{ profile?.initial }}</span>
        </div>
        <div *ngIf="profile">
          <strong>
            {{ profile?.name }}
          </strong>
        </div>
        <div class="ml-5" [appPermission]="'CanAccessToITHelpCenter'">
          <a href="https://helpdesk.uhg.com/hc" target="_blank"><img src="assets/logo/Help_Center.svg"/></a>
        </div>
      </div>
    </div>
  </div>
</div>
