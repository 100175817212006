import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { UshgDialogService } from '../services/ushg-dialog-service';

@Component({
  selector: 'app-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss']
})
export class DocumentPreviewComponent implements OnInit {

  pdfUrl
  config = {
    width: '70%',
    contentStyle: { height: '780px', overflow: 'auto' },
  };
  constructor(private dialogService: DynamicDialogConfig, private dialog: UshgDialogService) { }

  ngOnInit(): void {
    this.pdfUrl = this.dialogService.data.pdfUrl;
  }

  close() {
    this.dialog.close();
  }

}
