<div fxLayout="column" fxLayoutAlign="start stretch" style="height: 100vh;">
  <div class="p-4 bg-white mb-3 mt-3">
    <app-authorization-search-form (searchAuthorization)="searchAuthFiles($event)" (resetAuthForms)="resetFormsList($event)"></app-authorization-search-form>
  </div>
  <div style="padding-bottom:15px">
    <app-authorization-forms-list [viewAuthForms]="enableListGrid" [authforms]="authForms" [pageIndex]="pageIndex"></app-authorization-forms-list>
  </div>
</div>
<div class="progress-spinner" *ngIf="showSpinner">
  <p-progressSpinner></p-progressSpinner>
</div>