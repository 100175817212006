import { Injectable } from "@angular/core";
import { Actions, ofAction, ofActionCompleted, ofActionSuccessful, Store } from "@ngxs/store";
import { take } from "rxjs/operators";
import { ApplicationsForFollowUpQueue, ApsUsers, AssignedApplicationsForLoggedInUser, OrderQueueApplications, UnAssignedApplications, UserProfileData } from "src/app/store/dispatchers";
import { QueryResponse } from "../models/query-reponse";
import { ApsApiService } from "../services/aps-api-service";
import { ApsService } from "../services/aps-service";

@Injectable({
    providedIn: 'root'
})

export class Resolvers {
    constructor(private apsService: ApsService, private store: Store, private storeActions?: Actions) { }

    newRequestQueueResolver = () => {
        return this.resolveCallBack(this.storeActions.pipe((ofActionCompleted(UnAssignedApplications))));
    }

    myQueueResolver = () => {
         this.store.dispatch(new ApsUsers()).toPromise();
         return this.resolveCallBack(this.storeActions.pipe((ofActionCompleted(ApsUsers))));
    }

    workQueueResolver() {
        return this.resolveCallBack(this.storeActions.pipe((ofActionCompleted(OrderQueueApplications))));
    }

    followupQueueResolver = () => {
        this.store.dispatch(new ApsUsers())
        this.storeActions.pipe(ofActionSuccessful(ApsUsers)).subscribe((res) => {
            this.store.dispatch(new ApplicationsForFollowUpQueue('', 0, 10));
        });
        return this.resolveCallBack(this.storeActions.pipe((ofActionCompleted(ApplicationsForFollowUpQueue), take(1))).toPromise());

    }

    resolveCallBack = (value?) => {
        return {
            resolve: (value) => {
                return value
            }
        }
    }
}