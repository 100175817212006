import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'ushg-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent implements OnInit {
  constructor() { }
  @Input() public disable = false
  @Input() public placeholder: string;
  @Output() public searchInputEventHandler = new EventEmitter<string>();

  @Input() set selectedValue(value: string) {
    if (this.form) {
      this.form.get('input').setValue(value);
    }
  }

  public form: UntypedFormGroup;

  public get formValue() {
    return this.form.get('input').value;
  }

  public ngOnInit() {
    this.form = new UntypedFormGroup({
      input: new UntypedFormControl(''),
    });
  }

  onEnterPressed(){
    this.searchInputEventHandler.emit('EnterPressed');
  }

  public searchInput() {
    this.searchInputEventHandler.emit(this.formValue);
  }

  public clearInput() {
    this.form.reset();
    this.searchInputEventHandler.emit(this.formValue);
  }
}
