import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Actions, ofActionCompleted, Store } from '@ngxs/store';
import { Paginator } from 'primeng/paginator';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import {
  Application,
  selectedApplicantdetailsModel,
} from 'src/app/aps/models/application-model';
import {
  ApplicationStatus,
  ApplicationStatusQueryId,
  PAGE_STATE,
  RedirectUrl,
} from 'src/app/aps/models/enums';
import { ApplicationFilterData } from 'src/app/aps/models/filter-data-model';
import { ApsService } from 'src/app/aps/services/aps-service';
import { OrderService } from 'src/app/aps/services/aps/order-service';
import { createSearchCriteria } from 'src/app/aps/services/graphql-service';
import { OrdersList } from 'src/app/aps/services/jsondata';
import { UtilityService } from 'src/app/aps/services/utility-service';
import {
  ApplicationFilterConfiguration,
  ApplicationSearchInfo,
} from 'src/app/shared/application-filters/application-filters.component';
import { PageStateService } from 'src/app/shared/services/page-state.service';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import {
  AssignedApplicationsForLoggedInUser,
  ClearQuery,
  CurrentOrderData,
  FilterAssignedApplicationsForMyQueue,
  ResetSpinner,
  ResetState,
  SelectedApplicationDetails,
  SetPageNo,
  UpdateSelectedApplicationDetails,
} from 'src/app/store/dispatchers';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-my-queue',
  templateUrl: './my-queue.component.html',
  styleUrls: ['./my-queue.component.scss'],
  providers: [PageStateService, ToastrService],
})
export class MyQueueComponent implements AfterViewInit, AfterContentChecked {
  @ViewChild('paginator', { static: true }) paginator: Paginator;
  assignedApplications$: Observable<Application[]>
  profile = this.store.selectSnapshot((state) => state.data.userProfile);
  readonly MY_QUEUE = 'myQueue';
  selectedIndex: number = 0;
  currentPage = 0;
  applicationFilterData: ApplicationSearchInfo;
  listOfApplications: Application[] = [];
  selectedApplicationIndex = 0;
  selectedApplication: Application;
  ordersList = OrdersList;
  selectedApplicationDetails = selectedApplicantdetailsModel;
  lastName;
  timeInQueue;
  reminderDate;
  expired;
  type;
  searchValue;
  applicationStatusId;
  orderBy;
  applicationFilterConfig: ApplicationFilterConfiguration;
  pageNumber = 1;
  componentConfiguration = {
    component: 'myQueue',
  };
  constructor(
    private store: Store,
    private pageStateService: PageStateService,
    private cd: ChangeDetectorRef,
    private storeActionHandlers: Actions,
    private utilityService: UtilityService,
    private orderService: OrderService,
    private toastrService: ToastrService,
    private apsService: ApsService
  ) { }

  // public setApplicationFilters() {
  //   // this.orderBy = data.orderBy;
  //   this.applicationFilterData = new ApplicationSearchInfo();
  //   // this.filter(this.applicationFilterData);

  //   const paginate = {};
  //   paginate["pageDirection"] = '';
  //   paginate["pageNumber"] = 1;

  //   const { domainUserName } = this.store.selectSnapshot(
  //     (state) => state.data.userProfile
  //   );

  //   data.filters = data.filters || {};
  //   data.filters.assignedTo = domainUserName;

  //   this.applicationFilterData.paginate = data.paginate

  // }

  public setApplicationFilters(data) {
    this.orderBy = data.orderBy;
    this.applicationFilterData = new ApplicationFilterData(this, this)
      .filterData as ApplicationSearchInfo;
  }

  public ngAfterViewInit() {

    this.setApplicationFilters({});
    // this.setAppConfigdata()
    this.paginate(1, true);
    const persistedPageNo = this.store.selectSnapshot((state) => state.data.pageNo)
    persistedPageNo ? (this.pageNumber = persistedPageNo, this.currentPage = persistedPageNo) : this.pageNumber = this.pageNumber

    this.assignedApplications$ = this.store.select((state) => state.data.assignedApplications)

    this.store.select((state) => state.data.assignedApplications)
      .pipe(
        map((applications) => {
          if (applications?.length) {
            applications = this.sortRequestedTypes(applications);
          }
          applications = applications.sort(function (a, b) {
            return new Date(b.assignedOn) as any > new Date(a.assignedOn) ? -1 : 1;
          })
          return applications;
        })
      )
      .subscribe((Application) => {
        this.listOfApplications = Application;
        const savedApplicationId = this.store.selectSnapshot((state) => state.data.selectedApplicationDetails)?.applicationId
        if (this.listOfApplications?.length) {
          if (!savedApplicationId) {
            this.selectedApplication = this.listOfApplications[0];
            this.selectedApplicationIndex = 0;
          } else {
            this.selectedApplication = this.apsService.getObjectFromList(this.listOfApplications, savedApplicationId);
            this.selectedApplicationIndex = this.listOfApplications.findIndex(
              (application: Application) => {
                return application.applicationId === savedApplicationId;
              }
            );

            if (this.selectedApplicationIndex <= 0) {
              this.selectedApplication = this.listOfApplications[0];
              this.selectedApplicationIndex = 0;
            }
          }

          this.selectApplication(
            this.selectedApplication,
            this.selectedApplicationIndex
          );
          this.setAppConfigdata();
        }
        else {
          this.selectedApplication = null
        }
      });
  }

  public ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  private setAppConfigdata(): void {
    this.applicationFilterConfig = new ApplicationFilterConfiguration({
      defaultSetting: this.applicationFilterData,
      displayStatusDropdown: true,
      pageName: PAGE_STATE.MY_QUEUE,
      displaySearchByTypeRadio: true
    });
  }

  public filter(data: ApplicationSearchInfo): void {
    data.paginate = data.paginate || {};
    data.paginate.queryId = ApplicationStatusQueryId.myQueue
    const { pageDirection, pageNumber, pageInfo } = data.paginate;
    data.paginate.pageDirection = pageDirection ? pageDirection : '';
    data.paginate.pageNumber = pageNumber ? pageNumber : 1;
    data.paginate.pageInfo = pageInfo;
    data.page = this.MY_QUEUE;

    const { domainUserName } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    data.filters = data.filters || {};
    data.filters.assignedTo = domainUserName;
    this.store.dispatch(new FilterAssignedApplicationsForMyQueue(createSearchCriteria(data)))
  }

  selectApplication(application, index) {
    this.store.dispatch(new ResetState());
    this.setIndex(index);
    this.selectedApplication = application;
    if (this.selectedApplication) {
      this.selectedApplication.index = this.selectedApplicationIndex;

    }

    this.getSelectedApplicationDetails(application.id, application.convertedVersion);
    this.applicationFilterData.applicationId =
      this.selectedApplication.applicationId;
    this.pageStateService.updateUrlWithPageStateInformation(
      this.MY_QUEUE,
      this.applicationFilterData
    );
  }

  async getSelectedApplicationDetails(appId, convertedVersion) {
    this.store.dispatch(new SelectedApplicationDetails(appId, convertedVersion));
    await this.storeActionHandlers
      .pipe(ofActionCompleted(SelectedApplicationDetails), take(1))
      .toPromise();
    this.store.dispatch(new ResetSpinner())
    this.selectedApplication = {
      ...this.selectedApplication,
      ...this.store.selectSnapshot(
        (state) => state.data.selectedApplicationDetails
      ),
    };
    this.selectedApplication.orderDetails =
      this.orderService.processOrderByStatus(
        this.selectedApplication.orderDetails
      );
    this.selectedApplication.isFollowup = false;
    this.selectedApplication.redirectUrl = RedirectUrl.MY_QUEUE
    this.store.dispatch(
      new UpdateSelectedApplicationDetails(this.selectedApplication)
    );
  }

  dispatchOrderData(data) {
    this.store.dispatch(new CurrentOrderData(data));
  }

  setIndex(index: number) {
    this.selectedIndex = index;
    this.selectedApplicationIndex = index;
  }

  // filterApplications(data?) {
  //   this.applicationFilterData = data;
  //   data ? this.paginate(data.paginate.pageNumber, true) : null;
  // }

  removeFilters() {
    this.store.dispatch(new ClearQuery());
    this.pageNumber = 1;
    this.applicationFilterData = new ApplicationFilterData(this, this)
      .filterData as ApplicationSearchInfo;
    this.store.dispatch(new AssignedApplicationsForLoggedInUser())
  }

  paginate(pageNumber: any, onLoad?: boolean) {
    if (pageNumber) {
      this.pageNumber = pageNumber;

      const assignedApplications = this.store.selectSnapshot(
        (state) => state.data.assignedApplications
      );
      const paginate = this.applicationFilterData.paginate;
      let pageDirection;
      if (onLoad) {
        pageDirection = paginate.pageDirection;
      } else {
        pageDirection = pageNumber <= this.currentPage ? 'BEFORE' : 'AFTER';
      }

      paginate.pageDirection = pageDirection;
      paginate.pageNumber = pageNumber;
      paginate.queryId = ApplicationStatusQueryId.myQueue;
      this.applicationFilterData.filters.assignedTo =
        this.profile?.domainUserName;
      paginate.pageInfo.startCursor = (assignedApplications && assignedApplications.length > 0)
        ? assignedApplications[0].pageInfo.startCursor
        : paginate.pageInfo.startCursor;

      paginate.pageInfo.endCursor = (assignedApplications && assignedApplications.length > 0)
        ? assignedApplications[0].pageInfo.endCursor
        : paginate.pageInfo.endCursor;

      this.updatePaginateInfo(this.applicationFilterData, pageNumber);
      if (onLoad) {
        const query = this.store.selectSnapshot((state) => state.data.query)
        const persistedPageNo = this.store.selectSnapshot((state) => state.data.pageNo)
        persistedPageNo ? (this.pageNumber = persistedPageNo, this.currentPage = persistedPageNo) : this.pageNumber = pageNumber
        this.updatePaginateInfo(this.applicationFilterData, this.pageNumber);

        pageDirection = this.pageNumber <= this.currentPage ? 'BEFORE' : 'AFTER';
        this.store.dispatch(
          new FilterAssignedApplicationsForMyQueue(query ? query : createSearchCriteria(this.applicationFilterData))
        );
      }
      else {
        this.store.dispatch(new SetPageNo(pageNumber))
        this.store.dispatch(
          new FilterAssignedApplicationsForMyQueue(createSearchCriteria(this.applicationFilterData))
        );
      }

      this.selectedApplicationIndex = 0;
      this.currentPage = pageNumber;
    }
  }

  get ApplicationStatus(): typeof ApplicationStatus {
    return ApplicationStatus;
  }

  private updatePaginateInfo(
    filterData: ApplicationSearchInfo,
    pageNumber: number
  ) {
    if (!this.listOfApplications?.length) {
      return;
    }
    filterData.paginate.pageInfo = this.listOfApplications[0].pageInfo;
    filterData.paginate.pageNumber = pageNumber;

    this.pageStateService.updateUrlWithPageStateInformation(
      this.MY_QUEUE,
      filterData
    );
  }

  private sortRequestedTypes(applications: Application[]) {
    for (const application of applications) {
      const types = {};
      for (const orderTracker of application.orderTrackers) {
        const type = orderTracker.requestType;
        if (types[type]) {
          types[type]++;
        } else {
          types[type] = 1;
        }
      }
      application.sortedRequestType = types;
    }
    return applications;
  }

  ngOnDestroy() {
    // this.currentPage=0
  }
}
