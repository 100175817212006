import {
  State,
  Action,
  StateContext,
  Selector,
  createSelector,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  ApplicationLogsAndNotes,
  LoadEFulfillmnentMetrics,
  LoadSelectedFullApplication,
  ApsUsers,
  ApsUsersRolesList,
  AssignedApplications,
  AssignedApplicationsForLoggedInUser,
  CurrentOrderData,
  CurrentPageSteps,
  FetchNotes,
  FilterAssignedApplications,
  LoadNBCVCallDashboardData,
  LoadNErrors,
  NBCApplicationsMetrics,
  NBCInprogressApplications,
  NBCVCallDashboard,
  OrderQueueApplications,
  ProviderPreferences,
  RequestOrderPdf,
  ResetCurrentSteps,
  ResetRequestOrderPdf,
  ResetState,
  SelectedApplicationDetails,
  UnAssignedApplications,
  UserProfileData,
  InsertRequestCheck,
  SetSpinner,
  ResetSpinner,
  InsertAuditLog,
  sendSecureEmail,
  GetUnderWriters,
  GetPaymentCheck,
  UpdateOrder,
  FilterOrderQueueApplications,
  FilterNewRequestQueueApplications,
  AddDirtyFlag,
  AssignApplicationsToApsUser,
  UpdateSelectedApplicationDetails,
  NewOrderLogEntry,
  ToggleOrderLogEntryPin,
  ApplicationsForFollowUpQueue,
  UshgNotification,
  SaveReminderDate,
  GeneratePdf,
  LoadCallDurationMetrics,
  ClearUshgNotification,
  FilterAssignedApplicationsForFollowupQueue,
  SetQuery,
  ClearQuery,
  SetPageNo,
  FilterAssignedApplicationsForMyQueue,
  ClearSelectedApplicationDetails,
  SearchFilters,
  GetCompanyInsights,
  GetProductInsights,
  UpdateOrderProcessStatus,
  SelectedApplicationDetailsByApplicationId,
  GetNewRequestStatisticsForApsDashboard,
  GetCompletedRequestsStatisticsForApsDashboard,
  AuditLogs,
  Orders,
  UWSPoliciesStatus,
  LockFollowupApplication,
  UpdateApplicationProperties,
  UpdateOrderProperties,
  UpdateOrderProviderPhoneExtension,
  LockApplication,
  ReleaseApplication,
  PoliciesWithBRCode,
  SelectedApplicationStatusDetails,
  ApplicationStatusHistory
} from './dispatchers';
import { StateModel } from './state-model';
import { NbcApiService } from '../nbc/services/nbc-api-services/nbc-api-service';
import { DateService } from '../nbc/services/general-functions/date.service';
import { Undoable } from 'ngxs-history-plugin';
import { VCallApiService } from '../aps/services/vcall-api/vcall-api.service';
import { ApsService } from '../aps/services/aps-service';
import { ApsApiService } from '../aps/services/aps-api-service';
import { environment } from 'src/environments/environment';
import { testProfile } from '../aps/services/jsondata';
import { concatMap, filter, map, take, tap } from 'rxjs/operators';
import { Application, ApsUserModel } from '../aps/models/application-model';
import { ApplicationSearchInfo } from '../shared/application-filters/application-filters.component';
import { UserProfileService } from '../aps/services/user/user-profile.service';
import { QueryResponse } from '../aps/models/query-reponse';
import { UtilityService } from '../aps/services/utility-service';
import {
  AuditLog,
  AuditLogModel,
  NotificationModel,
  NotificationType,
} from '../aps/models/ui-models';
import { PageStateService } from '../shared/services/page-state.service';
import { ApplicationFilterData } from '../aps/models/filter-data-model';
import { OrderCreatedModelComponent } from '../aps/components/core/create-order-components/create-lab-order-ae-components/order-created-model/order-created-model.component';
import { UshgDialogService } from '../shared/services/ushg-dialog-service';
import { UserProfile } from '../aps/models/user-profile';
import {
  ApplicationStatusQueryId,
  AuditTypeId,
  PAGE_STATE,
  RedirectUrl,
  RequestTypeStatus,
} from '../aps/models/enums';
import { ErrorService } from '../shared/services/error.service';
import { query, state } from '@angular/animations';
import {
  getPageInfo,
  sortByLastName,
  sortByType,
  sortBySearchValue,
  handleExpired,
  handleReminderDate,
  handleState,
  handleApplicationStatusId,
  handleAssignedTo,
  handleOrderBy,
  createFollowupSearchCriteria,
} from '../aps/services/graphql-service';
import { newGuid } from '@microsoft/applicationinsights-core-js';
import { LogsAndNotes } from '../nbc/Models/nbc-models';
@State<StateModel>({
  name: 'data',
  defaults: {
    applications: [],
    selectedApplications: [],
    unAssignedApplications: [],
    assignedApplications: [],
    apsUsers: [],
    apsUsersRoles: [],
    currentPageSteps: [],
    currentOrderData: {},
    requestOrderPdf: [],
    orderQueueApplications: [],
    selectedApplicationDetails: null,
    filterText: null,
    providerPreferences: null,
    logsAndNotes: [],
    nbcApplicationsMetrics: [],
    nbcInprogressApplications: [],
    nbcVCallsDashboard: {
      byCallCenterByCallStatus: {},
      byCallTypeByCallCenter: {},
      byCallCenter: [],
      byCallType: [],
      byCallStatus: [],
      byCompany: [],
      byProduct: [],
    },
    errorList: [],
    selectedFullApplication: null,
    decisionMatrix: [],
    efulfillmentMetrics: [],
    userProfile: {},
    spinner: false,
    underWriters: [],
    paymentChecks: [],
    isFormDirty: false,
    notification: null,
    callDurationMetricsBySection: [],
    assignedApplicationsForFollowup: [],
    query: '',
    pageNo: null,
    companyInsights: [],
    productInsights: [],
    cachedApplicationDetails: new Map<number, Application>(),
    cachedLogsAndNotes: new Map<number, LogsAndNotes[]>(),
    fullApplications: new Map<number, any>(),
    cache: new Map<string, any>(), //Generic cache dictionary.
    searchFilters: { followup: {}, myqueue: {} },
    cachedApplicationDetailsByAppId: new Map<number, Application>(),
    newRequestStatisticsForApsDashboard: {},
    completedRequestsStatisticsForApsDashboard: {},
    auditLogs: [],
    orders: null,
    uwsPoliciesStatus: {},
    appProperties: null,
    selectedApplicationStatusDetails: null,
    policiesWithBRCode: [],
    cachedApplicationStatusDetails: new Map<number, Application>(),
    applicationStatusHistory: []
  },
})
@Injectable({
  providedIn: 'root',
})
export class Actions {
  constructor(
    private nbcApiService: NbcApiService,
    private dateService: DateService,
    private apsService: ApsService,
    private apsApiService: ApsApiService,
    private store: Store,
    private vcallApiService: VCallApiService,
    private userProfileService: UserProfileService,
    private utilityService: UtilityService,
    private pageStateService: PageStateService,
    private dialogService: UshgDialogService
  ) {}
  setSelectedApplicants(
    ctx: StateContext<StateModel>,
    { selectedApplicationsList }
  ) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      nbcVCallsDashboard: selectedApplicationsList,
      selectedApplications: selectedApplicationsList,
    });
  }

  @Action(UnAssignedApplications)
  setunAssignedApplications(ctx: StateContext<StateModel>) {
    return this.apsApiService
      .getListofApplicants()
      .subscribe(({ data }: QueryResponse) => {
        let sortedApplications = data['applications'].sort((a, b) => {
          return (
            new Date(a.whenRoutedToTeam).valueOf() -
            new Date(b.whenRoutedToTeam).valueOf()
          );
        });
        return ctx.patchState({
          unAssignedApplications: sortedApplications,
        });
      });
  }

  @Action(OrderQueueApplications)
  async orderQueueApplications({ patchState }: StateContext<StateModel>) {
    const { data } = await this.apsApiService.getWorkQueueList().toPromise();
    let sortedApplications = data['applications'].sort((a, b) => {
      return (
        new Date(a.whenRoutedToTeam).valueOf() -
        new Date(b.whenRoutedToTeam).valueOf()
      );
    });
    sortedApplications =
      this.userProfileService.setDisplayNameToApplications(sortedApplications);

    patchState({
      orderQueueApplications: sortedApplications,
    });
  }

  @Action(AssignedApplications)
  AssignedApplicants(ctx: StateContext<StateModel>, { assignedApplications }) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      assignedApplications,
    });
  }

  @Action(ApsUsers)
  async ApsUsers({ patchState, getState }: StateContext<StateModel>) {
    const currentState = getState();
    let users;
    users = currentState.apsUsers;
    Promise.all([
      users.length <= 1
        ? this.apsApiService.getApsUsersList().toPromise()
        : Promise.resolve(users),
      this.apsService.getUsersWorkload().toPromise(),
    ]).then((res: any[]) => {
      users = users.length <= 1 ? res[0].data.users : users;
      const usersAggregrationData = res[1].data.usersAggregrationData;
      const usersWorkload = this.userProfileService.assignUsersWithWorkload(
        usersAggregrationData
      );

      users.apsUsersRoles =
        this.apsService.setApsUsersRolesListFromApsUsersList(
          users,
          usersWorkload
        );

      currentState.apsUsers = Object.values([
        ...currentState.apsUsers,
        ...new Set(
          users.map((user) => {
            const noSlashUser = user.domainUserName.replace(/\\/g, '');
            user.workload = usersWorkload[noSlashUser]
              ? usersWorkload[noSlashUser]
              : { new: 0, pending: 0 };
            return user;
          })
        ),
      ]);
      const apsUsers = [...currentState.apsUsers, ...users];
      return patchState({
        apsUsers: [
          ...new Map(
            apsUsers.map((item) => [item['displayName'], item])
          ).values(),
        ],
        apsUsersRoles: users.apsUsersRoles,
      });
    });
  }

  @Action(ApsUsersRolesList)
  ApsUsersRolesList(ctx: StateContext<StateModel>, { apsUsersRoles }) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      apsUsersRoles,
    });
  }

  @Action(FilterNewRequestQueueApplications)
  filterNewRequestQueueApplications(
    { patchState }: StateContext<StateModel>,
    filters?: {
      applications: ApplicationSearchInfo;
    }
  ) {
    this.apsService
      .listOfWorkQueueApplicationsBasedOnSearch(filters)
      .pipe(
        tap(({ data, errors }) => {
          if (!errors) {
            data.applicationsQueue.edges = data.applicationsQueue.edges.length
              ? data.applicationsQueue.edges.map((edge) => {
                if(edge && edge.node) {
                  edge = edge.node;
                  edge.pageInfo = data.applicationsQueue.pageInfo;
                  edge.nodetotalCount = data.applicationsQueue.totalCount;
                }
                  return edge;
                })
              : [];
            let sortedApplications = data.applicationsQueue.edges.length
              ? data.applicationsQueue.edges.sort((a, b) => {
                  return (
                    new Date(a.whenRoutedToTeam).valueOf() -
                    new Date(b.whenRoutedToTeam).valueOf()
                  );
                })
              : [];
            this.store.dispatch(new ResetSpinner());
            patchState({ unAssignedApplications: sortedApplications });
          } else {
            this.store.dispatch(new ResetSpinner());
          }
        })
      )
      .subscribe();
  }

  @Action(FilterOrderQueueApplications)
  filterOrderQueueApplications(
    { patchState }: StateContext<StateModel>,
    filters?: {
      applications: ApplicationSearchInfo;
    }
  ) {
    let applications;
    this.apsService
      .listOfWorkQueueApplicationsBasedOnSearch(filters)
      .pipe(
        // concatMap((data) => {
        //   //applications = data.data.applications;

        //   return this.store.selectOnce((state) => state.data.apsUsers);
        // }),
        tap(({ data, errors }) => {
          if (!errors) {
            const apsUsers = data?.data?.users;
            let sortedApplications = [];
            if (data.applicationsQueue.edges.length > 0) {
              data.applicationsQueue.edges = data.applicationsQueue.edges.map(
                (edge) => {
                if (edge && edge.node) { 
                  edge = edge.node;
                  edge.pageInfo = data.applicationsQueue.pageInfo;
                  edge.nodetotalCount = data.applicationsQueue.totalCount;
                }
                  return edge;
                }
              );
              let orderQueueApplications =
                this.userProfileService.setDisplayNameToApplications(
                  data.applicationsQueue.edges
                );
              sortedApplications = orderQueueApplications.sort((a, b) => {
                return (
                  new Date(a.whenRoutedToTeam).valueOf() -
                  new Date(b.whenRoutedToTeam).valueOf()
                );
              });
            }
            this.store.dispatch(new ResetSpinner());
            patchState({ orderQueueApplications: sortedApplications });
          } else {
            this.store.dispatch(new ResetSpinner());
          }
        })
      )
      .subscribe();
  }

  @Action(FilterAssignedApplications)
  filterAssignedApplicationsForMyqueue(
    { patchState }: StateContext<StateModel>,
    filters: {
      applications: ApplicationSearchInfo;
    }
  ) {
    this.store.dispatch(new SetSpinner());
    const persistedQuery = this.store.selectSnapshot(
      (state) => state.data.query
    );
    this.apsService
      .filterAssignedApplications(filters)
      .pipe(
        tap(({ data, errors }) => {
          if (!errors) {
            data.applicationsQueue.edges = data.applicationsQueue.edges.map(
              (edge) => {
              if(edge && edge.node) {
                edge = edge.node;
                edge.pageInfo = data.applicationsQueue.pageInfo;
                edge.nodetotalCount = data.applicationsQueue.totalCount;
              }
                return edge;
              }
            );
            let applications = this.store.selectSnapshot(
              (state) => state.data.applications
            );
            applications[filters.applications.page] =
              data.applicationsQueue.edges;
            const assignedApplications =
              this.userProfileService.setDisplayNameToApplications(
                data.applicationsQueue.edges
              );
            this.store.dispatch(
              new SetQuery(
                filters.applications ? filters.applications : filters
              )
            );
            this.store.dispatch(new ResetSpinner());
            patchState({
              applications,
              assignedApplications,
            });
          } else {
            this.store.dispatch(new ResetSpinner());
          }
        })
      )
      .subscribe();
  }

  @Action(SetPageNo)
  setPageNo({ patchState }: StateContext<StateModel>, { pageNo }) {
    patchState({ pageNo });
  }

  createSearchCriteria(filters) {
    const filter = filters.applications.filters;
    const orderBy = filters.applications.orderBy;
    const paginate = filters.applications.paginate;
    const page = filters.applications.page;

    const { pageDirection, pageCursor } = getPageInfo(paginate);
    const lastName = sortByLastName(filter.lastName);
    const type = sortByType(filter.type);
    const searchValue = sortBySearchValue(
      filter.searchValue,
      filter.filterType
    );
    const dueDate = handleExpired(filter.expired);
    const reminderDate = handleReminderDate(filter.reminderDate);
    const state = handleState(filter.state);
    const applicationStatusId = handleApplicationStatusId(
      filter.applicationStatusId
    );
    const assignedTo = handleAssignedTo(filter);
    const orderByQuery = handleOrderBy(orderBy, page);
    const filterQuery = `OR: [${lastName}${dueDate}]${type}${reminderDate}${searchValue}${applicationStatusId}${assignedTo}`;
    return `${pageDirection}: 10,
    ${pageCursor}
    
    where :  {${paginate.queryId} ${state} ${filterQuery}} order: {${orderByQuery}}`;
  }

  @Action(FilterAssignedApplicationsForFollowupQueue)
  filterAssignedApplications(
    { patchState }: StateContext<StateModel>,
    { query }
  ) {
    this.store.dispatch(new SetSpinner());
    this.apsService
      .filterAssignedApplications(query)
      .pipe(
        tap(
          async ({ data, errors }) => {
            if (!errors) {
              data.applicationsQueue.edges = data.applicationsQueue.edges.map(
                (edge) => {
                if(edge && edge.node) {
                  edge = edge.node;
                  edge.pageInfo = data.applicationsQueue.pageInfo;
                  edge.nodetotalCount = data.applicationsQueue.totalCount;
                }
                  return edge;
                }
              );
              let applications = this.store.selectSnapshot(
                (state) => state.data.applications
              );
              applications['followUp'] = data.applicationsQueue.edges;
              const assignedApplicationsForFollowup =
                this.userProfileService.setDisplayNameToApplications(
                  data.applicationsQueue.edges
                );
              this.store.dispatch(new SetQuery(query));
              this.store.dispatch(new ResetSpinner());

              patchState({
                applications,
                assignedApplicationsForFollowup,
              });
            } else {
              this.store.dispatch(new ResetSpinner());
            }
          },
          (err) => {
            this.store.dispatch(new ResetSpinner());
          }
        )
      )
      .subscribe();
  }

  @Selector()
  static FilteredUsers(string: string) {
    return createSelector([Actions], (state) => {
      return state.data.apsUsers.filter((user) => {
        if (string) {
          if (user.displayName.toLowerCase().includes(string.toLowerCase())) {
            return user;
          }
        } else {
          return user;
        }
      });
    });
  }

  @Selector()
  static assignedApplications() {
    const MY_QUEUE = 'myQueue';
    return createSelector([Actions], (state) => {
      return state.data.applications[MY_QUEUE];
    });
  }

  @Selector()
  static FilteredUsersByRole(string: string) {
    return createSelector([Actions], (state) => {
      return state.data.apsUsers.filter((user) => {
        const groupLabel = environment.config.azure_roles.find(
          (r) => r.role === string
        )?.name;
        if (string) {
          if (user.apsMemberRoles?.includes(groupLabel)) {
            return user;
          }
        } else {
          return user;
        }
      });
    });
  }

  @Selector()
  static sortAssignedApplications(id: number) {
    return createSelector([Actions], (state) => {
      return state.data.assignedApplications.filter((a) => {
        if (id) {
          return a.applicationStatusId === id;
        } else {
          return a;
        }
      });
    });
  }

  @Selector()
  static FilteredOrderQueueApplications(string: string) {
    return createSelector([Actions], (state) => {
      return state.data.orderQueueApplications.filter((user) => {
        if (string) {
          if (user.assignedTo === string) {
            return user;
          }
        } else {
          return user;
        }
      });
    });
  }

  @Selector()
  static FilteredApplications(string: string) {
    return createSelector([Actions], (state) => {
      return state.data.assignedApplications.filter((user) => {
        if (string) {
          if (
            user.primaryContactName.toLowerCase().includes(string.toLowerCase())
          ) {
            return user;
          }
        } else {
          return user;
        }
      });
    });
  }

  @Action(CurrentPageSteps)
  CurrentPageSteps(ctx: StateContext<StateModel>, { currentPageSteps }) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      currentPageSteps,
    });
  }

  @Action(ProviderPreferences)
  providerPreferences(ctx: StateContext<StateModel>, { providerPreferences }) {
    const currentState = ctx.getState();
    return ctx.patchState({
      providerPreferences,
    });
  }

  @Action(CurrentOrderData)
  CurrentOrderData(ctx: StateContext<StateModel>, { currentOrderData }) {
    //fix for no state in pdf
    if (currentOrderData.applicantzipCode === null) {
      currentOrderData.applicantCityStateZip = currentOrderData?.birthState;
    }

    //patternize fax number to be consistant with phone number
    currentOrderData.providerFax = currentOrderData.providerFax?.replace(
      /(\d{3})(\d{3})(\d{4})/,
      '($1) $2-$3'
    );
    if (currentOrderData.APS === null) {
      // currentOrderData.provider.providerFax = currentOrderData.provider.providerFax?.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
      currentOrderData.APS.provider.providerFax =
        currentOrderData.APS.provider?.providerFax?.replace(
          /(\d{3})(\d{3})(\d{4})/,
          '($1) $2-$3'
        );
    }
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      currentOrderData,
    });
  }

  @Action(RequestOrderPdf)
  @Undoable(RequestOrderPdf)
  RequestOrderPdf(ctx: StateContext<StateModel>, { requestOrderPdf }) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      requestOrderPdf: [...requestOrderPdf, ...currentState.requestOrderPdf],
    });
  }

  @Action(SelectedApplicationDetails)
  async SelectedApplicationDetails(
    ctx: StateContext<StateModel>,
    { appId, convertedVersion }
  ) {
    this.store.dispatch(new ApplicationLogsAndNotes(null, appId));
    const currentState = ctx.getState();
    const cachedItems = currentState.cachedApplicationDetails;
    const cached = cachedItems.get(appId);
    const load =
      cached === undefined ||
      cached === null ||
      cached.applicationTracker[0].convertedVersion !== convertedVersion ||
      convertedVersion === null;
    if (load) {
      this.store.dispatch(new SetSpinner());
      const { data } = await this.apsService
        .selectedApplicationDetails(appId)
        .toPromise();
      this.store.dispatch(new ResetSpinner());
      const { applicationDetailsByApplicationTrackerId } = data;
      applicationDetailsByApplicationTrackerId.applicationPropertyBags =
        applicationDetailsByApplicationTrackerId?.applicationTracker[0]?.applicationPropertyBags;
      cachedItems.set(appId, applicationDetailsByApplicationTrackerId);
      return ctx.patchState({
        selectedApplicationDetails: applicationDetailsByApplicationTrackerId,
        cachedApplicationDetails: cachedItems,
      });
    } else {
      return ctx.patchState({
        selectedApplicationDetails: cached,
      });
    }
  }

  @Action(ResetRequestOrderPdf)
  ResetRequestOrder(ctx: StateContext<StateModel>, { requestOrderPdf }) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      requestOrderPdf: [],
    });
  }

  @Action(ResetCurrentSteps)
  ResetCurrentPageSteps(ctx: StateContext<StateModel>) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      currentPageSteps: [],
    });
  }

  @Action(ResetState)
  ResetState(ctx: StateContext<StateModel>) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      currentPageSteps: [],
      requestOrderPdf: [],
      providerPreferences: null,
      isFormDirty: false,
    });
  }

  @Action(ApplicationLogsAndNotes)
  async selectedApplocationLogsAndNotes(
    ctx: StateContext<StateModel>,
    { logsAndNotes, applicationTrackerId }
  ) {
    const currentState = ctx.getState();
    const cachedItems = currentState.cachedLogsAndNotes;
    const cached = cachedItems.get(applicationTrackerId);
    if (cached) {
      return ctx.patchState({
        logsAndNotes: cached,
      });
    } else {
      const { data } = await this.apsApiService
        .getAuditLogAndNotes(applicationTrackerId)
        .toPromise();
      cachedItems.set(
        applicationTrackerId,
        data['auditLogsByApplicationTrackerId']
      );
      return ctx.patchState({
        logsAndNotes: data['auditLogsByApplicationTrackerId'],
        cachedLogsAndNotes: cachedItems,
      });
    }
  }

  @Action(AssignedApplicationsForLoggedInUser)
  assignedApplicationsForLoggedInUser(
    { patchState }: StateContext<StateModel>,
    { pageNo, queryId, pageDirection, pageCursor }
  ) {
    const applications = this.store.selectSnapshot(
      (state) => state.data.applications
    );
    const query = this.apsService.paginationQuery(pageDirection, pageCursor);
    const persistedQuery = this.store.selectSnapshot(
      (state) => state.data.query
    );
    const { domainUserName } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    return this.apsApiService
      .getListOfAssignedApplicationsToCurrentApsMember(
        persistedQuery ? persistedQuery : pageNo,
        queryId,
        pageCursor
      )
      .pipe(
        tap(({ data, errors }) => {
          if (!errors) {
            data.applicationsQueue.edges = data.applicationsQueue.edges.map(
              (edge) => {
              if(edge && edge.node) {
                edge = edge.node;
                edge.pageInfo = data.applicationsQueue.pageInfo;
                edge.pageInfo.nodetotalCount =
                  data.applicationsQueue.totalCount;
                edge.nodetotalCount = data.applicationsQueue.totalCount;
              }
                return edge;
              }
            );
            const loggedUserNameNoDomain = domainUserName.replaceAll('\\', '');
            this.store.dispatch(new ResetSpinner());
            this.store.dispatch(
              new SetQuery(
                `first: ${pageNo}, ${
                  pageCursor ? `${pageCursor}` : ''
                } where: { assignedTo_in : ["${domainUserName}","${loggedUserNameNoDomain}"],${queryId} }`
              )
            );
            applications['myQueue'] = data.applicationsQueue.edges;
            patchState({
              assignedApplications: data.applicationsQueue.edges,
              applications,
            });
          } else {
            this.store.dispatch(new ResetSpinner());
          }
        })
      );
  }
  @Action(FilterAssignedApplicationsForMyQueue)
  FilterassignedApplicationsForLoggedInUser(
    { patchState }: StateContext<StateModel>,
    { query }
  ) {
    let applications = this.store.selectSnapshot(
      (state) => state.data.applications
    );

    return this.apsApiService.getFilteredAssignApplications(query).pipe(
      tap(({ data, errors }) => {
        if (!errors) {
          data.applicationsQueue.edges = data.applicationsQueue.edges.map(
            (edge) => {
            if(edge && edge.node) {
              edge = edge.node;
              edge.pageInfo = data.applicationsQueue.pageInfo;
              edge.pageInfo.nodetotalCount = data.applicationsQueue.totalCount;
              edge.nodetotalCount = data.applicationsQueue.totalCount;
            }
              return edge;
            }
          );
          this.store.dispatch(new ResetSpinner());
          this.store.dispatch(new SetQuery(query ? query : ''));
          applications['myQueue'] = data.applicationsQueue.edges;
          patchState({
            assignedApplications: data.applicationsQueue.edges,
            applications,
          });
        } else {
          this.store.dispatch(new ResetSpinner());
        }
      })
    );
  }

  @Action(SetQuery)
  setQuery({ patchState }: StateContext<StateModel>, { query }) {
    patchState({ query });
  }

  @Action(ClearQuery)
  resetQuery({ patchState }: StateContext<StateModel>) {
    patchState({ query: '', pageNo: 1, selectedApplicationDetails: null });
  }

  @Action(SetSpinner)
  SetSpinner({ patchState }: StateContext<StateModel>) {
    patchState({ spinner: true });
  }

  @Action(ResetSpinner)
  ResetSpinner({ patchState }: StateContext<StateModel>) {
    patchState({ spinner: false });
  }

  //NBC ACTIONS Starts From Here

  @Action(NBCVCallDashboard)
  nbcVCallDashboard(ctx: StateContext<StateModel>, { nbcVCallDashboardData }) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      nbcVCallsDashboard: nbcVCallDashboardData,
    });
  }

  @Action(LoadNBCVCallDashboardData)
  loadNbcVCallDashboardData(
    ctx: StateContext<StateModel>,
    { fromDate, toDate }
  ) {
    fromDate = fromDate ? fromDate : this.dateService.getThisMonthDates()[0];
    toDate = toDate ? toDate : this.dateService.getThisMonthDates()[1];
    this.nbcApiService
      .getVCallDataForNBC(fromDate, toDate)
      .subscribe((data) => {
        const currentState = ctx.getState();
        ctx.setState({ ...currentState, nbcVCallsDashboard: data });
      });
  }

  @Action(NBCApplicationsMetrics)
  nbcApplicationsMetrics(
    ctx: StateContext<StateModel>,
    { applicationsMetrics }
  ) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      nbcApplicationsMetrics: applicationsMetrics,
    });
  }

  @Action(NBCInprogressApplications)
  nbcInprogressApplications(
    ctx: StateContext<StateModel>,
    { inprogressApplications }
  ) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      nbcInprogressApplications: inprogressApplications,
    });
  }

  @Action(LoadNErrors)
  loadNErrors(ctx: StateContext<StateModel>, { error }) {
    const currentState = ctx.getState();
    return ctx.setState({
      ...currentState,
      errorList: [{ message: error.statusText }],
    });
  }

  @Action(LoadSelectedFullApplication)
  loadFullApplication(ctx: StateContext<StateModel>, { fullApplication }) {
    if (fullApplication) {
      const cache = ctx.getState().fullApplications;
      const cached = cache.get(fullApplication);

      if (cached === undefined) {
        return this.vcallApiService
          .getFullApplication(fullApplication)
          .subscribe((data) => {
            const currentState = ctx.getState();
            cache.set(fullApplication, data);

            return ctx.setState({
              ...currentState,
              selectedFullApplication: data,
              fullApplications: cache,
            });
          });
      } else {
        const currentState = ctx.getState();
        return ctx.setState({
          ...currentState,
          selectedFullApplication: cached,
        });
      }
    }

    return null; //HACK : dont know what to do.
  }

  @Action(LoadEFulfillmnentMetrics)
  loadEFulfillmnentMetrics(ctx: StateContext<StateModel>, { year }) {
    this.nbcApiService.getEFulfillment(year).subscribe((data) => {
      const currentState = ctx.getState();
      return ctx.setState({
        ...currentState,
        efulfillmentMetrics: data,
      });
    });
  }

  @Action(FetchNotes)
  fetchNotes(ctx: StateContext<StateModel>, { id }) {
    if (id !== undefined && id !== '') {
      this.apsApiService.getAuditLogAndNotes(id).subscribe((data) => {
        const currentState = ctx.getState();
        const cachedItems = currentState.cachedLogsAndNotes;
        cachedItems.set(id, data['auditLogsByApplicationTrackerId']);
        return ctx.setState({
          ...currentState,
          logsAndNotes: data.data['auditLogsByApplicationTrackerId'],
          cachedLogsAndNotes: cachedItems,
        });
      });
    }
  }

  @Action(UserProfileData)
  userProfile({ getState, patchState }: StateContext<StateModel>, { user }) {
    const developerProfile: ApsUserModel = testProfile(user);
    const { apsUsers } = getState();
    patchState({
      apsUsers: environment.testMode
        ? apsUsers.some(
            (user) => user.displayName === developerProfile.displayName
          )
          ? apsUsers
          : [...apsUsers, ...[developerProfile]]
        : apsUsers,
      userProfile: user,
    });
  }

  @Action(InsertRequestCheck)
  async InsertRequestCheck(ctx: StateContext<StateModel>, { check }) {
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    check.AppId = currentOrderData.applicationId;
    check.checkType = currentOrderData.requestType;
    check.ApplicationState = currentOrderData.state;
    check.policyNumber = currentOrderData.policyNumber;
    if (currentOrderData.APS && currentOrderData.APS.provider) {
      check.providerName = currentOrderData.APS.provider.providerFullName;
    }
    await this.apsApiService
      .insertCheckRequest(check)
      .toPromise()
      .then((resp) => {
        const selectedApplicationDetails = this.store.selectSnapshot(
          (state) => state.data.selectedApplicationDetails
        );
        const currentOrderData = this.store.selectSnapshot(
          (state) => state.data.currentOrderData
        );
        selectedApplicationDetails.checkDetails.push(resp);
        this.store.dispatch(
          new SelectedApplicationDetails(
            currentOrderData.applicationTrackerId,
            null
          )
        );
        this.store.dispatch(new GetPaymentCheck());
      });
  }

  @Action(NewOrderLogEntry)
  newOrderLogEntry({ patchState }, { payload }) {
    this.apsService.newOrderLogEntry(payload).subscribe((res: any) => {
      const id = res.data.insertAuditRecord.id;
      let currentOrderData = this.store.selectSnapshot(
        (state) => state.data.currentOrderData
      );
      let selectedApplicant = this.store.selectSnapshot(
        (state) => state.data.selectedApplicationDetails
      );
      selectedApplicant.applicationTracker[0].convertedVersion =
        res.data.insertAuditRecord.convertedVersion;
      currentOrderData.convertedVersion =
        res.data.insertAuditRecord.convertedVersion;
      selectedApplicant.applicationTrackerId =
        res.data.insertAuditRecord.applicationTrackerId;
      currentOrderData.applicationTrackerId =
        res.data.insertAuditRecord.applicationTrackerId;
      res.data.insertAuditRecord.convertedVersion;
      if (id) {
        const allNotes = this.store.selectSnapshot(
          (state) => state.data.logsAndNotes
        );
        payload = { ...payload, id };
        allNotes.push(payload);
        patchState({
          logsAndNotes: allNotes,
        });
        this.store.dispatch(new CurrentOrderData(currentOrderData));
        this.store.dispatch(
          new SelectedApplicationDetails(
            selectedApplicant.applicationTrackerId ||
              currentOrderData.applicationTrackerId,
            res.data.insertAuditRecord.convertedVersion
          )
        );
      }
     if(res.data.insertAuditRecord.skipEmail) 
      this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.WARNING,
          '',
          `The automatic email to the agent's internal email address was skipped`
        )
      )
    );
    });
  }

  @Action(ToggleOrderLogEntryPin)
  toggleOrderLogEntryPin({ patchState }, { payload }) {
    this.apsService.toggleOrderLogEntryPin(payload).subscribe((res: any) => {
      const id = res.data.toggleAuditRecordPin.id;
      let currentOrderData = this.store.selectSnapshot(
        (state) => state.data.currentOrderData
      );
      if (id) {
        const allNotes = this.store.selectSnapshot(
          (state) => state.data.logsAndNotes
        );
        let notePos = allNotes.findIndex((x) => x.id === id);
        allNotes[notePos] = {
          ...allNotes[notePos],
          isPinned: payload.isPinned,
        };
        patchState({
          logsAndNotes: allNotes,
        });
        this.store.dispatch(new CurrentOrderData(currentOrderData));
      }
    });
  }

  @Action(InsertAuditLog)
  async insertAuditLog(ctx: StateContext<StateModel>, { payload }) {
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    payload.convertedVersion = currentOrderData.convertedVersion;
    payload.orderTrackerId = currentOrderData.orderTrackerId;
    await this.apsApiService
      .insertAuditRecord(payload)
      .subscribe((res: QueryResponse) => {
        currentOrderData.convertedVersion =
          res.data.insertAuditRecord.convertedVersion;
        this.store.dispatch(
          new FetchNotes(currentOrderData.applicationTrackerId)
        );
        this.store.dispatch(new CurrentOrderData(currentOrderData));
      });
  }

  @Action(sendSecureEmail)
  sendSecureEmaill(
    { patchState }: StateContext<StateModel>,
    { form, attachments, dialogRef }
  ) {
    this.apsService.sendSecureEmail(form, attachments, dialogRef);
  }

  @Selector()
  static AuditLogsById(ids: Array<any>) {
    return createSelector([Actions], (state) => {
      return state.data.logsAndNotes.filter((logs) => {
        return ids.includes(logs.auditTypeId);
      });
    });
  }

  @Action(GetUnderWriters)
  async GetUnderWriters(ctx: StateContext<StateModel>) {
    let users = ctx.getState().cache.get('GetUnderWriters');

    if (users === undefined) {
      await this.apsApiService.getUnderWriters().then((data) => {
        users = data.data['users'];
        const currentState = ctx.getState();
        users.map((user) => {
          user.domainUserName = UserProfile.getDomainUserName(
            user.userPrincipalName,
            true
          );
          user;
        });

        const cache = ctx.getState().cache;
        cache.set('GetUnderWriters', users);

        return ctx.setState({
          ...currentState,
          underWriters: users,
          cache: cache,
        });
      });
    } else {
      const currentState = ctx.getState();
      return ctx.setState({
        ...currentState,
        underWriters: users,
      });
    }

    return null;
  }

  @Action(GetPaymentCheck)
  async GetPaymentCheck({ patchState }: StateContext<StateModel>) {
    if (
      this.store.selectSnapshot(
        (state) => state.data.selectedApplicationDetails
      )
    ) {
      const { applicationTrackerId } = this.store.selectSnapshot(
        (state) => state.data.currentOrderData
      );
      let paymentChecks = await this.apsApiService
        .getPaymentCheck(applicationTrackerId)
        .toPromise();
      patchState({ paymentChecks });
    }
  }

  @Action(UpdateOrder)
  async UpdateOrder({ patchState }, { orderData, orderAction }) {
    this.store.dispatch(new SetSpinner());
    await this.apsService.mapPoliciesBrcodes(orderData.applicationId);
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );

    const { domainUserName } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    const brcodes = await this.store.selectSnapshot((state) => state.data.policiesWithBRCode);
    const brCode = brcodes.find(item => item.policyNumber === orderData.policyNumber)?.brCode;
    orderData.convertedVersion = currentOrderData.convertedVersion;
    orderData.updatedBy = domainUserName;
    orderData.updatedDate = new Date();
    orderData.BrCode = brCode;
    this.apsApiService.updateOrder(orderData, orderAction).subscribe(
      async (res) => {
        if (!res.errors) {
          currentOrderData.convertedVersion =
            res.data['upsertOrder'].applicationDetails.convertedVersion;
          currentOrderData.orderStatusId = orderData.orderStatusId;
          currentOrderData.skipEmail = res.data['upsertOrder'].inputOrderDetails.skipEmail;
          await Promise.all([
            this.store
              .dispatch(new CurrentOrderData(currentOrderData))
              .toPromise(),

            this.store
              .dispatch(
                new InsertAuditLog(
                  new AuditLogModel(AuditTypeId.ORDER_CREATED, currentOrderData)
                )
              )
              .toPromise(),
            this.store
              .dispatch(
                new SelectedApplicationDetails(
                  currentOrderData.applicationTrackerId,
                  currentOrderData.convertedVersion
                )
              )
              .toPromise(),
          ]);
          if (orderData.isFinalStep) {
            if (
              currentOrderData.config &&
              !currentOrderData.config.isFromApplicationDetailPage
            ) {
              this.refreshApplications(currentOrderData);
            }
            const dialogRef = this.dialogService
              .open(OrderCreatedModelComponent)
              .onClose.subscribe((res) => {
                this.apsService.redirectToBase();
              });
          }

          if (
            orderData.updateOrders &&
            currentOrderData.config &&
            !currentOrderData.config.isFromApplicationDetailPage
          ) {
            await this.refreshApplications(currentOrderData);
          }

          this.store.dispatch(new ResetSpinner());
        } else {
          this.store.dispatch(new ResetSpinner());
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.ERROR,
                'Error',
                'There was an error processing the request. Please refresh and try again'
              )
            )
          );
        }
      },
      (err: Error) => {
        this.store.dispatch(new ResetSpinner());
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(
              NotificationType.ERROR,
              'Error',
              'There was an error processing the request. Please refresh and try again'
            )
          )
        );
      }
    );
  }

  async refreshApplications(currentOrderData) {
    if (!currentOrderData.isFollowup) {
      const query = this.store.selectSnapshot((state) => state.data.query);
      return this.store
        .dispatch(new FilterAssignedApplicationsForMyQueue(query))
        .toPromise();
    } else if (currentOrderData.isFollowup) {
      const query = this.store.selectSnapshot((state) => state.data.query);
      const searchFilters = this.store.selectSnapshot((state) => state.data.searchFilters);
      let applicationFilterData: ApplicationSearchInfo = {} as ApplicationSearchInfo;
      if (searchFilters && searchFilters.followUp) {
        searchFilters.followUp.filters['assignedTo'] = null;
        applicationFilterData = searchFilters.followUp;
      }

      const criteria = searchFilters && searchFilters.followUp ? createFollowupSearchCriteria(applicationFilterData) : query;
      const pageNumber = this.store.selectSnapshot((state) => state.data.pageNo);
      const skip = Math.max(0, (pageNumber - 1) * 10);
      
      return await this.store
        .dispatch(new ApplicationsForFollowUpQueue(criteria, skip, 10))
        .toPromise();
    }

    return Promise.resolve(true);
  }
  refreshDetailPageApplication(applicationTrackerId: number) {
    return this.store.dispatch(
      new SelectedApplicationDetails(applicationTrackerId, null)
    );
  }

  @Action(AddDirtyFlag)
  AddDirtyFlag({ patchState }) {
    return patchState({
      isFormDirty: true,
    });
  }

  @Action(AssignApplicationsToApsUser)
  async AssignApplicationsToApsUser(
    { patchState },
    { apsMember, selectedApplications }
  ) {
    const { domainUserName } = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    const assignedApplications = selectedApplications.map(
      async (eachApplication) => {
        eachApplication.domainUserName = domainUserName;
        return await this.apsService
          .assignApplicationToApsUser(
            { ...eachApplication, ...apsMember },
            apsMember
          )
          .toPromise();
      }
    );

    return Promise.all(assignedApplications);
  }

  @Action(UpdateApplicationProperties)
  UpdateApplicationProperties({ patchState }, { applications }) {
    patchState({
      applications: applications,
    });
  }

  @Selector()
  static appPropertiesValues(state: any) {
    return state.data.appProperties;
  }

  @Action(LockApplication) lockApplication({ patchState }, { appProperties }) {
    const { applications, selectedApplication } =
      this.apsService.updatePropertiesOnLock(appProperties);
    this.store.dispatch(new UpdateApplicationProperties(applications));
    this.store.dispatch(
      new UpdateSelectedApplicationDetails(selectedApplication)
    );
    patchState({ appProperties: appProperties });
  }

  @Action(ReleaseApplication) releaseApplication(
    { patchState },
    { appProperties }
  ) {
    const { applications, selectedApplication } =
      this.apsService.updatePropertiesOnUnlock(appProperties);
    this.store.dispatch(new UpdateApplicationProperties(applications));
    this.store.dispatch(
      new UpdateSelectedApplicationDetails(selectedApplication)
    );
    patchState({ appProperties: appProperties });
  }

  @Action(UpdateSelectedApplicationDetails)
  UpdateSelectedApplicationDetails({ patchState }, { application }) {
    patchState({
      selectedApplicationDetails: application,
    });
  }

  @Action(ApplicationsForFollowUpQueue)
  async ApplicationsForFollowUpQueue({ patchState }: StateContext<StateModel>, query) {
    this.apsApiService
      .getListOfAssignedApplicationsForFollowupQueue(query)
      .pipe(
        tap(
          ({ data, errors }) => {
            if (!errors) {
              data.applicationsQueueWithOffSetPaging.items = data.applicationsQueueWithOffSetPaging.items.map(
                (a) => {
                  a.nodetotalCount = data.applicationsQueueWithOffSetPaging.totalCount;
                  return a;
                }
              );
              let applications = this.store.selectSnapshot(
                (state) => state.data.applications
              );
              applications['followUp'] = data.applicationsQueueWithOffSetPaging.items;
              const assignedApplicationsForFollowup =
                this.userProfileService.setDisplayNameToApplications(
                  data.applicationsQueueWithOffSetPaging.items
                );
              this.store.dispatch(new ResetSpinner());
              this.store.dispatch(
                new SetQuery(ApplicationStatusQueryId.followQueue)
              );
              patchState({
                applications,
                assignedApplicationsForFollowup,
              });
            } else {
              this.store.dispatch(new ResetSpinner());
            }
          },
          (err) => {
            this.store.dispatch(new ResetSpinner());
          }
        )
      )
      .subscribe();
  }

  @Action(UshgNotification)
  UshgNotification({ patchState }: StateContext<StateModel>, { notification }) {
    patchState({
      notification,
    });
  }

  @Action(ClearUshgNotification)
  ClearUshgNotification(
    { patchState }: StateContext<StateModel>,
    { notification }
  ) {
    patchState({
      notification: null,
    });
  }

  @Action(SaveReminderDate)
  SaveReminderDate({ patchState }: StateContext<StateModel>, { reminderDate }) {
    this.store.dispatch(new SetSpinner());
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    const profile = this.store.selectSnapshot(
      (state) => state.data.userProfile
    );
    if (currentOrderData) {
      let followupDateTimePayload = {
        state: currentOrderData.state,
        orderTrackerId: currentOrderData.orderTrackerId,
        applicationTrackerId: currentOrderData.applicationTrackerId,
        createdBy: profile.name,
        convertedVersion: currentOrderData.convertedVersion,
        createdDate: new Date().toISOString(),
        reminderDate,
      };
      return this.apsApiService
        .saveReminderDate(followupDateTimePayload)
        .subscribe(
          ({ data }) => {
            if (data.updateReminderDate.convertedVersion) {
              currentOrderData.convertedVersion =
                data.updateReminderDate.convertedVersion;
              currentOrderData.orderTrackers.map((order) => {
                if (order.id === followupDateTimePayload.orderTrackerId) {
                  order.reminderDate = data.reminder;
                }
                return currentOrderData;
              });
              this.store.dispatch(
                new UshgNotification(
                  new NotificationModel(
                    NotificationType.SUCCESS,
                    '',
                    'Reminder date updated sucessfully'
                  )
                )
              );
              this.store.dispatch(new CurrentOrderData(currentOrderData));
              const query = this.store.selectSnapshot(
                (state) => state.data.query
              );
              this.store.dispatch(
                new ApplicationsForFollowUpQueue(query, 0, 10)
              );
              this.store.dispatch(new ResetSpinner());
            }
          },
          (err) => {
            this.store.dispatch(new ResetSpinner());
            this.store.dispatch(
              new UshgNotification(
                new NotificationModel(
                  NotificationType.ERROR,
                  '',
                  'There was an error processing the request. Please refresh and try again'
                )
              )
            );
          }
        );
    } else {
      return true;
    }
  }

  @Action(GeneratePdf)
  GeneratePdf({}, { pdfTitle }) {
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );

    this.apsApiService.getRequestOrderPdf(pdfTitle, currentOrderData);
  }

  @Action(LoadCallDurationMetrics)
  loadCallDurationMetrics(ctx: StateContext<StateModel>, { payload }) {
    this.nbcApiService.getCallDurationMetrics(payload).subscribe((data) => {
      const currentState = ctx.getState();
      return ctx.setState({
        ...currentState,
        callDurationMetricsBySection: data,
      });
    });
  }

  @Action(SearchFilters)
  searchFilters({ patchState }, { filters }) {
    patchState({
      searchFilters: filters,
    });
  }

  @Action(GetCompanyInsights)
  loadCompanyInsights(ctx: StateContext<StateModel>, { fromDate, toDate }) {
    fromDate = fromDate ? fromDate : this.dateService.getThisMonthDates()[0];
    toDate = toDate ? toDate : this.dateService.getThisMonthDates()[1];
    this.nbcApiService
      .getVCallDataForNBC(fromDate, toDate)
      .subscribe((data) => {
        const currentState = ctx.getState();
        ctx.setState({ ...currentState, companyInsights: data.byCompany });
      });
  }

  @Action(GetProductInsights)
  loadProductInsights(ctx: StateContext<StateModel>, { fromDate, toDate }) {
    fromDate = fromDate ? fromDate : this.dateService.getThisMonthDates()[0];
    toDate = toDate ? toDate : this.dateService.getThisMonthDates()[1];
    this.nbcApiService
      .getVCallDataForNBC(fromDate, toDate)
      .subscribe((data) => {
        const currentState = ctx.getState();
        ctx.setState({ ...currentState, productInsights: data.byProduct });
      });
  }

  @Action(UpdateOrderProcessStatus)
  async updateOrderProcessStatus(
    ctx: StateContext<StateModel>,
    { selecetdOrderData }
  ) {
    let currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    this.apsService.updateOrderStatusFlag(selecetdOrderData).subscribe(
      (res) => {
        if (
          currentOrderData.config &&
          currentOrderData.config.isFromApplicationDetailPage
        ) {
          this.refreshDetailPageApplication(
            currentOrderData.config.applicationTrackerId
          );
        } else {
          this.refreshApplications(currentOrderData);
        }
      },
      (err: Error) => {
        this.store.dispatch(new ResetSpinner());
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(
              NotificationType.ERROR,
              'Error',
              'There was an error processing the request. Please refresh and try again'
            )
          )
        );
      }
    );
  }

  @Action(SelectedApplicationDetailsByApplicationId)
  async SelectedApplicationDetailsByApplicationId(
    ctx: StateContext<StateModel>,
    { applicationId, convertedVersion }
  ) {
    const currentState = ctx.getState();
    const cachedItems = currentState.cachedApplicationDetailsByAppId;
    const cached = cachedItems.get(Number(applicationId));
    const load = cached === undefined || cached === null;
    if (load) {
      this.store.dispatch(new SetSpinner());
      const { data } = await this.apsService
        .getApplicationDetailsByApplicationId(applicationId)
        .toPromise();
      this.store.dispatch(new ResetSpinner());
      const { application } = data;
      cachedItems.set(Number(applicationId), application);
      if (application.applicationTracker !== undefined) {
        this.store.dispatch(
          new ApplicationLogsAndNotes(
            null,
            application.applicationTracker[0].id
          )
        );
      }

      return ctx.patchState({
        selectedApplicationDetails: application,
        cachedApplicationDetailsByAppId: cachedItems,
      });
    } else {
      if (cached.applicationTracker !== undefined) {
        this.store.dispatch(
          new ApplicationLogsAndNotes(null, cached.applicationTracker[0].id)
        );
      }
      return ctx.patchState({
        selectedApplicationDetails: cached,
      });
    }
  }

  @Action(LockFollowupApplication)
  lockFollowupApplication(ctx: StateContext<StateModel>) {}

  @Action(GetNewRequestStatisticsForApsDashboard)
  loadNewRequestStatisticsForApsDashboard(
    ctx: StateContext<StateModel>,
    { fromDate, toDate }
  ) {
    fromDate = fromDate ? fromDate : this.dateService.getThisMonthDates()[0];
    toDate = toDate ? toDate : this.dateService.getThisMonthDates()[1];
    this.nbcApiService
      .getNewRequestStatisticsForApsDashboard(fromDate, toDate)
      .subscribe((data) => {
        const currentState = ctx.getState();
        ctx.setState({
          ...currentState,
          newRequestStatisticsForApsDashboard: data,
        });
      });
  }

  @Action(GetCompletedRequestsStatisticsForApsDashboard)
  loadCompletedRequestStatisticsForApsDashboard(
    ctx: StateContext<StateModel>,
    { fromDate, toDate }
  ) {
    fromDate = fromDate ? fromDate : this.dateService.getThisMonthDates()[0];
    toDate = toDate ? toDate : this.dateService.getThisMonthDates()[1];
    this.nbcApiService
      .getCompletedRequestStatisticsForApsDashboard(fromDate, toDate)
      .subscribe((data) => {
        const currentState = ctx.getState();
        ctx.setState({
          ...currentState,
          completedRequestsStatisticsForApsDashboard: data,
        });
      });
  }

  @Action(AuditLogs)
  getAuditLogs(ctx: StateContext<StateModel>, { fromDate, toDate }) {
    fromDate = fromDate ? fromDate : this.dateService.getThisMonthDates()[0];
    toDate = toDate ? toDate : this.dateService.getThisMonthDates()[1];
    this.nbcApiService.getAuditLogs(fromDate, toDate).subscribe((response) => {
      const currentState = ctx.getState();
      ctx.setState({ ...currentState, auditLogs: response.data.auditLogs });
    });
  }

  @Action(Orders)
  getOrders(ctx: StateContext<StateModel>, { fromDate, toDate }) {
    fromDate = fromDate ? fromDate : this.dateService.getThisMonthDates()[0];
    toDate = toDate ? toDate : this.dateService.getThisMonthDates()[1];
    this.nbcApiService.getOrders(fromDate, toDate).subscribe((response) => {
      const currentState = ctx.getState();
      ctx.setState({ ...currentState, orders: response.data.orders });
    });
  }

  @Action(UWSPoliciesStatus)
  getUWSPoliciesStatus(ctx: StateContext<StateModel>, { fromDate, toDate }) {
    fromDate = fromDate ? fromDate : this.dateService.getThisMonthDates()[0];
    toDate = toDate ? toDate : this.dateService.getThisMonthDates()[1];
    this.nbcApiService
      .getUWSPoliciesStatus(fromDate, toDate)
      .subscribe((res) => {
        const currentState = ctx.getState();
        ctx.setState({ ...currentState, uwsPoliciesStatus: res });
      });
  }

  @Action(UpdateOrderProperties)
  updateOrderProperties(ctx: StateContext<StateModel>, { orderProperties }) {
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    this.apsApiService.upsertOrderProperties(orderProperties).subscribe(
      (res: QueryResponse) => {
        if (res.data?.upsertOrderProperties) {
          currentOrderData.convertedVersion =
            res.data?.upsertOrderProperties?.convertedVersion;
          currentOrderData.properties =
            res.data?.upsertOrderProperties?.properties;
          currentOrderData.orderDetails.properties =
            res.data?.upsertOrderProperties?.properties;
          this.store.dispatch(new CurrentOrderData(currentOrderData));
          this.store.dispatch(
            new SelectedApplicationDetails(
              currentOrderData.applicationTrackerId,
              currentOrderData.convertedVersion
            )
          );
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.SUCCESS,
                '',
                'Changes saved sucessfully'
              )
            )
          );
        }
      },
      (err: Error) => {
        this.store.dispatch(new ResetSpinner());
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(
              NotificationType.ERROR,
              '',
              'There was an error processing the request. Please refresh and try again'
            )
          )
        );
      }
    );
  }

  @Action(UpdateOrderProviderPhoneExtension)
  updateOrderProvider(
    ctx: StateContext<StateModel>,
    { orderProviderPhoneExtension }
  ) {
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    this.apsApiService
      .updateProviderPhoneExtension(orderProviderPhoneExtension)
      .subscribe(
        (res: QueryResponse) => {
          if (res.data?.upsertProviderPhoneExtension) {
            currentOrderData.convertedVersion =
              res.data?.upsertProviderPhoneExtension?.convertedVersion;

            if (currentOrderData.APS.providerOverride) {
              currentOrderData.APS.providerOverride.phoneExtension =
                res.data?.upsertProviderPhoneExtension?.aps.providerOverride.phoneExtension;
            } else {
              currentOrderData.APS.provider.phoneExtension =
                res.data?.upsertProviderPhoneExtension?.aps.provider.phoneExtension;
            }

            this.store.dispatch(new CurrentOrderData(currentOrderData));
            this.store.dispatch(
              new SelectedApplicationDetails(
                currentOrderData.applicationTrackerId,
                currentOrderData.convertedVersion
              )
            );
            this.store.dispatch(
              new UshgNotification(
                new NotificationModel(
                  NotificationType.SUCCESS,
                  '',
                  'Phone Extension Updated'
                )
              )
            );
          }
        },
        (err: Error) => {
          this.store.dispatch(new ResetSpinner());
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.ERROR,
                '',
                'There was an error processing the request. Please refresh and try again'
              )
            )
          );
        }
      );
  }

  @Action(SelectedApplicationStatusDetails)
  async SelectedApplicationStatusDetails(
    ctx: StateContext<StateModel>,
    { appId, convertedVersion }
  ) {
    await this.store.dispatch(new ApplicationLogsAndNotes(null, appId));
    const currentState = ctx.getState();
    const cachedItems = currentState.cachedApplicationStatusDetails;
    const cached = cachedItems.get(appId);
    const load =
      cached === undefined ||
      cached === null ||
      cached.applicationTracker[0].convertedVersion !== convertedVersion ||
      convertedVersion === null;
    if (load) {
      this.store.dispatch(new SetSpinner());
      const { data } = await this.apsService
        .selectedApplicationStatusDetails(appId)
        .toPromise();
      this.store.dispatch(new ResetSpinner());
      const { applicationDetailsByApplicationTrackerId } = data;
      cachedItems.set(appId, applicationDetailsByApplicationTrackerId);
      return ctx.patchState({
        selectedApplicationStatusDetails: applicationDetailsByApplicationTrackerId,
        cachedApplicationStatusDetails: cachedItems,
      });
    } else {
      return ctx.patchState({
        selectedApplicationStatusDetails: cached,
      });
    }
  }

  @Action(PoliciesWithBRCode)
  async policiesWithBRCode(ctx: StateContext<StateModel>, { applicationId }) {
    const data = await this.apsApiService
      .getBrcodes(applicationId)
      .pipe(
        map((result) => {
          if (result) {
            result['brCodeDetails'] =
              result['brCodeDetails'] &&
              result['brCodeDetails'].map(
                ({ policyNo: policyNumber, ...rest }) => ({
                  policyNumber,
                  ...rest,
                })
              );
          }
          return result;
        })
      )
      .toPromise();
      
    const currentState = ctx.getState();
    ctx.setState({
      ...currentState,
      policiesWithBRCode: data['brCodeDetails'],
    });
  }

  @Action(ApplicationStatusHistory)
    applicationStatusHistories(ctx:StateContext<StateModel>, {fromDate, toDate}) {
      this.nbcApiService.getApplicationStatusHistories(fromDate, toDate).subscribe((res)=>{
        const currentState = ctx.getState();
        ctx.setState({
          ...currentState,
          applicationStatusHistory: res.data['applicationStatusHistory']
        })
      })
  }
}
