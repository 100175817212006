import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[chipColor]',
})
export class ChipColorDirective implements AfterViewInit {
  @Input() status: string;
  constructor(private elRef: ElementRef) {
    const color = this.getStatus();
  }

  ngAfterViewInit() {
    const styleClassValues =
      this.elRef.nativeElement.getAttribute('styleClass');
    const color = this.getStatus();

    this.elRef.nativeElement.setAttribute(
      'styleclass',
      styleClassValues + ' ' + color
    );
  }
  getStatus() {
    const status = this.elRef.nativeElement.innerHTML;
    let className;
    switch (status) {
      case 'pending':
        className = 'primary bg-primary';
        break;
      default:
        className = 'primary bg-primary';
        break;
    }
    return className;
  }
}
