import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DateService {

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }


  getThisMonthDates(){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    return [`${mm}/01/${yyyy}`, `${mm}/${dd}/${yyyy}`];
  }

  getTodayAndYesterdayDates(){
    var today = new Date();
    var todayDate = String(today.getDate()).padStart(2, '0');
    var YesterdayDate = String(today.getDate()-1).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    
    if(today.getDate() === 1) {
      const yesterday = new Date(today.getTime() - 24 * 60 * 60 * 1000);
      const YesterdayDate = String(yesterday.getDate()).padStart(2, '0');
      const previousMonth = String(yesterday.getMonth() + 1).padStart(2, '0');
      const previousYear = yesterday.getFullYear();
      return [`${previousMonth}/${YesterdayDate}/${previousYear}`, `${mm}/${todayDate}/${yyyy}`];
    }
    else {
        return [`${mm}/${YesterdayDate}/${yyyy}`, `${mm}/${todayDate}/${yyyy}`];
     }
  }

  getDatesByMonths(numberOfMonths: number){
    var firstOfThisMonth = this.getThisMonthDates()[0];
    var lastDateOfPreviousMonthTemp = new Date( new Date(firstOfThisMonth).getTime() - (1000*60*60*24*1) );
    var toDate = String(lastDateOfPreviousMonthTemp.getDate()).padStart(2, '0');
    var toMonth = String(lastDateOfPreviousMonthTemp.getMonth() + 1).padStart(2, '0'); //January is 0!
    var toYear = lastDateOfPreviousMonthTemp.getFullYear();
    var fromYear;
    var fromMonth;
    var today = new Date();
    var currentMonth = today.getMonth() + 1;
    if(numberOfMonths === 1.5) {
      fromMonth = String(lastDateOfPreviousMonthTemp.getMonth() + 1).padStart( 2,'0');
      fromYear = lastDateOfPreviousMonthTemp.getFullYear();
    } else  {
      if(currentMonth === 1 ){
        fromYear = toYear;
        fromMonth = String((currentMonth-numberOfMonths) + 12).padStart(2, '0');
      } else if(currentMonth - numberOfMonths <= 0 ){
        fromYear = toYear - 1;
        fromMonth = String((currentMonth-numberOfMonths) + 12).padStart(2, '0');
      } else {
        fromYear = toYear;
        fromMonth = String(currentMonth-numberOfMonths).padStart(2, '0');
      }
    }
    return [`${fromMonth}/01/${fromYear}`,`${toMonth}/${toDate}/${toYear}`];
  }

  formatTheDateForApi(incomingDate: Date){
    var dd = String(incomingDate.getDate()).padStart(2, '0');
    var mm = String(incomingDate.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = incomingDate.getFullYear();
    return `${mm}/${dd}/${yyyy}`;
  }

  getTodayDates() {
    var today = new Date();
    var todayDate = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    return `${yyyy}-${mm}-${todayDate}`;
  }

  setParams(fromDate: string, toDate: string){
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: { fromDate: fromDate, toDate: toDate }
      });
  }
}
