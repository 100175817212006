<ng-container [ngSwitch]="formControlComponent.formControlType">
    <div [formGroup]='controlContainer["form"]'>

        <input *ngSwitchDefault type="text" pInputText [formControlName]='formControlComponent.formControlName'
            [placeholder]="formControlComponent.placeholder?formControlComponent.placeholder:''"
             class=" w-full"
             maxlength="{{formControlComponent.maxLength}}"
            [ngClass]="{
                'disabled':formControlComponent.disable
              }"
              (blur)="actions(formControlComponent.onblur)"
            />


        <span *ngSwitchCase="'textarea'">
            <textarea pInputTextarea [rows]="formControlComponent.rows" [cols]="formControlComponent.columns"
                class="p-inputtextarea p-inputtext p-component p-element w-full"
                (blur)="actions(formControlComponent.onblur)"
                [formControlName]='formControlComponent?.formControlName'></textarea>
        </span>

        <span *ngSwitchCase="'select'">
            <p-dropdown [options]="formControlComponent.options"
                [formControlName]='formControlComponent.formControlName' class="w-full" optionLabel="label"
                optionValue='value' [placeholder]='formControlComponent?.placeholder' >
            </p-dropdown>
        </span>
        <span *ngSwitchCase="'datepicker'">
            <p-calendar [formControlName]='formControlComponent.formControlName'
                (onSelect)="dateFormatChange($event,formControlComponent)" [showIcon]="true" inputId="icon"
                [readonlyInput]="true"
                [minDate]="formControlComponent.minimumDate" [showTime]="formControlComponent.showTime" [hourFormat]="formControlComponent.hourFormat">
            </p-calendar>
        </span>

        <span *ngSwitchCase="'file'">
            <div class="grid flex">
                <div class="col-12 flex">
                    <div class=" file surface-100">
                        <i class="pi pi-file-pdf text-secondary mr-3 text-xl" style="color: red"></i>
                        <span class="font-bold">
                            Special request Letter
                        </span>
                    </div>
                    <div class="col-2">
                        <p-fileUpload #fubauto mode="basic" name="demo[]" accept="image/*"
                            (onUpload)="uploadHandler($event)" [auto]="true" [uploadIcon]="'pi pi-upload'">
                        </p-fileUpload>
                    </div>
                    <div class="col-1    text-center">

                        <a target="_blank" download="">
                            <i class="pi pi-trash text-primary  text-lg p-2 surface-100"></i>
                        </a>
                    </div>
                </div>
            </div>


        </span>
        <span *ngSwitchCase="'decimals'">

            <p-inputNumber [formControlName]='formControlComponent.formControlName' [name]="formControlComponent.formControlName"
                (onInput)="patchPrimeNgInputValue($event, formControlComponent.formControlName)"
                inputId="minmaxfraction" mode="decimal" [maxFractionDigits]="2" [minFractionDigits]="2" class="w-full" [maxlength]="6">
            </p-inputNumber>

        </span> 
        <span *ngSwitchCase="'brCodeDropDown'">
            <app-brcode-dropdown  [mode]="'edit'" (childComponentHasError)="propogateErrorToParent($event)"></app-brcode-dropdown>
        </span>

        <input *ngSwitchCase="'phone'" pattern="^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$" type="text"
            [formControlName]='formControlComponent.formControlName'
            [placeholder]="formControlComponent.placeholder?formControlComponent.placeholder:''" mask="(000) 000-0000"
            [showMaskTyped]="true" [dropSpecialCharacters]="false" [disabled]='formControlComponent.disable'
            class=" w-full" pInputText />

        <input *ngSwitchCase="'ssn'" pattern="^(\([0-9]{3}\ |[0-9]{3}-)[0-9]{2}-[0-9]{4}$" type="text"
            [formControlName]='formControlComponent.formControlName'
            [placeholder]="formControlComponent.placeholder?formControlComponent.placeholder:''" mask="000-00-0000"
            [showMaskTyped]="true" [dropSpecialCharacters]="false" [disabled]='formControlComponent.disable'
            class=" w-full" pInputText />
            <p class="invalid-formcontrol m-1" *ngIf="controlContainer['form'].controls[formControlComponent.formControlName].errors || isInValid">
                {{formControlComponent.validationMessage}} 
            </p>
    </div>
</ng-container>