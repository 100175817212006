import { skip } from 'rxjs/operators';
import {
  Application,
  NewOrderLogEntryInterface,
  ToggleOrderLogEntryPinInterface,
} from '../aps/models/application-model';
import {
  ApplicationStatusQueryId,
  OrderActionType,
  TemplateTitle,
} from '../aps/models/enums';
import {
  NotificationModel,
  ProcessStatusPayload,
} from '../aps/models/ui-models';

export class SelectedApplications {
  static readonly type = '[New Request Queue] selectedApplicantsList';
  list = new Array();
  constructor(public selectedApplicationsList) {
    this.list = selectedApplicationsList;
  }
}

export class RequestOrderPdf {
  static readonly type = '[My Queue] get requestOrderPdf';
  list = [];
  constructor(public requestOrderPdf) {
    this.list = requestOrderPdf;
  }
}

export class ProviderPreferences {
  static readonly type = '[My Queue] set Provider Preferces';
  list = {};
  constructor(public providerPreferences) {
    this.list = providerPreferences;
  }
}

export class ResetRequestOrderPdf {
  static readonly type = '[My Queue] RESET requestOrderPdf';
  constructor() { }
}

export class ResetCurrentSteps {
  static readonly type = '[My Queue] RESET PreviousSteps';
  constructor() { }
}

export class AssignedApplicationsForLoggedInUser {
  static readonly type = '[My Queue] Assigned Applications';
  pageNo: number;
  queryId: any;
  pageCursor: string;
  pageDirection: number;
  constructor(
    pageNo = 10,
    queryId = ApplicationStatusQueryId.myQueue,
    pageDirection?,
    pageCursor?
  ) {
    (this.pageNo = pageNo),
      (this.queryId = queryId),
      (this.pageDirection = pageDirection),
      (this.pageCursor = pageCursor);
  }
}

export class UnAssignedApplications {
  static readonly type = '[New Request Queue] Un Assigned Applicants';
}

export class UpdateApplicationProperties {
  static readonly type = 'App Props';
  applications:  Application[];
  constructor(public apps: any) {
    this.applications = apps;
  }
}

export class LockApplication {
  static readonly type = '[APS Service] Lock Application';
  appProperties: any;
  constructor(public prop: any) {
    this.appProperties = prop;
  }
}

export class ReleaseApplication {
  static readonly type = '[Message handler service] Release Application';
  appProperties: any;
  constructor(public prop: any) {
    this.appProperties = prop;
  }
}

export class SelectedApplicationDetails {
  static readonly type = '[MYQUEUE SELECTED APPLICATION DETAILS]';
  appId: Number;
  constructor(public appplicationId: Number, public convertedVersion: string) {
    this.appId = appplicationId;
  }
}

export class AssignedApplications {
  static readonly type = '[New Request Queue] Assigned Applications';
  list = [];
  constructor(public assignedApplications) {
    this.list = assignedApplications;
  }
}
export class OrderQueueApplications {
  static readonly type = '[Order Queue]  Applications';
}

export class FilterNewRequestQueueApplications {
  static readonly type = '[NewRequestQueue] FilterNewRequestQueueApplications';
  constructor(public applications: any) { }
}

export class FilterOrderQueueApplications {
  static readonly type = '[OrderQueue] FilterOrderQueue';
  constructor(public applications: any) { }
}

export class CurrentPageSteps {
  static readonly type = '[Create Order] PageSteps';
  list = [];
  constructor(public currentPageSteps) {
    this.list = currentPageSteps;
  }
}

export class ResetState {
  static readonly type = 'Reset State';
  constructor() { }
}

export class CurrentOrderData {
  static readonly type = '[Create Order] orderData';
  currentOrderData;
  constructor(public currentOrderDataa) {
    this.currentOrderData = currentOrderDataa;
  }
}

export class AssignApsUsers {
  static readonly type = '[APS] apsUsers';
}

export class ApsUsers {
  static readonly type = '[APS Members Workload] setting Aps Users';
  list = [];
  constructor(public apsUsers?) {
    this.list = apsUsers;
  }
}

export class ApsUsersRolesList {
  static readonly type = '[APS Members Workload] setting Aps Users Roles List';
  list = [];
  constructor(public apsUsersRoles) {
    this.list = apsUsersRoles;
  }
}

export class FilteredApsUsers {
  static readonly type = '[New Request Queue] getting Aps Users';
  searchString;
  constructor(public userName) {
    this.searchString = userName;
  }
}

export class NBCApplicationsMetrics {
  static readonly type = '[NBC] Load Applications Metrics';
  list = [];
  constructor(public applicationsMetrics) {
    this.list = applicationsMetrics;
  }
}

export class NBCInprogressApplications {
  static readonly type = '[NBC] Load Inprogress Applications';
  list = [];
  constructor(public inprogressApplications) {
    this.list = inprogressApplications;
  }
}

export class NBCVCallDashboard {
  static readonly type = '[NBC] load VCall dashboard data';
  list = [];
  constructor(public nbcVCallDashboardData) {
    this.list = nbcVCallDashboardData;
  }
}

export class LoadNBCVCallDashboardData {
  static readonly type = '[NBC] load VCall dashboard data by loader';
  constructor(public fromDate?: string, public toDate?: string) { }
}

export class LoadNErrors {
  static readonly type = '[Errors] Loading error';
  constructor(public error: any) { }
}

export class ApplicationLogsAndNotes {
  static readonly type = '[APS] load Logs and Notes data';
  list = [];
  applicationTrackerId: number
  constructor(public logsAndNotes, applicationTrackerId: number) {
    this.list = logsAndNotes;
    this.applicationTrackerId = applicationTrackerId;
  }
}

export class FilterAssignedApplications {
  static readonly type = '[AssignedApplications] FilterAssignedApplications';
  constructor(public applications: any) { }
}

export class FilterAssignedApplicationsForFollowupQueue {
  static readonly type =
    '[AssignedApplications] FilterAssignedApplicationsForFollowupQueue';
  constructor(public query: any) { }
}

export class FilterAssignedApplicationsForMyQueue {
  static readonly type =
    '[AssignedApplications] FilterAssignedApplicationsForMyQueue';
  constructor(public query: any) { }
}

export class UserProfileData {
  static readonly type = '[APS] get User Profile';
  list = {};
  constructor(public user) {
    this.list = user;
  }
}
export class FetchNotes {
  static readonly type = '[APS] fetch Notes data';
  id: number;
  constructor(public applicationId) {
    this.id = applicationId;
  }
}

export class SetQuery {
  static readonly type = '[APS] Set Query';
  queryString: string;
  constructor(public query) {
    this.queryString = query;
  }
}

export class SetPageNo {
  static readonly type = '[APS] Set Pagination';
  pageNumber: number;
  constructor(public pageNo) {
    this.pageNumber = pageNo;
  }
}

export class ClearQuery {
  static readonly type = '[APS] clear Pagination';
}

export class SetSpinner {
  static readonly type = 'Intialized Spinner';
  id: number;
  constructor() { }
}
export class ResetSpinner {
  static readonly type = 'Reset Spinner';
  id: number;
  constructor() { }
}

export class LoadSelectedFullApplication {
  static readonly type = '[APS] load full application';
  fullApplication = null;
  constructor(public application: any) {
    this.fullApplication = application;
  }
}

export class LoadEFulfillmnentMetrics {
  static readonly type = '[NBC] load efulfillment metrics';
  year = null;
  constructor(public eventValue?: any) {
    this.year = eventValue;
  }
}

export class InsertRequestCheck {
  static readonly type = '[APS] insert the check request';
  list: {};
  constructor(public check) {
    this.list = check;
  }
}

export class NewOrderLogEntry {
  static readonly type = '[APS] new order log entry';
  payload: NewOrderLogEntryInterface;
  constructor(public data) {
    this.payload = data;
  }
}

export class ToggleOrderLogEntryPin {
  static readonly type = '[APS] pin/unpin order log entry';
  payload: ToggleOrderLogEntryPinInterface;
  constructor(public data) {
    this.payload = data;
  }
}

export class InsertAuditLog {
  static readonly type = '[APS] insert Audit log';
  payload = {};
  constructor(public data) {
    this.payload = data;
  }
}

export class sendSecureEmail {
  static readonly type = '[APS]Send Secure Email';
  form: {};
  attachments;
  dialogRef;
  constructor(form, attachments, dialogRef) {
    (this.form = form),
      (this.attachments = attachments),
      (this.dialogRef = dialogRef);
  }
}

export class GetUnderWriters {
  static readonly type = '[APS] Underwriters List';
}

export class GetPaymentCheck {
  static readonly type = '[APS] Get Payment Check';
}

export class UpdateOrder {
  static readonly type = '[APS] Update Order';
  orderData;
  orderAction;

  constructor(order, orderAction = OrderActionType.UPDATE_ORDER) {
    this.orderData = order;
    this.orderData.UpdatedDate = new Date();
    this.orderAction = orderAction;
    if (orderAction === OrderActionType.CREATE_ORDER) {
      this.orderData.orderCreatedOn = Date.now().toString();
    }
  }
}

export class AddDirtyFlag {
  static readonly type = '[APS] Adding Dirty Flag To Form';
}

export class AssignApplicationsToApsUser {
  static readonly type =
    '[APS WORKLOAD] Assigning applications To APS team member';
  apsMember;
  applications: Application[];
  constructor(public apsTeamMember, public selectedApplications) {
    if (apsTeamMember) {
      this.applications = this.selectedApplications;
      this.apsMember = apsTeamMember;
    }
  }
}
export class UpdateSelectedApplicationDetails {
  static readonly type = 'Update Selected Application Details ';
  selectedApplication;
  constructor(public application) {
    this.selectedApplication = application;
  }
}
export class ApplicationsForFollowUpQueue {
  static readonly type = 'Applications for Followup Queue';
  queryValue: any;
  skipValue: number;
  takeValue: number;
  constructor(public query, skip, take) {
    this.queryValue = query;
    this.skipValue = skip;
    this.takeValue = take;
  }
}

export class UshgNotification {
  static readonly type = 'Notification Triggered';
  notificationData;
  constructor(public notification: NotificationModel) {
    this.notificationData = notification;
  }
}
export class ClearUshgNotification {
  static readonly type = 'Notification Triggered';
}

export class SaveReminderDate {
  static readonly type = '[APS] save reminder date';
  reminderDate;
  constructor(date) {
    this.reminderDate = date;
  }
}

export class GeneratePdf {
  static readonly type = '[APS] GeneratePDF';
  pdfTitle: string;
  constructor(title: TemplateTitle) {
    this.pdfTitle = title;
  }
}

export class LoadCallDurationMetrics {
  static readonly type = '[NBC] load call Duration metrics by section';
  payload;
  constructor(public payloadData?: any) {
    this.payload = payloadData;
  }
}

export class ClearSelectedApplicationDetails {
  static readonly type = '[APS] Clear SelectedApplication Details';
}

export class GetCompanyInsights {
  static readonly type = '[NBC] load Company Insights data by loader';
  constructor(public fromDate?: string, public toDate?: string) { }
}


export class SearchFilters {
  static readonly type = '[APS] Set Search Filters';
  filters
  constructor(public searchFilters) {
    this.filters = searchFilters
  }
}
export class GetProductInsights {
  static readonly type = '[NBC] load Product Insights data by loader';
  constructor(public fromDate?: string, public toDate?: string) { }
}
export class UpdateOrderProcessStatus {
  static readonly type = '[APS] update order Process Status Flag';
  selecetdOrderData;
  constructor(public payloadData?: ProcessStatusPayload) {
    this.selecetdOrderData = payloadData;
  }
}
export class SelectedApplicationDetailsByApplicationId {
  static readonly type = '[APPLICATION  DETAILS BY APPLICATION ID]';
  applicationId: Number;
  appId: Number;
  constructor(public appplicationId: Number, public convertedVersion: string) {
    this.applicationId = appplicationId;
  }
}
export class GetNewRequestStatisticsForApsDashboard {
  static readonly type = '[NBC] Get New Request Statistics For Aps Dashboard';
  constructor(public fromDate?: string, public toDate?: string) { }
}
export class GetCompletedRequestsStatisticsForApsDashboard {
  static readonly type = '[NBC] Get Completed Request Statistics For Aps Dashboard';
  constructor(public fromDate?: string, public toDate?: string) { }
}

export class AuditLogs {
  static readonly type = '[NBC] Get Audit Logs For Aps Dashboard';
  constructor(public fromDate?: string, public toDate?: string) { }
}

export class Orders {
  static readonly type = '[NBC] Get Orders For Aps Dashboard';
  constructor(public fromDate?: string, public toDate?: string) { }
}

export class UWSPoliciesStatus {
  static readonly type = '[NBC] Get UWS Policies Status';
  constructor (public fromDate?:string, public toDate?:string) {}
}

export class LockFollowupApplication {
  static readonly type = '[APS] Unlock Followup Application';
  constructor(public userName: string, applicationId: number) {}
}
export class UpdateOrderProperties {
  static readonly type = '[NBC] Update Order Properties';
  orderProperties;
  constructor (public payloadData ) {
    this.orderProperties = payloadData;
  }
}
export class UpdateOrderProviderPhoneExtension {
  static readonly type = '[NBC] Update Order Provider Phone Extension';
  orderProviderPhoneExtension;
  constructor (public payloadData ) {
    this.orderProviderPhoneExtension = payloadData;
  }
}
export class SelectedApplicationStatusDetails {
  static readonly type = '[SELECTED APPLICATION STATUS DETAILS]';
  appId: Number;
  constructor(public appplicationId: Number, public convertedVersion: string) {
    this.appId = appplicationId;
  }
}
export class PoliciesWithBRCode {
  static readonly type = '[NBC] Get polies With BRCode';
  constructor (public applicationId?:number) {}
}

export class ApplicationStatusHistory {
  static readonly type = '[NBC] Application Status Histories';
  constructor(public fromDate?: string, public toDate?: string) {}
}