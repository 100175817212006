import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

@Component({
  selector: 'app-order-created-model',
  templateUrl: './order-created-model.component.html',
  styleUrls: ['./order-created-model.component.scss'],
  providers:[DialogService]
})
export class OrderCreatedModelComponent implements OnInit {
  currentOrderData = this.store ? this.store.selectSnapshot((state) => state.data.currentOrderData) : {}
  config = {
    width: '30%',
    closable: false,
    showHeader: false,
  };
  title = 'Order Created Successfully';
  public view = 'view';
  buttonsList = [
    {
      label: 'Ok',
      type: 'custom',
      onClick: () => {
        this.service.cancel()
        this.apsService.redirectToBase()

      }
    }
  ];
  constructor(private service?: UshgDialogService, private router?: Router, private apsService?: ApsService, private store?: Store) { }

  ngOnInit(): void {
    this.title = this.currentOrderData.isEditOrder ? "Order Updated Successfully" : this.title;
  }
}
