import { Component, Input, OnInit } from '@angular/core';
import { RequestTypes } from 'src/app/aps/models/enums';

@Component({
  selector: 'app-request-type',
  templateUrl: './request-type.component.html',
  styleUrls: ['./request-type.component.scss'],
})
export class RequestTypeComponent {
  @Input() requestType;
  requestTypes = RequestTypes;
  constructor() {}
}
