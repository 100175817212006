import { Validators } from "@angular/forms";
import { SECURE_EMAIL_TEMPLATES } from "src/app/aps/models/enums";

export const secureEmailConfig = (This) => {
    return [
        {
            formControlName: "template",
            label: "Template",
            formControlType: 'select',
            options: [
                {
                    label: 'LAB', value: SECURE_EMAIL_TEMPLATES.LABORATORY,
                }
            ],
            value: 'LAB',
            placeholder: "LAB",
            validators: [Validators.required],
            disable: true
        },
        {
            formControlName: "company",
            label: "Company",
            value: 'company',
            formControlType: 'text',
            validators: [Validators.required],
            disable: true
        },
        {
            formControlName: "orderContactName",
            label: "Applicant Name",
            formControlType: 'text',
            value: 'orderContactName',
            validationMessage: "This field is required",
            validators: [Validators.required]
        },
        {
            formControlName: "email",
            label: "Applicant Email Address",
            formControlType: 'text',
            value: 'email',
            validationMessage: "Please enter valid Email address",
            validators: [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,8}$")]
        },
        {
            formControlName: "agentName",
            label: "Agent Name",
            formControlType: 'text',
            value: 'agentName',
            validationMessage: "This field is required",
            validators: [Validators.required],
        },
        {
            formControlName: "agentEmailAdress",
            label: "Agent Email Address",
            formControlType: 'text',
            value: 'agentEmailAdress',
            validationMessage: "Please enter valid Email address",
            validators: [Validators.required, Validators.pattern("^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,8}$")],
            onblur: () => {
                This.secureEmailForm.patchValue({ agentEmailAdress: This.secureEmailForm.value.agentEmailAdress.toUpperCase() });
            }
        },
        {
            formControlName: "cc1",
            label: "CC1",
            formControlType: 'text',
        },
        {
            formControlName: "cc2",
            label: "CC2",
            formControlType: 'text',
        },

    ]
}

export const faxDetailsConfig = (This) => {
    return [
        {
            formControlName: "providerFullName",
            label: "Provider Name",
            formControlType: 'text',
            value: 'providerFullName',
            disable: true,
            placeholder: 'Provider Name',
        },
        {
            formControlName: "faxNo",
            label: "Fax No",
            formControlType: 'text',
            value: "providerFax",
            disable: true,
            placeholder: 'Fax Number',
        },

    ]
}