import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Document} from 'src/app/aps/models/ui-models'
import { ApsService } from 'src/app/aps/services/aps-service';
import { ConfirmModalComponent } from 'src/app/aps/components/core/modals/confirm-modal/confirm-modal.component';
import { UshgDialogService } from '../services/ushg-dialog-service';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-ushg-doc',
  templateUrl: './ushg-doc.component.html',
  styleUrls: ['./ushg-doc.component.scss']
})
export class UshgDocComponent implements OnInit {
  @Input() document;
  @Input() SelectedDocument: Document = {};
  @Input() classWidth: any;
  @Input() canDisableDownload: boolean;
  @Input() canDisableDelete: boolean;
  @Input() canDisplayActionIconsVertical: boolean;
  @Input() specialRequestLetter: boolean;
  @Output() deleteDocument = new EventEmitter();
  @Input() deleteIndex: number;
  @Input() canDisplayActionIcons: boolean;
  @Input() upload: boolean;
  @Input() canDisplaySpecialRequestActions: boolean;
  readonly Special_request_Letter = 'Special request Letter.pdf';
  readonly SPECIAL_REQUEST_LETTER_TYPE = 'Special Request Letter';
  timeZone: string = this.store.selectSnapshot((state)=> state.data.userProfile).timeZone;
  
  constructor(private apsService: ApsService, private dialogService: UshgDialogService, private store: Store) { }

  ngOnInit() {
    if (this.document && this.specialRequestLetter) {
      this.document.label = this.Special_request_Letter;
    }
  }

  downloadFile(file) {
    this.apsService.downloadDocument(file.name);
  }

  deleteFile(selectedDocument, index) {
    const compRef = this.dialogService.open(ConfirmModalComponent, selectedDocument, 'Confirmation');
    compRef.onClose.subscribe((res) => {
      if (res) {
        this.deleteDocument.next(index);
      }
      else {
        compRef.close();
      }
    })

  }

   isDocumentEmpty(doc) {
    return doc?Object.keys(doc).length === 0:[];
}

  fileType(file) {
    const fileName = file.name || file.label;
    return fileName?.split('.').pop();
  }

}
