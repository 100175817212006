<div class="p-4 bg-white shadow-3 mb-3">
  <h3 class="mt-0">{{SearchApplicationForm.formHeading}}</h3>
  <form [formGroup]="searchForm" id="searchForm" (submit)="searchApplications()">
    <div class="d-flex">
      <div class="mr-3 search-col">
        <label for="applicationId">{{SearchApplicationForm.applicationNumber}}</label>
        <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchApplications()" pInputText name="applicationId" id="applicationId"
          formControlName="applicationId" autocomplete="off" maxlength="9" numbersOnly class="w-full" />
      </div>
      <div class="mr-3 search-col">
        <label for="firstName">{{SearchApplicationForm.firstName}}</label>
        <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchApplications()" pInputText formControlName="firstName" class="w-full" autocomplete="off" />
      </div>
      <div class="mr-3 search-col">
        <label for="lastName">{{SearchApplicationForm.lastName}}</label>
        <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchApplications()" pInputText formControlName="lastName" class="w-full" autocomplete="off" />
      </div>
      <div class="mr-3 search-col">
        <label for="controlNumber">{{SearchApplicationForm.controlNumber}}</label>
        <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchApplications()" pInputText formControlName="controlNumber" class="w-full"
          autocomplete="off" />
      </div>
      <div class="mr-3 search-col">
        <label for="policyNumber">{{SearchApplicationForm.policyNumber}}</label>
        <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchApplications()" pInputText formControlName="policyNumber" class="w-full"
          autocomplete="off" />
      </div>

      <div class="search-col-submit">
        <span class="mr-2"><ushg-button [label]="'Reset'" [type]="'outline'" (click)="reset()"></ushg-button></span>
        <ushg-button [label]="'Search'" [type]="'primary'" (click)="searchApplications()"
          [disable]="searchForm.invalid">
        </ushg-button>
      </div>
    </div>
  </form>
</div>
<div class="col-12 shadow-3 p-0">
  <ushg-table [headerList]="headersList" [rows]="100" [rowData]="searchApplicationList">
  </ushg-table>
</div>



<div *ngIf="enablePrimaryOrAddonGrid">
  <h3>{{PrimaryOrAddonGrid.heading}}</h3>
  <div class="col-12 shadow-3 p-0">
    <ushg-table [headerList]="subHeadersList" [rows]="10" [actions]="actionsList" [rowData]="detailedApplicationList">
    </ushg-table>
  </div>
</div>
<div class="card p-5" *ngIf="applicationUnavailable">
  <div class="px-5 py-4 bg-yellow-200">
    <div>{{ImportGrid.applicationNotFoundInCosmos}}</div>
    <br />
    <div class="d-flex justify-content-between align-items-center">
      <div>{{ImportGrid.importMessage}}
      </div>
      <div>
        <ushg-button label="Import" [type]="'primary'" (click)="importApplication()" [disable]="searchForm.invalid">
        </ushg-button>
      </div>
    </div>
  </div>
</div>
<div class="progress-spinner" *ngIf="showSpinner">
  <p-progressSpinner></p-progressSpinner>
</div>