/* eslint-disable */
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Dropdown } from 'primeng/dropdown';

@Component({
  selector: 'ushg-drop-down',
  templateUrl: './drop-down.component.html',
  styleUrls: ['./drop-down.component.scss'],
})
export class DropDownComponent implements OnChanges {
  constructor() {}

  @ViewChild('dropdownRef', { static: false }) dropdownRef: Dropdown;
  @Input() selectedValue: string | number;
  @Input() filterByTyping = false;
  @Input() dropDown: DropDownModel;
  @Input() clearDropDown;
  @Input() disabled;
  @Output() select: EventEmitter<string> = new EventEmitter();

  public actionsEvent = (callback: Function, ev) => {
    if (callback) {
      callback(ev);
    }
  };

  ngOnChanges(changes) {
    if (changes?.clearDropDown?.currentValue) {
      this.dropdownRef?.clear(null);
    }
  }

  dropDownChanged(e) {
    this.select.emit(e.value);
  }
}

export interface DropDownModel {
  label: string;
  filterValues: Values[];
  placeholder?: string;
  onSelect?: Function;
  searchFilterVar?: string;
  selectedValue?: string | number;
  toolTip?: string;
}

interface Values {
  name: string;
  value?: string | number;
  onSelect?: Function;
}
