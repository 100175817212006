import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToVCallService {

constructor() { }

openVCallLinkInNewTab(appId: number, tabName: string){
  let redirectLink;
  switch(tabName){
    case 'verification':
      redirectLink = `/worksheet/application/${appId}?tabName=EZApp`;
    break;
    case 'recall':
      redirectLink = `/worksheet/application/${appId}?tabName=Recalls`;
    break;
    case 'additionalInfo':
      redirectLink = `/worksheet/application/${appId}?tabName=APSRequests&viewmode=aps`;
    break;
    case 'underwriting':
      redirectLink = `/worksheet/application/${appId}?tabName=Review`;
    break;
    case 'addOns':
      redirectLink = `/worksheet/application/${appId}?tabName=addOns&viewmode=addOns`;
    break;
  }

    window.open(`${environment.serverUrls.VCall}${redirectLink}`, '_blank');
}

}
