import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { LockStatus } from 'src/app/aps/models/lock-or-release-application';

@Component({
  selector: 'app-ushg-toggle-switch',
  templateUrl: './ushg-toggle-switch.component.html',
  styleUrls: ['./ushg-toggle-switch.component.scss']
})
export class UshgToggleSwitchComponent implements OnChanges {
  @Output() emitEvent = new EventEmitter<any>();
  @Input() lockStatus: string;
  lockControlSwitch: boolean = false;

  constructor() { }

  ngOnChanges() {
    if (this.lockStatus) {
      this.lockControlSwitch = this.lockStatus === LockStatus.LockedByLoggedInUser ? true : false;
    }
  }

  onClick() {
    this.emitEvent.emit(!this.lockControlSwitch);
  }

  get LockStatusModel(): typeof LockStatus {
    return LockStatus;
  }

}
