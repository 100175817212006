<div class="border-bottom p-4 shadow-3 bg-white">
    <div class="container">
    <div class="grid d-flex">
        <div class="col-3">
            <label for="" class="font-bold">Applicant :
            </label>
            <span class="text-lg  uppercase">
                {{details?.orderContactName}}
                <i class="pi pi-info-circle"></i>
            </span>


        </div>
        <div class="col-5 justify-content-center text-center">
            <label for="" class="font-bold">Request Type :
            </label>
            <span class="text-lg">
               <app-request-type [requestType]="details?.orderType?.requestType"></app-request-type>
            </span>
        </div>
        <div class="col-4  text-right">
            <span class="text-lg cursor " (click)="cancelOrderCreation()">
                Cancel
            </span>
        </div>
    </div>
    </div>
</div>
