<div class="grid p-4">
    <div class="col-1"></div>
    <div class="col-10 mb-4">
        <h3 class="font-semibold">
            Route this application to
        </h3>
        <div class="col-12 ml-5">
            <div class="field-radiobutton mb-5">
                <p-radioButton name="routeTo" value="currentUnderWriter" [(ngModel)]="selectedValue"
                    inputId="currentUnderWriter">
                </p-radioButton>
                <label for="currentUnderWriter" class="font-lg font-semibold ml-5">Current Underwriter  ( {{applicationDetails['currentUnderWriter'] }} )</label>
            </div>
            <div class="field-radiobutton mb-5">
                <p-radioButton name="routeTo" value="UnderWriting" [(ngModel)]="selectedValue"
                    inputId="specific">
                </p-radioButton>
                <label for="specific" class="font-lg font-semibold ml-5 mr-5">Specific Name </label>
                <p-dropdown [options]="listOfUnderWriters"  optionLabel="label" placeholder="Select an Underwriter" [ngClass]="{'p-disabled':selectedValue !== 'UnderWriting'}"

                    optionValue="value" [(ngModel)]="selectedUnderWriter"></p-dropdown>
            </div>
            <div class="field-radiobutton mb-5">
                <p-radioButton name="routeTo" value="Issues" [(ngModel)]="selectedValue" inputId="issuesteam"
                    [disabled]='checkPendingOrder'>
                </p-radioButton>
                <label for="city3" class="font-lg font-semibold ml-5" [ngClass]="{'disabled':checkPendingOrder}">Issue
                    Queue</label>
            </div>
            <div class="field-radiobutton ">
                <p-radioButton name="routeTo" value="FollowUp" [(ngModel)]="selectedValue" inputId="FollowUp"
                    [ngClass]="{'disabled':applicationDetails.isFollowup ||  checkPendingOrder }">
                </p-radioButton>
                <label for="FollowUp" class="font-lg font-semibold ml-5"
                    [ngClass]="{'disabled':applicationDetails.isFollowup ||  checkPendingOrder }">FollowUp Queue</label>
            </div>
        </div>




    </div>

    <div class="col-12">
        <app-ushg-dialog-footer [mode]="'custom'" [buttonsList]="buttonsList"></app-ushg-dialog-footer>
    </div>

</div>