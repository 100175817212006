<div class="container">
    <div class="grid pl-3 pr-3">
        <div class="col p-0 flex align-items-center" [class]="detail.class" *ngFor="let detail of detailsToDisplay ">
            <label for="" class="label">{{detail.label}}:</label>
            <span class="inline-flex"  *ngIf="!detail['type']">
              <app-request-type [requestType]="data[detail.value]" *ngIf="detail.label === 'Request Type'; else other_details"></app-request-type>
              <ng-template #other_details>{{data[detail.value] | reverseString:detail.value:'providerName' || "N/A"}}</ng-template>
            </span>
            <span  class="inline-flex"  *ngIf="detail['type'] === 'DOB' ">
                {{data.orderApplicant.birthDate}}
            </span>
            <span  class="inline-flex"  *ngIf="detail['type'] === 'address' ">
                <span *ngIf="data.orderApplicant.applicantstreetAddress !== undefined && data.orderApplicant.applicantstreetAddress !== null">
                    {{data.orderApplicant.applicantstreetAddress}},
                    {{data.orderApplicant.applicantCity}}, {{ data.orderApplicant.contactState }} - {{ data.orderApplicant.applicantzipCode }}
                </span>
            </span>
            <span  class="inline-flex"  *ngIf="detail['type'] === 'phone' ">
                {{data.orderApplicant.applicantHomePhone}}
            </span>
                <ushg-chip styleClass=" inline-flex" [value]=" data[detail.value]"  *ngIf="detail['type']==='OrderStatusId'"  > {{OrderStatus[data[detail.value]]}}  </ushg-chip>
        </div>
    </div>
</div>