import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Application, ProfileModel } from 'src/app/aps/models/application-model';
import { AuditTypeId, NotesEnum, RequestTypes } from 'src/app/aps/models/enums';
import { IDialogAware } from 'src/app/aps/models/IDialogAware';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

import { Store } from '@ngxs/store';
import { FetchNotes, InsertAuditLog } from 'src/app/store/dispatchers';
import { ApsService } from 'src/app/aps/services/aps-service';
import { AuditLog } from 'src/app/aps/models/ui-models';
@Component({
  selector: 'app-add-notes',
  templateUrl: './add-notes.component.html',
  styleUrls: ['./add-notes.component.scss']
})
export class AddNotesComponent implements OnInit, IDialogAware {
  noteForm: UntypedFormGroup;
  applicationDetails: Application;
  config = {
    width: '35%',
    closable: false,
  };
  buttonsList = [
    {
      label: 'Cancel',
      type: 'secondaryoutline',
      onClick: () => {
        this.dialog.cancel();
      }
    },
    {
      label: 'Submit',
      type: 'primarylg',
      disable: 'disable',
      onClick: () => {
        this.onSave();
      }
    },
  ]
  listOfOrders = [{ label: 'Related to the order (Optional)', value: '' }]
  notes = new AuditLog();
  profile: ProfileModel;
  ordersList;

  constructor(
    private dialogService: DynamicDialogConfig,
    private dialog: UshgDialogService,
    private fb: UntypedFormBuilder,
    private store: Store,
    private apsService: ApsService
  ) { }

  ngOnInit() {
    this.profile = this.store.selectSnapshot((state) => state.data.userProfile)
    this.initForm();
    this.applicationDetails = this.dialogService.data;
    this.ordersList = this.applicationDetails['orderDetails'];
    this.notes.applicationTrackerId = this.applicationDetails?.applicationTrackerId || this.applicationDetails['orderDetails'][0].applicationTrackerId || this.applicationDetails['orderTrackers'][0].applicationTrackerId;
    this.ordersList = this.apsService.patchIndividualApplicationDetails(
      this.ordersList,
      this.applicationDetails
    );
    if (this.ordersList.length > 0) {
      this.ordersList.forEach(order => {
        this.listOfOrders.push({ 'label': 'Order ' + order.orderTrackerId + '-' + order.orderContactName + ' (' + RequestTypes[order.requestType] + ')', 'value': order.requestType +'-'+order.orderTrackerId })
      });
    }
    // enable disable submit butto based on the notes input
    this.noteForm.valueChanges.subscribe((resp) => {
      let selectedOrder;
      this.noteForm.valid ?
        delete this.buttonsList[1].disable :
        this.buttonsList[1].disable = "true";

      if (this.noteForm.value.selectOrder !== '' && this.noteForm.value.selectOrder !== null) {
        let selectedValue = this.noteForm.value.selectOrder.split('-');
        selectedOrder = this.ordersList.filter((order) => {
          return (order.requestType === selectedValue[0] && order.orderTrackerId === Number(selectedValue[1]))
          })[0];
        this.notes.auditTypeId = AuditTypeId.ORDER_NOTES;
        this.notes.orderTrackerId = selectedOrder?.orderTrackerId;
      } else {
        this.notes.auditTypeId = AuditTypeId.APPLICATION_NOTES;
      }
    })
  }

  initForm() {
    this.noteForm = this.fb.group({
      notes: ['', Validators.required],
      selectOrder: []
    })
  }

  async onSave() {
    if (this.noteForm.valid) {
      this.notes['notes'] = this.noteForm.value.notes;
      await this.store.dispatch(new InsertAuditLog(new AuditLog(this.notes.auditTypeId, this.notes))).toPromise()
      this.store.dispatch(new FetchNotes(this.applicationDetails.id))
      this.noteForm.reset();
      this.dialog.cancel();
    }
  }
}


