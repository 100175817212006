<div
  class="
    d-flex
    justify-content-around
    align-items-end
    p-4
    mt-2
    bg-white
    shadow-3
  "
>
  <div class="mr-3">
    <label>Application ID</label>
    <input 
      id="float-input"
      type="text"
      pInputText
      maxlength="8"
      (keyup.enter)="search()"
      [(ngModel)]="appId"
      numbersOnly
    />
  </div>
  <div class="mr-3">
    <label>Control No</label>
    <input
      id="float-input"
      type="text"
      (keyup.enter)="search()"
      pInputText
      [(ngModel)]="controlNo"
    />
  </div>
  <div class="mr-3">
    <label for="icon">Recieved From</label>
    <p-calendar
      [showIcon]="true"
      inputId="icon"
      [maxDate]="today"
      [(ngModel)]="fromDate"
      [readonlyInput]="true"
    >
    </p-calendar>
  </div>
  <div class="mr-3">
    <label for="icon">Recieved To</label>
    <p-calendar
      [showIcon]="true"
      inputId="icon"
      [maxDate]="today"
      [(ngModel)]="toDate"
      [readonlyInput]="true"

    >
    </p-calendar>
  </div>
  <ng-container *ngIf="showDropDownFilter">
    <div class="mr-3 col-2 p-0" *ngFor="let dropDown of dropDownFilterList">
      <label for="icon">{{ dropDown.label }}</label>
      <ushg-drop-down
        [dropDown]="dropDown"
        [selectedValue]="dropDown.selectedValue"
        (select)="dropDownSelected($event, dropDown.searchFilterVar,dropDown)"
        [clearDropDown]="dropDown.clearDropDown"
      ></ushg-drop-down>
    </div>
  </ng-container>
  <ng-container *ngIf="showDropDownView">  
  <div class="mr-3">
    <label for="icon">View</label>
    <ushg-drop-down
      [dropDown]="lastNameFilter"
      [clearDropDown]="clearDropDown"
    ></ushg-drop-down>
  </div>
</ng-container>
  <div class="d-flex">
    <div class="mr-2">
      <ushg-button
        [label]="'Reset'"
        [type]="'outline'"
        (click)="reset()"
      ></ushg-button>
    </div>
    <div>
      <ushg-button
        [label]="'Search'"
        [type]="'primary'"
        (click)="search()"
      ></ushg-button>
    </div>
  </div>
</div>
