import { Injectable } from "@angular/core";
import { OrderStatus } from "../../models/enums";

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor() {}

  processOrderByStatus(orderDetails) {
    if (orderDetails?.length > 0) {
      orderDetails.map((eachOrder) => {
        if (eachOrder?.orderTrackers?.length > 0) {
          eachOrder?.orderTrackers.forEach((orderTracker) => {
            if (eachOrder.orderTrackerId == orderTracker.id) {
              if (orderTracker.orderStatusId == 3) {
                let curDate = new Date().getTime();
                let closeOutDate = new Date(
                  orderTracker?.closeOutStartDate
                ).getTime();
                let withDrawalDate = new Date(
                  orderTracker?.withDrawalStartDate
                ).getTime();
                if (curDate < closeOutDate) {
                  eachOrder.orderStatusId = OrderStatus.Pending;
                } else if (curDate > closeOutDate && curDate < withDrawalDate) {
                  eachOrder.orderStatusId = OrderStatus.CloseOut;
                } else if (curDate > withDrawalDate) {
                  eachOrder.orderStatusId = OrderStatus.Withdrawal;
                }
              }
            }
          });
        }
      });
    }
    return orderDetails;
  }
}

