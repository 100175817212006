import { Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr"
import { environment } from "src/environments/environment";
import { MessageHandlerService } from "src/app/aps/services/message-handler.service";
import { MsalService } from "@azure/msal-angular";
import { AuthenticationResult } from "@azure/msal-browser";
import { AuthService } from "./auth.service";


@Injectable({
    providedIn: 'root'
})

export class SignalrService {
    private hubConnection: signalR.HubConnection;
    constructor(
        private messageService: MessageHandlerService,
        private authService: AuthService
    ) {}

    public startConnection = async () => {
        const token = await this.authService.getAccessToken();
        let base = this;
        this.hubConnection = await new signalR.HubConnectionBuilder()
        .withUrl(`${environment.serverUrls.nbcApiURl}apshub`, {
            withCredentials: false,
            accessTokenFactory: () => token
        }).build();
        this.hubConnection.start().then(() => {
          console.log('connection started');
          this.addDataListener(base);
        }).catch(err => console.log('error while starting connection' + err));
    }

    public addDataListener = (base) => {
        // Client-side method to handle messages from the server
        if (base.hubConnection) {
            base.hubConnection.on('sendclientsmessage', (appProperties) => {
              this.messageService.handleMessage(appProperties);
            });
          } else {
            console.error('Hub connection is not initialized.');
          }
    }
    
    public invokeCall = (invokeMethod: string, payload: any, successMessage: string): Promise<void> => {
        return this.hubConnection.invoke(invokeMethod, payload).then(res => {
            this.messageService.handleSuccessMessage(successMessage);
            return res;
        }).catch(err => console.error(err));
    }
}