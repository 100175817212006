import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TemplateTitle } from 'src/app/aps/models/enums';
import { NavigationService } from 'src/app/aps/services/aps-navigation-service';
import { ApsService } from 'src/app/aps/services/aps-service';
import { formConfig } from 'src/app/shared/models/formconfig-model';
import { Navigation } from 'src/app/shared/services/navigation';
import { UshgFormService } from 'src/app/shared/services/ushg-form-service';
import {
  CurrentOrderData,
  RequestOrderPdf,
  ResetRequestOrderPdf,
} from 'src/app/store/dispatchers';
import {
  detailsToDisplay,
  labletterNotes,
  requestLettersFormConfig,
  requestLettersFormConfigForHW,
} from './request-letters-form-config';

@Component({
  selector: 'ushg-request-letters',
  templateUrl: './request-letters.component.html',
  styleUrls: ['./request-letters.component.scss'],
})
export class RequestLettersComponent
  extends Navigation
  implements OnInit, OnDestroy {
  requestLettersDetails = this.store.selectSnapshot(
    (state) => state.data.currentOrderData
  );
  labLetterPdf = this.store.selectSnapshot(
    (state) => state.data.requestOrderPdf
  );

  requestLettersForm: UntypedFormGroup;
  checked = true;
  requestLettersFormConfig: formConfig[] = requestLettersFormConfig;
  requestLettersFormConfigForHW: formConfig[] = requestLettersFormConfig;
  detailsToDisplay = detailsToDisplay;
  labNotesConfig = labletterNotes;
  requests: any;
  canDisplayLabNotesModal: boolean;
  selectedControl: AbstractControl;

  constructor(
    private navigation: NavigationService,
    private form: UshgFormService,
    private router: Router,
    private apsService: ApsService,
    private store: Store,
    private fb: UntypedFormBuilder
  ) {
    super(navigation);
  }

  ngOnInit(): void {
    this.initForm();
    this.concateRequestTypes(); 
    this.getAgentNotes();
    this.checkAgentNotesValid();
  }

  concateRequestTypes() {
    this.requestLettersDetails['requestTypes'] =
      this.requestLettersDetails.labs.reduce((acc, current) => {
        acc.push(current.name);
        return acc;
      }, []);
  }

  initForm() {
    console.log(this.requestLettersDetails.orderDocuments)
    this.requests = JSON.parse(JSON.stringify(this.requestLettersDetails.labs));
    this.requestLettersForm = this.fb.group({
      tests: this.fb.array([]),
      labNotes: null,
      agentNotes: ['', Validators.required],
    });
    this.controlCreateOrderForm();
    this.requests.forEach((element) => {
      this.onLoadenableTestNotes(element);
    });
  }

  // created the form with config list
  controlCreateOrderForm() {
    if (this.requestLettersDetails.requestType === 'LAB_AE_HTWTBP') {
      this.requestLettersFormConfig = requestLettersFormConfigForHW;
      this.requestLettersFormConfig.forEach((labControl) => {
        this.labTests.push(this.createFormGroup(labControl));
      });
    } else {
      this.requestLettersFormConfig.forEach((labControl) => {
        this.labTests.push(this.createFormGroup(labControl));
      });
    }
    this.requestLettersForm.controls['tests'].setValidators(
      this.minSelectedCheckboxes()
    );
    this.labTests.controls.forEach((control) => {
      control['controls'].notes.disable();
      control['controls'].notes.setValidators([Validators.required]) &&
        control['controls'].notes.updateValueAndValidity()

    });
    this.requestLettersForm.controls['tests'].updateValueAndValidity();
  }

  minSelectedCheckboxes(min = 1) {
    const validator: ValidatorFn = (formArray: UntypedFormArray) => {
      const totalSelected = formArray.controls.filter(
        (control) => control.value.status === true
      ).length;
      if (totalSelected > 0) {
        return null;
      }
      return totalSelected >= min ? null : { required: true }; // if the total is not greater than the minimum, return the error message
    };
    return validator;
  }

  // lab tests form Array groups
  createFormGroup(control): UntypedFormGroup {
    return this.fb.group({
      name: control !== undefined ? control.label : null,
      notes: '',
      status: false,
      groupName: control !== undefined ? control.groupName : null,
      rows: control !== undefined ? control.rows : 7,
      columns: control !== undefined ? control.columns : 55,
      formControlWidth: control !== undefined ? control.formControlWidth : '',
    });
  }

  get labTests(): UntypedFormArray {
    return <UntypedFormArray>this.requestLettersForm.get('tests');
  }

  // on load check the values notes and status
  onLoadenableTestNotes(controlCheck) {
    this.labTests.controls.forEach((control) => {
      if (controlCheck.name === control['controls'].name.value) {
        control['controls'].notes.setValue(controlCheck.notes);
        control['controls'].status.setValue(controlCheck.status);
        controlCheck.status === true
          ? control['controls'].notes.enable()
          : control['controls'].notes.disable();
      }
    });
    this.requestLettersForm.controls['tests'].setValidators(
      this.minSelectedCheckboxes()
    );
    this.bindLabNotes(controlCheck);
  }

  enableTestNotes(controlCheck) {
    this.selectedControl = controlCheck;
    // to enable selected checkbox text notes
    this.labTests.controls.forEach((control) => {
      if (controlCheck.value.name === control['controls'].name.value) {
        controlCheck.value.status === false
          ? control['controls'].notes.enable()
          : control['controls'].notes.disable();
      }

      // enable disable based on the selection groups
      if (
        controlCheck.value.status === false &&
        controlCheck.value.groupName !== control['controls'].groupName.value
      ) {
        control['controls'].status.setValue(false);
        control.value.status = false;
        control['controls'].notes.disable();
      }
      this.requestLettersForm.controls['tests'].setValidators(
        this.minSelectedCheckboxes()
      );
      return control;
    });
    // Initially it load the test notes based on selected test
    // when we move back and forth and update the notes it will do the comparision if it's not same
    // It will display popup for resetting the data
    // In the second condition is not to trigger for checkbox false event
    if (
      this.compareLabNotes(
        this.requestLettersDetails.labNotes,
        this.bindLabNotesFromConfig(controlCheck.value)
      ) &&
      !JSON.parse(this.selectedControl.value.status)
    ) {
      this.canDisplayLabNotesModal = true;
    }
  }

  getNotesChange(control, index) {
    this.labTests.setControl(index, control);
    this.getAgentNotes();
  }

  getAgentNotes() {
    // combining all the entered test notes into one string and deviding with break for each test
    let agentPortalNotes = '';
    this.labTests.value.forEach((test) => {
      if (test.notes && test.name) {
        agentPortalNotes += `${test.name}: ${test.notes} \n`;
      }
    });
    this.requestLettersForm.controls.agentNotes.setValue(agentPortalNotes);
  }

  bindLabNotes(control) {
    this.requestLettersForm.controls.labNotes.setValue(
      this.configLabNotes(control)
    );
  }

  configLabNotes(control) {
    // If notes was already there in the store just prefil that
    // otherwise else case will work and bind it from the config

    let testDescription;
    if (this.requestLettersDetails.labNotes) {
      testDescription = this.requestLettersDetails.labNotes;
    } else {
      testDescription = this.bindLabNotesFromConfig(control);
    }
    return testDescription;
  }

  bindLabNotesFromConfig(control) {
    // more than two tests are selected default notes will be triggered
    // otherwise it just filters based on selected test control
    this.checkAgentNotesValid();
    return this.requests.length > 1
      ? this.labNotesConfig.filter((notes) => notes.default === true)[0].notes
      : this.labNotesConfig.filter((lab) => {
        return Array.isArray(lab.name) && lab.name.includes(control.name)
          ? lab
          : lab.name === control.name
            ? lab
            : null;
      })[0].notes;
  }

  checkAgentNotesValid() {
    this.requestLettersForm.get('agentNotes').valueChanges.subscribe((resp) => {
      if (resp === '\n') {
        this.requestLettersForm.controls.agentNotes.setValue('');
      }
    });
  }

  /**
   *
   * @param prev saved notes in ngxs store
   * @param current prefilled notes from config
   * @returns if both matches returns false otherwise returns true
   */
  compareLabNotes(prev: string, current: string) {
    const prevNotes =
      prev && prev.toLocaleLowerCase().split('').reverse().join('');
    const currentNotes =
      current && current.toLocaleLowerCase().split('').reverse().join('');
    return prevNotes === currentNotes ? false : true;
  }

  keepExistingNotes() {
    this.initForm();
    this.canDisplayLabNotesModal = false;
  }

  overrideToDefault() {
    this.requestLettersForm.controls.labNotes.setValue(
      this.bindLabNotesFromConfig(this.selectedControl.value)
    );
    this.canDisplayLabNotesModal = false;
  }

  nextButtonEventHandler(ev) {
    this.requestLettersDetails.labs =
      this.requestLettersForm.value.tests.filter((test) => test.status);
    this.requestLettersDetails.labsNotes =
      this.requestLettersForm.controls.labNotes.value;
    this.requestLettersDetails.labNotes =
      this.requestLettersForm.controls.labNotes.value;
    this.requestLettersDetails.orderDetails.agentNotes =
      this.requestLettersForm.controls.agentNotes.value;
    this.store.dispatch(new CurrentOrderData(this.requestLettersDetails));
    const labLetterPdf = this.store.selectSnapshot((state) => state.data.requestOrderPdf)
    const currentOrderData = this.store.selectSnapshot((state) => state.data.currentOrderData);

      this.generateLabLetterPdf();
    this.router.navigate(['aps/create-order/generate-pdf'], {
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy() {
    console.log(this.requestLettersDetails.orderDocuments,'')
    this.store.dispatch(new CurrentOrderData(this.requestLettersDetails));
  }

  generateLabLetterPdf() {
    this.apsService.generatePdf(this.requestLettersDetails.requestType !== 'LAB_AE_HTWTBP' ? TemplateTitle.LABLETTER : TemplateTitle.HTWTBP);
  }


}
