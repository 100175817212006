import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { Dropdown } from 'primeng/dropdown';
import { lastNamesFilter } from '../constants/filter-constants';
import { DropDownModel } from '../drop-down/drop-down.component';

@Component({
  selector: 'ushg-search-filter',
  templateUrl: './search-filter.component.html',
  styleUrls: ['./search-filter.component.scss'],
})
export class SearchFilterComponent implements AfterViewInit {
  constructor(private store: Store) { }

  apsUsers = this.store.selectSnapshot((state) => state.data.apsUsers);
  @ViewChild('dropdownRef', { static: false }) dropdownRef: Dropdown;
  @Input() applicationFilters: any;
  @Input() showDropDownFilter = false;
  @Input() dropDownFilterList = [];
  @Input() showDropDownView = false;
  @Output() searchEventHandler = new EventEmitter();
  @Output() resetEventHandler = new EventEmitter();
  @Output() selectEventHandler = new EventEmitter();

  public clearDropDown = false;

  public fromDate;
  public toDate;
  public appId;
  public assignee;
  public status;
  public controlNo;
  today = new Date();

  ngAfterViewInit(): void {
    for (const key in this.applicationFilters) {
      if (
        (key === 'fromDate' || key === 'toDate') &&
        this.applicationFilters[key]
      ) {
        this[key] = new Date(this.applicationFilters[key]);
      } else {
        this[key] = this.applicationFilters[key];
      }
    }
  }

  getAllFieldValues() {
    let dropDownFilterValues = {};

    for (const key of this.dropDownFilterList) {
      dropDownFilterValues[key.searchFilterVar] = this[key.searchFilterVar]
    }

    const formValues = {
      assignee: this.setEmptyStringIfFalsy(this.assignee),
      appId: this.setEmptyStringIfFalsy(this.appId),
      toDate: this.setEmptyStringIfFalsy(this.toDate),
      fromDate: this.setEmptyStringIfFalsy(this.fromDate),
      controlNo: this.setEmptyStringIfFalsy(this.controlNo)
    };

    const allFormValues = Object.assign(dropDownFilterValues, formValues);

    return allFormValues;
  }

  search() {
    const allFormValues = this.getAllFieldValues();
    this.searchEventHandler.next(allFormValues);
    this.clearDropDownValue(false);
  }

  reset = () => {
    this.fromDate = '';
    this.toDate = '';
    this.appId = '';
    this.clearDropDown = true;
    this.controlNo = null;
    this.clearDropDownValue(true);
    const allFormValues = this.getAllFieldValues();
    this.resetEventHandler.next(allFormValues);
  };

  dropDownSelected(ev, relatedVariable,dropDown) {
    this[relatedVariable] = ev;
    dropDown.clearDropDown = false
    this.selectEventHandler.next(ev);
  }

  clearDropDownValue(condition: Boolean) {
    this.dropDownFilterList = this.dropDownFilterList.map((dropDown) => {
      dropDown.clearDropDown = condition;
      return dropDown;
    })
  }

  lastNameFilter: DropDownModel = {
    label: 'View',
    placeholder: 'All Files',
    filterValues: lastNamesFilter,
    onSelect: (data) => { },
  };

  setEmptyStringIfFalsy(data) {
    return data ? data : ""
  }
}
