<div class="container p-4 mt-3 d-flex justify-content-end w-100">
    <ushg-button [type]="'outline'" (click)="backButtonEventHandler()" [disable]="isBackButtonDisabled"
        [ngClass]="{'visibility': isBackButtonHide}"><i class="pi pi-chevron-left mr-2 mt-1"></i> Back
    </ushg-button>
    <ushg-button [type]="'primarylg'" (click)="nextButtonEventHandler()" [disable]="isNextButtonDisabled" class="ml-2" [ngClass]="{'disabled':isNextButtonDisabled}" >
        <ng-container *ngIf="!isLastStep else lastStep">
            Next
            <i class="pi pi-chevron-right ml-2 mt-1"></i>
        </ng-container>
        <ng-template #lastStep>
            <ng-container [ngSwitch]="true">
                <span class="ml-0" *ngSwitchCase="currentOrderData.requestType==='APS'&& !currentOrderData.isEditOrder">
                    Complete
                </span>
                <span class="ml-2" *ngSwitchCase="currentOrderData.requestType==='APS'&& currentOrderData.isEditOrder">
                    Save
                </span>
                <span class="ml-2" *ngSwitchDefault>
                    Send
                </span>
            </ng-container>
        </ng-template>
    </ushg-button>
</div>
