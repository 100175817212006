import { KeyValue } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Permission } from '../models/permission';
import { UserProfileService } from './user/user-profile.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  SupportedRoles = environment.config.adRoles.split(',').map((r) => r.trim());

  PermissionMatrix: Array<KeyValue<string, Permission[]>> = [
    {
      key: 'DEV_VCall2_HQSysAdmin',
      value: [
        Permission.CanAccessApplicationAssignemnt,
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessNewRequestQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAccessTeamWorkLoad,
        Permission.CanAccessWorkQueue,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanPerformActionsOnOrderModal,
        Permission.CanAnswerCall,
        Permission.CanAddOrEditDocument,
        Permission.CanChangeOrderStatus,
        Permission.CanAccessRequestCheck,
        Permission.CanViewOrderDetails,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
      ],
    },
    {
      key: 'USHG_APS2_SysAdmin_PRD',
      value: [
        Permission.CanAccessApplicationAssignemnt,
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessNewRequestQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAccessTeamWorkLoad,
        Permission.CanAccessWorkQueue,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanAnswerCall,
        Permission.CanPerformActionsOnOrderModal,
        Permission.CanAddOrEditDocument,
        Permission.CanChangeOrderStatus,
        Permission.CanAccessRequestCheck,
        Permission.CanViewOrderDetails,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
        
      ],
    },
    {
      key: 'USHG_APS2_Supervisor_TST',
      value: [
        Permission.CanAccessApplicationAssignemnt,
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessNewRequestQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAccessTeamWorkLoad,
        Permission.CanAccessWorkQueue,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanAnswerCall,
        Permission.CanPerformActionsOnOrderModal,
        Permission.CanAddOrEditDocument,
        Permission.CanChangeOrderStatus,
        Permission.CanAccessRequestCheck,
        Permission.CanViewOrderDetails,
        Permission.CanAccessToITHelpCenter,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
      ],
    },
    {
      key: 'USHG_APS2_MatchupMember_PRD',
      value: [
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessNewRequestQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAccessTeamWorkLoad,
        Permission.CanAccessWorkQueue,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanAnswerCall,
        Permission.CanPerformActionsOnOrderModal,
        Permission.CanAddOrEditDocument,
        Permission.CanChangeOrderStatus,
        Permission.CanAccessRequestCheck,
        Permission.CanViewOrderDetails,
      ],
    },

    {
      key: 'USHG_APS2_TeamMember_TST',
      value: [
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanAnswerCall,
        Permission.CanPerformActionsOnOrderModal,
        Permission.CanAddOrEditDocument,
        Permission.CanChangeOrderStatus,
        Permission.CanAccessRequestCheck,
        Permission.CanViewOrderDetails,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
      ],
    },
    {
      key: 'USHG_APS2_FieldSupport_PRD',
      value: [
        Permission.CanAccessApplicationDetails,
        Permission.CanViewOrderDetails,
      ],
    },
    {
      key: 'USHG_APS2_FieldSupport_TST',
      value: [
        Permission.CanAccessApplicationDetails,
        Permission.CanViewOrderDetails,
      ],
    },
    {
      key: 'AZU_APS2_USER_SysAdmin_PRD',
      value: [
        Permission.CanAccessApplicationAssignemnt,
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessNewRequestQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAccessTeamWorkLoad,
        Permission.CanAccessWorkQueue,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanAnswerCall,
        Permission.CanPerformActionsOnOrderModal,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
      ],
    },
    {
      key: 'USHG_APS2_Supervisor_PRD',
      value: [
        Permission.CanAccessApplicationAssignemnt,
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessNewRequestQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAccessTeamWorkLoad,
        Permission.CanAccessWorkQueue,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanAnswerCall,
        Permission.CanPerformActionsOnOrderModal,
        Permission.CanAccessToITHelpCenter,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
      ],
    },
    {
      key: 'AZU_APS2_USER_MatchupMember_PRD',
      value: [
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessNewRequestQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAccessTeamWorkLoad,
        Permission.CanAccessWorkQueue,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanAnswerCall,
        Permission.CanPerformActionsOnOrderModal,
      ],
    },

    {
      key: 'USHG_APS2_TeamMember_PRD',
      value: [
        Permission.CanAccessApplicationStatus,
        Permission.CanAccessAttachDocuments,
        Permission.CanAccessCreateOrder,
        Permission.CanAccessDocumentGeneration,
        Permission.CanAccessFollowUp,
        Permission.CanAccessMyQueue,
        Permission.CanAccessRouteApplication,
        Permission.CanAddReminderDate,
        Permission.CanEditOrderAndRequestCheck,
        Permission.CanAnswerCall,
        Permission.CanPerformActionsOnOrderModal,
        Permission.MembershipVCallRead,
        Permission.MembershipRecallRead,
      ],
    },
    {
      key: 'AZU_APS2_USER_FieldSupport_PRD',
      value: [
        Permission.CanAccessApplicationDetails,
        Permission.CanViewOrderDetails,
      ],
    },
    /****Added the below groups to check field support functionality */
    {
      key: 'PRD_VCall2_ReadOnly',
      value: [
        Permission.CanAccessApplicationDetails,
        Permission.CanViewOrderDetails,
      ],
    },
    {
      key: 'TST_VCall2_ReadOnly',
      value: [
        Permission.CanAccessApplicationDetails,
        Permission.CanViewOrderDetails,
      ],
    },
    {
      key: 'DEV_VCall2_ReadOnly',
      value: [
        Permission.CanAccessApplicationDetails,
        Permission.CanViewOrderDetails,
      ],
    },
    /****Added the below groups to check field support functionality */
  ];
  UserRoles: string[];
  serializedPermissions = null;

  constructor(
    private userProfileService: UserProfileService,
    private router: Router
  ) {
    this.userProfileService.Roles.subscribe((roles) => {
      this.UserRoles = roles;
    });
  }

  IsPermitted(permission: Permission): Observable<boolean> {
    return new Observable<boolean>((s) => {
      this.userProfileService.Roles.subscribe((roles) => {
        if (roles && roles.length > 0) {
          const appliedRoles = roles.filter((r) =>
            this.SupportedRoles.includes(r)
          );

          const result = appliedRoles.some((role) =>
            this.PermissionMatrix.some(
              (m) => m.key === role && m.value.includes(permission)
            )
          );

          s.next(result);
        } else {
          // this.router.navigate(['not-authorized'])
        }
      });
    });
  }

  PermissionSnapShotSerialized(): string {
    if (this.serializedPermissions) {
      return this.serializedPermissions;
    }

    const userRoles = this.UserRoles.filter((r) =>
      this.SupportedRoles.includes(r)
    );
    let permissions = [];
    let result = [];
    userRoles.map(
      (r) =>
        (permissions = permissions.concat(
          this.PermissionMatrix.find((i) => i.key === r)
        ))
    );

    // only keeping distinct values.
    permissions.map((p) =>
      p?.value.map((v) => {
        if (!result.find((r) => r === v)) {
          result.push(v);
        }
      })
    );

    this.serializedPermissions = result.join(',');

    return this.serializedPermissions;
  }
}
