export const providerDetailsConfig = [
    {
        formControlName: 'name',
        label: "Name",
        value: "name",
        formControlType: 'text',
        class: "col-4 p-1",
        placeholder: 'Provider Name',
        required: 'true'

    },

    {
        formControlName: 'street_address',
        label: "Address",
        value: 'street_address',
        formControlType: 'text',
        class: 'col-7 p-1',
        placeholder: 'Address'

    },
    {
        formControlName: 'city',
        label: "City",
        value: 'city',
        formControlType: 'text',
        class: 'col-4 p-1',
        placeholder: 'City'


    },
    {
        formControlName: 'state',
        label: "State",
        value: 'state',
        formControlType: 'text',
        class: 'col-4 p-1',
        placeholder: 'State'



    },
    {
        formControlName: 'zipcode',
        label: "ZIP",
        value: 'zipcode',
        formControlType: 'text',
        class: 'col-3 p-1',
        placeholder: 'zip code'



    },
    {
        formControlName: 'phone_numbers',
        label: "Phone",
        value: 'phone_numbers',
        formControlType: 'text',
        class: 'col-4 p-1',
        placeholder: "(000) 000-0000"
    },

    {
        formControlName: "tax_id",
        label: "Tax ID",
        value: 'tax_id',
        formControlType: 'text',
        class: "col-4 p-1",
        placeholder:"Enter Tax ID",
    },
    // {
    //     formControlName: 'fax',
    //     label: "Fax",
    //     value: 'fax',
    //     formControlType: 'text',
    //     class: 'col-4 p-1',
    //     placeholder:'(000) 000-0000'


    // },
]