import { Injectable } from "@angular/core";
import { NavigationService } from "src/app/aps/services/aps-navigation-service";

@Injectable({
    providedIn: 'root'
})
export class Navigation {
      constructor(public navigationService?: NavigationService) {
    }

    backButtonEventHandler = (ev) => {
        this.navigationService.previouStep()
    }

    nextButtonEventHandler(ev) {
        this.navigationService.nextStep()
    }
}