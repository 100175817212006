<div class="flex justify-content-center">
    <div>
        Document Preview
    </div>
</div>
<div class="flex m-0">
    <div class="card pdf-div col-12">
        <iframe width="100%" height="772" [src]="pdfUrl|safe:'resourceUrl'"></iframe>
    </div>
</div>
<div class="flex justify-content-center">
    <ushg-button [label]="'Cancel'" [type]="'secondaryoutline'" (click)="close();"></ushg-button>
</div>