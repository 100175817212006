export const processStatusColors = [
  { id: 'lightgrey', key: 'New_Files', value: 'lightgrey' },
  { id: 'red', key: 'Attention', value: 'red' },
  { id: 'black', key: 'Appointment_Met', value: 'black' },
  { id: 'green', key: 'In_Progress', value: 'green' },
  { id: 'purple', key: 'New', value: 'purple' },
  { id: 'blue', key: 'Paid', value: 'blue' },
  { id: 'orange', key: 'Verify', value: 'orange' },
  { id: 'yellow', key: 'Waiting', value: 'yellow' },
];
