import { Component, Input, OnInit } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  providers: [MessageService],
})
export class ErrorComponent {
  @Input() data: any;
  constructor() {}
}
