<div class="custom-card applicant-card">
  <table cellspacing="0" cellpadding="0">
    <tr class="heading-row">
      <th *ngFor="let heading of headersList" class="heading-label">
        {{ heading.label }}
      </th>
    </tr>
    <ng-container *ngFor="let data of ordersList; index as i">
      <tr class="orderList">
        <td>{{ data.orderContactName }}</td>
        <td>
          <app-request-type [requestType]="data.requestType"></app-request-type>
        </td>
        <td>{{ data.providerName }}</td>
        <td>{{ data.notes }}</td>
        <td>{{ data.orderTrackerId }}</td>
        <!-- <td>{{ data.applicationStatus.name }}</td> -->
        <td>
          <div
            *ngFor="let document of data.orderDocuments?.documents"
            class="document d-flex justify-content-center px-5 mb-2"
          >
            {{ document.name }}
          </div>
        </td>
        <td>
          <div class="buttons">
            <!-- <button class="custom-button py-1 mt-2">Follow Up</button> -->
            <button class="outline-button py-2 mt-2">Edit</button>
            <!-- <button class="custom-button py-1 my-2">Request Check</button> -->
          </div>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
