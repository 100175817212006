import * as _ from 'lodash';

export const lastNamesFilter = [
  {
    name: 'All Files',
    value: 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  },
  {
    name: 'A-B',
    value: 'AB',
  },
  {
    name: 'C-E',
    value: 'CDE',
  },
  {
    name: 'F-H',
    value: 'FGH',
  },
  {
    name: 'I-L',
    value: 'IJKL',
  },
  {
    name: 'M-P',
    value: 'MNOP',
  },
  {
    name: 'Q-S',
    value: 'QRS',
  },
  {
    name: 'T-Z',
    value: 'TUVWXYZ',
  },
];

export const typeFilter = [
  {
    name: 'APS',
    value: 'APS',
  },
  {
    name: 'Lab-OE',
    value: 'LAB_OE',
  },
  {
    name: 'Lab-AE',
    value: 'LAB_AE',
  },
  {
    name: 'HTWTBP',
    value: 'LAB_AE_HTWTBP',
  },
];

export const orderByFilter = [

  {
    name: 'Due Date',
    value: 'dueDate',
  },
  {
    name: 'Reminder Date',
    value: 'reminderDate',
  },
];

export const orderByFilterWithSorting = [
  {
    name: 'Due Date &#9650;',
    value: 'dueDate_asc',
  },
  {
    name: 'Due Date &#9660;',
    value: 'dueDate_desc',
  },
  {
    name: 'Created Date &#9650;',
    value: 'createdDate_asc',
  },
  {
    name: 'Created Date &#9660;',
    value: 'createdDate_desc',
  },

  {
    name: 'Followup Date &#9650;',
    value: 'followUpDate_asc',
  },
  {
    name: 'Followup Date &#9660;',
    value: 'followUpDate_desc',
  },
  {
    name: 'Reminder Date &#9650;',
    value: 'reminderDate_asc',
  },
  {
    name: 'Reminder Date &#9660;',
    value: 'reminderDate_desc',
  },
];

export const timeInQueueFilter = [
  {
    name: '1 day',
    value: '1 day',
  },
  {
    name: '2 day',
    value: '2 day',
  },
  {
    name: '3 day',
    value: '3 day',
  },
];

export const FollowUpFilter = [
  {
    name: 'Old',
    value: 'Old',
  },
  {
    name: 'New',
    value: 'New',
  },
  {
    name: 'Immediate',
    value: 'Immediate',
  },
  {
    name: 'Next 2 days',
    value: 'Next 2 days',
  },
];

export const StatusFilter = [
  {
    name: 'Pending',
    value: 'Pending',
  },
  {
    name: 'Order Queue',
    value: 'Order_Queue',
  },
];

export const StatusFilterWorkQueue = [
  {
    name: 'All',
    value: null,
  },
  {
    name: 'Follow Up',
    value: 'APSPending_FollowUp',
  },
  {
    name: 'Pending',
    value: 'Pending',
  },
  {
    name: 'Order Queue',
    value: 'Order_Queue',
  },

];

export const StatesFilter = [
  {
    name: 'AL',
    value: 'AL',
  },
  {
    name: 'AK',
    value: 'AK',
  },

  {
    name: 'AZ',
    value: 'AZ',
  },
  {
    name: 'AR',
    value: 'AR',
  },
  {
    name: 'CA',
    value: 'CA',
  },
  {
    name: 'CO',
    value: 'CO',
  },
  {
    name: 'CT',
    value: 'CT',
  },
  {
    name: 'DE',
    value: 'DE',
  },

  {
    name: 'FL',
    value: 'FL',
  },
  {
    name: 'GA',
    value: 'GA',
  },

  {
    name: 'HI',
    value: 'HI',
  },
  {
    name: 'ID',
    value: 'ID',
  },
  {
    name: 'IL',
    value: 'IL',
  },
  {
    name: 'IN',
    value: 'IN',
  },
  {
    name: 'IA',
    value: 'IA',
  },
  {
    name: 'KS',
    value: 'KS',
  },
  {
    name: 'KY',
    value: 'KY',
  },
  {
    name: 'LA',
    value: 'LA',
  },
  {
    name: 'ME',
    value: 'ME',
  },

  {
    name: 'MD',
    value: 'MD',
  },
  {
    name: 'MA',
    value: 'MA',
  },
  {
    name: 'MI',
    value: 'MI',
  },
  {
    name: 'MN',
    value: 'MN',
  },
  {
    name: 'MS',
    value: 'MS',
  },
  {
    name: 'MO',
    value: 'MO',
  },
  {
    name: 'MT',
    value: 'MT',
  },
  {
    name: 'NE',
    value: 'NE',
  },
  {
    name: 'NV',
    value: 'NV',
  },
  {
    name: 'NH',
    value: 'NH',
  },
  {
    name: 'NJ',
    value: 'NJ',
  },
  {
    name: 'NM',
    value: 'NM',
  },
  {
    name: 'NY',
    value: 'NY',
  },
  {
    name: 'NC',
    value: 'NC',
  },
  {
    name: 'ND',
    value: 'ND',
  },

  {
    name: 'OH',
    value: 'OH',
  },
  {
    name: 'OK',
    value: 'OK',
  },
  {
    name: 'OR',
    value: 'OR',
  },

  {
    name: 'PA',
    value: 'PA',
  },
  {
    name: 'RI',
    value: 'RI',
  },
  {
    name: 'SC',
    value: 'SC',
  },
  {
    name: 'SD',
    value: 'SD',
  },
  {
    name: 'TN',
    value: 'TN',
  },
  {
    name: 'TX',
    value: 'TX',
  },
  {
    name: 'UT',
    value: 'UT',
  },
  {
    name: 'VT',
    value: 'VT',
  },
  {
    name: 'VI',
    value: 'VI',
  },
  {
    name: 'VA',
    value: 'VA',
  },
  {
    name: 'WA',
    value: 'WA',
  },
  {
    name: 'WV',
    value: 'WV',
  },
  {
    name: 'WI',
    value: 'WI',
  },
  {
    name: 'WY',
    value: 'WY',
  },
];

export const SearchByTypes = [
  {
    name: 'Name',
    value: 'name',
  },
  {
    name: 'App Id',
    value: 'appId',
  }, {
    name: 'Control No',
    value: 'controlNumber',
  }, {
    name: 'Copy Service',
    value: 'copyService',
  },

];
export const CopyServices = _.sortBy([

  { name: 'CIOX', value: 'CIOX' },
  { name: 'MRO', value: 'MRO' },
  { name: 'HEALTHMARK', value: 'HEALTHMARK' },
  { name: 'MEDICOPY', value: 'MEDICOPY' },
  { name: 'SCANSTAT', value: 'SCANSTAT' },
  { name: 'SHARECARE', value: 'SHARECARE' },
  { name: 'VITAL CHART', value: 'VITAL_CHART' },
  { name: 'MMRA', value: 'MMRA' },
  { name: 'CHART PRO', value: 'CHART_PRO'},
  { name: 'GULF COAST PROCESS', value: 'GULF_COAST_PROCESS'},
  { name: 'AMERICAN MEDICAL SOLUTIONS', value: 'AMERICAN_MEDICAL_SOLUTIONS'},
  { name: 'MEDSOUTH', value: 'MEDSOUTH'},
  { name: 'CHART REQUEST', value: 'CHART_REQUEST'},
  { name: 'VERISMA', value: 'VERISMA'},
],'name');
