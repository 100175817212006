import { DocumentType } from "src/app/aps/models/enums";

export const DOCUMENT_TYPE_LIST = {
    APS: [
        { name: 'APS', value: DocumentType.APS },
        { name: 'Invoice', value: DocumentType.INVOICE },
        { name: 'Payment Check', value: DocumentType.PAYMENT_CHECK },
        { name: 'Provider Request Letter', value: DocumentType.PROVIDER_REQUEST_LETTER },
        { name: 'Signed Authorization Letter', value: DocumentType.AUTHORIZATION },
        { name: "Other", value: DocumentType.OTHER }
    ],
    LAB_AE: [
        {
            name: "Lab Records", value: DocumentType.LABRECORDS
        },
        { name: "Other", value: "Other" }
    ],
    LAB_OE: [
        {
            name: "Lab Records", value: DocumentType.LABRECORDS
        },
        { name: "Other", value: "Other" }
    ],
    LAB_AE_HTWTBP: [
        {
            name: "Lab Records", value: DocumentType.LABRECORDS
        },
        { name: "Other", value: "Other" }
    ]
}



export const ORDER_STATUS_LIST = {
    "APS": [
        { name: 'Canceled', value: 6 },
        { name: 'Closed', value: 4 },
        { name: 'Completed', value: 5 },
        { name: 'Pending', value: 3 },
    ],
    "LAB_OE": [
        { name: 'Appointment Met', value: 7 },
        { name: 'Canceled', value: 6 },
        { name: 'Closed', value: 4 },
        { name: 'Completed', value: 5 },
        { name: 'Pending', value: 3 },
    ],
    "LAB_AE": [
        { name: 'Canceled', value: 6 },
        { name: 'Closed', value: 4 },
        { name: 'Completed', value: 5 },
        { name: 'Pending', value: 3 },
    ],
    "LAB_AE_HTWTBP": [
        { name: 'Canceled', value: 6 },
        { name: 'Closed', value: 4 },
        { name: 'Completed', value: 5 },
        { name: 'Pending', value: 3 },
    ],
    "INITIAL": [
        { name: 'Open', value: 0 },
        { name: 'Canceled', value: 6 },

    ]
}