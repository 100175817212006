<form [formGroup]='formGroup' *ngIf="providerDetailsConfig">

    <div class="grid p-5 pt-2 ml-5">

        <div class="col-11 flex justify-content-end cursor-pointer" (click)="showAdvancedSearch=!showAdvancedSearch">
            <a>Advanced Search</a>
        </div>
        <div class="col-6">
            <ng-select [items]="providersList$|async" bindLabel="name" [ngModelOptions]="{standalone: true}"
                [(ngModel)]='selectedProvider' (change)="selectedProviderEventHandler($event)"
                [loading]="providerLoading" [typeahead]="providerInput$" #providerDropdown
                [placeholder]='searchInputParameters[searchType].placeholder'>
                <ng-template ng-option-tmp let-r="item">
                    <div class="provider-row">
                        <div>
                            <div class="row">
                                <div class="col-7 font-bold">
                                    {{
                                    (r.first_name
                                    ? r.first_name + " " + r.last_name
                                    : r.name
                                    ) | titlecase
                                    }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    {{ r.street_address }}, {{ r.city }}, {{ r.state }}
                                    {{ r.zipcode }}-{{ r.phone }}
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </ng-select>

        </div>
        <div class="col-6 ">
            <div class="col-12">
                <div class="grid">
                    <div class="col-3">
                        <div class="field-radiobutton">
                            <p-radioButton name="doctor" value="0" [(ngModel)]="searchType"
                                [ngModelOptions]="{standalone: true}" (onClick)="providerDropdown.handleClearClick()">
                            </p-radioButton>
                            <label for="doctor" class="ml-3">Doctor</label>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="field-radiobutton">
                            <p-radioButton name="facility" value="1" [(ngModel)]="searchType"
                                (onClick)="providerDropdown.handleClearClick()" [ngModelOptions]="{standalone: true}"></p-radioButton>
                            <label for="facility" class="ml-3">Facility</label>
                        </div>
                    </div>
                    <div class="col-5" *ngIf="showAdvancedSearch">
                        <div class="field-radiobutton">
                            <p-radioButton name="facility" value="2" [(ngModel)]="searchType"
                                (onClick)="providerDropdown.handleClearClick()" [ngModelOptions]="{standalone: true}"></p-radioButton>
                            <label for="facility" class="ml-3">Phone Number</label>
                        </div>
                    </div>

                </div>
            </div>



        </div>
        <div class="col-12">
            <div class="grid">

                <div [class]="key.class" *ngFor="let key of providerDetailsConfig">
                    <p class="label">{{key.label}}</p>
                    <ushg-form [formControlComponent]="key"></ushg-form>
                </div>
            </div>
        </div>
    </div>

    <app-ushg-dialog-footer [buttonsList]='buttonsList'>
    </app-ushg-dialog-footer>
</form>