import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { Application } from 'src/app/aps/models/application-model';
import { ApsService } from 'src/app/aps/services/aps-service';

@Component({
  selector: 'app-ushg-application-detail',
  templateUrl: './ushg-application-detail.component.html',
  styleUrls: ['./ushg-application-detail.component.scss'],
})
export class UshgApplicationDetailComponent implements OnInit, OnDestroy {
  selectedApplication: Application;
  applicationId: number;
  applicationTrackerId: number;
  applicationDetailConfig = {
    isOpenButton: true,
    disableRouteTo: true,
    isFromApplicationDetailPage: true,
    applicationTrackerId: 0,
  };
  subscribeApplicationDetails: Subscription;

  constructor(private route: ActivatedRoute, private service: ApsService) { }
  ngOnDestroy(): void {
    this.subscribeApplicationDetails.unsubscribe();
  }
  async ngOnInit() {
    this.fetchQueryParameters();
    this.fetchSearchedApplication();
    if (!!this.applicationTrackerId) {
      this.applicationByApplicationTrackerId();
    } else {
      if (!!this.applicationId) {
        this.applicationByapplicationId();
      }
    }
  }
  fetchQueryParameters() {
    this.route?.queryParams.subscribe((params) => {
      this.applicationId = params.appId;
      this.applicationTrackerId = params.applicationTrackerId;
      this.applicationDetailConfig.applicationTrackerId =
        this.applicationTrackerId;
    });
  }
  fetchSearchedApplication() {
    this.subscribeApplicationDetails = this.service
      .getSelectedApplicationFromStore()
      .subscribe((application) => {
        this.selectedApplication = application;
      });
  }
  async applicationByApplicationTrackerId() {
    await this.service
      .getApplicationDetailsByApplicationTrackerId(
        Number(this.applicationTrackerId)
      )
      .toPromise();
  }
  async applicationByapplicationId(): Promise<void> {
    await this.service
      .getApplicationDetailsByApplicationId(Number(this.applicationId))
      .toPromise();
  }
}
