import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { ButtonConfig } from './buttons.config';

@Component({
  selector: 'ushg-button',
  templateUrl: './ushg-button.component.html',
  styleUrls: ['./ushg-button.component.scss'],
})
export class UshgButtonComponent implements AfterViewInit {
  @Input() label: string;
  @Input() class: string;
  @Input() disable: Boolean;
  @Input() type: string;
  constructor() {}

  ngAfterViewInit() {
    this.concatClass();
  }

  get buttonConfig(): typeof ButtonConfig {
    return ButtonConfig;
  }

  concatClass() {
    const button = document.getElementById(this.label);
    if (button) {
      button.classList.add(this.class);
    }
  }
}
