import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { UtilityService } from 'src/app/aps/services/utility-service';

@Directive({
    selector: '[timeInQueue]',
})
export class TimeinQueueDirective implements AfterViewInit {
    constructor(private elRef: ElementRef, private utilityService: UtilityService) { }

    ngAfterViewInit() {
        this.elRef.nativeElement.innerHTML = this.utilityService.convertDifferenceBetweenDatesToDays(this.elRef.nativeElement.innerHTML);
    }
}