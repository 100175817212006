<div class="container mx-auto p-3">
  <div class="grid">
    <div class="col-12 grid justify-content-center" *ngIf="currentOrderData.isEditOrder">
      <ushg-message [severity]="'warn'"
          [text]="'The newly re-generated documents will save only after you navigate to the next page by clicking the Next Button'">
      </ushg-message>
  </div>
    <div class="col-3 left-nav py-2 pl-5">
      <div class="row">
        <h3 class="col pb-2 mr-5 mb-3 border-bottom text-center">
          Required Letters/Documents
        </h3>
      </div>
      <div class="wrapper min-h-60 ">
        <div
          *ngFor="let content of Documents$|async" (click)="selectDocument(content)">
            <app-ushg-doc [document]="content" [canDisableDelete]="false" [canDisableDownload]="true" [canDisplayActionIcons]="false" [canDisplaySpecialRequestActions]="true" [SelectedDocument]="selectedDocument" (deleteDocument)="deleteFile($event)"></app-ushg-doc>
        </div>
      </div>
      <p class="p-3 bg-yellow-300 text-yellow-700 text-sm font-semibold"  *ngIf="communicationPreferences?.specialRequestLetter">
        *Please upload the provider's special request letter
      </p>
      <div class=" special-request-letter flex justify-content-center "
        *ngIf="communicationPreferences?.specialRequestLetter">
        <p-fileUpload #file name="myfile[]" mode="basic" chooseLabel="Upload" accept=".pdf" [uploadIcon]="'pi pi-chevron-up'"
      [customUpload]="true" (onSelect)="uploadFile($event)"></p-fileUpload>
    </div>
    </div>
    <div class="col-9 p-2">
      <div class="cnt-area p-3 pt-0" >
        <div class="loader"  *ngIf="!(selectedDocument && selectedDocument.blob)">
          <p-progressSpinner></p-progressSpinner>
        </div>
        <iframe width="100%" height="772" *ngIf="selectedDocument && selectedDocument.blob"   [src]="selectedDocument.blob|safe:'resourceUrl'"
          download='getFilenameFromContentDisposition(selectedDocument.blob)'></iframe>
      </div>
    </div>

  </div>

</div>
<ushg-navigation-buttons (nextButtonClick)="goToNextStep($event)"
[isNextButtonDisabled]="disableNextButton()"

  (backButtonClick)="backButtonEventHandler($event)">
</ushg-navigation-buttons>
