import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Actions, ofActionCompleted, Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import {
  Application,
  ApsUserModel,
} from 'src/app/aps/models/application-model';
import {
  NotificationModel,
  NotificationType,
} from 'src/app/aps/models/ui-models';
import { Actions as Action } from 'src/app/store/actions';
import {
  ApsUsers,
  AssignApplicationsToApsUser,
  ResetSpinner,
  SetSpinner,
  UshgNotification,
} from 'src/app/store/dispatchers';
import { environment } from 'src/environments/environment';
import { DropDownModel } from '../drop-down/drop-down.component';
import { ToastrService } from '../services/toastr-service';

@Component({
  selector: 'ushg-aps-users-workload',
  templateUrl: './ushg-users-workload.component.html',
  styleUrls: ['./ushg-users-workload.component.scss'],
})
export class UshgUsersWorkloadComponent implements OnInit {
  apsUsers: ApsUserModel[] = [];
  @Input() selectedApplications = [];
  @Output() applicationAssigned = new EventEmitter<boolean>();
  apsUsersList: [];
  @Select((state) => state.data.AssignedApplications)
  AssignedApplications$: Observable<Application[]>;
  viewByFilter: DropDownModel = {
    label: 'View',
    placeholder: 'Select Role',
    filterValues: environment.config.azure_roles.map((r) => {
      r['value'] = r.role;
      return r;
    }),
    onSelect: (role) => {
      this.store
        .select(Action.FilteredUsersByRole(role.value))
        .subscribe((res) => {
          this.apsUsersList = res;
        });
    },
  };
  constructor(
    private store: Store,
    private toastr: ToastrService,
    private storeActions: Actions
  ) {}

  async ngOnInit() {
    this.store.dispatch(new ApsUsers());
    this.store
      .select((state) => state.data.apsUsers)
      .subscribe((res) => {
        this.apsUsersList = res;
      });
  }
  ngOnChanges() {
    this.selectedApplications = this.selectedApplications;
  }

  async assignToApsUser(agent) {
    if (this.selectedApplications.length === 0) {
      this.store.dispatch(
        new UshgNotification(
          new NotificationModel(
            NotificationType.ERROR,
            '',
            'Please Select an Application'
          )
        )
      );
      return;
    }
    this.store.dispatch(new SetSpinner());
    this.store
      .dispatch(
        new AssignApplicationsToApsUser(agent, this.selectedApplications)
      )
      .subscribe(
        (res) => {
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.SUCCESS,
                'Application(s) Assigned ',
                `to ${agent.displayName} ${
                  agent.jobTitle ? agent.jobTitle : ''
                }`
              )
            )
          );
          this.store.dispatch(new ResetSpinner());
          this.applicationAssigned.emit(true);
          this.store.dispatch(new ApsUsers());
        },
        (err) => {
          this.store.dispatch(new ResetSpinner());
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.ERROR,
                'Application(s) not Assigned ',
                `to ${agent.displayName} ${
                  agent.jobTitle ? agent.jobTitle : ''
                }`
              )
            )
          );
        }
      );
  }

  createIntials(user) {
    const userName = user?.displayName?.split(',');
    return userName ? `${userName[1]?.charAt(1)}${userName[0].charAt(0)}` : '';
  }

  search(ev) {
    this.store.select(Action.FilteredUsers(ev)).subscribe((res) => {
      this.apsUsersList = res;
    });
  }
}
