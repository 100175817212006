<div class="container">
    <div class="grid">
        <div class="col-12 text-center">
        <img class="created-updated-order" src="assets/svg-files/Order_Created.svg" alt="order created" style="width:15%;">
        </div>
        <div class="col-12 text-center">
            <h3>{{title}}</h3>
        </div>
        <div class="col-12 text-center" *ngIf="currentOrderData.skipEmail">
            <div class="agent-email-txt">The automatic email to the agent's internal email address was skipped</div>
            <p><img class="text-color font-medium text-sm" src="assets\icons\Info_Icon.svg" alt="Email Skipped">Email Skipped</p>
        </div>
    </div>
</div>
<app-ushg-dialog-footer [mode]="view" [buttonsList]='buttonsList' ></app-ushg-dialog-footer>
