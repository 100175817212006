import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TemplateTitle } from 'src/app/aps/models/enums';
import { Contact, NotificationModel, NotificationType } from 'src/app/aps/models/ui-models';
import { ApsService } from 'src/app/aps/services/aps-service';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { UshgFormService } from 'src/app/shared/services/ushg-form-service';
import { CurrentOrderData, RequestOrderPdf, ResetRequestOrderPdf, UshgNotification } from 'src/app/store/dispatchers';
import { demographicFormConfig } from './demographic-form-config';

@Component({
  selector: 'app-lab-order-ae-demographic',
  templateUrl: './lab-order-ae-demographic.component.html',
  styleUrls: ['./lab-order-ae-demographic.component.scss']
})
export class LabOrderAeDemographicComponent implements OnInit {
  applicationDetails = this.store.selectSnapshot((state) => state.data.currentOrderData)
  isFormDirty = this.store.selectSnapshot((state) => state.data.isFormDirty)
  labLetterPdf = this.store.selectSnapshot((state) => state.data.requestOrderPdf)
  orderApplicant = this.applicationDetails.orderApplicant
  detailsConfig = demographicFormConfig
  demographicFormGroup: UntypedFormGroup
  constructor(
    private ushgForm: UshgFormService,
    private store: Store,
    private toastr: ToastrService,
    private apsService: ApsService,

  ) { }

  ngOnInit(): void {
    if (this.applicationDetails.policyInformation.length > 0) {
      this.applicationDetails.policyInformation.forEach((policy) => {
        this.detailsConfig[0].options.push({ 'label': policy.policyNumber, 'value': policy.policyNumber });
      });
      this.applicationDetails.orderApplicant.policyNumber = this.applicationDetails.applicantOverride?.policyNumber ? this.applicationDetails.applicantOverride?.policyNumber : (this.applicationDetails.orderApplicant.policyNumber?this.applicationDetails.orderApplicant.policyNumber :this.detailsConfig[0].options?.length > 0 ? this.detailsConfig[0].options[0].value : '');
      this.applicationDetails.orderDetails.policyNumber = this.applicationDetails.orderApplicant.policyNumber
    }  
      this.applicationDetails.isEditOrder
    ? this.store.dispatch(
      new RequestOrderPdf(this.applicationDetails.orderDocuments.documents)
    )
    : '';

    this.demographicFormGroup = this.ushgForm.createForm(this.detailsConfig, this.orderApplicant)
  }

  saveApplicationDetails(ev) {
    this.applicationDetails.orderApplicant = ev;
    this.applicationDetails['applicantCityStateZip'] = `${this.applicationDetails.orderApplicant.applicantCity || ''}, ${this.applicationDetails.orderApplicant.contactState || ''} - ${this.applicationDetails.orderApplicant['applicantZip'] || ''}`;
    this.applicationDetails.orderContactName = `${ev.firstName} ${ev.lastName}`;
    this.applicationDetails.applicantOverride = ev;
    this.applicationDetails.contactOverride = new Contact(ev);
    this.applicationDetails.contact = new Contact(ev);
    this.applicationDetails.orderDetails.policyNumber = this.applicationDetails.orderApplicant.policyNumber
    this.store.dispatch(new CurrentOrderData(this.applicationDetails));
    this.store.dispatch(new UshgNotification(new NotificationModel(NotificationType.SUCCESS, '', 'Changes Updated Sucessfully')));
    this.store.dispatch(new ResetRequestOrderPdf());
  }

  generateLabLetterPdf() {
    this.apsService.generatePdf(TemplateTitle.LABLETTER);
  }

  ngOnDestroy(){
    this.detailsConfig[0].options=[]
  }

}
