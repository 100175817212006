import { Validators } from "@angular/forms"
import { CopyServices } from 'src/app/shared/constants/filter-constants';
import * as _ from 'lodash';

export const doctorDetailsConfigKeys = [

    {
        formControlName: 'fullname',
        label: "Name",
        value: 'fullname',
        formControlType: 'text',
        disable: false,
        validators: [Validators.required]
    },
    {
        formControlName: 'facilityName',
        label: "Facility Name",
        value: 'facilityName',
        formControlType: 'text',
        validationMessage: "Please enter a valid Facility name",

    },
    {
        formControlName: 'providerstreetAddress',
        label: "Street",
        value: 'providerstreetAddress',
        formControlType: 'text',
        validationMessage: "Please enter a valid Street address",
        validators: [Validators.required]

    },
    {
        formControlName: 'city',
        label: "City",
        value: 'city',
        validationMessage: "Please enter a valid City name",

        formControlType: 'text',
        validators: [Validators.required]

    },
    {
        formControlName: 'providerState',
        label: "State",
        value: 'providerState',
        validationMessage: "Please Please enter a valid State name",
        formControlType: 'text',
        validators: [Validators.maxLength(2), Validators.pattern('^[a-zA-Z]+$'), Validators.required],

    },
    {
        formControlName: 'zipCode',
        label: "ZIP",
        value: 'zipCode',
        validationMessage: "Please enter a valid Zip code",
        formControlType: 'text',
        validators: [Validators.pattern('^([0-9]{5}(-[0-9]{4})?)?$'), Validators.required]
    },
    {
        formControlName: 'taxId',
        label: "Tax ID",
        value: 'taxId',
        validationMessage: "Please enter a valid Tax ID",
        formControlType: 'text'
    },
    {
        formControlName: 'providerPhone',
        label: "Phone",
        value: 'providerPhone',
        validationMessage: "Please enter a valid Phone number",
        formControlType: 'phone',
        validators: [Validators.minLength(14), Validators.required]
    },
    {
        formControlName: 'phoneExtension',
        label: "Phone Extension",
        value: 'phoneExtension',
        validationMessage: "Please enter a valid Phone Extension",
        formControlType: 'text',
        validators: [Validators.pattern(/^[A-Za-z0-9.,*&\/#\s]+$/), Validators.maxLength(100)]
    },
    {
        formControlName: 'providerFax',
        label: "Fax",
        value: 'providerFax',
        validationMessage: "Please enter a valid Fax",
        formControlType: 'text',
        validators: [Validators.minLength(10)]
    }
]

export const communicationPaymentPreferencesKeys = [
    {
        formControlName: "communicationMode",
        label: "Preferred Communication Mode",
        value: 'communicationMode',
        validationMessage: "Please select a valid option",
        controlValue: 'preferredCommunicationMode',
        formControlType: 'select',
        customClass: 'col-6',
        validators: [Validators.required],
        options: [
            {
                label: 'Select an Option'
            },
            {
                label: 'Copy Service', value: 'COPY_SERVICE',
            },
            {
                label: 'FAX', value: 'FAX',
            },
            {
                label: 'SnailMail', value: 'MAIL',
            },
            {
                label: 'N/A', value: 'NA',
            }

        ],
    },
    {
        formControlName: "preferredCopyService",
        label: "Preferred 'Copy Service'",
        value: 'preferredCopyService',
        controlValue: 'acceptsCopyService',
        formControlType: 'select',
        customClass: 'col-6',
        validators: [],
        validationMessage : "Please select a valid option",
        options: [
            { label: 'Select an Option' },
            { label: 'AMERICAN MEDICAL SOLUTIONS', value: 'AMERICAN_MEDICAL_SOLUTIONS'},
            { label: 'CHART PRO', value: 'CHART_PRO'},
            { label: 'CHART REQUEST', value: 'CHART_REQUEST'},
            { label: 'CIOX', value: 'CIOX' },
            { label: 'GULF COAST PROCESS', value: 'GULF_COAST_PROCESS'},
            { label: 'HEALTHMARK', value: 'HEALTHMARK' },
            { label: 'MEDICOPY', value: 'MEDICOPY' },
            { label: 'MEDSOUTH', value: 'MEDSOUTH'},
            { label: 'MMRA', value: 'MMRA' },
            { label: 'MRO', value: 'MRO' },
            { label: 'SCANSTAT', value: 'SCANSTAT' },
            { label: 'SHARECARE', value: 'SHARECARE' },
            { label: 'VERISMA', value: 'VERISMA'},
            { label: 'VITAL CHART', value: 'VITAL_CHART' },   
        ]
    },
    {
        formControlName: "electronicSignature",
        label: "Accepts 'Electronic Signature' documents",
        value: 'electronicSignature',
        validationMessage: "Please select a valid option",
        controlValue: 'acceptsElectronicSignature',
        formControlType: 'select',
        customClass: 'col-6',
        validators: [Validators.required],
        options: [
            {
                label: 'Select an Option'
            },
            {
                label: 'YES', value: "true",
            },
            {
                label: 'NO', value: "false",
            },
            {
                label: 'N/A', value: "N/A",
            },
        ],
    },
    {
        formControlName: "creditCardPayments",
        label: "Accepts 'Credit Card' Payments",
        value: 'creditCardPayments',
        controlValue: 'acceptsCreditCard',
        validationMessage: "Please select a valid option",
        validators: [Validators.required],
        customClass: 'col-6',
        formControlType: 'select',
        options: [
            {
                label: 'Select an Option'
            },
            {
                label: 'YES', value: "true",
            },
            {
                label: 'NO', value: "false",
            },
            {
                label: 'N/A', value: "N/A",
            },

        ],
    },
    {
        formControlName: "trunAroungTime",
        label: "Turn Around Time",
        value: 'trunAroungTime',
        customClass: 'col-6',
        controlValue: 'trunAroungTime',
        formControlType: 'text',
    },
    {
        formControlName: "specialRequestLetter",
        label: "Special Request Letter",
        value: 'specialRequestLetter',
        validationMessage: "Please select a valid option",
        controlValue: 'specialRequestLetter',
        formControlType: 'select',
        customClass: 'col-6',
        validators: [Validators.required],

        options: [
            {
                label: 'Select an Option'
            },
            {
                label: 'Required', value: true,
            },
            {
                label: 'Not Required', value: false,
            },

        ],
    },
]

export const apsDemographicFormConfig =
    [
        {
            formControlName: "policyNumber",
            label: "Select Policy",
            value: 'policyNumber',
            detailsDirection: "column",
            class: "w-full p-0",
            customClass: "col-8",
            labelClass: "col-4",
            controlValue: 'policyNumber',
            formControlType: 'brCodeDropDown',
            options: [],
            validationMessage: " * BR code does not exist for this policy number.",
            validators: [ Validators.required],
            type:'brCode'
        },
        {
            formControlName: "firstName",
            label: "FirstName",
            value: 'firstName',
            validationMessage: "Please enter a valid First name",
            formControlType: 'text',
            validators: [Validators.pattern("^[a-zA-Z\- ]+$"), Validators.required],
        },
        {
            formControlName: "middleName",
            label: "MiddleName",
            value: 'middleName',
            validationMessage: "Please enter a valid Middle name",
            formControlType: 'text',
            validators: [Validators.pattern("^[a-zA-Z\- ]+$")]
        },
        {
            formControlName: "lastName",
            label: "LastName",
            value: 'lastName',
            validationMessage: "Please enter a valid Last name",
            formControlType: 'text',
            validators: [Validators.pattern("^[a-zA-Z\- ]+$"), Validators.required]
        },
        {
            formControlName: "birthDate",
            label: "DOB",
            value: 'birthDate',
            validationMessage: "Please select a valid Birth date",
            formControlType: 'datepicker',
            type: 'date',
            validators: [Validators.required]
        },
        {
            formControlName: "gender",
            label: "Gender",
            value: 'gender',
            validationMessage: "Please select a Gender",
            formControlType: 'select',
            customClass: 'col-6',
            validators: [Validators.required],
            options: [
                {
                    label: 'Select an Option'
                },
                {
                    label: 'M', value: 'M',
                },
                {
                    label: 'F', value: 'F',
                }
            ]

        },
        {
            formControlName: "ssn",
            label: "SSN",
            value: 'ssn',
            validationMessage: "Please enter a valid SSN number",
            formControlType: 'ssn',
            mask: true
        },
        {
            formControlName: "birthCountry",
            label: "Location",
            validationMessage: "Please enter a valid Birth country",
            validators: [Validators.pattern("^[a-zA-Z]+$"), Validators.required],
            value: 'birthCountry',
            formControlType: 'text',
        },
        {
            formControlName: "contactState",
            label: "State",
            value: 'contactState',
            formControlType: 'text',
            validationMessage: "Please enter a valid State name",
            validators: [Validators.required, Validators.maxLength(2), Validators.pattern('^[a-zA-Z]+$')]
        },
        {
            formControlName: "applicantstreetAddress",
            label: "Street",
            value: 'applicantstreetAddress',
            formControlType: 'text',
            validationMessage: "Please enter a valid Street address",
            validators: [Validators.required]

        },
        {
            formControlName: "applicantCity",
            label: "City",
            value: 'applicantCity',
            formControlType: 'text',
            validationMessage: "Please enter a valid City name",
            validators: [Validators.required]

        },
        {
            formControlName: "applicantmobilePhone",
            label: "Mobile",
            value: 'applicantmobilePhone',
            validationMessage: "Please enter a valid Phone number",
            formControlType: 'phone',

        },
        {
            formControlName: "applicantHomePhone",
            label: "Home",
            value: 'applicantHomePhone',
            validationMessage: "Please enter a valid Phone number",
            formControlType: 'phone',
            validators: [Validators.required]
        },
        {
            formControlName: "applicantzipCode",
            label: "Zip",
            value: 'applicantzipCode',
            validationMessage: "Please enter a valid Zip code",
            formControlType: 'text',
            validators: [Validators.pattern('^([0-9]{5,6}(-[0-9]{4})?)?$'), Validators.required]
        },
        {
            formControlName: "applicantEmail",
            label: "Email",
            value: 'applicantEmail',
            validationMessage: "Please enter valid Email address",
            formControlType: 'text',
            validators: [Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,8}$"), Validators.required]
        },
    ]

export const healthInformationForAuthorizationLetter = [
    {
        formControlName: "datesOfTreatmentRequested",
        customClass: "col-8",
        labelClass: "col-4",
        label: "Date(s) of Treatment Requested",
        value: 'datesOfTreatmentRequested',
        validationMessage: "This field is required",
        formControlType: 'text',
        validators: [Validators.required]
    },
    {
        formControlName: "informationToBeDisclosed",
        customClass: "col-8",
        labelClass: "col-4",

        label: "Information to be disclosed",
        value: 'informationToBeDisclosed',
        validationMessage: "This field is required",
        formControlType: 'text',
        validators: [Validators.required]
    },
    {
        formControlName: "reasonForDisclosure",
        customClass: "col-8",
        labelClass: "col-4",
        label: "Reason for the Disclosure is",
        value: 'reasonForDisclosure',
        validationMessage: "This field is required",
        formControlType: 'text',
        validators: [Validators.required]
    },
    {
        formControlName: "authorizationExpiresOn",
        customClass: "col-8",
        labelClass: "col-4",
        label: "Authorizaton expires on",
        value: 'authorizationExpiresOn',
        validationMessage: "This field is required",
        formControlType: 'text',
        validators: [Validators.required]
    },

]
