import { AfterViewInit, Directive, ElementRef, Input } from "@angular/core";

@Directive({
    selector: "[mask]"
})
export class MaskDirective implements AfterViewInit {
    @Input('mask') data: any;

    constructor(private elRef: ElementRef) { }

    ngAfterViewInit() {
        let maskedData = "";
        
        if(this.data && this.data.key && this.data.value && this.data.value.length > 4)
        {
            maskedData = this.data?.key ? '***-**-' + this.data?.value.slice(-4) : this.data?.value;
        }
        
        this.elRef.nativeElement.innerHTML = maskedData === undefined ? "" : maskedData;
    }
}