<p-table [value]="rowData" [paginator]="true" [rows]="rows" paginatorleft="true" [showCurrentPageReport]="true"
  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onRowSelect)="selctedRowsHandler()"
  (onRowUnselect)="selctedRowsHandler()" #table [(selection)]="selectedRows">
  <ng-template pTemplate="header" let-columns >
    <tr class="f14" *ngIf="rowData">
      <th *ngIf="checkbox">
        <p-tableHeaderCheckbox (click)="selctedRowsHandler()"></p-tableHeaderCheckbox>
      </th>

      <ng-container *ngFor="let header of headerList">
        <ng-container *ngIf="header.label !== 'UW Notes' && header.label !== 'APS Notes'">
          <th [class]="header.class">
            <span> {{ header.label }}</span>
          </th>
        </ng-container>
      </ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-data let-rowData let-i="rowIndex">
    <tr [pSelectableRow]="rowData" class="f12">
      <td *ngIf="checkbox">
        <p-tableCheckbox [value]="data"> </p-tableCheckbox>
      </td>

      <ng-container *ngFor="let header of headerList">
        <ng-container *ngIf="header.label !== 'UW Notes' && header.label !== 'APS Notes'">
          <td [class]="header.class">
            <ng-container [ngSwitch]="header.type">
              <span>
                <span *ngSwitchCase="ColumnType.aplicationProcessState" class="dropDownWidthHeight">
                  <app-ushg-order-process-status [rowData]="rowData" [header]="header" [data]="data"
                    [config]="config" [disabled]="disableActions"></app-ushg-order-process-status>
                </span>
              </span>
              <span>
                <span *ngSwitchCase="ColumnType.DATE_ONLY">
                  {{data[header.field] | dateTime : timeZone : "date"}}
                </span>
              </span>
              <span *ngSwitchCase="ColumnType.uwReason">
                <div *ngIf="data[header.field] && data[header.field].length > 0" class="pb-1">
                  <span class="font-bold">{{data[header.field][0]}}</span>
                  <span *ngIf="data[header.field].length > 1" class="px-2 text-blue-400">{{data[header.field].length - 1}} more </span>
                </div>
                <div>{{data[header.subField] && data[header.subField][0]}}</div>
              </span>
              <span *ngSwitchDefault>
                <span>{{
                  data[header.field]
                  | reverseString : header.field : "providerName" || "N/A"
                  }}
                  <br />
                  {{ data[header.subField] }}
                </span>
                <span *ngIf="data[header.addonPill] === 'AddOnApplication'">
                  <ushg-chip [value]="'AddOn'" [chipLabel]="'Add On'"></ushg-chip>
                </span>
              </span>
              <span *ngSwitchCase="ColumnType.index">
                {{ i + 1 }}
              </span>
              <span *ngSwitchCase="ColumnType.documents">
                <ng-container *ngIf="data[header.field]?.['documents'] else documents">
                  <!-- <div class="position-relative d-flex">
                    <img
                      src="../../../assets/icons/PDF_Icon.svg"
                      alt="image"
                      width="28px"
                      [pTooltip]="showToolTip(data[header.field]?.['documents'])"
                      [escape]="false"
                      tooltipPosition="top"
                      tooltipStyleClass="custom_tool_tip"
                    />
                    <sup
                      class="position-absolute"
                      >{{data[header.field]?.['documents'].length}}</sup
                    >
                  </div> -->
                </ng-container>
                <!-- emailLog documents -->
                <ng-template #documents>
                  <div class="grid align-items-center col" *ngFor="let file of data[header.field]">
                    <div class="col-10">
                      <span class="">{{ file.FileName || "N/A" | titlecase }}</span>
                    </div>
                    <div class="col-2">
                      <i class="pi pi-download downloadfile cursor-pointer" (click)="downloadFile(file)"
                        appPermission="CanPerformActionsOnOrderModal"></i>
                    </div>
                  </div>
                </ng-template>
              </span>
              <span *ngSwitchCase="ColumnType.tools" class="d-inline-flex">
                <ng-container *ngFor="let tool of header.toolsList">
                  <div class="position-relative inline-flex mr-2">
                    <img [src]="tool.icon" alt="image" width="28px" [pTooltip]="showToolTip(tool.data(data),tool.type)"
                      [escape]="false" tooltipPosition="top" tooltipStyleClass="custom_tool_tip" />
                    <sup class="position-absolute">{{tool.count(data)}}</sup>
                  </div>
                </ng-container>
              </span>
              <ushg-table-actions *ngSwitchCase="ColumnType.actions"
                [actions]="header.actionList ? header.actionList(data) : actions" [ref]="data" [index]="i" [disabled]="disableActions">
              </ushg-table-actions>
              <p [class]="header.class" class="w-full m-0" *ngSwitchCase="ColumnType.orderTrackerId">
                <ushg-table-actions *ngSwitchCase="ColumnType.orderTrackerId"
                  [actions]="header.actionList ? header.actionList(data) : actions" [ref]="data" [index]="i" [disabled]="disableActions">
                </ushg-table-actions>
              </p>
              <p *ngSwitchCase="ColumnType.date" [class]="header.class" class="w-full m-0">
                {{ data[header.field] | dateTime : timeZone : "date" }} <br />
                {{ data[header.field] | dateTime : timeZone : "time" }}
              </p>
              <p *ngSwitchCase="ColumnType.assignedTo" [class]="header.class" class="w-full m-0">
                {{ data[header.field] || "Unassigned" }}
              </p>
              <p *ngSwitchCase="ColumnType.applicationStatus" applicationStatus class="m-0">
                {{ data[header.field] }}
              </p>
              <span>
                <ushg-chip [value]="data[header.field]" *ngSwitchCase="ColumnType.orderStatusId">
                  <span OrderStatus>
                    {{ data[header.field] }}
                  </span>
                </ushg-chip>
              </span>
              <!-- reminder date display -->
              <div *ngSwitchCase="ColumnType.reminderDate" class="align-items-center justify-content-space-between d-flex"
                style="width: min-content">
                <div *ngIf="data[header.field] !== null">
                  <img src="./assets/icons/bell_black.svg" alt="" class="mr-2 mt-2" width="14px" />
                </div>
                <div *ngIf="data[header.field] !== null">
                  <div class="f12">{{ data[header.field] }}</div>
                </div>
              </div>
              <span>
                <ushg-chip *ngSwitchCase="ColumnType.requestType" [value]="data[header.field]" [chipLabel]="'requestType'"
                  [chipStatusId]="'orderStatusId'">
                </ushg-chip>
              </span>
              <p *ngSwitchCase="ColumnType.OrderRequestType" class="m-0">
                <app-request-type [requestType]="data[header.field]?.requestType">
                </app-request-type>
              </p>
              <p *ngSwitchCase="ColumnType.information" [class]="header.class" class="w-full m-0" [class]="header.class">
                <ushg-audit-log-information [notes]="data[header.field]">
                </ushg-audit-log-information>
              </p>
              <p *ngSwitchCase="ColumnType.auditParameter" class="m-0">
                {{ data[header.field] }}
              </p>
              <p *ngSwitchCase="ColumnType.amount" class="m-0">
                {{ data[header.field] | currency : "USD" }}
              </p>
              <p *ngSwitchCase="ColumnType.timeInQueue" timeInQueue class="m-0">
                {{ data[header.field] }}
              </p>
              <p *ngSwitchCase="ColumnType.DATE_WITHOUT_CONVERSION" class="m-0">
                {{ data[header.field] }}
              </p>
              <span>
                <ushg-button *ngSwitchCase="ColumnType.select" [label]="'Select'" (click)="header.onChange(data)">
                </ushg-button>
              </span>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>
    </tr>
    <!--Row for UW Notes and APS Notes-->
    <tr>
      <td [attr.colspan]="headerList.length + (checkbox ? 1 : 0)" class="border-none">
        <div class="flex flex-wrap">
        <ng-container *ngFor="let header of headerList">
            <div *ngIf="header.type === ColumnType.uw_notes" class="notes-main-div">
              <div class="notes-header">{{header.label}}</div>
              <ng-container *ngFor="let tool of header.toolsList">
                <div *ngIf="tool.data(data).length > 0; else noNotes">
                  <ng-container *ngFor="let note of tool.data(data)">
                    <div *ngIf="note?.information && note?.information.trim() !== ''">
                      <div class="notes-sub-div">
                        <table class="noteTable">
                          <tr>
                            <td class="noteText">{{note?.information}}</td>
                          </tr>
                          <tr>
                            <td class="noteAuthor">{{note?.loggedDate | date: 'MM/dd'}} - ({{note?.loggedBy}})</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </ng-container>
                </div>
                <ng-template #noNotes>
                  <p-message severity="info" text="No records" styleClass="p-mr-2 w-100"></p-message>
              </ng-template>
             </ng-container>
            </div>
            <div *ngIf="header.type === ColumnType.aps_notes" class="notes-main-div">
              <div class="notes-header">{{header.label}}</div>
              <ng-container *ngFor="let tool of header.toolsList">
                <div *ngIf="tool.data(data).length > 0; else noNotes">
                  <ng-container *ngFor="let note of tool.data(data)">
                      <div *ngIf="note?.information && note?.information.trim() !== ''">
                        <div class="notes-sub-div">
                          <table class="noteTable">
                            <tr>
                              <td class="noteText">{{note?.information}}</td>
                            </tr>
                            <tr>
                              <td class="noteAuthor">{{note?.loggedDate | date: 'MM/dd'}} - ({{note?.loggedBy}})</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                  </ng-container>
                </div>
                <ng-template #noNotes>
                  <p-message severity="info" text="No records" styleClass="p-mr-2 w-100"></p-message>
                </ng-template>
             </ng-container>
            </div>
        </ng-container>
        </div>
      </td>
    </tr> 
  </ng-template>
</p-table>