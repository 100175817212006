import {
  AfterContentChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { Actions, ofActionCompleted, ofActionSuccessful, Store } from '@ngxs/store';
import { tap, take, takeLast } from 'rxjs/operators';
import { Application, HeadersList } from 'src/app/aps/models/application-model';
import { ApplicationStatusQueryId } from 'src/app/aps/models/enums';
import { ApplicationFilterData } from 'src/app/aps/models/filter-data-model';
import { Permission } from 'src/app/aps/models/permission';
import { ApsService } from 'src/app/aps/services/aps-service';
import { sortByLastName } from 'src/app/aps/services/graphql-service';
import { UtilityService } from 'src/app/aps/services/utility-service';
import { ApplicationSearchInfo } from 'src/app/shared/application-filters/application-filters.component';
import { StatusFilterWorkQueue } from 'src/app/shared/constants/filter-constants';
import { DropDownModel } from 'src/app/shared/drop-down/drop-down.component';
import { PageStateService } from 'src/app/shared/services/page-state.service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import {
  ApsUsers,
  FilterOrderQueueApplications,
  OrderQueueApplications,
  SelectedApplicationDetails, 
} from 'src/app/store/dispatchers';
import { ApplicationRequestModalComponent } from '../application-request-modal/application-request-modal.component';
import { workQueueHeaders } from './work-queue-config';

@Component({
  selector: 'ushg-work-queue',
  templateUrl: './work-queue.component.html',
  styleUrls: ['./work-queue.component.scss'],
  providers: [PageStateService],  
})
export class WorkQueueComponent implements AfterViewInit, AfterContentChecked {
  applicationFilterData: Application;
  orderQueueList: Application[] = this.store.selectSnapshot(
    (state) => state.data.orderQueueApplications
  );
  showAssignApsUserModal = false;
  filterValue: string;
  lastNameFilter: string;
  assigneeSelectedFilterValue: string;
  assignee: string;

  controlNo: string;
  private readonly WORK_QUEUE = 'workQueue';
  appId: string;
  fromDate: string;
  toDate: string;
  status: string;
  selectedApplication: Application[];
  headersList: HeadersList[] = workQueueHeaders;
  dropDownFilterList: DropDownModel[];
  actionsList = [
    {
      label: 'View',
      button: true,
      type: 'primary_m2',
      permission: Permission.CanAccessWorkQueue,
      onClick: async (data) => {
        this.viewActionEventHandler(data)
      },
    },
    {
      label: 'Assign',
      button: true,
      type: 'primary_m2',
      permission: Permission.CanAccessWorkQueue,
      onClick: (data) => {
        this.showAssignApsUserModal = true;
        this.selectedApplication = [data];
      },
    },
  ];
  applicationSearchInfo: ApplicationSearchInfo;
  currentPage = 0;
  pageNumber = 1;

  constructor(
    private cd: ChangeDetectorRef,
    private dialogService: UshgDialogService,
    private pageStateService: PageStateService,
    private store: Store,
    private stroreActions: Actions,
    private apsService: ApsService,
    private utilityService: UtilityService,

  ) {
    this.setDropDownData()
  }

  public setApplicationFilters(data) {
    if (!this.utilityService.isEmpty(data)) {
      this.applicationSearchInfo = new ApplicationFilterData(data, this)
        .filterData as ApplicationSearchInfo;
    } else {
      this.applicationSearchInfo = new ApplicationFilterData(this, this)
        .filterData as ApplicationSearchInfo;
    }
  }


  public ngAfterViewInit(): void {
    this.pageStateService
      .getPageStateInformationFromUrlOrSessionStorage(this.WORK_QUEUE)
      .pipe(
        tap((res) => {
          this.applicationFilterData = res;
          this.setApplicationFilters(res);
          this.setDropDownData()
          this.assignFormValues(this.applicationFilterData);
          this.filter(this.applicationSearchInfo);
        }),
        take(1)
      )
      .subscribe();
    this.store.select((state) => state.data.orderQueueApplications).subscribe((applications) => {
      this.cd.detectChanges();
      this.orderQueueList = applications;
    })
  }

  public ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  filterInputHandler(ev) {
    this.filterValue = ev;
  }

  applicationAssignedToApsMember(ev) {
    this.showAssignApsUserModal = false;
    this.resetFilter();
  }

  assignFormValues(filters) {
    for (const key in filters) {
      this[key] = filters[key];
    }
  }

  search(filters?) {
    filters.page = this.WORK_QUEUE;
    filters.status=this.status
    this.pageStateService.updateUrlWithPageStateInformation(
      this.WORK_QUEUE,
      filters
    );
    this.applicationSearchInfo = this.applicationFilters();
    this.applicationSearchInfo.filters = filters;
    this.filter(this.applicationSearchInfo);
  }

  resetFilter(filters?) {
    this.status = null;
    this.appId = '';
    this.assigneeSelectedFilterValue = '';
    this.lastNameFilter = '';
    this.fromDate = '';
    this.toDate = '';
    this.applicationSearchInfo = this.applicationFilters();
    this.applicationSearchInfo['applications'] = {...this.applicationSearchInfo};
    this.pageStateService.updateUrlWithPageStateInformation(
      this.WORK_QUEUE,
      this.applicationSearchInfo
    );
    this.filter(this.applicationSearchInfo);
  }

  applicationFilters() {
    return {
      filters: {
        appId: this.appId,
        toDate: this.toDate,
        fromDate: this.fromDate,
        status: this.status,
        assigneeSelectedFilterValue: this.assigneeSelectedFilterValue,
        lastNameFilter: this.lastNameFilter,

      },
      paginate: {
        pageDirection: null,
        queryId: null,
        pageNumber: null,
        pageInfo: {
          startCursor: null,
          endCursor: null
        }
      },
    } as ApplicationSearchInfo;
  }

  selectEventHandler(ev) {
    if (ev?.lastNameFilter) {
      this.lastNameFilter = sortByLastName(ev?.lastNameFilter);
    }
  }

  async viewActionEventHandler(data) {
    this.store.dispatch(new SelectedApplicationDetails(data.applicationTrackerId, data.convertedVersion))
    await this.stroreActions.pipe(ofActionSuccessful(SelectedApplicationDetails), take(1)).toPromise()
    const selectedApplicationDetails = this.store.selectSnapshot((state) => state.data.selectedApplicationDetails)
    this.dialogService.open(ApplicationRequestModalComponent, {
      ...data,
      ...selectedApplicationDetails,
    });
  }

  async setDropDownData() {
    await this.stroreActions.pipe(ofActionCompleted(ApsUsers), take(1)).toPromise()
    this.dropDownFilterList = [
      {
        label: 'Status',
        searchFilterVar: 'status',
        filterValues: StatusFilterWorkQueue,
        selectedValue: this.status,
        onSelect: (ev) => {
          this.status = ev.value;
          console.log(this.status)
        },
      },
      {
        label: 'Assignee',
        filterValues: [],
        searchFilterVar: 'assignee',
        selectedValue: this.assigneeSelectedFilterValue,
        placeholder: 'Select an Assignee',
        onSelect: (ev) => {
          this.assigneeSelectedFilterValue = ev.value;
        },
      },
    ];
    this.store.select((state) => state.data.apsUsers).subscribe((res) => {
      this.dropDownFilterList[1].filterValues = this.apsService.sortListByKey(res.map((user) => {
        return { name: user.displayName, value: user.domainUserName };
      }), 'name')
    })
  }


  public filter(data: ApplicationSearchInfo): void {
    const { pageDirection, pageNumber, pageInfo } = data.paginate;
    data.paginate.pageDirection = pageDirection;
    data.paginate.pageNumber = pageNumber;
    data.paginate.pageInfo = pageInfo;
    data.page = this.WORK_QUEUE;
    this.filterApplications(data);
  }

  public filterApplications(data?) {
    this.applicationSearchInfo = data;
    data ? this.paginate(data.paginate.pageNumber, true) : null;
  }

  paginate(pageNumber: any, onLoad?: boolean) {
    this.pageNumber = pageNumber;
    const assignedApplications = this.store.selectSnapshot(
      (state) => state.data.unAssignedApplications
    );
    const paginate = this.applicationSearchInfo.paginate;
    let pageDirection;

    if (onLoad) {
      pageDirection = paginate.pageDirection;
    } else {
      pageDirection = pageNumber <= this.currentPage ? 'BEFORE' : 'AFTER';
    }

    paginate.pageDirection = pageDirection;
    paginate.pageNumber = pageNumber;
    paginate.queryId = ApplicationStatusQueryId.workQueue;

    paginate.pageInfo.startCursor = (assignedApplications && assignedApplications.length > 0)
      ? assignedApplications[0].pageInfo.startCursor
      : paginate.pageInfo.startCursor;

    paginate.pageInfo.endCursor = (assignedApplications && assignedApplications.length > 0)
      ? assignedApplications[0].pageInfo.endCursor
      : paginate.pageInfo.endCursor;

    this.updatePaginateInfo(this.applicationSearchInfo, pageNumber);
    this.store.dispatch(
      new FilterOrderQueueApplications(this.applicationSearchInfo)
    );
    this.currentPage = pageNumber;
  }

  private updatePaginateInfo(
    filterData: ApplicationSearchInfo,
    pageNumber: number
  ) {
    if (!this.orderQueueList?.length) {
      return;
    }
    filterData.paginate.pageInfo = this.orderQueueList[0].pageInfo;
    filterData.paginate.pageNumber = pageNumber;
    this.pageStateService.updateUrlWithPageStateInformation(
      this.WORK_QUEUE,
      filterData
    );
  }

}
