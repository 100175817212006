import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import * as relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

declare module 'dayjs' {
  interface Dayjs {
      fromNow();
  }
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor() {}

  public time;

  public setIdentity(name: string) {
    this.clarity('identify', name);
  }

  public setOrderStarted() {
    this.time = new Date().toISOString();
    this.clarity('set', 'Order_Started', this.time);
  }

  public setOrderEnded(startTime: string) {
    const orderCompleted = parseInt(
     dayjs(startTime).startOf('minute').fromNow().split(' ')[0]
    );

    if (orderCompleted <= 3) {
      this.clarity('set', 'Order_Duration', 'NORMAL');
    } else if (orderCompleted < 5) {
      this.clarity('set', 'Order_Duration', 'MEDIUM');
    } else if (orderCompleted < 10) {
      this.clarity('set', 'Order_Duration', 'HIGH');
    } else {
      this.clarity('set', 'Order_Duration', 'VERY_HIGH');
    }
  }

  private clarity(arg1: string, arg2: string, arg3?: string) {
    (<any>window).clarity(arg1, arg2, arg3);
  }
}
