import { Component, OnInit, ViewChild } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FileUpload } from 'primeng/fileupload';
import { Observable } from 'rxjs';
import { Document, DownloadDocumentsPayload, NotificationModel, NotificationType } from 'src/app/aps/models/ui-models';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { NavigationService } from 'src/app/aps/services/aps-navigation-service';
import { ApsService } from 'src/app/aps/services/aps-service';
import { Navigation } from 'src/app/shared/services/navigation';
import { CurrentOrderData, RequestOrderPdf, ResetSpinner, SetSpinner, UshgNotification } from 'src/app/store/dispatchers';
import { ToastrService } from '../services/toastr-service';
import { NgxsHistoryUndo } from 'ngxs-history-plugin'
import { FileService } from 'src/app/shared/services/file-service';
@Component({
  selector: 'app-generate-pdf',
  templateUrl: './generate-pdf.component.html',
  styleUrls: ['./generate-pdf.component.scss']
})
export class GeneratePdfComponent extends Navigation implements OnInit {
  @ViewChild('file') file: FileUpload;

  @Select((state) => state.data.requestOrderPdf) Documents$: Observable<Document[]>
  communicationPreferences = this.store.selectSnapshot((state) => state.data.providerPreferences)
  currentOrderData = this.store.selectSnapshot((state) => state.data.currentOrderData)
  documents
  selectedDocument: Document = {}
  orderData: any;
  uploadedFiles = false;
  latestUploadedFiles = [];

  constructor(private navigation: NavigationService,
    private store: Store,
    private apsApiService: ApsApiService,
    private apsService: ApsService,
    private fileService: FileService
  ) {
    super(navigation)
  }

  async ngOnInit() {
    this.store.select((state) => state.data.requestOrderPdf).subscribe((res) => {
      if (res) {
        this.documents = res
        this.selectedDocument = this.documents[0]
      }

    })
    this.orderData = this.store.selectSnapshot((state) => state.data.currentOrderData)
    //to retriev the blob url after Reload
    if (this.selectedDocument) {
      this.selectedDocument.blob = await this.generateBlob(this.selectedDocument?.byteStream)
    }
  }

  selectDocument(content) {
    this.selectedDocument = content;
    if (this.selectedDocument.byteStream) {
      this.generateBlob(this.selectedDocument.byteStream).then((res) => {
        this.selectedDocument.blob = res
      })
    }
    else {
      let orderData = this.store.selectSnapshot(
        (state) => state.data.currentOrderData
      );
      orderData = orderData
      const payload = new DownloadDocumentsPayload(orderData, content.name);
      this.apsApiService
        .downloadIndividualDocuments(payload).subscribe(({ data }: any) => {
          const byteStream = data.downloadOrderFiles?.documents[0]?.content;
          this.generateBlob(byteStream).then((res) => {
            this.selectedDocument.blob = res
          })
        })
    }
  }

  uploadFile = async (event) => {
    this.uploadedFiles = true
    const orderData = this.store.selectSnapshot((state) => state.data.currentOrderData)

    for (let file of event.files) {
      let content = await this.fileService.convertFileToByteStream(file)
      let blob = await this.fileService.convertBinaryDatatoBlob(content)
      this.latestUploadedFiles = [{ name: file.name, content, label: file.name, blob, updatedDate: new Date().toISOString() }]
    }
    this.latestUploadedFiles.map((document) => {
      let obj = `{"uploadedOn":"${new Date().toISOString()}", "appId":"${orderData?.applicationId
        }"}`;
      if (document.name) {
        document.name = document.label;
        document.updatedDate = new Date().toISOString();
        document.attributes = JSON.stringify(obj);
        this.apsApiService.savePdfToTheApplication(this.orderData, [document]).subscribe(
          ({ data }) => {
            // document.attributes = res.data['uploadFiles'].documents[0].attributes
            const documentsData = data.uploadFiles.documents[0];
            document.content = documentsData.content;
            document.name = documentsData.name;
            document.url = documentsData.content;
            document.eTag = documentsData.eTag;
            document.documentType = "Special Request Letter"
            this.selectDocument(document)

          }
        );
      }

      document.attributes = obj;
      return document;
    });
    // (this.orderData.orderDocuments?.documents?.length >= 2 && this.uploadedFiles) ? this.store.dispatch(new NgxsHistoryUndo()) : ''
    this.store.dispatch(new RequestOrderPdf(this.latestUploadedFiles))
    this.store.dispatch(new UshgNotification(new NotificationModel(NotificationType.SUCCESS, '', 'File Uploaded Sucessfully')));
    this.file.clear();
    this.ngOnInit()
    this.uploadedFiles = true
    this.orderData.isSpecialRequestLetterUploaded = true
    this.store.dispatch(new CurrentOrderData(this.orderData))

  }

  async generateBlob(bytes): Promise<string> {
    const blob: string = await this.fileService.convertBinaryDatatoBlob(bytes)
    return blob
  }

  getFilenameFromContentDisposition(data: any): string {
    const contentDisposition = data.headers.get('content-disposition')
    const regex = /filename=(?<filename>[^,;]+);/g;
    const match = regex.exec(contentDisposition);
    const filename = match.groups.filename;
    return filename;
  }

  async goToNextStep(ev) {
    this.orderData.orderDocuments = this.orderData.orderDocuments ? this.orderData.orderDocuments : {}
    this.orderData.orderDocuments.documents = [...this.orderData?.orderDocuments?.documents ? this.orderData.orderDocuments?.documents : [], ...this.latestUploadedFiles];
    this.store.dispatch(new SetSpinner());
    await this.apsApiService.saveGeneratedPdfToApplication();
    this.store.dispatch(new ResetSpinner());
    this.store.dispatch(new CurrentOrderData(this.orderData));
    this.navigation.nextStep();
  }

  disableNextButton() {
    const communicationPreferences = this.store.selectSnapshot((state) => state.data.providerPreferences)
    return !communicationPreferences?.specialRequestLetter || this.orderData.isEditOrder ? false : (!this.orderData.isSpecialRequestLetterUploaded ? true : false)
  }

  deleteFile(index) {
    this.documents.splice(index, 1);
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.SUCCESS,
          '',
          'File Deleted Sucessfully'
        )
      )
    );
  }
}
