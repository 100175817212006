import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DialogService } from 'primeng/dynamicdialog';
import { EditOrFollowupOrderComponent } from '../create-order-components/create-order-modal-components/edit-or-followup-order/edit-or-followup-order.component';

import {
  Application,
  AppPolicyInfo,
  HeadersList,
  selectedApplicantdetailsModel,
} from 'src/app/aps/models/application-model';
import {
  ApplicationStatus,
  AuditTypeId,
  ColumnType,
  OrderStatus,
  OrderType,
  RedirectUrl,
} from 'src/app/aps/models/enums';
import { UnderwriterDecision } from 'src/app/aps/models/underswriter-decision';
import {
  APSORDER_STEPS,
  LABORDER_AE_STEPS,
  LAB_AE_HTWTBP_STEPS,
} from 'src/app/aps/navigation-steps/navigation-steps';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

import {
  CurrentOrderData,
  CurrentPageSteps,
  GetUnderWriters,
  LoadSelectedFullApplication,
  ResetState,
  UpdateOrderProcessStatus,
} from 'src/app/store/dispatchers';

import { EditOrderComponent } from '../create-order-components/create-order-modal-components/edit-order/edit-order.component';
import { RequestCheckModalComponent } from '../create-order-components/create-order-modal-components/request-check-modal/request-check-modal.component';
import { RouteToComponent } from '../modals/route-to/route-to.component';
import { Actions } from 'src/app/store/actions';
import { Permission } from 'src/app/aps/models/permission';
import {
  auditLogHeadersList,
  headersList,
  notesHeadersList,
  paymentsCheckHeaders,
  UWDecisionHeadersList,
} from './selected-application-container.config';
import { PermissionService } from 'src/app/aps/services/permission.service';
import { AnalyticsService } from 'src/app/aps/services/analytics.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'ushg-selected-application-container',
  templateUrl: './selected-application-container.component.html',
  styleUrls: ['./selected-application-container.component.scss'],
  providers: [DialogService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectedApplicationContainerComponent
  implements OnInit, OnChanges {
  fullApplication$ = this.store.select(
    (state) => state.data.selectedFullApplication
  );
  currentOrderData = this.store.select((state) => state.data.currentOrderData);
  fullApplication;
  UWDecisionList = [];
  selectedApplicant;
  finalUWDecisionList: UnderwriterDecision[];
  applicants = [];
  paymentCheckData;
  @Input() selectedApplication: Application;
  @Input('config') config;
  selectedApplicantDetailsConfig = selectedApplicantdetailsModel;
  ordersList; //TODO: use a class.
  dataFromModalPopUp;
  logsAndNotes;
  selectedIndex = 0;
  notes = new Array();
  @Input() canEdit: boolean = false;
  UWDecisionHeadersList: HeadersList[] = UWDecisionHeadersList;
  headersList: HeadersList[] = headersList(this);
  auditLogHeadersList: HeadersList[] = auditLogHeadersList;
  notesHeadersList: HeadersList[] = notesHeadersList(this);
  paymentsCheckHeaders: HeadersList[] = paymentsCheckHeaders(this);
  @Input() lockStatus: string;
  @Input() disableActions: boolean;
  constructor(
    private router: Router,
    private store: Store,
    private dialogService: UshgDialogService,
    private apsService: ApsService,
    private permissionService: PermissionService,
    private analyticsService: AnalyticsService
  ) {
  }

  createOrderActions = [
    {
      button: true,
      label: 'Open',
      type: 'primarylg',
      permission: 'CanAccessCreateOrder',
      onClick: (data) => {
        data.orderDetails = this.apsService.gerOrderDetailsByOrderTrackerId(
          data,
          data.orderTrackerId
        );
        data['config'] = this.config;
        this.dispatchOrderData(data);
        this.dialogService.open(
          EditOrFollowupOrderComponent,
          { showFollowUp: true },
          null
        );
      },
      display: 'block mb-2',
    },
    {
      button: true,
      label: 'Create Order',
      type: 'outlinelg',
      permission: 'CanAccessCreateOrder',
      onClick: async (data) => {
        data.orderDetails = this.apsService.gerOrderDetailsByOrderTrackerId(
          data,
          data.orderTrackerId
        );
        data['config'] = this.config;
        this.dispatchOrderData(data);
        this.updateOrderActions(data);
      },
    },
  ];

  followUpOrderActions = [
    {
      button: true,
      label: 'Open',
      type: 'primarylg',
      permission: Permission.CanViewOrderDetails,
      onClick: (data) => {
        data.orderDetails = this.apsService.gerOrderDetailsByOrderTrackerId(
          data,
          data.orderTrackerId
        );
        data['config'] = this.config;
        this.dispatchOrderData(data);
        this.dialogService.open(EditOrFollowupOrderComponent, null, null);
      },
      display: 'block mb-2',
    },
    {
      button: true,
      label: 'Attach',
      type: 'primarylg',
      permission: Permission.CanAccessAttachDocuments,
      show: 1,
      onClick: (data) => {
        data.orderDetails = this.apsService.gerOrderDetailsByOrderTrackerId(
          data,
          data.orderTrackerId
        );
        data['config'] = this.config;
        this.dispatchOrderData(data);
        this.dialogService.open(
          EditOrFollowupOrderComponent,
          { showFollowUp: true },
          null
        );
      },
      display: 'block mb-2',
    },
    {
      button: true,
      label: 'Edit Order',
      type: 'outlinelg',
      permission: Permission.CanAccessAttachDocuments,
      show: 1,
      onClick: async (data) => {
        data.isEditOrder = true;
        data.orderDetails = this.apsService.gerOrderDetailsByOrderTrackerId(
          data,
          data.orderTrackerId
        );
        data['config'] = this.config;
        this.dispatchOrderData(data);
        this.updateOrderActions(data);
      },
      display: 'block mb-2',
    },
    {
      button: true,
      label: 'Request Check',
      type: 'outlinelg',
      permission: Permission.CanAccessFollowUp,
      onClick: (data) => {
        data.orderDetails = this.apsService.gerOrderDetailsByOrderTrackerId(
          data,
          data.orderTrackerId
        );
        data['config'] = this.config;
        this.dispatchOrderData(data);
        this.dialogService.open(RequestCheckModalComponent, data);
      },
      display: 'block mb-2',
    },
  ];

  notesActionsList = [
    {
      icon: 'pi pi-user-edit primarylg',
      label: 'Edit',
      permission: 'CanAccessAttachDocuments',
      show: 1,
      onClick: (data) => {
        this.dispatchOrderData(data);
        this.dialogService.open(EditOrderComponent);
      },
    },
  ];

  ngOnInit(): void {
    this.fetchFullApplicationDetails();
    this.fetchLogsandNotes();
    this.hideActions();
    this.concateRequestTypes();
  }

  hideActions() {
    if (this.selectedApplication?.hideActions) {
      this.headersList = this.headersList.filter(
        (header) => header.label !== 'Actions' && header.label !== 'Documents'
      );
    }
  }

  fetchLogsandNotes() {
    this.store
      .select((state) => state.data.logsAndNotes)
      .subscribe((resp) => {
        this.logsAndNotes = resp.map((note) => {
          note.notes = note;
          return note;
        });
        this.getNotes(this.logsAndNotes);
        this.getDecisionMetrix(this.logsAndNotes);
      });
  }

  fetchFullApplicationDetails() {
    this.store.dispatch(
      new LoadSelectedFullApplication(this.selectedApplication?.applicationId)
    );
    this.fullApplication$.subscribe((data) => {
      this.fullApplication = data;
      this.applicants =
        data && data !== undefined
          ? [data?.primaryApplicant, ...data?.family]
          : [];
      this.selectedApplicant =
        this.applicants.length > 0 ? this.applicants[0] : null;
      this.applicantChanged(this.applicants[0]?.clientNo);
    });
    this.store
      .select((state) => state.data.logsAndNotes)
      .subscribe((resp) => {
        this.logsAndNotes = resp.sort((a, b) => {
          return (
            new Date(b.loggedDate).getTime() - new Date(a.loggedDate).getTime()
          );
        });
        this.getNotes(this.logsAndNotes);
        this.getDecisionMetrix(this.logsAndNotes);
      });
    if (this.selectedApplication?.hideActions) {
      this.headersList = this.headersList.filter(
        (header) => header.label !== 'Actions' && header.label !== 'Documents'
      );
    }
    this.concateRequestTypes();
  }

  generateApsFollowUpButtonsList(data) {
    const actionButtons = this.followUpOrderActions.filter(
      (actionButton) =>
        actionButton.label !== 'Edit' && actionButton.label !== 'Attach'
    );
    actionButtons.map((action) => {
      action['disable'] = this.canEdit;

      if (
        [
          OrderStatus.CloseOut,
          OrderStatus.Completed,
          OrderStatus.Cancelled,
        ].includes(data.orderStatusId)
      ) {

        if (this.config && this.config.isFromApplicationDetailPage) {
          action['disable'] =
            action.label === 'Edit Order'
              ? true
              : false;
        } else {
          action['disable'] =
            action.label === 'Edit Order' || action.label === 'Request Check'
              ? true
              : false;
        }

      }

      return action;
    });
    let buttons = [];
    actionButtons.forEach((actionButton) => {
      this.permissionService
        .IsPermitted(actionButton.permission)
        .subscribe((res) => {
          if (res) {
            buttons.push(actionButton);
          }
        });
    });

    return buttons;
  }

  async followUpProcessOrderStatus(payload) {
    await this.store.dispatch(new UpdateOrderProcessStatus(payload));
  }

  async ngOnChanges() {
    this.store.dispatch(new GetUnderWriters());
    this.headersList = headersList(this);
    this.notesHeadersList = notesHeadersList(this);
    if (this.selectedApplication !== undefined) {
      this.logsAndNotes = this.store
        .selectSnapshot((state) => state.data.logsAndNotes)
        ?.sort((a, b) => {
          return (
            new Date(b.loggedDate).getTime() - new Date(a.loggedDate).getTime()
          );
        });
      //TODO: expose a variable from the actual Application Object

      this.ordersList =
        this.selectedApplication && this.selectedApplication['orderDetails'];
      await this.store.dispatch(
        new LoadSelectedFullApplication(this.selectedApplication?.applicationId)
      );

      if (this.router.url.includes(RedirectUrl.MY_QUEUE)) {
        this.headersList = this.headersList.filter(
          (header) => header.field !== 'aplicationProcessState'
        );
      }
    }
    if (this.config && this.config.isFromApplicationDetailPage) {
      const agentName = this.selectedApplication.agent && (this.selectedApplication.agent.lastName + ", " + this.selectedApplication.agent.firstName);
      this.selectedApplication.agentName = agentName;
    }
    this.ordersList = this.apsService.patchIndividualApplicationDetails(
      this.ordersList,
      this.selectedApplication
    );
    this.getNotes(this.logsAndNotes);
    this.getDecisionMetrix(this.logsAndNotes);
    this.concateRequestTypes();
  }

  getNotes(logsAndNotes) {
    if (!logsAndNotes) {
      this.notes = this.store.selectSnapshot(Actions.AuditLogsById([3, 4, 7]));
      if (this.notes && this.ordersList) {
        this.notes = this.notes
          .map((note) => {
            this.ordersList.forEach((order) => {
              note.orderTrackerId !== null &&
                note.orderTrackerId == order.orderTrackerId
                ? Object.assign(note, order)
                : note;
            });
            return note;
          })
          .sort((a, b) => {
            return (
              new Date(b.loggedDate).getTime() -
              new Date(a.loggedDate).getTime()
            );
          });
      }
    }
  }

  getDecisionMetrix(logsAndNotes) {
    this.UWDecisionList = [];
    if (logsAndNotes !== undefined && logsAndNotes?.length > 0) {
      this.UWDecisionList = this.store.selectSnapshot(
        Actions.AuditLogsById([AuditTypeId.DECISION_MATRIX])
      );
      this.UWDecisionList =
        this.UWDecisionList.length > 0
          ? this.UWDecisionList.map((metric) => {
            metric.information =
              typeof metric?.information === 'string'
                ? JSON.parse(metric?.information)
                : metric?.information;
            return metric;
          })
          : [];
      if (this.UWDecisionList.length > 0) {
        this.UWDecisionList.sort((a, b) => {
          return (
            new Date(b.loggedDate).getTime() - new Date(a.loggedDate).getTime()
          );
        }).forEach((item) => {
          if (
            item?.information?.Data?.Decisions !== undefined &&
            item?.information?.Data?.Decisions?.length > 0
          ) {
            this.UWDecisionList = [
              ...this.UWDecisionList,
              ...item?.information?.Data?.Decisions,
            ];
          }
        });
      }
    }
  }

  get ColumnType(): typeof ColumnType {
    return ColumnType;
  }

  get ApplicationtionStatus(): typeof ApplicationStatus {
    return ApplicationStatus;
  }

  dispatchOrderData(data) {
    this.store.dispatch(new CurrentOrderData(data));
  }

  concateRequestTypes() {
    this.selectedApplication = Object.assign({}, this.selectedApplication);
    this.selectedApplication['requestTypes'] =
      this.selectedApplication?.orderDetails?.reduce((acc, current) => {
        acc.push({ requestType: current.requestType });
        return acc;
      }, []);
  }

  clear() {
    this.store.dispatch(new ResetState());
  }

  openRouteToModal() {
    const selectedApplicationDetails = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    this.dialogService.open(
      RouteToComponent,
      selectedApplicationDetails,
      `${this.selectedApplication.primaryContactName} (${this.selectedApplication.applicationId})`
    );
  }

  applicantChanged(applicantId: any) {
    this.finalUWDecisionList =
      this.UWDecisionList.length > 0
        ? this.UWDecisionList.filter((item) => item.ClientNo === applicantId)
        : [];
    this.finalUWDecisionList.forEach((item) => {
      this.applicants.filter((applicant) => {
        if (applicant.clientNo === item.ClientNo) {
          item.clientName = `${applicant.firstName} ${applicant.lastName}`;
          this.fullApplication.policyInformation.filter((policy) => {
            if (policy.policyNumber === item.PolicyNo) {
              item.policyName = policy.product;
            }
          });
        }
      });
    });
  }

  setIndex(index) {
    this.selectedIndex = index;
  }

  updateOrderActions(data) {
    this.analyticsService.setOrderStarted();
    data.orderStarted = new Date().toISOString();
    this.apsService.mapPoliciesBrcodes( data.applicationId);
    if (data.orderType.requestType === OrderType.LAB_AE) {
      this.clear();
      this.store.dispatch(
        new CurrentPageSteps(JSON.parse(JSON.stringify(LABORDER_AE_STEPS)))
      );
      this.router.navigate(['aps/create-order/laborder-ae-demographic'], {
        ...this.setQueryParams(data),
      });
    } else if (data.orderType.requestType === OrderType.LAB_OE) {
      this.router.navigate(['aps/create-order'], {
        ...this.setQueryParams(data),
      });
    } else if (data.orderType.requestType === OrderType.APS) {
      this.clear();

      this.store.dispatch(
        new CurrentPageSteps(JSON.parse(JSON.stringify(APSORDER_STEPS)))
      );
      this.router.navigate(['aps/create-order/aps-demographic'], {
        ...this.setQueryParams(data),
      });
    } else if (data.orderType.requestType === OrderType.LAB_AE_HTWTBP) {
      this.clear();
      this.store.dispatch(
        new CurrentPageSteps(JSON.parse(JSON.stringify(LAB_AE_HTWTBP_STEPS)))
      );
      this.router.navigate(['aps/create-order/LAB_AE_HTWTBP'], {
        ...this.setQueryParams(data),
      });
    }
    this.dispatchOrderData(data);
  }

  setQueryParams(data) {
    return {
      queryParams: {
        orderId: data.orderTrackerId,
        appId: data.applicationId,
      },
      state: { orderData: data },
    };
  }

  setCurrentOrderPageSteps() {
    this.store.dispatch(
      new CurrentPageSteps(JSON.parse(JSON.stringify(LAB_AE_HTWTBP_STEPS)))
    );
  }
}
