import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'reverseString',
})
export class ReverseStringPipe implements PipeTransform {
  constructor() {}

  transform(value: string, arg: any, arg2: string) {
    if(!value) {
      return value;
    }
    if (arg === arg2) {
      value = value.split(',').reverse().join(' ');
    }
    return value;
  }
}
