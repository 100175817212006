export const detailsToDisplay = [
    {
      label: 'Request Details',
      value: 'requestTypes',
      class: 'col-6'
    },
    {
      label: "Reason",
      value: 'reason',
      class: "col-6"
    },
    {
      label: "Company",
      value: 'company',
      class: "col-6"
    }
  ]

export const requestLettersFormConfig = [

    {
        formControlName: 'Exam/Blood/Urine',
        label: 'Exam/Blood/Urine',
        formControlType: 'textarea',
        rows: 7,
        columns: 55,
        checked: true,
        groupName:"A",
        formControlWidth :"col-12"


    },
    {
        formControlName: 'Urinalysis',
        label: 'Urinalysis',
        formControlType: 'textarea',
        rows: 7,
        columns: 55,
        groupName:"B",
        formControlWidth :"col-6 inline-block"
    },
    {
        formControlName: 'Height/Weight + BP',
        label: 'Height/Weight + BP',
        formControlType: 'textarea',
        rows: 7,
        columns: 55,
        groupName:"B",
        formControlWidth :"col-6 inline-block"
    },
    {
        formControlName: "Saliva",
        label: "Saliva",
        formControlType: 'textarea',
        rows: 7,
        columns: 55,
        groupName:"B",
        formControlWidth :"col-6 inline-block"
    },


    {
        formControlName: "MVR",
        label: "MVR",
        formControlType: 'textarea',
        rows: 7,
        columns: 55,
        groupName:"B",
        formControlWidth :"col-6 inline-block"
    },
    {
        formControlName: "Other",
        label: "Other",
        formControlType: 'textarea',
        rows: 7,
        columns: 55,
        groupName:"B",
        formControlWidth :"col-6 inline-block"
    },
]

export const labletterNotes = [
  {
    name: ['Exam/Blood/Urine', 'Urinalysis', 'Saliva'],
    default: true,
    notes: 'We will need a current Comprehensive Metabolic Panel, Lipid Panel, GGT (gamma-glutamyl transferase), CDT (carbohydrate deficient transferrin), HIV test, Height and Weight measurements, 3 Blood Pressure Readings at 5 minute intervals and a Urinalysis.'
  },
  {
    name: 'Height/Weight + BP',
    notes: 'We will need 3 current Blood Pressure readings (taken at least 5 minutes apart) by a medical provider. You can obtain these readings with a visit to your physician or his office. The doctor or nurse may indicate this information on a prescription pad along with his/her signature and date. We will also need a current height and weight measurement.'
  },
  {
    name: 'MVR',
    notes: ''
  },
  {
    name: 'Other',
    notes: ''
  }
]

export const requestLettersFormConfigForHW = [
  {
      formControlName: 'Height/Weight + BP',
      label: 'Height/Weight + BP',
      formControlType: 'textarea',
      rows: 5,
      columns: 55,
      checked: false,
      groupName:"A",
      formControlWidth :"col-12"
  }
]