<div class="surface-200">
  <div class="flex justify-content-between px-4 py-2">
    <div>
        <h4>Applicant Name <span class="pl-2 text-lg font-medium">{{details.primaryContactName}}</span></h4>
        <h4 class="pt-2">Doctor/Physician <span class="pl-2 text-lg font-medium">{{details.providerName}}</span></h4>
    </div>
    <div>
      <h4>Request Type <span class="pl-2 text-lg font-medium">{{details.requestType}}</span></h4>
      <h4 class="pt-2">Assigned To <span class="pl-2 text-lg font-medium">{{details.assignedTo}}</span></h4>
    </div>
    <div>
      <h4>Status <span class="pl-2 text-lg font-medium">{{OrderStatus[details.orderStatusId]}}</span></h4>
      <h4 class="pt-2">Order Id <span class="pl-2 text-lg font-medium">{{details.applicationId}}</span></h4>
    </div>
  </div>
</div>
