import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';


@Component({
  selector: 'ushg-create-aps-order',
  templateUrl: './create-aps-order.component.html',
  styleUrls: ['./create-aps-order.component.scss']
})
export class CreateApsOrderComponent  {
  steps$ = this.store.select((state) => state.data.currentPageSteps)

  constructor(private store: Store) { }

}
