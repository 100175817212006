<ng-container *ngIf="
    selectedApplication && selectedApplication.primaryApplicant;
    else noData
  ">
  <ushg-applicant-info [selectedApplication]="selectedApplication" [config]="config" [lockStatus]="lockStatus" [disableActions]="disableActions"
   ></ushg-applicant-info>

  <div class="col-12 mt-2">
    <div class="grid">
      <div class="col-12 pl-1 position-rel">
        <p-tabView class="p-0 f12">
          <p-tabPanel header="Requests">
            <div class="grid shadow-3">
              <div class="col-12">
                <ushg-table [rowData]="ordersList" [headerList]="headersList" [rows]="7" [config]="config" [disableActions]="disableActions">
                </ushg-table>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="Logs">
            <div class="grid shadow-5">
              <div class="col-12">
                <ushg-table [rowData]="logsAndNotes" [headerList]="auditLogHeadersList" [rows]="6">
                </ushg-table>
              </div>
            </div>
          </p-tabPanel>
          <p-tabPanel header="UW Decisions">
            <div class="grid shadow-5">
              <div class="col-12">
                <div class="grid p-3">
                  <div class="col-2">
                    <p-listbox [options]="applicants" [(ngModel)]="selectedApplicant" optionLabel="name"
                      [style]="{ border: '0px' }" (onChange)="applicantChanged($event.value.clientNo)">
                      <ng-template pTemplate="header">
                        Applicants & Policies
                      </ng-template>
                      <ng-template let-item pTemplate="item" let-i="index">
                        {{ i + 1 }} &nbsp;&nbsp;{{ item.firstName }} &nbsp;
                        {{ item.lastName }}
                      </ng-template>
                    </p-listbox>
                  </div>
                  <div class="col-10">
                    <ushg-table [rowData]="finalUWDecisionList" [headerList]="UWDecisionHeadersList" [rows]="3">
                    </ushg-table>
                  </div>
                </div>
              </div>
            </div>
          </p-tabPanel>
        </p-tabView>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noData>
  <p-message severity="info" text="No records found" styleClass="p-mr-2 w-100"></p-message>
</ng-template>