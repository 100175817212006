import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { QueryResponse } from 'src/app/aps/models/query-reponse';
import { applicationStatusHistories, auditLogs, listOfApplicationsForInprogress, orders } from 'src/app/aps/queries/query-objects';
import { environment } from 'src/environments/environment';
import { applicationOverviewMetrics, InprogressList } from './temp-nbc-data';
import { HandledHttpClient } from 'src/app/shared/services/handledHttpClient';
@Injectable({
  providedIn: 'root',
})
export class NbcApiService {
  nbcBaseURL: string = environment.serverUrls.nbc;
  nbcLegacyURL : string = environment.serverUrls.nbcLegacy;
  vcallURL : string = environment.serverUrls.vcallURL;
  nbcApi = environment.serverUrls.nbcApiURl;

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private handleHttp: HandledHttpClient
  ) {}

  public getDummyApplicationsOverviewMetrics() {
    return new Observable<any>((o) => {
      const temp = applicationOverviewMetrics;
      o.next(temp);
    });
  }

  public getDummyInprogressApplicationsDetails() {
    return new Observable<any>((o) => {
      const temp = InprogressList;
      o.next(temp);
    });
  }

  public getVCallDataForNBC(
    startDate: string,
    endDate: string
  ): Observable<any> {
    return this.handleHttp.get(
      `${this.nbcBaseURL}/api/callzone/v1/Appointment/Statistics?StartDateUtc=${startDate}&EndDateUtc=${endDate}&IncludeUnknownCallCenters=false`
    );
  }

  getListOfApplicationsBasedOnSearch(
    filterConditions: string
  ): Observable<QueryResponse> {
    return this.apollo.query({
      // query: listOfApplicationsForInprogress(`{createdDate_lte:"2022-04-30T18:30:00.000Z",createdDate_gte:"2020-03-31T18:30:00.000Z"}`),
      query: listOfApplicationsForInprogress(filterConditions),
    });
  }

  getEFulfillment(year):Observable<any>{
    return this.http.get(`${this.nbcLegacyURL}/efulfillment/v1/GetEfulfillmentValues/${year}`);
  }

  getCallDurationMetrics(payload:{FromDate:String,ToDate:String,AppTypeID?:number}):Observable<any>{
    return this.handleHttp.post(`${this.vcallURL}/api/reports/v1/CallDurationMetrics`, payload);
  }

  getNewRequestStatisticsForApsDashboard(FromDate:String, ToDate:String):Observable<any> {
    const payload = {FromDate, ToDate }
    return this.http.post(`${this.nbcApi}api/reports/aps/v1/NewRequests`, payload);
  }

  getCompletedRequestStatisticsForApsDashboard(FromDate:String, ToDate:String):Observable<any> {
    const payload = {FromDate, ToDate }
    return this.http.post(`${this.nbcApi}api/reports/aps/v1/CompletedRequests`, payload);
  }

  getAuditLogs(FromDate:String, ToDate:String):Observable<any> {
    return this.apollo.query({
      query: auditLogs(FromDate, ToDate)
    });
  }
  
  getOrders(FromDate:String, ToDate:String):Observable<any> {
    return this.apollo.query({
      query: orders(FromDate, ToDate)
    });
  }
  
  getUWSPoliciesStatus(FromDate:String, ToDate:String):Observable<any> {
    const payload = { FromDate, ToDate }
    return this.http.post(`${this.nbcApi}api/reports/aps/v1/GetUWSPoliciesStatus`, payload);
  }

  getVcallDataForApplication(appId) {
    return this.http.get(
      `${environment.serverUrls.vCallExportUrl}/${appId}/export`
    );
  }

  getAnswersForApplication(appId) {
    return this.handleHttp.get(`${environment.serverUrls.vCallExportUrl}/${appId}/answer`);
  }

  getCallLogs(appId) {
    return this.handleHttp.get(`${environment.serverUrls.vCallExportUrl}/${appId}/callLog`)
  }

  getVerifierNotes(appId) {
    return this.handleHttp.get(`${environment.serverUrls.vCallExportUrl}/${appId}/Notes`)
  }

  getApplicationStatusHistories(FromDate, ToDate) {
    return this.apollo.query({
      query: applicationStatusHistories(FromDate, ToDate)
    });
  }
}
