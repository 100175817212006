import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';
import { V4MAPPED } from 'dns';
import {
  Application,
  selectedApplicantdetailsModel,
} from 'src/app/aps/models/application-model';
import { ApplicationStatus, ColumnType } from 'src/app/aps/models/enums';
import { LockOrReleaseApplication } from 'src/app/aps/models/lock-or-release-application';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

import { RouteToComponent } from '../../modals/route-to/route-to.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'ushg-applicant-info',
  templateUrl: './applicant-info.component.html',
  styleUrls: ['./applicant-info.component.scss'],
})
export class ApplicantInfoComponent implements OnChanges {
  timeZone: string = this.store.selectSnapshot(
    (state) => state.data.userProfile
  ).timeZone;
  profile = this.store.selectSnapshot((state) => state.data.userProfile);
  canDisplayFollowupLockFeature = environment.feature.followupLock;

  @Input('config') public config;
  applicationTracker: any;
  @Input() lockStatus: string;
  @Input() disableActions: boolean;
  @Input() public selectedApplication: Application;
  lockViewModel: LockOrReleaseApplication;

  constructor(private store: Store, private dialogService: UshgDialogService, private apsService: ApsService) { 
  }
  ngOnChanges(changes: SimpleChanges): void {
    for (let propName in changes) {
      if (propName == 'selectedApplication') {
        this.applicationTracker =
          this.selectedApplication &&
          this.selectedApplication.applicationTracker.find(
            (res) => res.id === this.selectedApplication.applicationTrackerId
          );
      }
    }
  }


  public selectedApplicantDetailsConfig = selectedApplicantdetailsModel;

  public get ApplicationtionStatus(): typeof ApplicationStatus {
    return ApplicationStatus;
  }

  public get ColumnType(): typeof ColumnType {
    return ColumnType;
  }

  public openRouteToModal(): void {
    let selectedApplicationDetails = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    selectedApplicationDetails['config'] = this.config;
    this.dialogService.open(
      RouteToComponent,
      selectedApplicationDetails,
      `${this.selectedApplication.primaryContactName} (${this.selectedApplication.applicationId})`
    );
  }
  get lockedUser() {
    const selectedApplication: Application = this.store.selectSnapshot(
      (state) => state.data.selectedApplicationDetails
    );
    this.lockViewModel = new LockOrReleaseApplication(selectedApplication, this.profile);
    return this.lockViewModel.lockedByUser;
  }

  lockOrReleaseApplication(event?) {
    this.apsService.lockOrReleaseFollowUpApplication(event);
  }
}
