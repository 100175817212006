import { Directive, ElementRef, Input, OnInit } from '@angular/core';
@Directive({
  selector: '[appChangeBackgroundColor]',
})
export class ChangeBackgroundColorDirective implements OnInit {
  @Input('backGroundColor') backGroundColor;
  constructor(private element: ElementRef) {}
  ngOnInit(): void {
    this.element.nativeElement.style.backgroundColor = this.backGroundColor
      ? this.backGroundColor
      : 'lightgrey';
  }
}
