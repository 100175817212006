import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpMessageInterceptorService } from './http-message-interceptor.service';
import { ErrorInterceptor } from './StaleDataInterceptor';
export const HttpInterceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpMessageInterceptorService,
    multi: true,
  }, {
    provide: HTTP_INTERCEPTORS,
    useClass: ErrorInterceptor,
    multi: true
  }
];
