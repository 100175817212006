import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ErrorService {
  public errors$: BehaviorSubject<any>;
  constructor() {
    this.errors$ = new BehaviorSubject([]);
    this.errors$.subscribe();
  }

  public addError(error: any) {
    this.errors$.next(this.errors$.value.concat([error]));
  }
}
