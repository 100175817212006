import { Component, Input, OnInit } from '@angular/core';
import { OrderStatus } from 'src/app/aps/models/enums';
import { DetailsToDisplay } from 'src/app/aps/models/ui-models';

@Component({
  selector: 'ushg-details-container',
  templateUrl: './ushg-details-container.component.html',
  styleUrls: ['./ushg-details-container.component.scss'],
})
export class UshgDetailsContainerComponent {
  @Input() detailsToDisplay: DetailsToDisplay[];
  @Input() data;

  constructor() {}

  get OrderStatus(): typeof OrderStatus {
    return OrderStatus;
  }
}
