import {
  ChangeDetectorRef,
  Component,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { TemplateTitle } from 'src/app/aps/models/enums';
import {
  CommunicationPreferences,
  Contact,
  Documents,
  HealthDiscloureInformation,
  NotificationModel,
  NotificationType,
  ProviderCityStateZip,
  ProviderModel,
} from 'src/app/aps/models/ui-models';
import { NavigationService } from 'src/app/aps/services/aps-navigation-service';
import { ApsService } from 'src/app/aps/services/aps-service';
import { DemographicDetailsComponent } from 'src/app/shared/demographic-details/demographic-details.component';

import { Navigation } from 'src/app/shared/services/navigation';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { UshgFormService } from 'src/app/shared/services/ushg-form-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import {
  CurrentOrderData,
  GeneratePdf,
  RequestOrderPdf,
  ResetRequestOrderPdf,
  UpdateOrderProperties,
  UshgNotification,
} from 'src/app/store/dispatchers';
import { ProviderLookupComponent } from '../../../modals/provider-lookup/provider-lookup.component';
import {
  apsDemographicFormConfig,
  communicationPaymentPreferencesKeys,
  doctorDetailsConfigKeys,
  healthInformationForAuthorizationLetter,
} from './aps-demographic-config';
import { ApsApiService } from 'src/app/aps/services/aps-api-service';
import { Actions } from 'src/app/store/actions';
import { Observable } from '@apollo/client/utilities';
import { StateModel } from 'src/app/store/state-model';
import { UtilityService } from 'src/app/aps/services/utility-service';
import { FileService } from 'src/app/shared/services/file-service';
import { Application, ProfileModel } from 'src/app/aps/models/application-model';

@Component({
  selector: 'app-create-aps-order-demographic',
  templateUrl: './create-aps-order-demographic.component.html',
  styleUrls: ['./create-aps-order-demographic.component.scss'],
})
export class CreateApsOrderDemographicComponent
  extends Navigation
  implements OnInit {
  @ViewChild('doctorDemographicDetails')
  doctorDemographicDetails: DemographicDetailsComponent;
  requestOrderPdf = this.store.selectSnapshot(
    (state) => state.data.requestOrderPdf
  );
  @Select(Actions) state: Observable<StateModel>;
  orderData = this.store.selectSnapshot((state) => state.data.currentOrderData);
  currentOrderData: Application;
  apsDemographicFormGroup: UntypedFormGroup;
  applicationDetailsconfig = {
    hideNavigationButtons: true,
    hideEdit: false,
    label: 'Applicant Details',
    keepSaveEnabled: false,
  };
  healthInformationForAuthorizationLetterConfig = {
    hideNavigationButtons: true,
    hideEdit: true,
    label: 'Disclosure of Health Information For Authorization Letter',
  };
  healthInformationForAuthorizationLetterKeys =
    healthInformationForAuthorizationLetter;
  doctorDetailsconfig = {
    hideNavigationButtons: true,
    hideEdit: false,
    detailsDirection: 'column',
    label: 'Doctor Details',
    keepSaveEnabled: false,
    editMenu: true,
    editMenuItems: [
      {
        items: [
          {
            label: 'Edit',
            command: () => {
              let keys = JSON.parse(JSON.stringify(doctorDetailsConfigKeys));
              this.doctorDetailsKey = keys.map((each) => {
                const validators = doctorDetailsConfigKeys.find(
                  (key) => key.formControlName === each.formControlName
                ).validators;
                if (each.formControlName === 'fullname') {
                  each.disable = true;
                }
                each.validators = validators;
                return each;
              });
              this.doctorDemographicDetails.editButtonHandler(true);
            },
          },
          {
            label: 'Clone',
            command: () => {
              this.doctorDetailsKey = doctorDetailsConfigKeys;
              this.doctorDemographicDetails.editButtonHandler(true);
            },
          },
          //feature has been removed
          // {
          //   label: 'Add',
          //   command: () => {
          //     const dialogRef = this.dialogService.open(ProviderLookupComponent)
          //     dialogRef.onClose.subscribe(async (res) => {
          //       this.changeDetector.detectChanges()
          //       await this.getProviderReferences();
          //       this.store.dispatch(new ResetRequestOrderPdf());
          //       this.currentOrderData.orderDetails.providerMode="Insert";
          //       this.store.dispatch(new CurrentOrderData(this.currentOrderData));
          //     });
          //   }
          // },
        ],
      },
    ],
  };
  communicationPaymentPreferencesConfig = {
    hideNavigationButtons: true,
    detailsDirection: 'column',
    label: 'Communication & Payment Preferences',
    labelClass: true,
    keepSaveEnabled: false,
  };
  doctorDetailsKey = doctorDetailsConfigKeys;
  communicationPaymentPreferencesKeys = communicationPaymentPreferencesKeys;
  communicationPaymentDetials;
  demographicDetailsConfig = apsDemographicFormConfig;
  isNextButtonDisabled = false;
  communicationPaymentPreferences: CommunicationPreferences;
  specialRequestLetterFile: Documents;
  isSpecialRequestLetterRequired: Boolean;
  docsList: ProviderModel[];
  selectedDocFromDropDown = {};
  agentNotes: string;
  isFormDirty: boolean;
  formInEditMode = new Array<{ formName: string; value: boolean }>();
  profile: ProfileModel;
  copyServiceType:string;

  constructor(
    private navigation: NavigationService,
    private formService: UshgFormService,
    private store: Store,
    private apsService: ApsService,
    private sanitizer: DomSanitizer,
    private dialogService: UshgDialogService,
    private apsApiService: ApsApiService,
    private changeDetector: ChangeDetectorRef,
    private utilityService: UtilityService,
    private fileService: FileService
  ) {
    super(navigation);
  }

  async ngOnInit() {
    this.profile = this.store.selectSnapshot((state) => state.data.userProfile);
    this.store.dispatch(new ResetRequestOrderPdf());
    if (this.orderData.policyInformation.length > 0) {
      this.orderData.policyInformation.forEach((policy) => {
        this.demographicDetailsConfig[0].options.push({
          label: policy.policyNumber,
          value: policy.policyNumber,
        });
      });
      this.orderData.orderApplicant.policyNumber = this.orderData
        .applicantOverride?.policyNumber
        ? this.orderData.applicantOverride?.policyNumber
        : this.orderData.orderApplicant.policyNumber
          ? this.orderData.orderApplicant.policyNumber
          : this.demographicDetailsConfig[0].options?.length > 0
            ? this.demographicDetailsConfig[0].options[0].value
            : '';
      this.orderData.orderDetails.policyNumber =
        this.orderData.orderApplicant.policyNumber;
    }
    this.state.subscribe((res) => {
      this.currentOrderData = res.currentOrderData as Application;
      this.isFormDirty = res.isFormDirty;
    });
    this.currentOrderData.disclosureHealthInformation =
      this.patchDisclosureHealthInformation();

    await this.getProviderReferences();
    if (!this.requestOrderPdf.length && !this.orderData.isEditOrder) {
      // this.generateApsRequiredPdf()
    }
  }

  async getProviderReferences() {
    this.communicationPaymentPreferences =
      await this.apsService.getProviderPreferences(
        this.currentOrderData.APS.providerOverride ? this.currentOrderData.APS.providerOverride.id : this.currentOrderData.APS.provider.id
      );
    this.copyServiceType = this.currentOrderData.properties ? this.currentOrderData.properties.find((property)=> property.key === "preferredCopyService").value : '' ;
    this.communicationPaymentPreferences = {
      ...this.communicationPaymentPreferences,
      preferredCopyService: this.communicationPaymentPreferences.communicationMode === 'COPY_SERVICE' ?  this.copyServiceType : '',
    } 
    if (!this.communicationPaymentPreferences.electronicSignature) {
      this.communicationPaymentPreferencesConfig['keepCancelDisabled'] = true;
    }
    this.createForm();
    this.formChanges();
  }

  createForm() {
    this.apsDemographicFormGroup = this.formService.createForm(
      [
        ...this.communicationPaymentPreferencesKeys,
        ...this.demographicDetailsConfig,
        ...this.doctorDetailsKey,
        ...this.healthInformationForAuthorizationLetterKeys,
      ],
      {
        ...this.communicationPaymentPreferences,
        ...this.currentOrderData.orderApplicant,
        ...this.currentOrderData.APS.providerOverride ? this.currentOrderData.APS.providerOverride : this.currentOrderData.APS.provider,
        ...this.currentOrderData.disclosureHealthInformation,
        ...this.currentOrderData.orderDetails,
      }
    );
  }

  removeSpecialRequestLetterFile() {
    this.communicationPaymentPreferences.documents = null;
  }

  getFormValidity() {
    return this.healthInformationForAuthorizationLetterKeys.every((key) => {
      return this.apsDemographicFormGroup.controls[key.formControlName]?.valid;
    });
  }

  sanitize(binaryData) {
    var blob = new Blob([this.fileService.stringToUint8Array(binaryData)], {
      type: 'application/pdf',
    });
    let url = URL.createObjectURL(blob);
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  async isFormInEditMode($event, formName) {
    let formExist = this.formInEditMode.find((f) => f.formName === formName);
    if (formExist) {
      formExist.value = $event;
    } else {
      this.formInEditMode.push({ formName: formName, value: $event });
    }
    this.isNextButtonDisabled =
      this.formInEditMode.filter((f) => f.value !== false).length > 0;
    this.communicationPaymentPreferences = this.providerPreferences;
    if(this.copyServiceType) {
      this.communicationPaymentPreferences['preferredCopyService'] = this.communicationPaymentPreferences.communicationMode === 'COPY_SERVICE' ?  this.copyServiceType : '';
    }
  }

  get communicationPaymentPreferencesDocuments() {
    return this.store.selectSnapshot((state) => state.data.providerPreferences)
      .documents;
  }

  get providerPreferences() {
    return this.store.selectSnapshot((state) => state.data.providerPreferences);
  }

  formChanges() {
    this.isSpecialRequestLetterRequired = this.communicationPaymentPreferences
      .specialRequestLetter
      ? true
      : false;

    this.apsDemographicFormGroup
      .get('providerFullName')
      ?.valueChanges.subscribe((res) => {
        this.providerDropDownValueChangesHandler(res);
      });
    
    if(this.apsDemographicFormGroup.get('communicationMode').value === "COPY_SERVICE") {
      this.apsDemographicFormGroup.get('preferredCopyService').enable();
    }
    else {
      this.apsDemographicFormGroup.get('preferredCopyService').disable();
      this.apsDemographicFormGroup.get('preferredCopyService').setValue('');
    }

    this.apsDemographicFormGroup.get('communicationMode')?.valueChanges.subscribe((res)=>{
      this.communicationModeDropDownValueChangesHandler(res);
    }); 
  }

  communicationModeDropDownValueChangesHandler(res) {
    if(res === "COPY_SERVICE") {
      this.apsDemographicFormGroup.get('preferredCopyService').enable();
      this.apsDemographicFormGroup.get('preferredCopyService').setValidators([Validators.required]);
      this.apsDemographicFormGroup.get('preferredCopyService').updateValueAndValidity();
    }
    else {
      this.apsDemographicFormGroup.get('preferredCopyService').disable();
      this.apsDemographicFormGroup.get('preferredCopyService').setValue('');
      this.apsDemographicFormGroup.get('preferredCopyService').clearValidators();
      this.apsDemographicFormGroup.get('preferredCopyService').updateValueAndValidity();
    }
  }

  providerDropDownValueChangesHandler(res) {
    this.selectedDocFromDropDown = this.docsList.find(
      (doc) => doc.fullname === res
    );
    const formControlKeys = this.doctorDetailsKey.map(
      (keys) => keys.formControlName
    );
    this.apsDemographicFormGroup.patchValue({
      ...formControlKeys,
      ...this.selectedDocFromDropDown,
    });
  }

  generateApsRequiredPdf() {
    const communicationPreferences = this.store.selectSnapshot(
      (state) => state.data.providerPreferences
    );
    const electronicSignature = communicationPreferences.electronicSignature;
    if (electronicSignature === 'true' || electronicSignature === 'N/A') {
      this.store.dispatch(new GeneratePdf(TemplateTitle.AUTHORIZATION));
    } else if (electronicSignature === 'false') {
      this.store.dispatch(new GeneratePdf(TemplateTitle.AUTHORIZATION));
      this.store.dispatch(
        new GeneratePdf(TemplateTitle.AUTHORIZATION_WITHOUT_E_SIGNATURE)
      );
    }

    !communicationPreferences.specialRequestLetter
      ? this.store.dispatch(new GeneratePdf(TemplateTitle.REQUESTLETTER))
      : '';
  }

  async saveCommunicationAndPreferences(ev) {
    ev.trunAroungTime = ev.trunAroungTime ? ev.trunAroungTime : '';
    await this.apsService.saveProviderPreferences(null, ev);
    this.communicationPaymentPreferences = this.store.selectSnapshot(
      (state) => state.data.providerPreferences
    );
    this.communicationPaymentPreferencesConfig['keepCancelDisabled'] = false;
    if (this.communicationPaymentPreferences?.documents?.label) {
      this.communicationPaymentPreferences.documents.label =
        'Special Request Letter.pdf';
    }
    this.saveCopyServiceType(ev);
  }

  async saveCopyServiceType(event) {
    this.copyServiceType = event.preferredCopyService;
    const properties = [{"key": 'preferredCopyService', "value": this.copyServiceType ? this.copyServiceType : ''}]
    const payload = {
      orderTrackerId: this.currentOrderData.orderTrackerId,
      Id: this.currentOrderData.id,
      CreatedBy: this.currentOrderData.orderDetails.updatedBy ? this.currentOrderData.orderDetails.updatedBy : this.profile.domainUserName,
      properties: properties,
    }
    await this.store.dispatch(new UpdateOrderProperties(payload));
  }

  saveApplicationDetails(ev) {
    this.currentOrderData.orderApplicant.applicantssn = ev.ssn;
    this.currentOrderData.orderApplicant = ev;
    this.currentOrderData.orderContactName = `${ev.firstName} ${ev.lastName}`;
    this.currentOrderData.AuthDisclAppSign =
      this.utilityService.capitalizeSentense(
        this.currentOrderData.orderContactName
      );
      this.currentOrderData.applicantOverride = ev;
      this.currentOrderData.orderDetails.applicantOverride = ev;
      this.currentOrderData.contactOverride = new Contact(ev);
    this.currentOrderData.orderDetails.contactOverride = new Contact(ev);
    this.currentOrderData.contact = new Contact(ev);
    this.orderData.orderDetails.policyNumber =
      this.orderData.orderApplicant.policyNumber;
    this.store.dispatch(new CurrentOrderData(this.currentOrderData));
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.SUCCESS,
          '',
          'Changes Updated Sucessfully'
        )
      )
    );
    this.store.dispatch(new ResetRequestOrderPdf());
  }

  async saveProviderDetails(ev) {
    this.currentOrderData.orderDetails.APS.providerOverride = {
      ...this.selectedDocFromDropDown,
      ...ev,
    };
    this.currentOrderData.orderDetails.APS.providerOverride.state = ev.providerState;
    this.currentOrderData.orderDetails.APS.providerOverride.streetAddress =
      ev.providerstreetAddress;
    //this key has been added to patch to the key in request letter PDF will not be stored in DB
    this.currentOrderData.orderDetails.APS.providerOverride.providerCityStateZip =
      new ProviderCityStateZip(
        this.currentOrderData.orderDetails.APS.providerOverride
      ).cityStateZip;
    this.communicationPaymentPreferences =
      await this.apsService.getProviderPreferences(
        this.currentOrderData.orderDetails.APS.providerOverride.providerFullName === ev.fullname
          ? this.currentOrderData.provider.id
          : null
      );
    this.currentOrderData.orderDetails.APS.providerOverride.providerFullName = ev.fullname;
    this.currentOrderData.orderDetails.providerMode = 'updated';
    this.currentOrderData.orderDetails.APS.providerOverride.phone = ev.providerPhone;
    this.currentOrderData.orderDetails.APS.providerOverride.fax = ev.providerFax;
    this.store.dispatch(new CurrentOrderData(this.currentOrderData));
    this.store.dispatch(new ResetRequestOrderPdf());
    this.store.dispatch(
      new UshgNotification(
        new NotificationModel(
          NotificationType.SUCCESS,
          '',
          'Changes Updated Sucessfully'
        )
      )
    );

    // commented as functionality removed

    // if (this.selectedDocFromDropDown['id']) {
    //   this.communicationPaymentPreferences = await this.apsService.getProviderPreferences(this.selectedDocFromDropDown['id']);
    //   const communicationPaymentPreferencesKeys = this.communicationPaymentPreferencesKeys.map((keys) => keys.formControlName);
    //   this.apsDemographicFormGroup.patchValue({
    //     ...communicationPaymentPreferencesKeys, ...this.communicationPaymentPreferences,
    //   })
    // }
  }

  get isSpecialRequestLetter() {
    return this.communicationPaymentPreferences?.specialRequestLetter;
  }

  getAgentNotes(ev) {
    this.currentOrderData.orderDetails.agentNotes = ev.target.value;
    this.store.dispatch(new CurrentOrderData(this.currentOrderData));
  }

  get OrderDetails() {
    this.currentOrderData.orderDetails['agentNotes'] === null
      ? (this.currentOrderData.orderDetails['agentNotes'] =
        this.currentOrderData.orderDetails['APS']['uWSNotes'])
      : this.currentOrderData.orderDetails['agentNotes'];
    return this.currentOrderData.orderDetails;
  }

  apsDemographicBackButtonEventHandler() {
    this.apsService.redirectToBase();
  }

  async demographicnextButtonEventHandler(ev) {
    const currentOrderData = this.store.selectSnapshot(
      (state) => state.data.currentOrderData
    );
    currentOrderData.APS.disclosureHealthInformation =
      new HealthDiscloureInformation(this.apsDemographicFormGroup.value);
    this.store.dispatch(
      new CurrentOrderData({
        ...currentOrderData,
        ...this.apsDemographicFormGroup.value,
      })
    );
    currentOrderData.isEditOrder
      ? this.store.dispatch(
        new RequestOrderPdf(currentOrderData.orderDocuments.documents)
      )
      : '';
    this.generateApsRequiredPdf();
    this.navigationService.nextStep();
  }

  patchDisclosureHealthInformation() {
    return this.currentOrderData.APS.disclosureHealthInformation
      ? { ...this.currentOrderData.APS.disclosureHealthInformation }
      : {
        ...new HealthDiscloureInformation(),
      };
  }

  ngOnDestroy() {
    this.demographicDetailsConfig[0].options = [];
  }

}
