<div class="results-cnt mx-4 pb-0 pt-3">
  <div class="verication-cnt carousel-steps">
    <div class="grid flex-nowrap mb-5 mt-2 carousel-cnt-items ">
      <ng-container  *ngFor="let section of steps ; first as isFirst; let last = last">
        <div class="col d-flex title border-right-0 pr-0 " [ngClass]="{
              'item-first': isFirst,
              'item-active': section.status === stepStatus.ACTIVE,
              'item-complete': section.status === stepStatus.COMPLETED
            }" title="{{ section.label }}">
          <div class="grid flex-nowrap align-items-center mt-0">
            <i *ngIf="section.status === stepStatus.COMPLETED" class="pi
              pi-check-circle completed-circle" aria-hidden="true"></i>
            <div class="progress-circle" *ngIf="section.status !== stepStatus.COMPLETED" >
  
            </div>
            <div class="col d-none d-lg-flex d-flex m-w-200">{{ section.label }}</div>
          </div>
        </div> 
        <span class="new-diagonal"  [ngClass]="{
          'hide-diagonal': last
        
        }"></span>

      </ng-container>
    </div>
  </div>
</div>