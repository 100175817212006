import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'ushg-date',
  templateUrl: './ushg-date.component.html',
  styleUrls: ['./ushg-date.component.scss']
})
export class ApsDateComponent implements OnInit {
  @Input() value: string|null = ''
  constructor(private date: DatePipe) { }

  ngOnInit(): void {
    
    this.value=this.date.transform(this.value,'MM/dd/yyyy')
  }

}
