export const STATES = [
  { name: 'AA', value: 'AA' },
  { name: 'AE', value: 'AE' },
  { name: 'AK', value: 'AK' },
  { name: 'AL', value: 'AL' },
  { name: 'AP', value: 'AP' },
  { name: 'AR', value: 'AR' },
  { name: 'AS', value: 'AS' },
  { name: 'AZ', value: 'AZ' },
  { name: 'CA', value: 'CA' },
  { name: 'CO', value: 'CO' },
  { name: 'CT', value: 'CT' },
  { name: 'DC', value: 'DC' },
  { name: 'DE', value: 'DE' },
  { name: 'FL', value: 'FL' },
  { name: 'FM', value: 'FM' },
  { name: 'GA', value: 'GA' },
  { name: 'GU', value: 'GU' },
  { name: 'HI', value: 'HI' },
  { name: 'IA', value: 'IA' },
  { name: 'ID', value: 'ID' },
  { name: 'IN', value: 'IN' },
  { name: 'IL', value: 'IL' },
  { name: 'KS', value: 'KS' },
  { name: 'KY', value: 'KY' },
  { name: 'LA', value: 'LA' },
  { name: 'MA', value: 'MA' },
  { name: 'MD', value: 'MD' },
  { name: 'ME', value: 'ME' },
  { name: 'MH', value: 'MH' },
  { name: 'MI', value: 'MI' },
  { name: 'MO', value: 'MO' },
  { name: 'MN', value: 'MN' },
  { name: 'MP', value: 'MP' },
  { name: 'MS', value: 'MS' },
  { name: 'MT', value: 'MT' },
  { name: 'NC', value: 'NC' },
  { name: 'ND', value: 'ND' },
  { name: 'NE', value: 'NE' },
  { name: 'NH', value: 'NH' },
  { name: 'NJ', value: 'NJ' },
  { name: 'NM', value: 'NM' },
  { name: 'NV', value: 'NV' },
  { name: 'NY', value: 'NY' },
  { name: 'OH', value: 'OH' },
  { name: 'OK', value: 'OK' },
  { name: 'OR', value: 'OR' },
  { name: 'PR', value: 'PR' },
  { name: 'PW', value: 'PW' },
  { name: 'PA', value: 'PA' },
  { name: 'RI', value: 'RI' },
  { name: 'SC', value: 'SC' },
  { name: 'SD', value: 'SD' },
  { name: 'TN', value: 'TN' },
  { name: 'TX', value: 'TX' },
  { name: 'UT', value: 'UT' },
  { name: 'VA', value: 'VA' },
  { name: 'VI', value: 'VI' },
  { name: 'VT', value: 'VT' },
  { name: 'WA', value: 'WA' },
  { name: 'WI', value: 'WI' },
  { name: 'WV', value: 'WV' },
  { name: 'WY', value: 'WY' },
];
