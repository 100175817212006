<ushg-application-detail-header [details]="createOrderData"></ushg-application-detail-header>
<div class="bg-light-gray pt-3">
  <div class="container bg-white shadow-3 mb-4">
    <ng-container [ngSwitch]='createOrderData?.orderType.requestType'>
      <ng-container *ngSwitchCase='"LAB_AE"'>
        <ushg-navigation-header [steps]="steps$"></ushg-navigation-header>
        <router-outlet></router-outlet>
      </ng-container>
      <ng-container *ngSwitchCase='"LAB_AE_HTWTBP"'>
        <ushg-navigation-header [steps]="steps$"></ushg-navigation-header>
        <router-outlet></router-outlet>
      </ng-container>
      <ng-container *ngSwitchCase='"LAB_OE"'>
        <ushg-create-lab-order-oe [orderDetails]="createOrderData"></ushg-create-lab-order-oe>
      </ng-container>
      <ng-container *ngSwitchCase='"APS"'>
        <ushg-create-aps-order></ushg-create-aps-order>
      </ng-container>
    </ng-container>
  </div>
</div>
