import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { PermissionService } from 'src/app/aps/services/permission.service';
/**
 * Authorization Guard
 */
@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate {
  /**
   * Authorization guard component constructor
   * @param adalSvc Adal service
   * @param route ActivatedRoute
   * @param userProfileService User profile service
   */
  constructor(
    private router: Router,
    private permissionService: PermissionService
  ) {}
  /**
   * Checks if user is authorized & returns boolean
   * @param next Activated route snapshot
   * @param state Router state snapshot
   */
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const requiredPermission = next.data.requiredPermission;

    if (!requiredPermission) {
      return true;
    }

    return new Observable<boolean>((o) => {
      this.permissionService
        .IsPermitted(requiredPermission)
        .subscribe((authorized) => {
          console.log(
            'Permission Guard Authorized = ',
            authorized,
            ' for ',
            requiredPermission
          );
          if (authorized) {
            o.next(true);
          } else {
            this.router.navigate(['not-authorized']);
            o.next(false);
          }
        });
    });
  }
}
