import { Component, Input, OnInit } from '@angular/core';
import { NavigationStepStatus } from 'src/app/aps/models/enums';
@Component({
  selector: 'ushg-navigation-header',
  templateUrl: './navigation-header.component.html',
  styleUrls: ['./navigation-header.component.scss']
})
export class NavigationHeaderComponent implements OnInit {
  @Input() steps
  constructor() { }

  ngOnInit(): void {
    this.steps.subscribe((step) => {
      this.steps = [...new Map(step.map(item => [item['label'], item])).values()]
    })
  }


  get stepStatus(): typeof NavigationStepStatus {
    return NavigationStepStatus
  }
}
