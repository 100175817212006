import { ChangeDetectorRef, Component } from '@angular/core';
import { ApsService } from 'src/app/aps/services/aps-service';

@Component({
  selector: 'app-authorization-forms-container',
  templateUrl: './authorization-forms-container.component.html',
  styleUrls: ['./authorization-forms-container.component.scss']
})
export class AuthorizationFormsContainerComponent {
  enableListGrid: boolean
  authForms;
  showSpinner: boolean = false;
  pageIndex: number = 0;
  constructor(private service: ApsService, private cd: ChangeDetectorRef) { }

  searchAuthFiles(searchFormGroup) {
    this.enableListGrid = true;
    this.showSpinner = true;
    this.service.searchAuthorizationFiles(searchFormGroup).subscribe((res) => {
      this.authForms = res;
      this.showSpinner = false;
    },
      (err) => { console.log('err', err); });
  }

  resetFormsList(event) {
    this.authForms = [];
    this.pageIndex = -1; // Temporarily set to a different value when reset to detect
    this.cd.detectChanges(); 
    this.pageIndex = 0;
    this.cd.detectChanges(); 
    this.enableListGrid = false;
  }

}
