import { Component, Input, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { IDialogAware } from 'src/app/aps/models/IDialogAware';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, IDialogAware {
  progress:any;
  config = {
    width: '35%',
    closable: false
  };
  constructor(
    public dialogService: DynamicDialogConfig,
    public dialog: UshgDialogService,
  ) {}

  ngOnInit() {
    
  }
}
