import { Directive, ElementRef, Input, OnInit } from "@angular/core";
import { Permission } from "src/app/aps/models/permission";
import { PermissionService } from "src/app/aps/services/permission.service";


@Directive({
    selector: '[appPermission]'
})

export class PermissionDirective implements OnInit {
    @Input() appPermission: any;
    constructor(private el: ElementRef,
                private permissionService: PermissionService) {}

    ngOnInit() {
        this.permissionService.IsPermitted(this.appPermission).subscribe((permitted) => {
            if (!permitted) this.el.nativeElement.style.display = 'none';
            else this.el.nativeElement.style.display = '';
        })
    }
}