<form [formGroup]="searchForm" id="searchForm" (submit)="searchAuthorizationForms()">
  <div class="d-flex">
    <div class="mr-3 search-col">
      <label for="doctorName">{{SearchAuthorizationFormLabels.doctorName}}</label>
      <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchAuthorizationForms()"
        pInputText name="applicationId" id="applicationId" formControlName="doctorName" autocomplete="off"
        class="w-full" />
    </div>
    <div class="mr-3 search-col">
      <label for="facilityName">{{SearchAuthorizationFormLabels.facilityName}}</label>
      <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchAuthorizationForms()"
        pInputText formControlName="facilityName" class="w-full" autocomplete="off" />
    </div>
    <div class="mr-3 search-col">
      <label for="state">{{SearchAuthorizationFormLabels.state}} </label>
      <p-dropdown id="state" formControlName="state" [options]="usStates" optionLabel="VALUE"
        placeholder="Select a State" autocomplete="off" class="w-full"></p-dropdown>
    </div>
    <div class="mr-3 search-col">
      <label for="city">{{SearchAuthorizationFormLabels.city}}</label>
      <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchAuthorizationForms()"
        pInputText formControlName="city" class="w-full" autocomplete="off" />
    </div>
    <div class="mr-3 search-col">
      <label for="zipCode">{{SearchAuthorizationFormLabels.zipCode}}</label>
      <input id="float-input" type="text" (keyup.enter)="searchForm.invalid===false && searchAuthorizationForms()"
        pInputText formControlName="zipCode" class="w-full" autocomplete="off" />
    </div>
    <div class="search-col-submit">
      <span class="mr-2"><ushg-button [label]="'Reset'" [type]="'outline'" (click)="onReset()"></ushg-button></span>
      <ushg-button [label]="'Search'" [type]="'primary'" (click)="searchAuthorizationForms()"
        [disable]="searchForm.invalid">
      </ushg-button>
    </div>
  </div>
</form>