import { query } from '@angular/animations';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { EMPTY, EmptyError, Observable, of, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { ErrorMessages } from 'src/app/aps/models/enums';
import {
  NotificationModel,
  NotificationType,
} from 'src/app/aps/models/ui-models';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { UshgNotification } from 'src/app/store/dispatchers';
import { LoggerService } from '../services/logger-service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private toaster: ToastrService,
    private loggerService: LoggerService,
    private store: Store
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      // There may be other events besides response
      filter((event) => event instanceof HttpResponse),
      tap((event: HttpResponse<any>) => {
        const errors = event.body.errors;
        try {
          var req = JSON.parse(JSON.stringify(request));
          if (req.body && req.body.query) {
            var { operationName: name, query, variables } = req.body;
          } else {
            var { body: payload } = req;
          }
          var generatedError = {
            url: req.url,
            query,
            name,
            message: errors,
            variables,
            payload,
          };
        } catch (err) {
          console.log(err);
        }
        if (
          errors &&
          errors[0].extensions &&
          errors[0].extensions.code === '409'
        ) {
          this.loggerService.logException(generatedError);
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.ERROR,
                'Error',
                ErrorMessages.staleDataErrorMessage
              )
            )
          );
        } else if (
          errors &&
          errors[0].extensions &&
          errors[0].extensions.code === '404'
        ) {
          return of(new HttpResponse({ status: 200, body: errors.json }));
        } else if (errors) {
          this.loggerService.logException(generatedError);
          const actionType = Object.keys(event.body.data)[0];
          this.store.dispatch(
            new UshgNotification(
              new NotificationModel(
                NotificationType.ERROR,
                'Error',
                ErrorMessages[actionType]
              )
            )
          );
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const { operationName: name, query } = request.body;
        const generatedError = {
          url: request.url,
          query,
          name,
          message: JSON.stringify(error),
        };
        if (error instanceof HttpErrorResponse)
          this.loggerService.logException(generatedError);
        this.store.dispatch(
          new UshgNotification(
            new NotificationModel(
              NotificationType.ERROR,
              'Error',
              ErrorMessages.defaultErrorMessage
            )
          )
        );

        return EMPTY;
      })
    );
  }
}
