const listOfTimeZonesWithUtc = [
  {
    timezone: 'Central Standard Time',
    utc: 'America/Chicago',
    shortFormTimeZone: 'CST',
  },
  {
    timezone: 'Eastern Standard Time',
    utc: 'America/Toronto',
    shortFormTimeZone: 'EST',
  },
  {
    timezone: 'Pacific Standard Time',
    utc: 'America/Los_Angeles',
    shortFormTimeZone: 'PST',
  },
  {
    timezone: 'Mountain Standard Time',
    utc: 'America/Denver',
    shortFormTimeZone: 'MST',
  },
  {
    timezone: 'US Mountain Standard Time',
    utc: 'America/Denver',
    shortFormTimeZone: 'US MST',
  },
  {
    timezone: 'Alaskan Standard Time',
    utc: 'America/Nome',
    shortFormTimeZone: 'AST',
  },
  {
    timezone: 'Central Brazilian Standard Time',
    utc: 'America/Cuiaba',
    shortFormTimeZone: 'CBST',
  },
  {
    timezone: 'Central Standard Time (Mexico)',
    utc: 'America/Mexico_City',
    shortFormTimeZone: 'CST(MEXICO)',
  },
  {
    timezone: 'E South America Standard Time',
    utc: 'America/Nuuk',
    shortFormTimeZone: 'ESAST',
  },
  {
    timezone: 'Hawalah Standard Time',
    utc: 'Pacific/Honolulu',
    shortFormTimeZone: 'HST',
  },
  {
    timezone: 'Hawaii  Standard Time',
    utc: 'Pacific/Honolulu',
    shortFormTimeZone: 'HST',
  },
  {
    timezone: 'SA Western Standard Time',
    utc: 'America/Manaus',
    shortFormTimeZone: 'SA WST',
  },
  {
    timezone: 'US Western Standard Time',
    utc: 'America/Mazatlan',
    shortFormTimeZone: 'US WST',
  },
];

export default listOfTimeZonesWithUtc;
