<form [formGroup]="secureEmailForm">
    <div class="container mx-auto p-4 p-auto pt-0 pl-5">
        <div class="grid " [ngClass]="{'shadow-3 p-5':providerPreferences?.electronicSignature==='false'}">
            <div class="col-12">
                <h3 class="
            ml-3">
                    {{routerData.requestType === 'APS'?'Fax Details':'Secure Email Details'}}
                </h3>
            </div>
            <div class="col-12 ml-3 ">
                <div class="grid" [ngClass]="{'block':routerData.orderType === 'APS'}">
                    <div class="col-5 m-1" *ngFor="let formControl of secureEmailConfig">
                        <p class="label">{{formControl.label}}
                            <span class="required" *ngIf="checkIfRequired(formControl)">
                                *
                            </span>
                        </p>
                        <ushg-form [formControlComponent]="formControl" class="mt-2"></ushg-form>
                    </div>
                    <br>
                    <div class="col-4 ml-3 p-0 mt-3" >
                        <div *ngFor="let file of documents" >
                            <div class="grid" *ngIf="(file.documentType !== TemplateTitle.AUTHORIZATION_WITHOUT_E_SIGNATURE && file.isNewlyAdded) || (!routerData.isEditOrder && file.documentType !== TemplateTitle.AUTHORIZATION_WITHOUT_E_SIGNATURE) ">
                                <div class="col-1 flex align-items-center">
                                    <p-checkbox name="groupname" value="val1" [ngModel]="file.isChecked" [binary]="true"
                                        [ngModelOptions]="{standalone: true}" (onChange)="documentscheckBoxHandler($event,file)" class="mr-3">
                                    </p-checkbox>
                                </div>
                                <div class="col-11">
                                     <app-ushg-doc [document]="file"
                                     [canDisableDelete]="true" [canDisableDownload]="false"
                                     [canDisplayActionIcons]="true"></app-ushg-doc>
                                </div>
                            </div>
                        </div>
                        <p-accordionTab  *ngIf=" routerData.isEditOrder"  >
                            <ng-template pTemplate="header" >Show Previous Documents</ng-template>
                            <ng-template pTemplate="content">
                                <ng-container *ngFor="let file of documents">
                                    <div class="wrapper flex" *ngIf="!file.isNewlyAdded && file.documentType !== TemplateTitle.AUTHORIZATION_WITHOUT_E_SIGNATURE">
                                        <div class="col-1 flex align-items-center"  >
                                            <p-checkbox [name]="file.name"  [binary]="true" 
                                              [ngModel]="file.isChecked"
                                            [ngModelOptions]="{standalone: true}"
                                            (onChange)="documentscheckBoxHandler($event,file)"
                                           class="mr-3">
                                       </p-checkbox>
                                        </div>
                                        <div class="col-11">
                                            <app-ushg-doc [document]="file" [canDisableDelete]="true" [canDisableDownload]="false" [canDisplayActionIcons]="true">
                                            </app-ushg-doc>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </p-accordionTab>
                    </div>
                    <div class="col-8"></div>
                    <div class="grid w-100" *ngIf="routerData.orderType.requestType === 'APS'">
                        <div class="col-12">
                            <h3>
                                <p-checkbox name="groupname" value="val1" [(ngModel)]="acknowledged" [binary]="true"
                                    [ngModelOptions]="{standalone: true}" (onChange)="checkBoxHandler($event)"
                                    class="mr-3">
                                </p-checkbox>
                                <span *ngIf="!routerData.isEditOrder else resentConfirmation ">
                                    I acknowledge that I have sent the fax using the eFuze system.
                                </span>
                                <ng-template #resentConfirmation>
                                    I acknowledge that I re-sent updated documents using the eFuze system
                                </ng-template>
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
<div class="grid mt-4 shadow-3 p-5 pl-5" *ngIf="providerPreferences?.electronicSignature==='false'">
    
    <div class="col-12 text-center">
        <h3>Applicant : Secure Email Details</h3>
    </div>
    <div class="col-12">
        <div *ngFor="let file of documents">
            <div class="grid" *ngIf="file.documentType===TemplateTitle.AUTHORIZATION_WITHOUT_E_SIGNATURE" >
                <div class="col-3">
                     <app-ushg-doc [document]="file"
                     [canDisableDelete]="true" [canDisableDownload]="false"
                     [canDisplayActionIcons]="false"></app-ushg-doc>
                </div>
                <div class="col-9 mt-3 pt-3 text-right" >
                    <ushg-button [label]="'Send Email'" [disable]="hasEmailSentToApplicant || withoutAuthorizationEmail" (click)="sendEmailToApplicant(file)" ></ushg-button>
                  
                </div>
             
                    <div class="col-6">
                    <h3 class="mt-0">

                        <p-checkbox  value="true" [(ngModel)]="withoutAuthorizationEmail" [binary]="true"
                        [ngModelOptions]="{standalone: true}" 
                        class="mr-3" [disabled]="hasEmailSentToApplicant">
                    </p-checkbox>
                        I don't want to send Email
                    </h3>
                    </div>
            </div>
        </div>
        <div *ngIf='hasEmailSentToApplicant  ' >
            <div class="grid">
                <div class="col-12">
                    <ushg-message [severity]="'info'"
                    [text]="'Email has been sent to Recipients'">
                </ushg-message>
                </div>
            </div>
        </div>
    </div>
</div>
        <ushg-navigation-buttons (nextButtonClick)="sendEmail()" (backButtonClick)="backButtonEventHandler($event)"
            [isNextButtonDisabled]=" !secureEmailForm.valid && !acknowledged ||  (providerPreferences?.electronicSignature==='false'&& !withoutAuthorizationEmail ? hasEmailSentToApplicant===false :null) || !documentsCheckBoxValidation">
        </ushg-navigation-buttons>
    </div>
</form>