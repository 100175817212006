import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpInterceptorProviders } from './interceptors/interceptor-list';
import { AuthorizationGuard } from './guards/authorization.guard';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [],
  providers: [
    AuthorizationGuard,
    HttpInterceptorProviders
  ]
})

export class CoreModule {}
