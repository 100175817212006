import { AfterContentChecked, AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component } from '@angular/core';
import { Actions, ofActionSuccessful, Store } from '@ngxs/store';
import { QueryRef } from 'apollo-angular';
import { take, tap } from 'rxjs/operators';
import { Application, HeadersList } from 'src/app/aps/models/application-model';
import { ApplicationStatusQueryId } from 'src/app/aps/models/enums';
import { ApplicationFilterData } from 'src/app/aps/models/filter-data-model';
import { Permission } from 'src/app/aps/models/permission';
import { TableActions } from 'src/app/aps/models/ui-models';
import { NavigationService } from 'src/app/aps/services/aps-navigation-service';
import { UtilityService } from 'src/app/aps/services/utility-service';
import { ApplicationSearchInfo } from 'src/app/shared/application-filters/application-filters.component';
import { Navigation } from 'src/app/shared/services/navigation';
import { PageStateService } from 'src/app/shared/services/page-state.service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { FilterNewRequestQueueApplications, SelectedApplicationDetails, UnAssignedApplications } from 'src/app/store/dispatchers';
import { ApplicationRequestModalComponent } from '../application-request-modal/application-request-modal.component';
import { EditOrderComponent } from '../create-order-components/create-order-modal-components/edit-order/edit-order.component';
import { newRequestQueueHeaders } from './new-request-queue-config';
@Component({
  selector: 'app-new-request-queue',
  templateUrl: './new-request-queue.component.html',
  styleUrls: ['./new-request-queue.component.scss'],
  providers: [PageStateService],
})
export class NewRequestQueueComponent
  extends Navigation
  implements AfterViewInit, AfterContentChecked, AfterViewChecked {
  listOfApplications: Application[] = this.store.selectSnapshot((state) => state.data.unAssignedApplications);
  selectedRows: Application[] = [];
  appId: string;
  fromDate: string;
  toDate: string;
  applicationFilterData: Application;
  applicationSearchInfo: ApplicationSearchInfo;
  headersList: HeadersList[] = newRequestQueueHeaders;
  actionsList: TableActions[];
  QueryRef: QueryRef<Response>;
  display: boolean;
  controlNo: string;
  currentPage = 0;
  pageNumber = 1;
  readonly NEW_REQUEST_QUEUE = "newRequestQueue"
  resetSelectedRows: boolean;

  constructor(
    private store: Store,
    private navigation: NavigationService,
    private cd: ChangeDetectorRef,
    private pageStateService: PageStateService,
    private service: UshgDialogService,
    private Storeactions: Actions,
    private utilityService: UtilityService,
  ) {
    super(navigation);
    this.actionsList = [
      {
        label: 'View',
        button: true,
        type: 'primary',
        permission: Permission.CanAccessNewRequestQueue,
        onClick: async (data) => {
          this.viewActionHandler(data);
        },
      },
    ];
  }

  public setApplicationFilters(data) {
    if (!this.utilityService.isEmpty(data)) {
      this.applicationSearchInfo = new ApplicationFilterData(data, this)
        .filterData as ApplicationSearchInfo;
    } else {
      this.applicationSearchInfo = new ApplicationFilterData(this, this)
        .filterData as ApplicationSearchInfo;
    }
  }

  ngAfterViewInit() {
    this.pageStateService.getPageStateInformationFromUrlOrSessionStorage(this.NEW_REQUEST_QUEUE)
      .pipe(
        tap((res) => {
          this.applicationFilterData = res;
          this.setApplicationFilters(res);
          this.assignFormValues(this.applicationFilterData);
          this.filter(this.applicationSearchInfo);
        }),
        take(1)
      )
      .subscribe();
    this.store.select((state) => state.data.unAssignedApplications).subscribe((applications) => {
      this.cd.detectChanges();
      this.listOfApplications = applications;
    })
  }

  ngAfterViewChecked(){
    this.cd.detectChanges();
  }

  public ngAfterContentChecked(): void {
    this.cd.detectChanges();
  }

  show() {
    this.service.open(EditOrderComponent);
  }

  getSelectedRows(event) {
    this.selectedRows = event;
  }

  assignFormValues(filters) {
    for (const key in filters) {
      this[key] = filters[key];
    }
  }

  searchwithFilters(filters?) {
    if (filters) {
      filters.page = this.NEW_REQUEST_QUEUE;
      this.pageStateService.updateUrlWithPageStateInformation(this.NEW_REQUEST_QUEUE, filters);
      this.applicationSearchInfo = this.applicationFilters();
      this.applicationSearchInfo.filters = filters;
      this.listOfApplications = [];
      this.filter(this.applicationSearchInfo);
    }
  }

  resetFilter(filters?) {
    this.appId = '';
    this.controlNo = ''
    this.fromDate = '';
    this.toDate = '';
    this.pageStateService.updateUrlWithPageStateInformation(this.NEW_REQUEST_QUEUE, {});
    this.applicationSearchInfo = this.applicationFilters();
    this.filter(this.applicationSearchInfo);
  }


  applicationFilters() {
    return {
      filters: {
        appId: this.appId,
        toDate: this.toDate,
        fromDate: this.fromDate,
        controlNo: this.controlNo
      },
      paginate: {
        pageDirection: null,
        queryId: null,
        pageNumber: null,
        pageInfo: {
          startCursor: null,
          endCursor: null
        }
      },
    } as ApplicationSearchInfo;
  }

  applicationAssigned() {
    this.listOfApplications = this.listOfApplications.filter(((application) => {
      return this.selectedRows.some((selectedRow) => selectedRow.applicationId !== application.applicationId);
    }));
    this.selectedRows = [];
    this.resetSelectedRows = true;
    this.resetFilter();
  }

  async viewActionHandler(data) {
    data.hideActions = true;
    console.log(data)
    this.store.dispatch(new SelectedApplicationDetails(data.applicationTrackerId, data.convertedVersion));
    await this.Storeactions.pipe(ofActionSuccessful(SelectedApplicationDetails), take(1)).toPromise();
    const selectedApplicationDetails = this.store.selectSnapshot((state) => state.data.selectedApplicationDetails);
    this.service.open(ApplicationRequestModalComponent, {
      ...data, 
      ...selectedApplicationDetails,
    });
  }

  public filter(data: ApplicationSearchInfo): void {
    const { pageDirection, pageNumber, pageInfo } = data.paginate;
    data.paginate.pageDirection = pageDirection;
    data.paginate.pageNumber = pageNumber;
    data.paginate.pageInfo = pageInfo;
    data.page = this.NEW_REQUEST_QUEUE;
    this.filterApplications(data);
  }

  public filterApplications(data?) {
    this.applicationSearchInfo = data;
    data ? this.paginate(data.paginate.pageNumber, true) : null;
  }

  paginate(pageNumber: any, onLoad?: boolean) {
    this.pageNumber = pageNumber;
    const assignedApplications = this.store.selectSnapshot(
      (state) => state.data.unAssignedApplications
    );
    const paginate = this.applicationSearchInfo.paginate;
    let pageDirection;

    if (onLoad) {
      pageDirection = paginate.pageDirection;
    } else {
      pageDirection = pageNumber <= this.currentPage ? 'BEFORE' : 'AFTER';
    }

    paginate.pageDirection = pageDirection;
    paginate.pageNumber = pageNumber;
    paginate.queryId = ApplicationStatusQueryId.newRequestQueue;

    paginate.pageInfo.startCursor = (assignedApplications && assignedApplications.length > 0)
      ? assignedApplications[0].pageInfo.startCursor
      : paginate.pageInfo.startCursor;

    paginate.pageInfo.endCursor = (assignedApplications && assignedApplications.length > 0)
      ? assignedApplications[0].pageInfo.endCursor
      : paginate.pageInfo.endCursor;

    this.updatePaginateInfo(this.applicationSearchInfo, pageNumber);
    this.store.dispatch(
      new FilterNewRequestQueueApplications(this.applicationSearchInfo)
    );
    this.currentPage = pageNumber;
  }

  private updatePaginateInfo(
    filterData: ApplicationSearchInfo,
    pageNumber: number
  ) {
    if (!this.listOfApplications?.length) {
      return;
    }
    filterData.paginate.pageInfo = this.listOfApplications[0].pageInfo;
    filterData.paginate.pageNumber = pageNumber;
    this.pageStateService.updateUrlWithPageStateInformation(
      this.NEW_REQUEST_QUEUE,
      filterData
    );
  }
}
