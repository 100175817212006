<form [formGroup]="createForm" id="searchForm" class="searchForm" (submit)="saveAuthorizationForm()"
    *ngIf="!showSpinner">
    <div>
        <div class="grid">
            <div class="col-4 ">
                <div class="text-left">
                    <label for="doctorFirstName">{{CreateAuthorizationFormLabels.doctorfirstName}}</label>
                    <input id="float-input" type="text"
                        (keyup.enter)="createForm.invalid===false && saveAuthorizationForm()" pInputText
                        name="doctorFirstName" id="doctorFirstName" formControlName="doctorFirstName" autocomplete="off"
                        class="w-full mt-1" />
                </div>
            </div>
            <div class="col-4">
                <div class="text-left ">
                    <label for="doctorLastName">{{CreateAuthorizationFormLabels.doctorLastName}}</label>
                    <input id="float-input" type="text"
                        (keyup.enter)="createForm.invalid===false && saveAuthorizationForm()" pInputText
                        name="doctorLastName" id="doctorLastName" formControlName="doctorLastName" autocomplete="off"
                        class="w-full mt-1" />
                </div>
            </div>
            <div class="col-4">
                <div class="text-left ">
                    <label for="facilityName">{{CreateAuthorizationFormLabels.facilityName}}</label>
                    <input id="float-input" type="text"
                        (keyup.enter)="createForm.invalid===false && saveAuthorizationForm()" pInputText
                        name="facilityName" id="facilityName" formControlName="facilityName" autocomplete="off"
                        class="w-full mt-1" />
                </div>
            </div>
            <div class="col-4">
                <div class="text-left ">
                    <label for="state">{{CreateAuthorizationFormLabels.state}}<span class="text-red-400">*</span></label>
                    <div class="mt-1">
                        <p-dropdown id="state" formControlName="state" [options]="usStates" optionLabel="VALUE"
                            placeholder="Select a State" autocomplete="off" class="w-full"></p-dropdown>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="text-left ">
                    <label for="city">{{CreateAuthorizationFormLabels.city}}</label>
                    <input id="float-input" type="text"
                        (keyup.enter)="createForm.invalid===false && saveAuthorizationForm()" pInputText name="city"
                        id="city" formControlName="city" autocomplete="off" class="w-full mt-1" />
                </div>
            </div>
            <div class="col-4">
                <div class="text-left ">
                    <label for="zipCode">{{CreateAuthorizationFormLabels.zipCode}}</label>
                    <input id="float-input" type="text"
                        (keyup.enter)="createForm.invalid===false && saveAuthorizationForm()" pInputText name="zipCode"
                        id="zipCode" formControlName="zipCode" autocomplete="off" class="w-full mt-1" />
                </div>
            </div>
        </div>
        <div class="my-2">
            <p-fileUpload #file name="myfile[]" mode="basic" chooseLabel="Attach" accept=".pdf, .jpg, .jpeg, .txt, .png"
                [customUpload]="true" (onSelect)="uploadFile($event)" [disabled]="!!uploadedFiles.length">
            </p-fileUpload>
        </div>
        <div class="grid mt-2 mb-2 pdfs">
            <div class="col-12 p-0">
                <ul class="pt-2 mt-0 pl-0" *ngIf="uploadedFiles.length">
                    <li class="grid" *ngFor="let file of uploadedFiles; index as i">
                        <div class="col-6 p-0 mr-0 ml-1">
                            <app-ushg-doc [document]="file" [canDisplayActionIconsVertical]="true"
                                [canDisplayActionIcons]="false">
                            </app-ushg-doc>
                        </div>
                        <div class="icon_prop mt-4 ml-3 icon cursor-pointer flex justify-content-center align-items-center"
                            (click)="deleteFile(file, i)">
                            <i class="pi pi-trash text-red"></i>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    <div class="flex align-items-center justify-content-center fixed-footer p-dialog-footer col-12 p-0 pt-3 mt-1">
        <div class="mr-3 mb-3">
            <ushg-button [label]="'Cancel'" [type]="'secondaryoutline'"
                (click)="cancel()"></ushg-button>
        </div>
        <div class="mr-3 mb-3">
            <button pButton pRipple type="button" label="Save" class="min-width" styleClass='mr-2'
                [disabled]="!uploadedFiles.length || createForm.invalid" id="Save" (click)="saveAuthorizationForm()">
            </button>
        </div>
    </div>
</form>
<div class="progress-spinner" *ngIf="showSpinner">
    <p-progressSpinner></p-progressSpinner>
</div>