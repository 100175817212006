<div class="grid mb-4 justify-content-center" [formGroup]="followUpForm">
  <div class="col-5 pr-0">
    <p-table
      [value]="allNotes"
      styleClass="p-datatable-striped"
      responsiveLayout="scroll"
      [scrollable]="true"
      scrollHeight="500px"
    >
      <ng-template pTemplate="header">
        <tr class="table-header-blue f14">
          <th class="text-center text-900">
            FollowUp Log
          </th>
          <th class="text-center text-900">Notes</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-orgDetail>
        <tr
          [ngClass]="{
            'bg-green-100': orgDetail?.auditParameter === ANSWERED,
            'bg-yellow-50': orgDetail?.auditParameter === NO_ANSWER,
            'bg-orange-100': orgDetail?.auditParameter === ADD_NOTE,
            'bg-orange-200': orgDetail?.auditParameter === null
          }"
        >
          <td class="py-0">
            <div class="flex ml-3 align-items-center">
              <div class="pr-3 togglePin">
                <i class="fa fa-thumbtack f-16 text-secondary" style="color:#ccc !important" *ngIf="!orgDetail?.isPinned"
                  (click)="togglePin(orgDetail)"></i>
                <i class="fa fa-thumbtack f-16 text-primary" *ngIf="orgDetail?.isPinned"
                  (click)="togglePin(orgDetail)"></i>
              </div>
              <div class="pr-3">
                <img
                  src="assets\icons\Call-answered.svg"
                  alt="image"
                  *ngIf="orgDetail?.auditParameter === ANSWERED"
                />
                <img
                  src="assets\icons\No-response.svg"
                  alt="image"
                  *ngIf="orgDetail?.auditParameter === NO_ANSWER"
                />
                <img
                  src="assets\icons\note_Icon.svg"
                  class="noteIcon"
                  alt="image"
                  *ngIf="orgDetail?.auditParameter === ADD_NOTE"
                />
                <img
                src="assets\icons\note_Icon.svg"
                class="noteIcon"
                alt="image"
                *ngIf="orgDetail?.auditParameter === null"
              />
                <img
                  src="assets\icons\aps.svg"
                  class="noteIcon"
                  alt="image"
                  *ngIf="orgDetail?.auditParameter === TO_UWS"
                />
                <img
                  src="assets\icons\uws.svg"
                  class="noteIcon"
                  alt="image"
                  *ngIf="orgDetail?.auditParameter === TO_APS"
                />
              </div>
              <div>
                <div class="wrapper f14">
                  {{ orgDetail?.loggedBy }},
                  <p class="p-0 m-0 mt-1">
                    {{ orgDetail?.loggedDate | dateTime: timeZone: 'date'  }}
                    {{ orgDetail?.loggedDate | dateTime: timeZone: 'time'  }}
                  </p>
                </div>
              </div>
            </div>
          </td>
          <td class="py-0">
            <p class="font-semibold f14 text-left">
              <span *ngIf="orgDetail && orgDetail.index" class="font-bold">
                FollowUp {{orgDetail.index | number:'2.0'}} :
              </span> {{ orgDetail?.information }}
            </p>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <p-divider layout="vertical"></p-divider>

  <div class="col-6 ml-3">
    <div class="col-12 grid">
      <div class="col-6">
        <strong>Due Date:</strong>
        <div class="inline-flex ml-1 mb-1" ushgDate>
          {{ dueDate }}
        </div>
        <ng-container *ngIf="selectedApplicationOrderData.requestType === APS && selectedApplicationOrderData.isFollowup">
          <div>
            <strong> Reminder Date: </strong>
          </div>
          <div
            class="d-flex justify-content-between align-items-center"
            *ngIf="!showDateTime && !reminderDate"
          >
            <div>No Reminder Date to Show. Please add it.</div>
          </div>
          <button
            class="search-button"
            appPermission="CanAddReminderDate"
            (click)="showReminderDateCalendar()"
            *ngIf="!showDateTime && !reminderDate"
          >
            Add
          </button>
          <div class="d-flex justify-content-between" *ngIf="showDateTime">
            <div class="input-container mr-1 w-full">
              <p-calendar
                [showIcon]="true"
                inputId="icon"
                [showTime]="true"
                (onSelect)="dateChange($event)"
                formControlName="followUpDate"
                dateFormat="mm/dd/yy"
                [minDate]="currentDate"
                [disabled]="disableReminderDate"
                hourFormat="12"
              >
              </p-calendar>
            </div>
          </div>
          <div class="d-flex mt-2" *ngIf="showDateTime">
            <div *ngFor="let button of reminderDateButtons" >
              <ushg-button [label]="button.label" [type]="button.type" [class]="button.class" (click)="actionsEvent(button.onClick)"></ushg-button>
           </div>
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="selectedApplicationOrderData.requestType === APS">
        <div class="col-6">
          <div class="mb-2">
            <strong>Preferred Copy Service:</strong>
          </div>
          <ushg-drop-down [dropDown]="copyServiceTypes" [selectedValue]="copyServiceTypes.selectedValue"></ushg-drop-down>
            <textarea
            rows="2"
            cols="10"
            pInputTextarea
            placeholder="Add Comment"
            formControlName="copyServiceNotes"
            class="w-full mt-1"
          ></textarea>
            <div class="text-right">
              <button
                pButton
                class="mt-2"
                type="button"
                label="Save"
                (click)="saveCopyServiceType()"
              ></button>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="selectedApplicationOrderData.requestType === APS  ">

      <p-divider></p-divider>
      <h4 class="mt-3 text-bold mb-3">FollowUp Physician/Provider details</h4>
      <div class="grid -m-1">
        <div class="col-6 p-1 inline-flex">
          <h4 class="mb-1 mt-0">Name:</h4>
          <p class="m-0">
            <span class="mb-1 mt-0 ml-2">
              {{ providerDetails.providerName }}
            </span>
          </p>
        </div>
        <div class="col-6 p-1 inline-flex">
          <h4 class="mb-1 mt-0">Phone 01:</h4>
          <p class="m-0">
            <span class="text-bold ml-2">{{
              providerDetails.providerPhone
              }}</span>
          </p>
        </div>
        <div class="col-12 p-1 inline-flex">
          <h4 class="mb-1 mt-0">Phone Extension:</h4>
          <ng-container *ngIf="!iconPhoneExtensionEdit">
            <p class="m-0">
              <i class="fas fa-edit f-16 text-primary editPhoneExt" (click)="showPhoneExtensionEdit()"></i>
            </p>
            <p class="m-0">
              <span class="text-bold ml-2">{{
                providerDetails.phoneExtension
                }}</span>
            </p>
          </ng-container>
        </div>
        <ng-container *ngIf="iconPhoneExtensionEdit">
          <div class="col-12 p-1 inline-flex" >
            <input type="text" pInputText formControlName="callingInstructions" required maxlength="100" class="w-full" />
          </div>
          <div *ngIf="followUpForm.get('callingInstructions').hasError('invalidCharacters') && iconPhoneExtensionEdit ">
            <p class="text-error">Invalid characters. Allowed characters are A-Z, 0-9, ., ,, *, &, /, and #.</p>
          </div>
          <div class="col-12">
            <div class="text-right">
              <button pButton type="button" (click)="savePhoneExtension()" [disabled]="followUpForm.get('callingInstructions').invalid || followUpForm.get('callingInstructions').value === ''" label="Save"></button>&nbsp;
              <button pButton type="button" class="p-button-secondary" (click)="hidePhoneExtensionEdit()" label="Cancel"></button>
            </div>
          </div>
      </ng-container>
        <div class="col-6 p-1 inline-flex">
          <h4 class="mb-1 mt-0">Facility:</h4>
          <p class="m-0">
            <span class="mb-1 mt-0 ml-2">
              {{ providerDetails.facilityName }}
            </span>
          </p>
        </div>
        <div class="col-6 p-1 inline-flex">
          <h4 class="mb-1 mt-0">Fax No:</h4>
          <p class="m-0">
            <span class="text-bold ml-2">{{
              providerDetails.providerFax
              }}</span>
          </p>
        </div>
        <div class="col-12 p-1 inline-flex">
          <h4 class="mb-1 mt-0">Address:</h4>
          <p class="m-0">
            <span class="text-bold ml-2"
                >{{providerDetails.providerstreetAddress }}, {{ providerDetails.city }}, {{ providerDetails.state }} - {{ providerDetails.zipCode }}
            </span>
          </p>
        </div>
      </div>
      <p-divider></p-divider>
    </ng-container>
    <h4 class="mb-1">Choose the option from dropdown</h4>
    <div class="pl-0 col-6">
      <ushg-drop-down [dropDown]="noteTypesList"></ushg-drop-down>
    </div>
    <p class="mt-0 font-semibold">
      Add your notes here
      <span class="required">*</span>
    </p>
    <textarea
      rows="3"
      cols="50"
      pInputTextarea
      formControlName="notes"
      (blur)="notesToUpper()"
      class="w-full"
    ></textarea>

    <div class="text-right pr-0 mr-0 pt-2 ml-5">
      <button
        pButton
        type="button"
        label="Add Note"
        (click)="addNotes()"
        [disabled]="followUpForm.invalid"
      ></button>
    </div>
  </div>
</div>
<app-ushg-dialog-footer [buttonsList]="buttonsList"></app-ushg-dialog-footer>