<div class="grid mt-2 ml-1">
  <div class="col-12">
    <div class="grid">
      <div class="col-3 pr-3 mr-0 pt-2">
        <app-application-filters *ngIf="applicationFilterConfig?.defaultSetting"
          [appFilterConfig]="applicationFilterConfig" (paginationEvent)="paginate($event)" (filter)="filter($event)"
          (removeFilters)="removeFilters()"></app-application-filters>
        <div class="d-flex justify-content-between align-items-center f12" *ngIf="listOfApplications?.length">
          <div>Total : <b>{{listOfApplications[0]?.nodetotalCount}} </b></div>
          <div>
            <pagination-controls class="my-pagination" (pageChange)="paginate($event)"></pagination-controls>
          </div>
        </div>
        <div class="col-12 border-right pr-2 w-100 overflow-y-scroll min-height-80 surface-ground">
          <ng-container *ngIf="listOfApplications?.length; else noData">
            <ng-container *ngFor="
                let application of assignedApplications$
                  | async
                  | paginate
                    : {
                        itemsPerPage: 10,
                        currentPage: pageNumber,
                        totalItems: listOfApplications[0]?.nodetotalCount
                      };
                index as i
              ">
              <div class="custom-card cursor-pointer mb-1" [class.active]="selectedIndex === i"
                (click)="selectApplication(application, i)">
                <div class="flex justify-content-between mb-1">
                  <div class="primaryContactName">
                    {{ application.primaryContactName }}
                  </div>
                  <div class="d-flex align-items-center">
                    <span *ngIf="application.applicationTrackerType === 'AddOnApplication'"
                      class="custom_addon_img mt-1 mr-1">
                      <ushg-chip [value]="'AddOn'" [chipLabel]="'Add On'"></ushg-chip>
                    </span>
                    <span timeInQueue class="f12">
                      {{application.whenRoutedToTeam ? application.whenRoutedToTeam : "N/A"}}
                    </span>
                  </div>
                </div>
                <div class="flex justify-content-between">
                  <div class="timeInQueue">
                    <span class="type" *ngFor="
                        let type of application.sortedRequestType | keyvalue;
                        last as last
                      ">
                      {{ type.key }}({{ type.value }})
                      {{ last ? "" : ", " }}
                    </span>
                  </div>
                  <div
                    *ngIf="(selectedIndex === i) && (ApplicationStatus[application.applicationStatusId] === 'APS OrderQueue'); else unselected">
                    <img src="assets/status-pill-images/Status_Icons/Application_Status/APS_Order_Queue_Selected.svg"
                      alt="loading failed!" width="64px">
                  </div>
                  <ng-template #unselected>
                    <span class="custom_status_img">
                      <ushg-chip styleClass="p-mr-2 " value="{{
                      ApplicationStatus[application.applicationStatusId]
                    }}">
                        {{ ApplicationStatus[application.applicationStatusId] }}
                      </ushg-chip>
                    </span>
                  </ng-template>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noData>
            <p-message severity="info" text="No records found" styleClass="p-mr-2 w-100"></p-message>
          </ng-template>
        </div>
      </div>
      <div class="col-9 pr-0 pl-0">
        <ushg-selected-application-container [selectedApplication]="selectedApplication"
          [config]="componentConfiguration">
        </ushg-selected-application-container>
      </div>
    </div>
  </div>
</div>