import { ColumnType } from "src/app/aps/models/enums";

export const emailLogHeaders = [
    { label: 'User Name', field: 'loggedBy' },
    { label: 'Date & Time', field: 'loggedDate', type: ColumnType.date },
    { label: 'To', field: 'RecipientName' },
    { label: 'Email Address', field: 'RecipientEmail' },
    { label: 'CC Email', field: 'CCEmail' },
    { label: 'Company', field: 'CompanyName' },
    { label: 'Documents', field: 'Attachments', type: ColumnType.documents, permission: 'CanPerformActionsOnOrderModal'},
];

export const demographicDetails = [
    {
        label: 'Applicant Name',
        value: 'orderContactName',
        class: 'col-4',
    },
    {
        label: 'App ID',
        value: 'applicationId',
        class: 'col-2',
    },
    {
        label: 'Policy No',
        value: 'policyNumber',
        class: 'col-2',
    },
    {
        label: 'Order ID',
        value: 'orderTrackerId',
        class: 'col-2',
    },
    {
        label: 'DOB',
        value: 'birthDate',
        class: 'col-2',
        type: 'DOB'
    },
    {
        label: 'Address',
        value: 'applicantstreetAddress',
        class: 'col-4',
        type: 'address'
    },
    {
        label: 'Home Ph',
        value: 'applicantHomePhone',
        class: 'col-2',
        type: 'phone'
    },
    {
        label: 'Request Type',
        value: 'requestType',
        class: 'col-2',
    },
    {
        label: 'Status',
        value: 'orderStatusId',
        class: 'col-2',
        type: 'OrderStatusId',
    },
];