<div class="mb-4">
  <div class="grid mt-3 justify-content-center">
    <div
      class="col-5"
      *ngIf="
        currentOrderData.orderStatusId !== OrderStatus.Requested ||
        uploadedFiles.length">
      <div class="grid align-items-center" appPermission="CanAddOrEditDocument">
        <div class="col-3">
          <label class="font-semibold">Document Type</label>
        </div>
        <div class="col-7">
          <ushg-drop-down [dropDown]="documentTypesList"></ushg-drop-down>
        </div>
        <div
          class="col-2 text-right"
          appPermission="CanPerformActionsOnOrderModal"
        >
          <p-fileUpload
            #file
            name="myfile[]"
            mode="basic"
            chooseLabel="Attach"
            accept=".pdf, .jpg, .jpeg, .txt, .png"
            [customUpload]="true"
            (onSelect)="uploadFile($event)"
            (onProgress)="uploadFileProgress($event)"
            [disabled]="!documentType || currentOrderData.orderStatusId === orderStatusId.Requested "
          >
          </p-fileUpload>
        </div>
      </div>
      <div
        class="grid mt-1 align-items-center"
        *ngIf="documentType === 'PAYMENT_CHECK'"
      >
        <div class="col-3">
          <label class="font-semibold">Payment Check</label>
        </div>
        <div class="col-7">
          <ushg-drop-down
            [dropDown]="paymentChecksList"
            (select)="paymentCheckDropDownHandler($event)"
          >
          </ushg-drop-down>
        </div>
      </div>
      
      <div class="grid mt-1 pdfs">
        <div class="col-12 p-0">
          <ul class="pt-2 mt-0 pl-0" *ngIf="uploadedFiles.length">
            <li class="grid" *ngFor="let file of uploadedFiles; index as i">
              <span
                class="mt-5 col-1 w-min"
                appPermission="CanPerformActionsOnOrderModal"
              >
                <p-checkbox
                  type="checkbox"
                  value="file"
                  [binary]="true"
                  (onChange)="checkBoxEventHandler($event, i)"
                ></p-checkbox>
              </span>
              <div class="col-11 p-0 mr-0">
                <app-ushg-doc
                  [document]="file"
                  [deleteIndex]="i"
                  (deleteDocument)="deleteFile($event)"
                  [canDisableDelete]="
                    currentOrderData.orderStatusId === 5 ? true : false
                  "
                  [canDisableDownload]="false"
                  [canDisplayActionIconsVertical]="true"
                  [canDisplayActionIcons]="true"
                  [upload]="isUpload"
                >
                </app-ushg-doc>
              </div>
            </li>
          </ul>
          <div
            class="text-right"
            appPermission="CanPerformActionsOnOrderModal"
            *ngIf="uploadedFiles.length"
          >
            <button
              pButton
              pRipple
              type="button"
              [ngClass]="{
                'p-disabled':
                  !documentsCheckedForEmail
              }"
              (click)="sendEmail()"
              class="p-element p-button-sm primarylg p-button p-component"
            >
              Send Email
            </button>
          </div>
        </div>
      </div>
    </div>

    <p-divider
      layout="vertical"
      class="ml-4 mr-4"
      *ngIf="
        currentOrderData.orderStatusId !== OrderStatus.Requested ||
        uploadedFiles.length
      "
    ></p-divider>
    <div class="col-5" >
      <div class="grid" appPermission="CanChangeOrderStatus">
        <div class="col-4 mt-2">
          <label class="font-semibold">Change Status To</label>
        </div>
        <ng-container
          *ngIf="
            currentOrderData.orderDetails.orderStatusId ===
              OrderStatus.Cancelled;
            else elseBlock
          "
        >
          <button
            pButton
            pRipple
            type="button"
            class="p-element p-button-sm primarylg p-button p-component mt-2"
            (click)="reopenCurrentOrder()"
          >
            Reopen
          </button>
        </ng-container>
        <ng-template #elseBlock>
          <div class="col-8" appPermission="CanPerformActionsOnOrderModal">
            <ushg-drop-down
              [dropDown]="status"
              (select)="getOrderStatus($event)"
              [selectedValue]="statusSelectedValue"
              placeholder="Select Status"
            ></ushg-drop-down>
          </div>
        </ng-template>
      </div>
      <div class="pt-2">
        <p class="font-semibold mt-0">
          APS Notes
          <span
            class="required"
            *ngIf="this.statusSelectedValue === OrderStatus.Cancelled"
            >*</span
          >
        </p>
        <textarea
          rows="8"
          cols="60"
          class="w-100"
          pInputTextarea
          [(ngModel)]="notes"
          [formControl]="apsNotes"
          (blur)="notesToUpper()"
        ></textarea>
      </div>
    </div>
  </div>
</div>
<app-ushg-dialog-footer
  [mode]="modeType"
  (saveDetails)="onSave()"
  [buttonsList]="buttonsList"
>
</app-ushg-dialog-footer>
