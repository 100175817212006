import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UserProfile } from '../../models/user-profile';
import { Store } from '@ngxs/store';
import { UserProfileData } from 'src/app/store/dispatchers';
import { ApsService } from '../aps-service';
import { Application, ApsUserModel } from '../../models/application-model';
import { UsersWorkload } from './users-workload';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService {
  /**
   * user roles
   */
  userRoles: string[] = [];
  /**
   * user profile
   */
  private profile: UserProfile;
  apsUsers: ApsUserModel[];
  userProfile: BehaviorSubject<UserProfile>;
  Roles = new BehaviorSubject<string[]>(this.userRoles);

  constructor(private store: Store, private apsService: ApsService) {
    this.userProfile = new BehaviorSubject<UserProfile>(null);
  }

  /**
   * Clears user profile and roles
   */
  clearProfile() {
    this.profile = null;
    this.userRoles = [];
    this.userProfile.next(this.profile);
  }

  /**
   * @param newProfile set the new profile info
   * @description set the user profile
   */
  setUserProfile(newProfile: UserProfile) {
    this.profile = newProfile;
    this.profile.roles = this.userRoles;
    this.profile.timeZone = 'Central Standard Time';
    this.userProfile.next(this.profile);
  }

  /**
   * @description get the user profile
   */
  getUserProfile() {
    return this.profile;
  }

  /**
   * @param roles set the user roles
   * @description set the user roles
   */
  // setRoles(roles: string[]) {
  //   this.userRoles = roles;
  //   this.profile.roles = this.userRoles;
  //   this.Roles.next(this.userRoles);
  //   this.userProfile.next(this.profile);
  // }

  /**
   * @description get user roles
   * @returns user roles
   */
  getRoles() {
    return this.userRoles;
  }

  async loadRoles(account) {
    if (this.userRoles && this.userRoles.length > 0) {
      return this.userRoles;
    }
    this.userRoles = [];
    const groups: [] = account.idTokenClaims['groups'] as [];
    groups.map((g) => this.userRoles.push(g));
    this.Roles.next(this.userRoles);
    this.profile.roles = this.userRoles;
    this.userProfile.next(this.profile);
    this.store.dispatch(new UserProfileData(this.profile));
    return this.userRoles;
  }

  /**
   * Determines if current user has a the specified application role
   * @param roleName Name of the user role
   */
  isInRole(roleName: string): boolean {
    if (roleName !== null && roleName !== '') {
      roleName = roleName.trim();
      if (this.userRoles !== null && this.userRoles !== undefined) {
        return this.userRoles.findIndex((role) => role === roleName) !== -1;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  setDisplayName(assignedTo: string) {
    const replacedAssignedTo = assignedTo && assignedTo.replace(/\\/g, '').replace(/\\\\/g, '').toLowerCase();
    this.apsUsers = this.store.selectSnapshot((state) => state.data.apsUsers);
    const user = this.apsUsers.find((user) => {
      const domainName = UserProfile.getDomainUserName(user.userPrincipalName)
        .replace(/\\\\/g, '')
        .toLowerCase();
      return domainName === replacedAssignedTo;
    });
    return user ? user.displayName : assignedTo;
  }

  setDisplayNameToApplications(applications) {
    if (applications?.length) {
      return applications.map((application: Application) => {
        application.displayName = this.setDisplayName(application.assignedTo);
        return application;
      });
    }
  }

  public assignUsersWithWorkload(usersWorkload: UsersWorkload[]) {
    const NEW_ID = 310;
    const PENDING_ID = 320;
    const users = {};
    usersWorkload.forEach((user: any) => {
      if (!user.assignedTo) {
        return;
      }
      const name = user.assignedTo.replace(/\\/g, '');
      if (!users[name]) {
        users[name] = {
          new: 0,
          pending: 0,
        };
      }
      if (user.applicationStatusId === NEW_ID) {
        users[name]['new'] = user.count;
      } else if (user.applicationStatusId === PENDING_ID) {
        users[name]['pending'] = user.count;
      }
    });
    return users;
  }
}
