<div class="container">
    <div class="grid">
        <div class="col-12 text-center">
            <img class="email-send" src="assets/svg-files/Email_Sent.svg" style="width:20%" alt="email sent">
        </div>
        <div class="col-12 text-center">
            <h3>{{title}}</h3>
        </div>
    </div>
</div>
<app-ushg-dialog-footer [mode]="view" [buttonsList]='buttonsList'></app-ushg-dialog-footer>
