
<ng-container *ngIf="mode!=='readOnly'&&!this.orderData.isEditOrder;else readOnly">
    <p-dropdown [options]="policyInformation"  
    class="w-full" optionLabel="label" optionValue='value' 
    (onChange)="onDropdownChange($event)" >
</p-dropdown>
</ng-container>
<ng-template #readOnly>
    <p>{{orderData.orderApplicant.policyNumber}}</p>
</ng-template>
