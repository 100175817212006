<!-- <span *ngFor="let action of actions;let i= index " [class]="action.display">
  <i [class]="action.icon" class="p-text-bold bold ml-2 p-2" (click)="actionsEvent(action.onClick)" *ngIf="action.icon"
    [ngClass]="{'p-disabled':action?.disable}"></i>
  <ushg-button type="button" [class]="action.class" [label]="action.label" *ngIf="(action.button)"
    (click)="actionsEvent(action.onClick)" [ngClass]="{'p-disabled':action?.disable}" [type]="action.type"
    [appPermission]="action.permission">
  </ushg-button>
</span> -->

<div class="p-1 d-flex justify-content-center">
  <button
    pButton
    pRipple
    label="Actions"
    class="p-button-primary p-button-sm"
    icon="pi pi-angle-down"
    style="font-size: 12px"
    iconPos="right"
    (click)="menu.toggle($event)"
    [disabled]="disabled"
  ></button>

  <p-menu
    #menu
    [popup]="true"
    [model]="filterActions"
    overlay="true"
    appendTo="body"
  >
  </p-menu>
</div>
