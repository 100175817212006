import { Component, Input } from '@angular/core';
import { OrderStatus } from 'src/app/aps/models/enums';

@Component({
  selector: 'ushg-ushg-order-details',
  templateUrl: './ushg-order-details.component.html',
  styleUrls: ['./ushg-order-details.component.scss']
})
export class UshgOrderDetailsComponent  {
  @Input() details;
  constructor() { }

  get OrderStatus():typeof OrderStatus{
    return OrderStatus
  }

}
