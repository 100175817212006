import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';

@Component({
  selector: 'app-email-sent-modal',
  templateUrl: './email-sent-modal.component.html',
  styleUrls: ['./email-sent-modal.component.scss'],
})
export class EmailSentModalComponent {
  config = {
    width: '30%',
    closable: true,
    showHeader: false,
  };
  title = 'Email Sent Successfully';
  buttonsList = [
    {
      label: 'Ok',
      type: 'custom',
      onClick: () => {
        const providerPreferences = this.store.selectSnapshot((state) => state.data.providerPreferences);
        const currentOrderData = this.store.selectSnapshot((state) => state.data.currentOrderData);
        this.service.cancel();
        // HACK: 
        // TODO:
        providerPreferences?.electronicSignature === 'false' || currentOrderData.stopRedirection ? '' : this.apsService.redirectToBase();
      }
    }
  ];
  public view = 'view';

  constructor(private service?: UshgDialogService, private apsService?: ApsService, private store?: Store) { }

}
