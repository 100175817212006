/* eslint-disable */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropDownModel } from '../drop-down/drop-down.component';

@Component({
  selector: 'ushg-multi-select-dropdown',
  templateUrl: './ushg-multi-select-dropdown.component.html',
  styleUrls: ['./ushg-multi-select-dropdown.component.scss']
})
export class UshgMultiSelectDropdownComponent {
  constructor() { };

  @Input() selectedValue: string[];
  @Input() dropDown: DropDownModel;
  @Output() select: EventEmitter<string> = new EventEmitter(); 

  public actionsEvent = (callback: Function, ev) => {
    if(callback){
      callback(ev);
    }
  }
}
