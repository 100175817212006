import { Injectable, OnDestroy } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject } from 'rxjs';
import { IDialogAware } from 'src/app/aps/models/IDialogAware';

@Injectable({
  providedIn: 'root',
})
export class UshgDialogService implements OnDestroy {
  saveDetails = new BehaviorSubject<any>(null);
  refs: DynamicDialogRef[] = [];
  data: any;
  constructor(private dialogService: DialogService) { }

  open(componentClass, data?, headerTitle = null) {
    const component: IDialogAware = new componentClass();
    let header = component?.header || component?.config?.header || headerTitle;
    const ref = this.dialogService.open(componentClass, {
      header,
      ...component.config,
      data,
    });
    this.refs.push(ref);
    return ref;
  }

  setData(data: any) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  cancel() {
    this.dialogService.dialogComponentRefMap.forEach((val) => val.destroy());
    this.refs.forEach((ref) => ref.close());
    this.refs = [];
  }

  close(condition?) {
    if (this.refs.length > 0) {
      const ref = this.refs.pop();
      if (ref) {
        ref.close(condition);
      }
    }
  }

  ngOnDestroy() {
    this.cancel();
  }
}
