<div class="flex w-100 mb-3">
  <div
    class="pdf_document col-10"
    [ngClass]="{
      'border-solid border-blue-500 bg-white':
        !isDocumentEmpty(SelectedDocument) &&
        document.label === SelectedDocument.label,
      'col-12': !(canDisplayActionIcons || canDisplaySpecialRequestActions)
    }"
  >
    <div class="d-flex justify-content-end text-xs">
      {{
        document?.updatedDate || document?.createdDate
          | dateTime : timeZone : "date-time"
      }}
    </div>
    <div class="flex pb-3 pl-3">
      <div class="inline-grid">
        <container-element [ngSwitch]="fileType(document)">
          <div *ngSwitchCase="'jpg'"><img src="../../../assets/icons/IMG_Icon.svg" alt="image"  class="img-icon"/></div>
          <div *ngSwitchCase="'jpeg'"><img src="../../../assets/icons/IMG_Icon.svg" alt="image" class="img-icon"/></div>
          <div *ngSwitchCase="'png'"><img src="../../../assets/icons/IMG_Icon.svg" alt="image"  class="img-icon"/></div>
          <div *ngSwitchCase="'txt'"><img src="../../../assets/icons/TXT_Icon.svg" alt="image" /></div>
          <div *ngSwitchDefault>
            <img src="../../../assets/icons/PDF_Icon.svg" alt="image" />

            <img
              src="../../../assets/icons/Newly_added_PDF.svg"
              alt="image"
              *ngIf="document?.isNewlyAdded"
              class="mt-2 ml-1"
            />
          </div>
       </container-element>
      </div>
      <div class="pl-3">
        <p class="font-bold pb-1 m-0 text-base">{{ document?.documentType }}</p>
        <span> </span>
        <p class="text-sm m-0">
          {{ document?.label || document?.name | titlecase }}
        </p>
      </div>
    </div>
    <!-- <pre>attributes{{document | json}}upload{{upload}}</pre> -->
    <div class="progress-bar" *ngIf="document?.attributes === null && upload">
      <div class="progress-fill"></div>
    </div>
  </div>
  <ng-container *ngIf="canDisplayActionIcons || (canDisplaySpecialRequestActions && document.documentType === SPECIAL_REQUEST_LETTER_TYPE)">
    <div class="block col-1 pr-0 py-0">
      <div
        class="icon_prop icon cursor-pointer flex justify-content-center align-items-center"
        (click)="downloadFile(document)"
        [ngClass]="{ 'pointer-events-none text-600': canDisableDownload }"
      >
        <i class="pi pi-arrow-down"></i>
      </div>
      <span appPermission="CanAddOrEditDocument">
        <div
          class="icon_prop mt-2 icon cursor-pointer flex justify-content-center align-items-center"
          (click)="deleteFile(document, deleteIndex)"
          [ngClass]="{ 'pointer-events-none': canDisableDelete }"
        >
          <i
            class="pi pi-trash text-red"
            [ngClass]="{ 'text-600': canDisableDelete }"
          ></i>
        </div>
      </span>
    </div>
  </ng-container>
</div>
