<form [formGroup]='requestCheckForm'>
    <div class="grid mb-5">
        <div class="col-11 p-0 mx-auto mt-2 ">
            <ushg-details-container [detailsToDisplay]="detailsToDisplay" [data]='orderDetails' class="mb-2">
            </ushg-details-container>
        </div>
        <div class="col-9 mx-auto mt-2 ">
            <div class="grid">
                <div *ngFor="let formControl of requestCheckFormConfig" [class]="formControl.class">
                    <p class="label">{{formControl.label}} <span  *ngIf="requiredClass(formControl)" class="required">*</span></p>
                    <ushg-form [formControlComponent]="formControl" class="mt-2" ></ushg-form>
                </div>
            </div>
        </div>
    </div>
<app-ushg-dialog-footer  (saveDetails)="onSave($event)" [buttonsList]="buttonsList"></app-ushg-dialog-footer>
</form>