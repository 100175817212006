<form [formGroup]="form">
  <span class="p-input-icon-left ">
    <i class="pi pi-search"></i>
    <input
      type="text"
      id="disabled-input"
      pInputText
      [placeholder]="placeholder"
      formControlName="input"
      class="p-inputtext w-full"
      (input)="searchInput()"
      (keyup.enter) = "onEnterPressed()"
      [attr.disabled]="disable? true : null"
    />
  </span>
</form>
