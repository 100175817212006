import { Validators } from "@angular/forms";
 const pattern = Validators.pattern(/^[a-zA-Z0-9,/@#%^&*+=[{}|`'';:<>?~"\-+() \]]*$/)
export const requestCheckFormConfig = (This)=> {
    return [
    {
        formControlName: "amount",
        label: "Amount",
        value: 'amount',
        formControlType: 'decimals',
        class: "col-4",
        validationMessage : "Please enter a valid Amount",
        placeholder:"Enter amount details",
        validators:[Validators.required, Validators.pattern(/^((?!0)\d{1,3}|0|\.\d{1,2})($|\.$|\.\d{1,2}$)/)],
    },   
    {
        formControlName: "taxId",
        label: "Tax ID",
        value: 'taxId',
        formControlType: 'taxId',
        validationMessage : "Please enter a valid Tax ID",
        class: "col-4",
        placeholder:"Enter Tax ID"
    },
    {
        formControlName: "checkRequestDate",
        label: "Check Request Date",
        value: 'checkRequestDate',
        formControlType: 'datepicker',
        class: "col-4",
        validationMessage : "Please select a valid Date",
        minimumDate: new Date(),
        showTime: true,
        hourFormat:12,
        validators:[Validators.required, pattern]
    },
    {
        formControlName: "checkName",
        label: "Check Name",
        value: 'checkName',
        formControlType: 'text',
        validationMessage: "The maximum number of characters permitted is restricted to 30",
        class: "col-12",
        validators:[Validators.required, Validators.maxLength(30) ,pattern]
    },
    {
        formControlName: "address",
        label: "Address",
        value: 'address',
        formControlType: 'text',
        class: "col-12",
        validationMessage : "The maximum number of characters permitted is restricted to 30",
        validators:[Validators.required, Validators.maxLength(30), pattern],
        onblur:()=>{
            This.requestCheckForm.patchValue({address:This.requestCheckForm.value.address.toUpperCase()});
        }
    },
    {
        formControlName: "city",
        label: "City",
        value: 'city',
        formControlType: 'text',
        validationMessage : "Please enter a valid City name",
        class: "col-4",
        validators:[Validators.required, pattern]
    },
    {
        formControlName: "state",
        label: "State",
        value: 'state',
        formControlType: 'text',
        validationMessage : "Please enter a valid State name",
        class: "col-4",
        validators:[Validators.required, pattern]
    },
    {
        formControlName: "zip",
        label: "Zip",
        value: 'zip',
        validationMessage : "Please enter a valid Zip code",
        formControlType: 'text',
        class: "col-4",
        minLength:'5',
        maxLength:10,
        validators:[Validators.required,
            Validators.pattern('^([0-9]{5,6}(-[0-9]{4})?)?$')]
    },
    {
        formControlName: "description",
        label: "Description",
        value: 'description',
        validationMessage : "The maximum number of characters permitted is restricted to 100",
        formControlType: 'textarea',
        rows:6,
        columns:105,
        class: "col-12 w-full",
        validators:[Validators.required,Validators.maxLength(100), pattern],
            onblur: () => {
                This.requestCheckForm.patchValue({ description: This.requestCheckForm.value.description.toUpperCase() });
            }
        },
]
}