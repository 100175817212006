<div class="grid mt-2 ml-1">
  <div class="col-12">
    <div class="grid">
      <div class="col-3 pr-3 mr-0 pt-2">
        <app-application-filters *ngIf="appFilterConfig?.defaultSetting" [appFilterConfig]="appFilterConfig"
          (paginationEvent)="paginate($event)" (filter)="filter($event)" (removeFilters)="removeFilters()">
        </app-application-filters>
        <div class="d-flex justify-content-between align-items-center f12 ml-2" *ngIf="listOfApplications?.length">
          <div>
            Total : <b>{{ listOfApplications[0]?.nodetotalCount }} </b>
            <span *ngIf="orderBy" class="ml-2">
              <label for="">Sorted by : </label>
              <span [innerHTML]='sortedOrderByFilter'></span> </span>
          </div>
          <div> 
            <span>Page No: {{this.pageNumber}}</span>
          </div>
        </div>
        <div class="col-12 border-right pr-2 w-100 overflow-y-scroll min-height-80 surface-ground">
          <ng-container *ngIf="listOfApplications?.length; else noData">
            <ng-container *ngFor="
                let application of assignedApplications$
                  | async
                  | paginate
                    : {
                        itemsPerPage: 10,
                        currentPage: pageNumber,
                        totalItems: listOfApplications[0]?.nodetotalCount
                      };
                index as i
              ">
              <div class="custom-card cursor-pointer mb-1" [class.active]="selectedIndex === i"
                (click)="selectApplication(application, i)">
                <div class="d-flex justify-content-between">
                  <div class="primaryContactName">
                    {{ application.primaryContactName }}
                  </div>
                  <div class="timeInQueue d-flex align-items-center">
                    <span *ngIf="
                        application.applicationTrackerType ===
                        'AddOnApplication'
                      " class="mr-1">
                      <ushg-chip [value]="'AddOn'" [chipLabel]="'Add On'"></ushg-chip>
                    </span>
                    <span timeInQueue>
                      {{
                      application.whenRoutedToTeam
                      ? application.whenRoutedToTeam
                      : "N/A"
                      }}
                    </span>
                  </div>
                </div>
                <div class="d-flex justify-content-between">
                  <div>
                    <span class="type" *ngFor="let type of application.orderTrackers | keyvalue">
                      <span appChangeBackgroundColor [backGroundColor]="
                          getStatusColor(type.value.processStatus)
                        " class="circle"></span>{{ type.value.requestType }}
                    </span>
                  </div>
                  <ushg-chip styleClass="p-mr-2" [value]="ApplicationStatus[application.applicationStatusId]">
                  </ushg-chip>
                  <div class="mt-2 d-flex align-items-center" *ngIf="application.reminderDate">
                    <img [src]="
                        selectedIndex === i
                          ? './assets/icons/bell_white.svg'
                          : './assets/icons/bell_black.svg'
                      " alt="" class="mr-2" width="12px" />
                    <span class="type">{{ application?.reminderDate }}</span>
                  </div>
                  <div class="flex justify-content-end pt-2" *ngIf="getLockStatus(application) === 'lockedByOther'">
                    <i class="fa-solid fa-lock" style="color: #d61111;" ></i>
                  </div>
                  <div class="flex justify-content-end pt-2" *ngIf="getLockStatus(application) === 'lockedByLoggedInUser'">
                    <i class="fa-solid fa-unlock" style="color: #4fef4f;"></i>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #noData>
            <p-message severity="info" text="No records found" styleClass="p-mr-2 w-100"></p-message>
          </ng-template>
        </div>
        <p-paginator *ngIf="listOfApplications && listOfApplications.length > 0" (onPageChange)="paginate($event)"  [first]="first" [rows]="10" [totalRecords]="listOfApplications[0]?.nodetotalCount" ></p-paginator>
      </div>
      <div class="col-9 pr-0 pl-0">
        <ushg-selected-application-container [selectedApplication]="selectedApplication"
          [config]="componentConfiguration" [lockStatus]="getLockStatus()" [disableActions]="disableActions && canDisplayFollowupLockFeature">
        </ushg-selected-application-container>
      </div>
    </div>
  </div>
</div>