import { Component, OnInit } from '@angular/core';
import { IdleTimerService } from '../services/idle-timer.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Idle } from '@ng-idle/core';
import { SecondsToMinutesFilterPipe } from '../pipes/seconds-to-minutes-filter.pipe';
import { UshgDialogService } from '../services/ushg-dialog-service';

@Component({
  selector: 'app-user-idle-modal',
  templateUrl: './user-idle-modal.component.html',
  styleUrls: ['./user-idle-modal.component.scss'],
})
export class UserIdleModalComponent implements OnInit {
  countdown: string;
  idleState: string;
  idleInstance: Idle;
  config = {
    width: "700px",
    showHeader: false
  };
  
  constructor(
    private idleTimerService: IdleTimerService,
    private secondsToMinutesFilter: SecondsToMinutesFilterPipe
  ){}

  ngOnInit(): void {
    this.idleInstance = this.idleTimerService.getIdleInstance();

    this.idleInstance.onTimeoutWarning.subscribe((countdown) => {
      this.countdown = this.secondsToMinutesFilter.transform(countdown);
    });
  }
  reset() {
    this.idleTimerService.reset();
  }
}
