import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Application } from 'src/app/aps/models/application-model';
import { ApsService } from 'src/app/aps/services/aps-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { GetUnderWriters } from 'src/app/store/dispatchers';

@Component({
  selector: 'app-route-to',
  templateUrl: './route-to.component.html',
  styleUrls: ['./route-to.component.scss']
})
export class RouteToComponent implements OnInit {
  applicationDetails: Application
  selectedValue = 'currentUnderWriter'
  selectedUnderWriter
  listOfUnderWriters = [];
  constructor(
    private dialogService: DynamicDialogConfig,
    private dialog: UshgDialogService,
    private apsService: ApsService,
    private store: Store,
  ) { }
  config = {
    width: '35%',
    closable: false
  }
  buttonsList = [

    {
      label: 'Cancel',
      type: 'secondaryoutline',

      onClick: () => {
        this.dialog.cancel();

      }
    },
    {
      label: 'Route',
      type: 'primarylg',
      onClick: () => {
        this.routeToEventHandler()
        this.dialog.cancel();
      }
    }
  ];


  ngOnInit(): void {
    this.applicationDetails = this.dialogService.data;
    this.applicationDetails.createdBy = this.store.selectSnapshot((state) => state.data.currentOrderData)?.currentUnderWriter;
    let users = this.store.selectSnapshot((state) => state.data.underWriters)
    this.listOfUnderWriters = (users !== undefined && users?.length > 0) ?
      this.apsService.sortListByKey(users?.map(user => {
        user['label'] = user?.displayName;
        user['value'] = user?.domainUserName
        return user
      }), "displayName") : [];
  }

  routeToEventHandler() {
    this.apsService.RouteTo(this.selectedValue, this.selectedUnderWriter)
  }

  get checkPendingOrder() {
    return this.applicationDetails?.orderDetails && this.applicationDetails?.orderDetails.some((order) => order.orderStatusId <= 1)
  }

}
