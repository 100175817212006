export enum ButtonConfig {
    primary = 'p-button-primary',
    outline = "p-button-outlined",
    outlineSm = "p-button-outlined p-button-sm",
    outlinelg = "p-button-outlined w-full",
    primarylg = "p-button-primary  w-full",
    info = "p-button-info",
    warning = "p-button-warning",
    danger = "p-button-danger",
    primary_m2 = "p-button-primary mr-2",
    secondaryoutline="p-button-outlined p-button-secondary",
    secondaryoutlineSm="p-button-outlined p-button-secondary p-button-sm",
    primarySm="p-button-primary p-button-sm"
}