import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { ApplicationStatus } from 'src/app/aps/models/enums';

@Directive({
  selector: '[applicationStatus]',
})
export class ApplicationStatusDirective implements AfterViewInit {
  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.elRef.nativeElement.innerHTML =
      ApplicationStatus[+this.elRef.nativeElement.innerHTML];
  }
}
