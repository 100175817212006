import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { MenuItem } from 'primeng/api';
import { ClearQuery } from 'src/app/store/dispatchers';

@Component({
  selector: 'ushg-nav-header',
  templateUrl: './nav-header.component.html',
  styleUrls: ['./nav-header.component.scss'],
})
export class NavHeaderComponent {
  @Input() navigationHeaderList: MenuItem[] = [];
  constructor(private store:Store) {}

  getActiveRoute(): MenuItem {
    const currentUrl = window.location.pathname;
    return this.navigationHeaderList.find((route) => {
      return currentUrl.includes(route.routerLink[0]);
    });
  }

  menuSelectionChanged(){
    console.log('cominger gerer')
    this.store.dispatch(new ClearQuery())
  }
}
