import { Application, ProfileModel } from "./application-model";

export class LockOrReleaseApplication {
    readonly LOCKED_USER_EVENT_KEY = 'USHG.NewBusiness.ApplicationEvents.Locked';
    applicationPropertyBags: Array<{ key: string; value: any }>;
    profile: ProfileModel;
    appProps: any;

    constructor(selectedApplication: Application, profile?: ProfileModel, appProps?: any) {
        this.applicationPropertyBags = selectedApplication && selectedApplication.applicationPropertyBags;
        this.profile = profile;
        this.appProps = appProps;
    }

    get lockedByUser(){ 
		  return this.applicationPropertyBags?.find(a => a.key === this.LOCKED_USER_EVENT_KEY)?.value
    }

    public processLockStatus(): LockStatus {
      const isLocked = this.applicationPropertyBags && this.applicationPropertyBags.length > 0;
      if (!isLocked) {
        return LockStatus.UnLocked;
      }
      if (this.lockedByUser === this.profile.name) {
        return LockStatus.LockedByLoggedInUser;
      } else {
        return LockStatus.LockedByOther;
      }
    }

    get disableActions() {
      return ((this.appProps && this.appProps.data.userName !== this.profile.name) || (this.applicationPropertyBags && this.applicationPropertyBags[0]?.value !== this.profile.name));
    }
    
  }

export enum LockStatus
{
  UnLocked = 'unlcoked',
	LockedByLoggedInUser = 'lockedByLoggedInUser',
	LockedByOther = 'lockedByOther'
}
  