<div class="container  mx-auto p-3 pl-5">
    <form [formGroup]="requestLettersForm">
        <div class="grid">
            <div class="col-10 p-0 mx-auto">
                <div class="grid">
                    <div class="col-12 p-3">
                        <ushg-details-container [detailsToDisplay]="detailsToDisplay" [data]='requestLettersDetails'>
                        </ushg-details-container>
                    </div>
                </div>
                <div formArrayName="tests" class="testContainer">
                    <ng-container *ngFor="let control of labTests.controls; let i = index">
                            <div [class]="control.value.formControlWidth" [formGroupName]="i">
                                <label class="d-block cursor-pointer" placement="top">
                                    <input type="checkbox" name="ailment" formControlName="status" (click)="enableTestNotes(control)" *ngIf="requestLettersDetails.requestType !== 'LAB_AE_HTWTBP'"/>
                                    <span class="pl-2 pb-2">{{ control.value.name }} <span *ngIf="control.value.status" class="required">*</span></span>
                                </label>

                                <textarea class="col-12 mt-2 form-control p-inputtextarea p-inputtext p-component p-element" [rows]="control.value.rows" [cols]="control.value.columns"
                                    formControlName="notes" (keyup)="getNotesChange(control, i)"></textarea>
                            </div>
                            <hr class="mt-3 mb-5 mb-0 ml-2 mr-2" *ngIf="i === 0 && requestLettersDetails.requestType !== 'LAB_AE_HTWTBP'">
                    </ng-container>
                </div>
                <div class="col-12">
                    <label>Lab Letter Notes</label>
                    <textarea class="col-12 mt-2 form-control p-inputtextarea p-inputtext p-component p-element"
                        rows="5" formControlName="labNotes"></textarea>
                </div>
                <div class="col-12">
                    <label>Agent Portal Notes <span class="required">*</span> </label>
                    <textarea class="col-12 mt-2 form-control p-inputtextarea p-inputtext p-component p-element"
                        rows="5" formControlName="agentNotes"></textarea>
                </div>
            </div>
        </div>
    </form>
    <ushg-navigation-buttons (nextButtonClick)="nextButtonEventHandler($event)"
        (backButtonClick)="backButtonEventHandler($event)"
        [isNextButtonDisabled]="!requestLettersForm.valid"
        ></ushg-navigation-buttons>

</div>

<p-dialog [(visible)]="canDisplayLabNotesModal" header="Confirm" [closable]="false" [breakpoints]="{'960px': '90vw'}" [style]="{width: '50vw'}">
    <div class="pl-2">
        <p>We found Lab letter Notes to be different than the default for your modified selection.</p>
        <p class="mt-2">
        Please select one of the following.
        </p>
    </div>
    <ng-template pTemplate="footer">
        <ushg-button [label]="'Keep Existing Notes'" (click)="keepExistingNotes()"></ushg-button>
        <ushg-button [label]="'Override to Default'" [class]="'ml-3'" (click)="overrideToDefault()"></ushg-button>
    </ng-template>
</p-dialog>
