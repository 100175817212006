import { AfterViewInit, Directive, ElementRef } from '@angular/core';
import { ApplicationStatus, OrderStatus } from 'src/app/aps/models/enums';

@Directive({
  selector: '[OrderStatus]',
})
export class OrderStatusDirective implements AfterViewInit {
  constructor(private elRef: ElementRef) {}

  ngAfterViewInit() {
    this.elRef.nativeElement.innerHTML =
      OrderStatus[+this.elRef.nativeElement.innerHTML];
  }
}
