import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { Application, ProfileModel } from 'src/app/aps/models/application-model';
import { UshgFormService } from 'src/app/shared/services/ushg-form-service';
import { UshgDialogService } from 'src/app/shared/services/ushg-dialog-service';
import { InsertRequestCheck, UshgNotification } from 'src/app/store/dispatchers';
import { requestCheckFormConfig } from './request-check-form-config';
import { ToastrService } from 'src/app/shared/services/toastr-service';
import { NotificationModel, NotificationType } from 'src/app/aps/models/ui-models';
import { UtilityService } from 'src/app/aps/services/utility-service';

@Component({
  selector: 'app-request-check-modal',
  templateUrl: './request-check-modal.component.html',
  styleUrls: ['./request-check-modal.component.scss']
})
export class RequestCheckModalComponent implements OnInit {
  requestCheckForm: UntypedFormGroup;
  modeType = "custom"
  detailsToDisplay = [
    {
      label: 'Applicant Name',
      value: 'orderContactName',
      class: 'col-4'
    },
    {
      label: "Request Type",
      value: 'requestType',
      class: "col-4"
    },
    {
      label: "Status",
      value: 'orderStatusId',
      class: "col-4 mt-3",
      type: "OrderStatusId"
    },
    {
      label: "Doctor/Physician",
      value: 'providerName',
      class: "col-4"
    },
    {
      label: "Assigned To",
      value: 'assignedTo',
      class: "col-4"
    },
    {
      label: "Order ID",
      value: 'orderTrackerId',
      class: "col-4"
    },
  ]
  orderDetails: Application
  config = {
    width: '54%',
    closable: false
  }
  buttonsList = [
    {
      label: 'Cancel',
      type: 'secondaryoutline',
      onClick: (data) => {
        this.dialogService.cancel();
      }
    },
    {
      label: 'Request',
      type: 'primary',
      disable: false,
      onClick: (data) => {
        this.onSave(data);

      }
    }
  ];
  requestCheckFormConfig = requestCheckFormConfig(this);
  profile: ProfileModel;

  constructor(
    private dialog: DynamicDialogConfig,
    private formService: UshgFormService,
    private dialogService: UshgDialogService,
    private utilityService: UtilityService,
    private store: Store,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.profile = this.store.selectSnapshot((state) => state.data.userProfile);
    this.orderDetails = this.dialog.data;
    this.initForm()
    this.requestCheckForm.valueChanges.subscribe((resp) => {
      this.checkFormValid();
    })
    this.checkFormValid();
  }

  initForm() {
    this.requestCheckForm = this.formService.createForm(this.requestCheckFormConfig, {});
    let provider = this.orderDetails.APS.providerOverride?this.orderDetails.APS.providerOverride: this.orderDetails.APS.provider;
    if (provider !== undefined) {
      this.requestCheckForm.patchValue({
        checkName: this.utilityService.transformFirstSpaceLastName(provider.providerName) + '; ' + provider.city + ' ' + provider.state,
        address: provider.streetAddress,
        city: provider.city,
        state: provider.state,
        zip: provider.zipCode,
        taxId: provider.taxId,
        description: this.orderDetails.requestType + ' - ' + this.orderDetails.orderContactName + ' - ' + this.orderDetails.policyNumber,
        checkRequestDate: new Date(), 
        amount: ''
      })
    }
  }

  checkFormValid() {
    this.requestCheckForm.valid ?
      delete this.buttonsList[1].disable :
      this.buttonsList[1].disable = true;
  }

  requiredClass(formControl) {
    if (formControl.hasOwnProperty('validators')) {
      return formControl.validators.some(control => {
        if (control.name !== "" && control.name === 'required') {
          return true;
        }
        return false
      })
    }
  }

  async onSave(ev) {
    if (this.requestCheckForm.valid) {
      this.requestCheckForm.value['ApplicationTrackerId'] = this.orderDetails.applicationTrackerId;
      this.requestCheckForm.value['clientNumber'] = this.orderDetails.clientNumber;
      this.requestCheckForm.value['orderTrackerId'] = this.orderDetails.orderTrackerId;
      this.requestCheckForm.value['createdBy'] = this.profile.domainUserName;
      this.requestCheckForm.value['createdDate'] = new Date().toISOString();
      this.requestCheckForm.value['policyNumber'] = this.orderDetails.policyNumber;
      this.requestCheckForm.value['providerName']= this.orderDetails.provider.providerName.replace(',','')
      this.requestCheckForm.value.amount = this.requestCheckForm.value.amount !== '' ? this.requestCheckForm.value.amount.toFixed(2) : '0.00';
      this.requestCheckForm.value.state = this.requestCheckForm.value.state.length > 2 ? this.requestCheckForm.value.state.substring(0, 2) : this.requestCheckForm.value.state;
      this.requestCheckForm.value.checkRequestDate = this.requestCheckForm.value.checkRequestDate !== '' ? this.requestCheckForm.value.checkRequestDate.toISOString() : null;
      await this.store.dispatch(new InsertRequestCheck(this.requestCheckForm.value));
      this.store.dispatch(new UshgNotification(new NotificationModel(NotificationType.SUCCESS, '', 'Check request created successfully')));
      this.requestCheckForm.reset();
      this.dialogService.cancel();
    }
  }

}
